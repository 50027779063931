<app-spinner *ngIf="isImporting || isLoadingShapeFile" [hasBackdrop]="true"></app-spinner>

<div class="main-wrapper">
  <app-side-drawer-header
    [showInfoHoverButton]="true"
    hotspotsShapeFileInfo
    title="{{ 'main.shapeFileImport.title' | translate }}"
    (action)="onCloseClick()"
    cancelIconName="back"
  >
    <app-side-drawer-info-hover buttonText="info"
      ><p>{{ 'main.hotspots.hotspotsShapeFileInfo' | translate }}</p></app-side-drawer-info-hover
    >
  </app-side-drawer-header>

  <app-side-drawer-body>
    <form [formGroup]="uploadForm">
      <div>
        <mat-form-field class="example-full-width">
          <mat-label>{{ 'main.shapeFileImport.selectProjection' | translate }}</mat-label>
          <input type="text" matInput formControlName="projection" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete" autoSelectActiveOption="" [displayWith]="displayFn">
            @for (option of filteredOptions | async; track option) {
              <mat-option [value]="option">{{ option.projection }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div *ngIf="!uploadEnabled" class="projectionError">{{ 'main.shapeFileImport.projectionNotSelected' | translate }}</div>
      <app-file-upload
        [disabled]="!uploadEnabled"
        (fileInputChange)="onFileInputChange($event)"
        [acceptFileExtensions]="[fileFormats.Zip]"
        labelText="{{ 'main.shapeFileImport.fileUpload.addFile' | translate }}"
        invalidFileTypesMessage="{{ 'main.shapeFileImport.fileUpload.badFileType' | translate }}"
        [allowMultipleFiles]="false"
      >
      </app-file-upload>
    </form>

    <form [formGroup]="formGroup" class="import-form">
      <div *ngIf="(selectedFarms$ | async)!.length > 1">
        <app-select
          formControlName="farm"
          placeholder="{{ 'main.shapeFileImport.farmSelector.selectFarm' | translate }}"
          (valueChange)="onFarmSelect($event)"
        >
          <app-select-option *ngFor="let farm of selectedFarms$ | async" [value]="farm">
            <div>{{ farm.name }}</div>
          </app-select-option>
        </app-select>
      </div>
      <div>
        <app-select formControlName="hotspotType" placeholder="{{ 'main.shapeFileImport.selectHotspotType' | translate }}">
          <app-select-option *ngFor="let type of hotspotTypes" [value]="type">
            <div>{{ type.name }}</div>
          </app-select-option>
        </app-select>
      </div>
      <!--   Until note extraction from shape file is implemented, this select should be disabled.   -->
      <!--      <div>-->
      <!--        <app-select formControlName="noteColumn" placeholder="{{'main.shapeFileImport.selectNoteColumn' | translate}}">-->
      <!--          <app-select-option *ngFor="let column of columns" [value]="column">-->
      <!--            <div>{{column.label}}</div>-->
      <!--          </app-select-option>-->
      <!--        </app-select>-->
      <!--      </div>-->

      <div>
        <app-date-picker [(date)]="registeredDate" placeholder="{{ 'common.date' | translate }}"></app-date-picker>
      </div>

      <div>
        <app-select [multiple]="true" formControlName="hotspotGroups" [placeholder]="'main.fieldmap.hotspotInfo.selectGroups' | translate">
          <app-select-option *ngFor="let g of hotspotGroupOptions" [value]="g">
            {{ g.name }}
          </app-select-option>
        </app-select>
      </div>
    </form>

    <app-square-button buttonType="Primary" [disabled]="formGroup.invalid" (clicked)="onImportClick()">
      <app-icon icon="cloud_upload"></app-icon> {{ 'main.shapeFileImport.import' | translate }}
    </app-square-button>

    <ng-container *ngIf="importErrorMessages.length">
      <h5>{{ 'main.shapeFileImport.messages' | translate }}/{{ 'main.shapeFileImport.errors' | translate }}:</h5>
      <ul>
        <li *ngFor="let message of importErrorMessages">{{ getShapeFileImportErrorMsg(message.item1) }}</li>
      </ul>
    </ng-container>
  </app-side-drawer-body>
</div>
