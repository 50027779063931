import { endpoints } from 'environments/endpoints';
import { WmsUrlRoots } from './wms-consts';

export enum WMSLayerType {
  SHADOW = 1,
  HEIGHT = 2,
  HEIGHT05 = 3,
  GEO_SOIL = 4,
}

export class wmsLayerUrl {
  constructor(
    private rootURL: string,
    private type: string,
    private layerName: string
  ) {}

  public get getURL() {
    return `${this.rootURL}${this.type}?service=WMS&request=GetCapabilities&layers=${this.layerName}`;
  }

  static readonly heightmapUrl = new wmsLayerUrl(endpoints.dataforsyningApi, 'dhm_DAF', 'dhm_kurve_traditionel');
  static readonly heightmapUrl05m = new wmsLayerUrl(endpoints.dataforsyningApi, 'dhm_DAF', 'dhm_kurve_0_5_m');
  static readonly shadowmapUrl = new wmsLayerUrl(endpoints.dataforsyningApi, 'wms/dhm_temp', 'quickDHM_skyggekort_terraen_overdrevet');
  static readonly SoilMapUrl = new wmsLayerUrl(WmsUrlRoots.fvm, 'Jordbunds_og_terraenforhold/wms', 'Jordbundskort_2024');
}
