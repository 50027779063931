import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory } from '@ngneat/elf-state-history';

export interface FilterCrop {
  id: string;
  fieldIds: number[];
  color: string;
  name: string;
  totalArea: number;
  succession: number;
  enabled: boolean;
}

@Injectable({ providedIn: 'root' })
export class CropFilterStore {
  constructor() {}

  public readonly store = createStore({ name: 'crop-filter' }, withEntities<FilterCrop>());

  public readonly history = entitiesStateHistory(this.store);
}
