<app-side-drawer-wrapper>
  <app-side-drawer-header
    [cancelIconName]="'back'"
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
    [showMinimizeButton]="true"
    [title]="'main.yieldPrognosis.title' | translate"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <app-spinner *ngIf="loadingState.isLoading$ | async" [hasBackdrop]="false" [message]="(loadingState.message$ | async)!"></app-spinner>
    <div *ngIf="!(loadingState.isLoading$ | async)" class="yield-prognosis-content">
      <div class="info-box">{{ sideDrawerBodyText }}</div>
    </div>
  </app-side-drawer-body>
</app-side-drawer-wrapper>

<app-ol-map-feature-select (event)="onFieldSelect($event)" [layers]="selectableLayers"></app-ol-map-feature-select>
