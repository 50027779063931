import { NgModule } from '@angular/core';
import { CornHarvestPrognosisModule } from '@app/map/features/prognosis/corn-harvest-prognosis/corn-harvest-prognosis.module';
import { NToolModule } from '@app/shared/n-tool/n-tool.module';
import { SharedModule } from '@app/shared/shared.module';
import { JournalSideComponent } from '../journal-side/journal-side.component';
import { DecisionSupportComponent } from './decision-support.component';
import { DecisionSupportService } from './decision-support.service';

@NgModule({
  imports: [SharedModule, CornHarvestPrognosisModule, NToolModule],
  declarations: [DecisionSupportComponent, JournalSideComponent],
  exports: [DecisionSupportComponent],
  providers: [DecisionSupportService],
})
export class DecisionSupportModule {}
