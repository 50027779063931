import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { DirtyGuard } from '@app/core/guards/dirty-guard.service';
import { FarmGuard } from '@app/core/guards/farm-guard.service';
import { AccessControlGuard, AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';
import { FieldAnalysisFeaturesComponent } from './features/field-analysis-features.component';
import { FieldAnalysisSideDrawerComponent } from './field-analysis-side-drawer/field-analysis-side-drawer.component';

export const sideDrawerOutlet = 'sideDrawer';

export const routes: AccessControlRoutes = [
  {
    path: '',
    canActivate: [AuthGuard, ConsentGuard, FarmGuard, AccessControlGuard],
    data: {
      module: 'field_analysis',
    },
    canDeactivate: [DirtyGuard],
    canLoad: [],
    children: [
      {
        path: '',
        component: FieldAnalysisFeaturesComponent,
      },
      {
        path: '',
        component: FieldAnalysisSideDrawerComponent,
        outlet: sideDrawerOutlet,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FieldAnalysisRoutingModule {}
