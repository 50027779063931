import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { WaterBalanceDTO } from '@app/core/repositories/water-balance/water-balance-dto.interface';
import { getChartLegendStyle } from '@app/helpers/chart-legend-style/chart-legend-style.fn';
import { WindowStateService } from '@app/state/services/window/window-state.service';
import { CategoryAxis, ChartComponent, PlotAreaHoverEvent, ValueAxis } from '@progress/kendo-angular-charts';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { isArray } from '../utils/utils';
import { WaterBalanceService } from './water-balance.service';

@Component({
  selector: 'app-water-balance',
  templateUrl: './water-balance.component.html',
  styleUrls: ['./water-balance.component.scss'],
})
export class WaterBalanceComponent implements OnInit, OnDestroy {
  @Input() public field?: Field;

  @ViewChild('chart', { static: true }) public chart?: ChartComponent;

  public isLoading = false;
  public errorMessage = '';
  public topPane = 'precipitationPane';
  public bottomPane = 'waterBalancePane';
  public topAxis = 'precipitationAxis';
  public bottomAxis = 'waterBalanceAxis';
  public visibleData?: WaterBalanceDTO;
  public rootCapacityArea: number[][] = [];
  public redArea: number[][] = [];
  public yellowArea: number[][] = [];
  public greenArea: number[][] = [];
  public sowingDateString?: string;
  public harvestDateString?: string;
  public manThatsALotOfRain = 35;

  public markersHidden = {
    visible: false,
  };

  public highlightsHidden = {
    visible: false,
  };

  public categoryAxis: CategoryAxis = {
    name: 'timeAxis',
    baseUnit: 'days',
    type: 'date',
    min: 0,
    max: 0,
    majorGridLines: {
      visible: false,
    },
    labels: {
      rotation: 45,
      position: 'end',
      margin: { bottom: 14 },
      dateFormats: {
        days: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
        weeks: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
        months: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
      },
    },
    pane: this.bottomPane,
  };

  public waterBalanceValueAxis: ValueAxis = {
    majorUnit: 5,
    max: 5,
    min: 0,
    title: {
      text: 'mm',
      margin: {
        right: 2,
      },
    },
  };
  public precipitationValueAxis: ValueAxis = {
    majorUnit: 5,
    max: 35,
    min: 0,
    title: {
      text: 'mm',
      margin: {
        right: 6,
      },
    },
  };

  private subscriptions = new Subscription();

  constructor(
    private waterBalanceService: WaterBalanceService,
    private languageService: LanguageService,
    private windowSelector: WindowStateService
  ) {
    const now = DateTime.now();
    this.categoryAxis.max = now.plus({ days: 9 });
    this.categoryAxis.min = now.set({ day: 3 }).set({ weekday: 1 });
  }

  public ngOnInit() {
    this.getWeatherData();
    this.subscriptions.add(
      this.windowSelector.screenSize$.subscribe((size) => {
        this.categoryAxis = {
          ...this.categoryAxis,
          maxDivisions: this.waterBalanceService.getNoOfDivisionsFromScreenSize(size),
        };
      })
    );
    this.subscriptions.add(
      this.chart?.plotAreaHover.subscribe((e: PlotAreaHoverEvent) => {
        this.triggerTooltip(e);
      })
    );
    this.subscriptions.add(
      this.chart?.plotAreaLeave.subscribe(() => {
        this.removeHilights();
      })
    );
    this.subscriptions.add(
      this.chart?.zoomEnd.pipe(filter((e) => e.originalEvent?.type === 'panend')).subscribe((e: any) => {
        const range = e.axisRanges.timeAxis ? e.axisRanges.timeAxis : e.axisRanges.precipitationCategoryAxis;
        this.categoryAxis.min = range.min;
        this.categoryAxis.max = range.max;
      })
    );
    this.subscriptions.add(
      this.chart?.zoomEnd.subscribe(() => {
        this.updateGraphLines();
      })
    );
    this.subscriptions.add(
      this.chart?.dragEnd.subscribe(() => {
        this.updateGraphLines();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public updateGraphLines() {
    if (!this.visibleData) {
      return;
    }
    const annotations = this.waterBalanceService.mapCropAnnotations(this.visibleData.cropAnnotations);
    const group = this.waterBalanceService.createGraphLines(this.chart, annotations);
    this.chart?.findPaneByName(this.bottomPane).visual.insert(/* z-index */ 10, group);
  }

  public getWeatherData() {
    this.isLoading = true;
    return this.waterBalanceService
      .getWaterBalanceData(this.field?.id /* Known as fieldYearId in api */)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((waterData) => this.onWaterBalanceDateRecieved(waterData));
  }

  private onWaterBalanceDateRecieved(waterData: WaterBalanceDTO) {
    this.visibleData = this.waterBalanceService.mapBalanceData(waterData);
    if (this.visibleData) {
      this.updateAxes(this.visibleData);
      this.updateCapacityAreas(this.visibleData);
    } else {
      this.errorMessage = this.languageService.getText('waterBalance.noDataError');
    }
  }

  private updateAxes(wbo: WaterBalanceDTO) {
    this.waterBalanceValueAxis = this.waterBalanceService.getValueAxis(wbo, this.waterBalanceValueAxis);
    this.categoryAxis = this.waterBalanceService.getMaxMinValuesFromData(wbo, this.categoryAxis);
  }

  private updateCapacityAreas(wbo: WaterBalanceDTO) {
    this.rootCapacityArea = this.waterBalanceService.getRootCapacityAreaValues(wbo);
    const res = this.waterBalanceService.getDroughtAreas(wbo);
    this.redArea = res.red!;
    this.yellowArea = res.yellow!;
    this.greenArea = res.green!;
  }

  public toggleInfo() {
    this.waterBalanceService.showInfoModal();
  }

  public triggerTooltip(e: PlotAreaHoverEvent) {
    const dateHovered = isArray(e.category) ? e.category[0] : e.category;
    // Only have sticky tooltips in bottom pane
    if (e.value < 0) {
      e.sender.showTooltip((point: any) => {
        return point && point.color === '#005521' && DateTime.fromISO(point.category) === DateTime.fromISO(dateHovered);
      });
    }
    e.sender.toggleHighlight(false, () => true);
    e.sender.toggleHighlight(true, (point) => {
      return (
        point &&
        point.dataItem &&
        !!(point.dataItem.precipitation || point.dataItem.irrigation || point.dataItem.waterDeficit) &&
        DateTime.fromISO(point.category as any) === DateTime.fromISO(dateHovered)
      );
    });
  }

  public removeHilights() {
    this.chart?.toggleHighlight(false, () => true);
  }

  public legendItemVisual = (args: any) => getChartLegendStyle(args);

  public barStyleFunction = (args: any) => {
    return args && args.value > this.manThatsALotOfRain ? '#e95d0f' : '#82CDF0';
  };
}
