<div class="my-location-btn-container">
  <button
    mat-mini-fab
    color="secondary"
    aria-label="zoom out on map"
    (click)="onClickPDF()"
    onclick="this.blur()"
    [disabled]="(disablePDFButton$ | async) === true"
    appGTagEventTracker
    eventName="pdfButton (click)"
    eventCategory="Other"
    matTooltip="{{ 'common.tooltips.create-pdf' | translate }}"
    [matTooltipShowDelay]="1000"
    [matTooltipPosition]="'after'"
  >
    <mat-icon class="material-symbols-outlined">picture_as_pdf</mat-icon>
  </button>
</div>
