<ng-container *ngIf="queryParams$ | async as queryParams">
  <div *ngIf="farmsSelected$ | async" class="route-container" [ngClass]="{ minimized: (menuMinimized$ | async) }">
    <div *ngFor="let parent of items$ | async" class="route" routerLinkActive="active">
      <app-accordion
        attr.data-testid="navigation-element-{{ parent.translationString }}"
        [isDisabled]="parent.disabled"
        [templateRef]="accBody"
        [titleTemplateRef]="accTitle"
        [elHeight]="'32px'"
        [isExpanded]="{ expanded: isLinkActive(parent.route) }"
        appGTagEventTracker
        eventName="{{ parent.translationString | lastSegment }} (click)"
        eventCategory="Navigation"
        class="nav-accordion"
        [ngClass]="{ minimized: (menuMinimized$ | async), 'has-children': parent.items.length }"
        *ngIf="parent.featureToggleKey | featureToggle | async"
      >
        >
      </app-accordion>
      <!-- New Flag Directive should be placed in the subsequent div. This ensures that new flags are positioned correctly.
        Do not show flag when minimized! -->
      <div class="new-flag-directive" [ngClass]="{ minimized: (menuMinimized$ | async) }"></div>
      <ng-template #accTitle>
        <a
          class="nav-btn"
          (click)="navigate(parent.route, queryParams, parent.disabled)"
          [appResponsiveClass]="{ mobile: 'lt-Medium' }"
          [ngClass]="{
            'no-children': !parent.items.length,
            'parent-selected': isLinkActive(parent.route),
            minimized: (menuMinimized$ | async),
            disabled: parent.disabled,
          }"
          id="e2e-{{ parent.translationString }}"
          matTooltip="{{
            parent.disabled && parent.disabledTranslationString
              ? (parent.disabledTranslationString | translate)
              : (parent.translationString | translate)
          }}"
          matTooltipPosition="after"
          matTooltipShowDelay="200"
          [matTooltipDisabled]="!(menuMinimized$ | async) && !parent.disabled"
          matTooltipClass="nav-tooltip"
        >
          <span class="nav-item-icon" *ngIf="parent.icon" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
            <app-icon [icon]="parent.icon"></app-icon>
          </span>
          <span
            class="nav-item-text"
            [appResponsiveClass]="{ mobile: 'lt-Medium' }"
            [ngClass]="{ hidden: (menuMinimized$ | async), disabled: parent.disabled }"
            >{{ parent.translationString | translate }}</span
          >
        </a>
      </ng-template>

      <ng-template #accBody>
        <div *ngFor="let item of parent.items">
          <a
            class="nav-btn inner-nav no-children"
            (click)="navigate(item.route, queryParams, item.disabled)"
            [ngClass]="{ minimized: (menuMinimized$ | async), selected: isLinkActive(item.route), disabled: item.disabled }"
            appGTagEventTracker
            eventName="{{ item.translationString | lastSegment }} (click)"
            eventCategory="Navigation"
            matTooltip="{{
              item.disabled && item.disabledTranslationString
                ? (item.disabledTranslationString | translate)
                : (item.translationString | translate)
            }}"
            matTooltipPosition="after"
            matTooltipShowDelay="200"
            [matTooltipDisabled]="!(menuMinimized$ | async) && !item.disabled"
            matTooltipClass="nav-tooltip"
          >
            <app-icon class="inner-nav-icon" [ngClass]="{ hidden: !(menuMinimized$ | async) }" [icon]="item.icon"></app-icon>
            <span class="inner-nav-text" [ngClass]="{ hidden: (menuMinimized$ | async) }">
              {{ item.translationString | translate }}
            </span>
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
