<div class="create-hotspot-main-wrapper">
  <app-side-drawer-header
    title="{{ 'navigation.hotspots' | translate }}"
    (action)="onCloseClick()"
    (onHide)="hideDrawer()"
    cancelIconName="minimize"
    [showInfoHoverButton]="true"
  >
    <app-side-drawer-info-hover buttonText="info">
      <p>{{ 'main.hotspots.hotspotInfo' | translate }}</p>
    </app-side-drawer-info-hover>
  </app-side-drawer-header>
  <app-side-drawer-body #createHotspotDrawer>
    <app-spinner *ngIf="isLoading$ | async" [hasBackdrop]="true" [message]="(loadingMessage$ | async)!"></app-spinner>

    <div class="create-hotspot-header-body">
      <p class="create-hotspot-description" *ngIf="!selectedHotspotType">
        {{ 'main.hotspots.chooseHotspotTypeDescription' | translate }}
      </p>

      <div *ngIf="selectedHotspotType">
        <p class="create-hotspot-description">
          {{ 'main.hotspots.chooseDrawingToolDescription' | translate }}
        </p>
        <div class="hotspot-create-buttons">
          <app-tooltip *ngFor="let geometryType of geometryTypes" [tooltipText]="getGeometryTypeTooltip(geometryType)! | translate">
            <app-square-button
              class="draw-hotspot-btn"
              [buttonClass]="{ selected: selectedGeometryType === geometryType }"
              [disabled]="!isGeometryTypeEnabled(geometryType)"
              (clicked)="setGeometryTypeForDraw(geometryType, this.selectedHotspotType.id)"
            >
              <app-icon [icon]="getGeometryTypeIcon(geometryType)"></app-icon>
            </app-square-button>
          </app-tooltip>
        </div>
        <p class="draw-guidance" *ngIf="selectedGeometryType === GeometryType.LINE">
          {{ 'main.hotspots.drawLineDoubleclickTip' | translate }}
        </p>
        <p class="draw-guidance" *ngIf="selectedGeometryType === GeometryType.POINT">{{ 'main.hotspots.drawPoint' | translate }}</p>
        <p class="draw-guidance" *ngIf="selectedGeometryType === GeometryType.POLYGON">{{ 'main.hotspots.drawPolygon' | translate }}</p>
      </div>
    </div>

    <div class="hotspot-types">
      <div
        class="hotspot-type"
        *ngFor="let type of hotspotTypes$ | async"
        (click)="setHotspotTypeForCreate(type)"
        [ngClass]="{ selected: type.id === selectedHotspotType?.id }"
      >
        <app-icon class="hotspottype-icon" [icon]="type.iconName"></app-icon>
        <span class="hotspot-type-name">{{ type.name | translate }}</span>
      </div>
    </div>

    <div class="action-button-wrapper">
      <app-square-button buttonType="Primary" (clicked)="createHotspotGroup()">
        {{ 'main.hotspots.createGroup' | translate }}
      </app-square-button>
    </div>

    <div *hasAccessTo="'hotspots_import_shape_file'" class="action-button-wrapper">
      <app-square-button [buttonType]="'Secondary'" (clicked)="onImportShapeFile()">
        <app-icon [icon]="'Import'"></app-icon> {{ 'main.hotspots.importShapefile' | translate }}
      </app-square-button>
    </div>
  </app-side-drawer-body>
</div>
