import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Legend } from '@app/map/services/legend/legend.model';
import { LegendService } from '@app/map/services/legend/legend.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { distinctUntilChanged, filter, first, map, mergeMap, Observable, Subscription, tap } from 'rxjs';
import { MapLayerControlService } from '../map-layer-control.service';

@Component({
  selector: 'app-map-layer-control-legend',
  templateUrl: './map-layer-control-legend.component.html',
  styleUrls: ['./map-layer-control-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLayerControlLegendComponent implements OnInit, OnDestroy {
  protected choosableLegends$: Observable<Legend[]> = this.legendService.getChooseableLegends$();
  protected chosenLegendName$: Observable<string> = this.legendService.chosenLegendName$;

  protected showLayerLegends = signal<boolean>(false);
  protected distanceFromEdge = this.mapLayerControlService.sidedrawerWidth$;

  protected showOutliersCheckBox$ = this.chosenLegendName$.pipe(map((legendName) => legendName === 'IsoXml'));

  protected excludeOutliers$ = this.legendService.excludeOutliers$;

  protected enableLegend$ = this.choosableLegends$.pipe(
    map((legends) => legends.length > 0),
    distinctUntilChanged(),
    tap(() => this.cdr.markForCheck())
  );

  protected form!: FormGroup;

  private _subscriptions = new SubscriptionArray();

  constructor(
    private legendService: LegendService,
    private mapLayerControlService: MapLayerControlService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  get legendData(): FormArray {
    return this.form.get('data') as FormArray;
  }

  ngOnInit(): void {
    this._subscriptions.add(this.populateForm());
    this._subscriptions.add(this.handleChoosableLegends());

    this._subscriptions.add(
      this.legendData.valueChanges.subscribe((data) => {
        this.cdr.markForCheck();
      })
    );

    this._subscriptions.add(
      this.choosableLegends$.subscribe((legends) => {
        this.cdr.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private initForm(legend: Legend): void {
    if (!legend) return;

    this.form = this.fb.group({
      name: [legend.name],
      description: [legend.description],
      data: this.fb.array([]),
      totalAmount: [legend.totalAmount],
      averageAmount: [legend.averageAmount],
      unit: [legend.unit],
      unitText: [legend.unitText],
      additionalText: [legend.additionalText],
    });
  }

  private populateForm(): Subscription {
    return this.chosenLegendName$
      .pipe(
        mergeMap((legendName) =>
          this.legendService.getLegend$(legendName).pipe(
            tap((legend) => {
              if (legend) {
                this.initForm(legend);
              }
            }),
            mergeMap(() =>
              this.legendService.getLegendData$(legendName).pipe(
                tap((data) => {
                  if (data) {
                    this.populateLegendData(data);
                  }
                })
              )
            )
          )
        )
      )
      .subscribe(() => this.cdr.markForCheck());
  }

  private populateLegendData(data: any[]): void {
    this.legendData.clear();
    data?.forEach((item) => {
      this.legendData.push(
        this.fb.group({
          color: [item?.color, Validators.required],
          value: [item?.value, Validators.required],
          unit: [item?.unit],
          unitText: [item?.unitText],
          decimalOverride: [item?.decimalOverride],
          forceString: [item?.forceString],
        })
      );
    });
  }

  private handleChoosableLegends(): Subscription {
    return this.choosableLegends$
      .pipe(
        distinctUntilChanged(),
        filter((legends) => legends.length > 0),
        tap((legends) => {
          this.ensureValidChosenLegend(legends);
        })
      )
      .subscribe(() => this.cdr.markForCheck());
  }

  private ensureValidChosenLegend(legends: Legend[]): void {
    this.chosenLegendName$.pipe(first()).subscribe((chosenLegend) => {
      const isCurrentLegendValid = legends.some((legend) => legend.name === chosenLegend);

      if (!isCurrentLegendValid) {
        this.chooseLegend(legends[0].name);
      }
    });
  }

  protected toggleLayerLegends(): void {
    this.showLayerLegends.set(!this.showLayerLegends());
  }

  protected chooseLegend(legendName: string): void {
    this.legendService.setChosenLegend(legendName);
  }

  protected toggleOutliers(): void {
    this.legendService.excludeOutliers$.next(!this.excludeOutliers$.value);
  }
}
