import { Injectable } from '@angular/core';
import { latest } from '@app/shared/constants/rxjs-constants';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith } from 'rxjs';
import { PrescriptionMap } from '../interfaces/prescription-map.interface';
import { AdjustAmountRepository } from './adjust-amount-repository';
import { BasisLayerRepository } from './basis-layer.repository';
import { CellRepository } from './cell.repository';
import { MinMaxRepository } from './min-max.repository';
import { PotassiumSoilSampleCorrectionsRepository } from './potassium-soil-sample-corrections.repository';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';
import { SoilSampleAdjustmentsRepository } from './soil-sample-adjustments.repository';
import { SoilSampleCorrectionsRepository } from './soil-sample-corrections.repository';

export type Status = PrescriptionMap['state'] | 'draft';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(
    private pmQuery: PrescriptionMapQuery,
    private cellRepo: CellRepository,
    private basisLayerRepo: BasisLayerRepository,
    private adjustAmountRepo: AdjustAmountRepository,
    private minMaxRepo: MinMaxRepository,
    private soilSampleAdjustmentsRepo: SoilSampleAdjustmentsRepository,
    private correctionsRepo: SoilSampleCorrectionsRepository,
    private potassiumCorrectionsRepo: PotassiumSoilSampleCorrectionsRepository
  ) {}

  private readonly _hasPast$ = combineLatest([
    this.cellRepo.hasHistory$.pipe(startWith(false)),
    this.basisLayerRepo.hasPast$.pipe(startWith(false)),
    this.adjustAmountRepo.hasPast$.pipe(startWith(false)),
    this.minMaxRepo.hasPast$.pipe(startWith(false)),
    this.soilSampleAdjustmentsRepo.hasPast$.pipe(startWith(false)),
    this.correctionsRepo.hasPast$.pipe(startWith(false)),
    this.potassiumCorrectionsRepo.hasPast$.pipe(startWith(false)),
  ]).pipe(map((args) => args.some(Boolean))); // args.some(Boolean) same as arg[0] || arg[1] || arg[2] etc... - OR'd

  public readonly status$: Observable<Status> = combineLatest([this.pmQuery.stateByActiveTask$, this._hasPast$]).pipe(
    map(([state, hasPast]) => (hasPast ? 'draft' : state)),
    shareReplay(latest)
  );

  public readonly validationErrorsSubject$ = new BehaviorSubject<boolean>(false);
  public readonly adjustAmountErrorsSubject$ = new BehaviorSubject<boolean>(false);
  public readonly limeNeedErrorsSubject$ = new BehaviorSubject<boolean>(false);

  public readonly canSave$ = combineLatest([
    this.validationErrorsSubject$,
    this.adjustAmountErrorsSubject$,
    this.limeNeedErrorsSubject$,
    this.pmQuery.hasWarnings$,
  ]).pipe(
    map(
      ([validationErrors, adjustAmountErrors, limeNeedErrors, warnings]) =>
        !validationErrors && !adjustAmountErrors && !limeNeedErrors && !warnings
    )
  );
}
