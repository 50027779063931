export enum MapLayerId {
  NONE = '',
  FIELDBLOCKS = 'fieldblocks', // Overlay for showing available field blocks (yellow field outlines)
  BORDERZONES = 'borderzones', // Overlay for showing borders zones of fields
  GLMALL = 'glmall', // Overlay for showing lakes and ancient burial hills
  HOTSPOTS = 'hotspots', // Hotspot polygons and dots
  HOTSPOTMARKERS = 'hotspotmarkers', // Hotspot markers
  FIELDS = 'fields', // Used to show both fields with colors by crop and number/area label and fields with no colors but still with label
  FIELDPLANS = 'fieldplans', // Used to shoe fields when in edit/create field mode
  HIGH_RES_FIELD_MARKERS = 'high-res-field-markers', // Markers for field location when zooming far out
  HIGH_RES_POTATO_MARKERS = 'high-res-potato-markers', // Markers for potato field location when zooming far out
  OSM = 'osm', // Open Street Map base layer
  AIRPHOTO = 'airphoto', // Orto photo (Denmark only) for showing real air photos
  AIRPHOTO_BING = 'airphotoBing', // Air photos for outside of Denmark
  REDEDGENDVI = 'rededgendvi', // Overlay for showing NDVI biomass index of the fields
  SOILSAMPLES = 'soilsamples', // Overlay for showing soil samples on each field
  SOILSAMPLELABELS = 'soilsamplelabels', // Labels for soil samples
  REGION = 'region', // Region polygons for yield benchmark
  VRA = 'vra', // VRA cell polygons showing each 10x10m polygon in the fields for a vra task
  VRA_FIELDS = 'vra-fields',
  GROWTHCURVE = 'growthcurve', // Used for selection of NDVI field to show a growth curve
  GROWTHREGULATION = 'growthregulation', // Field polygons for growthregulation prognosis for winterwheat
  CORNPROGNOSIS = 'cornprognosis', // Field polygons for corn harvest prognosis
  GEOLOCATION = 'geolocation', // Shows the location of the device on the map
  AS_APPLIED = 'as-applied', // Shows as applied layer under Field analysis > As Applied
  AS_APPLIED_HIGHLIGHT = 'as-applied-highlight',
  ISOXML = 'isoxml', // Shows isoxml layer under Field analysis > ISOXML
  ISOXML_POINTS = 'isoxml-points',
  ISOXML_POINTS_NODATA = 'isoxml-points-nodata',
  YIELD_PROGNOSIS = 'yield-prognosis',
  BASIS_LAYER = 'BASIS_LAYER',
  DRONE_IMAGE_IMPORT = 'drone-image-import',
  POTATO_BLIGHT = 'potato-blight',
  BLIGHT_POLYGONS = 'blight-polygons',
  BLIGHT_FIELD_POLYGONS = 'blight-field-polygons',
  BLIGHT_FIELDS = 'blight-fields',
  AS_APPLIED_POINTS = 'as-applied-points',
}
