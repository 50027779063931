<table mat-table [dataSource]="legendData.controls" class="mat-elevation-z0" data-testid="legend-table">
  <!-- Color Column -->
  <ng-container matColumnDef="color">
    <td mat-cell *matCellDef="let formGroup">
      <div class="legend-row-color">
        <div
          class="color-square"
          [ngStyle]="{
            'background-color': formGroup.value.color,
            'box-shadow': isWhite(formGroup.value.color) ? 'inset 0 0 0 1px #ddd' : 'none',
          }"
        ></div>
      </div>
    </td>
  </ng-container>

  <!-- Value Column -->
  <ng-container matColumnDef="value">
    <td mat-cell *matCellDef="let formGroup">
      <div class="legend-row-value">
        {{ formGroup.value.value | unit: formGroup.value.unit : formGroup.value.decimalOverride : formGroup.value.forceString }}
        {{ formGroup.value.unitText }}
      </div>
    </td>
  </ng-container>

  <!-- Data row definition -->
  <tr mat-row *matRowDef="let formGroup; let i = index; columns: columns"></tr>
</table>
