import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { LanguagePickerService } from '@app/core/language-picker/language-picker.service';
import { NavigationItem } from '@app/layouts/navigation/navigation-item';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import isEqual from 'lodash-es/isEqual';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { navigationItemsContainer } from './navigation-items.const';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public farmsAreSelected$ = this.farmStateService.farmsAreSelected$;
  private _activeLink?: string;
  private _routerSubscription: Subscription;

  constructor(
    private farmStateService: FarmStateService,
    private router: Router,
    private languagePickerService: LanguagePickerService,
    private featureToggleService: FeatureToggleService,
    private harvestYearStateService: HarvestYearStateService,
    private accessControlService: AccessControlService
  ) {
    this._routerSubscription = this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this._activeLink = (event as NavigationEnd).urlAfterRedirects.split('?')[0];
      });
  }

  public getQueryParamsWhenNavigating$(): Observable<{}> {
    return combineLatest([
      this.harvestYearStateService.harvestYear$,
      this.farmStateService.selectedFarmIds$,
      this.languagePickerService.getLangShortCode$(),
    ]).pipe(
      distinctUntilChanged(([harvestYearPrev, farmIdsPrev, currentLanguagePrev], [harvestYear, farmIds, currentLanguage]) => {
        const isFarmIdsSame = isEqual(farmIdsPrev, farmIds);
        const isHarvestYearSame = isEqual(harvestYearPrev, harvestYear);
        const isCurrentLanguageSame = isEqual(currentLanguagePrev, currentLanguage);
        return isFarmIdsSame && isHarvestYearSame && isCurrentLanguageSame;
      }),
      map(([harvestYear, farmIds, currentLanguage]) => {
        return {
          harvestYear: harvestYear,
          farmIds: farmIds,
          currentLanguage: currentLanguage,
        };
      })
    );
  }

  public isLinkActive(url: string | undefined) {
    if (!url || !this._activeLink) return false;

    if (this._activeLink.startsWith('/map/iso') && url === 'map/field-analysis') return true;
    if (this._activeLink.startsWith('/map/field-plan') && url === 'map/cultivation-journal') return true;
    return this._activeLink.startsWith('/' + url);
  }

  /**
   * Returns an observable that emits an array of navigation items.
   * The items are transformed to include a `disabled` property based on the user's access control.
   * If the `plant_cropmanager_open-vra` feature toggle is disabled, VRA-related items are removed.
   * @returns An observable that emits an array of navigation items.
   */
  public getNavigationItems(): Observable<NavigationItem[]> {
    // combine observables for modules, resources, and feature toggles
    return combineLatest([
      this.accessControlService.modules$,
      this.accessControlService.resources$,
      this.featureToggleService.get$('plant_cropmanager_open-vra'),
      this.featureToggleService.get$('pla-cropmanager_benchmark'),
    ]).pipe(
      map(([modules, resources, isOpenVraEnabled, isBenchMarkEnabled]) =>
        // transform navigation items to include disabled state based on access control
        Object.values(navigationItemsContainer)
          .map<NavigationItem>((nav) => ({
            ...nav,
            // disable items the user does not have access to
            disabled: this.shouldDisable(modules, resources, nav),
            // ... and disable child items recursively
            items: nav.items.map((item) => ({ ...item, disabled: this.shouldDisable(modules, resources, item) })),
          }))
          // filter out feature toggled VRA if not enabled
          .filter((nav) => nav.module !== 'vra' || isOpenVraEnabled)
          .filter((nav) => nav.module !== 'yield_benchmark' || isBenchMarkEnabled)
      )
    );
  }

  /**
   * Determines whether a navigation item should be disabled based on the user's permissions.
   * The user should have access to the module (if any), and at least one of the resources.
   */
  private shouldDisable(userModules: readonly string[], userResources: readonly string[], item: NavigationItem): boolean {
    // If the navigation item has both a single resource and a list of resources, an error is thrown.
    if (item.resource && item.resources) {
      throw new Error('Navigation item cannot have both a single resource and a list of resources');
    }

    // Check if the user has access to the module (if any) required by the navigation item.
    // If the navigation item does not have a module, the user is assumed to have access.
    const hasModule = item.module ? userModules.includes(item.module) : true;

    // Check if the user has access to any of the resources required by the navigation item.
    // If the navigation item does not have a list of resources, the user is assumed to have access.
    const hasResources = item.resources?.some((resource) => userResources.includes(resource)) ?? true;

    // Check if the user has access to the single resource required by the navigation item.
    // If the navigation item does not have a single resource, the user is assumed to have access.
    const hasResource = item.resource ? userResources.includes(item.resource) : true;

    // Disable the item if the user does not have access to the module, any of the resources, or the resource.
    // This means that the user should have access to the module (if any), and at least one of the resources.
    return (hasModule && hasResources && hasResource) === false;
  }
}
