import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { MapSelector } from '@app/map/map.selector';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { PrognosisSideDrawerComponent } from './prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisActionsService } from './prognosis.actions.service';
import { PrognosisService } from './prognosis.service';

@Component({
  selector: 'app-prognosis',
  templateUrl: './prognosis.component.html',
  styleUrls: ['./prognosis.component.scss'],
})
export class PrognosisComponent implements OnInit, OnDestroy {
  public scaleLegendSettings: ScaleLegendSettings = {
    items: [],
    title: '',
    note: '',
    summaryLines: [],
    visible: false,
    unit: '',
  };

  // for cover flow
  public mapCoverFlowItems: MapCoverFlowItem[] = [];

  public selectedMapCoverFlowItem: MapCoverFlowItem | null = null;
  public isMapCoverFlowVisible = false;
  public isMapCoverFlowLoading = false;

  public canSeeSelectedField!: boolean;

  // to use MapLayerId enum in template
  public mapLayerId = LayerId;

  public selectedMapFeature: LayerId = LayerId.NONE;

  public visibleLayerId$ = this.mapSelector.selectedMapCoverFlowItem$.pipe(
    filter((item) => !!item),
    map((item) => {
      return item.mapCoverFlowLayersId;
    })
  );

  // scale legend
  public isScaleLegendVisible = false;

  public sideDrawerRef!: SideDrawerRef<PrognosisSideDrawerComponent, void, void>;

  public loadingState = this.prognosisService.mapLoadingState;

  private subscriptions = new SubscriptionArray();
  constructor(
    private mapService: OlMapService,
    private prognosisService: PrognosisService,
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private mapSelector: MapSelector,
    private prognosisActionDispatchers: PrognosisActionsService,
    private sideDrawerService: SideDrawerOverlayService,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit(): void {
    this.setupMapCoverFlowSubscriptions();
    this.mapService.mapReady$.pipe(first()).subscribe(() => {
      setTimeout(() => {
        this.openSideDrawer();
        this.handleMapReady();
        this.mapService.setNewActivePage('prognosis');
      });
    });
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.sideDrawerRef.close();
  }

  public handleMapReady() {
    setTimeout(() => {
      this.initMapSubscriptions();
      this.farmStateService.fieldFeatures$
        .pipe(
          filter((fieldFeatures) => !!fieldFeatures),
          take(1)
        )
        .subscribe((fieldFeatures) => {
          //this.mapService.addFieldsToMap(fieldFeatures, LayerId.FIELDS, true);
          this.mapService.removeSelectInteraction();
        });
    });
  }

  public openSideDrawer() {
    if (this.sideDrawerRef && this.sideDrawerService.sideDrawerRefIsDefined()) {
      this.sideDrawerRef.show();
      this.prognosisService.drawerWidth = SideDrawerConfig.widthAsOpened;
      this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsOpenedPx);
      return;
    }

    this.prognosisService.drawerWidth = SideDrawerConfig.widthAsOpened;

    this.sideDrawerRef = this.sideDrawerService.openCustomSideDrawer(PrognosisSideDrawerComponent, {
      panelClass: 'prognosis-side-drawer',
      width: SideDrawerConfig.widthAsOpened,
      hasBackdrop: false,
    });

    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsOpenedPx);
    this.sideDrawerRef.onClose.subscribe(() => {
      this.prognosisService.drawerWidth = SideDrawerConfig.widthAsClosed;
      this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
    });
  }

  private initMapSubscriptions(): void {
    this.setupMapOptionsSubscriptions();
    this.setupSubscriptionLevelSubscriptions();
    this.setupSelectedMapCoverFlowSubscriptions();
  }

  private setupSelectedMapCoverFlowSubscriptions() {
    this.subscriptions.push(
      this.mapSelector.selectedMapCoverFlowItem$.subscribe((mapCoverFlowItem) => {
        if (!mapCoverFlowItem) {
          this.mapCoverFlowItems.forEach((coverFlowItem) => (coverFlowItem.isVisible = false));
        } else {
          mapCoverFlowItem.isVisible = true;
          this.selectedMapCoverFlowItem = mapCoverFlowItem;

          this.selectedMapFeature = mapCoverFlowItem.mapCoverFlowLayersId!;
          this.mapCoverFlowItems
            .filter((coverFlowItem) => coverFlowItem !== mapCoverFlowItem && coverFlowItem.isVisible)
            .forEach((coverFlowItem) => (coverFlowItem.isVisible = false));
          this.isMapCoverFlowVisible = true;
        }
      })
    );
  }

  private setupSubscriptionLevelSubscriptions() {
    this.subscriptions.push(
      this.mapSelector.subscriptionLevel$.subscribe((level) => (this.canSeeSelectedField = level >= SubscriptionLevel.BASIC))
    );
  }

  private setupMapOptionsSubscriptions() {
    this.subscriptions.push(
      this.mapSelector.mapOptions$.subscribe((options) => {
        this.isMapCoverFlowVisible = options?.isSettingsVisible;
      })
    );
  }

  private setupMapCoverFlowSubscriptions() {
    this.subscriptions.push(
      combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$])
        .pipe(switchMap(() => this.prognosisService.getCoverFlowLayersItems()))
        .subscribe((coverFlowItems) => {
          this.mapService.deselectFeatures();
          this.updateMapCoverFlowItems(coverFlowItems);
        })
    );
  }

  private updateMapCoverFlowItems(coverFlowItems: MapCoverFlowItem[]) {
    this.mapCoverFlowItems = coverFlowItems;
    this.prognosisActionDispatchers.setMapCoverFlowItems(coverFlowItems);
    this.isMapCoverFlowLoading = false;
  }
}
