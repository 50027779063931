<app-management-action
  [icon]="'cf-notification_outline'"
  [tooltip]="'footer.actions.notifications' | translate"
  [minimized]="minimized"
  [noReminders]="(reminderCount ?? 0) === 0"
  (actionClick)="openReminders()"
></app-management-action>

<mat-icon
  [matBadge]="reminderCount"
  matBadgePosition="after"
  matBadgeColor="accent"
  matBadgeSize="medium"
  aria-hidden="false"
  class="reminder-counter"
  [ngClass]="{ minimized: minimized, unread: (reminderCount ?? 0) > 0 }"
></mat-icon>
