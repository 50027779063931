import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FeatureBranchRepoService } from '@app/core/repositories/feature-branch/feature-branch-repo.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs';
import { FeatureBranchConfig } from '../environment-indicator/feature-branch-config';

@Component({
  selector: 'app-enviroment-branch-selector',
  templateUrl: './enviroment-branch-selector.component.html',
  styleUrls: ['./enviroment-branch-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnviromentBranchSelectorComponent {
  private _bottomSheetRef = inject<MatBottomSheetRef<EnviromentBranchSelectorComponent>>(MatBottomSheetRef);

  private default = 'Default';

  public environment = environment.env;
  public visible = this.environment !== 'prod';

  public fo_branches$ = this.service.getFoFeatureBranches().pipe(map((branches) => [this.default, ...branches]));
  public cm_branches$ = this.service.getCmFeatureBranches().pipe(map((branches) => [this.default, ...branches]));
  public vra_branches$ = this.service.getVraFeatureBranches().pipe(map((branches) => [this.default, ...branches]));
  public fo_selectedBranch: string = sessionStorage.getItem(FeatureBranchConfig.StorageKey) ?? this.default;
  public cm_selectedBranch: string = sessionStorage.getItem(FeatureBranchConfig.StorageKey_CM) ?? this.default;
  public vra_selectedBranch: string = sessionStorage.getItem(FeatureBranchConfig.StorageKey_VRA) ?? this.default;

  constructor(private service: FeatureBranchRepoService) {}

  public close() {
    this._bottomSheetRef.dismiss();
  }

  public changeFoFeatureBranch(event: any) {
    if (event === this.default) sessionStorage.removeItem(FeatureBranchConfig.StorageKey);
    else sessionStorage.setItem(FeatureBranchConfig.StorageKey, event);

    this.fo_selectedBranch = event;
  }

  public changeCmFeatureBranch(event: any) {
    if (event === this.default) sessionStorage.removeItem(FeatureBranchConfig.StorageKey_CM);
    else sessionStorage.setItem(FeatureBranchConfig.StorageKey_CM, event);

    this.cm_selectedBranch = event;
  }

  public changeVraFeatureBranch(event: any) {
    if (event === this.default) sessionStorage.removeItem(FeatureBranchConfig.StorageKey_VRA);
    else sessionStorage.setItem(FeatureBranchConfig.StorageKey_VRA, event);

    this.vra_selectedBranch = event;
  }
}
