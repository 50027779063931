import { Injectable } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { LocalState } from '@app/helpers/local-state';
import { CultivationJournalComponentEnum } from '@app/map/features/cultivation-journal/CultivationJournalComponentEnum.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CultivationJournalStateService {
  private _fieldSubject = new BehaviorSubject<Field | undefined>(undefined);
  private _cultivationJournalBtnShownSubject = new BehaviorSubject(false);
  private shownComponentState = new LocalState<CultivationJournalComponentEnum>(CultivationJournalComponentEnum.CultivationJournal);

  public get field$() {
    return this._fieldSubject.asObservable();
  }

  public set field(field: Field | undefined) {
    this._fieldSubject.next(field);
  }

  public get cultivationJournalBtnShown$(): Observable<boolean> {
    return this._cultivationJournalBtnShownSubject.asObservable();
  }

  public set cultivationJournalBtnShown(hidden: boolean) {
    this._cultivationJournalBtnShownSubject.next(hidden);
  }

  public shownComponentState$ = this.shownComponentState.changes$;
  constructor() {}

  public setShownComponentState(component: CultivationJournalComponentEnum) {
    this.shownComponentState.setState(component);
  }
}
