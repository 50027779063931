import { Injectable } from '@angular/core';
import { FilterItem } from '@app/map/features/cultivation-journal/filter/filter-item';
import { FilterStateService } from '@app/map/features/cultivation-journal/filter/filter-state.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterLogicService {
  constructor(private filterStateService: FilterStateService) {}

  /**
   * If an equal filter item exists in any scope, it is removed from all scopes.
   */
  public toggleFilterItem(filterItem: FilterItem<any>) {
    this.filterStateService
      .getFilterItems$()
      .pipe(first())
      .subscribe((filterItems) => {
        !!filterItems.find((item) => item.isEqual(filterItem))
          ? this.filterStateService.removeFilterItem(filterItem)
          : this.filterStateService.addFilterItem(filterItem);
      });
  }
}
