import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { FarmGuard } from '@app/core/guards/farm-guard.service';
import { AccessControlGuard, AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';
import { FarmTasksOverviewComponent } from './components/farm-tasks-overview.component';

export const overviewLazyRoute: Route = {
  path: 'overview',
  loadChildren: () => import('./farm-tasks-overview.module').then((m) => m.FarmTasksOverviewModule),
};

const routes: AccessControlRoutes = [
  {
    path: '',
    component: FarmTasksOverviewComponent,
    canActivate: [AuthGuard, ConsentGuard, FarmGuard, AccessControlGuard],
    data: {
      module: 'farm_tasks_overview',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FarmTasksOverviewRoutingModule {}
