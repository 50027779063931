import { Component } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { OlLayerQuery } from '@app/map/services/layer/layer.query';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerBundle } from '@app/map/services/layer/layer.store';
import { filterNullOrEmpty } from '@app/shared/operators';
import { first } from 'rxjs';

@Component({
  selector: 'app-map-layer-generel-layers-selector',
  templateUrl: './map-layer-generel-layers-selector.component.html',
  styleUrls: ['./map-layer-generel-layers-selector.component.scss'],
})
export class MapLayerGeneralLayersSelectorComponent {
  constructor(
    private _layerService: OlLayerService,
    private _layerQuery: OlLayerQuery,
    private _notificationService: NotificationService,
    private _languageService: LanguageService
  ) {}

  protected generelLayers$ = this._layerQuery.generalLayers$.pipe(filterNullOrEmpty());
  protected enabledGeneralLayerCount$ = this._layerQuery.enabledGenerelLayerCount$;

  public hideAll() {
    this.generelLayers$.pipe(first()).subscribe((layers) => {
      layers.forEach((layer) => {
        this._layerService.setLayerVisibility(layer.id, false);
      });
    });
  }

  public toggleHide(layer: LayerBundle, event: MouseEvent) {
    event.stopPropagation();
    this.enabledGeneralLayerCount$.pipe(first()).subscribe((count) => {
      if (count >= 5 && !layer.enabled) {
        this._notificationService.showInfo(this._languageService.getText('main.map.layerControl.maxLayers'));
        event.preventDefault();
        return;
      }
      this._layerService.toggleLayerVisibility(layer.id);
    });
  }

  getCheckboxStyles(color: string) {
    if (color === '') {
      // Diagonal split colors
      return {
        background: `repeating-linear-gradient(
          45deg,
          #e5e5e5,
          #e5e5e5 10px,
          #ffffff 10px,
          #ffffff 20px
        )`,
        border: '1px dashed #cccccc',
        width: '15px',
        height: '15px',
      };
    }
    return {
      background: color,
    };
  }
}
