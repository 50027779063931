<button mat-button class="soilsample-btn" #soilsample="matMenuTrigger" [matMenuTriggerFor]="soilsampleMenu">
  <div class="soilsample-btn-content">
    <span>{{ 'common.map-controls.soilsample.title' | translate }}</span>
    <mat-icon class="material-symbols-outlined">{{ soilsample.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>

<mat-menu #soilsampleMenu="matMenu" class="soilsample-menu" (click)="$event.stopPropagation()">
  <ng-template matMenuContent *ngIf="hasAccess$ | async; else noAcess">
    <div class="soilsample-menu-content">
      <div class="soilsample-layer-menu-header">
        <b>{{ 'common.map-controls.soilsample.title' | translate }}</b>
        <mat-icon class="material-symbols-outlined info-icon" (click)="openInfo()">info</mat-icon>
        <span class="spacer"></span>

        <u class="soilsample-layer-menu-hide-all" (click)="onClearClick(); $event.stopPropagation()">{{
          'common.map-controls.hide-all' | translate
        }}</u>
      </div>
    </div>
    <div class="date-selection" (click)="$event.stopPropagation()">
      <form class="soilsample-form" [formGroup]="form">
        <mat-form-field class="custom-select-soil" appearance="outline">
          <mat-select formControlName="selectedGroups" [placeholder]="'common.map-controls.soilsample.pickdate' | translate" multiple>
            @for (soilsampleGroup of soilsampleGroups$ | async; track soilsampleGroup.date) {
              <mat-option class="option" [value]="soilsampleGroup"
                >{{ soilsampleGroup.dateString }} ({{ soilsampleGroup.numberOfSamples }})
                {{ 'common.map-controls.soilsample.samples' | translate }}</mat-option
              >
            }
          </mat-select>
        </mat-form-field>
        <div class="soilsample-types-selection">
          <span class="show-for">{{ 'common.map-controls.soilsample.showfor' | translate }}</span>
          <mat-form-field class="custom-select-soil" appearance="outline">
            <mat-select formControlName="selectedType" [placeholder]="'common.map-controls.soilsample.picktype' | translate">
              <mat-option class="option" [value]="undefined">{{ 'common.map-controls.hide-all' | translate }}</mat-option>
              @for (type of selectableMeasurementType$ | async; track type) {
                <mat-option [disabled]="!type.enabled" class="option" [value]="type.parameterId">
                  <div class="option-row">
                    <span class="soilsample-text"> {{ 'common.map-controls.soilsample.types.' + type.name | translate }} </span>
                  </div>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </ng-template>
</mat-menu>

<ng-template #noAcess matMenuContent>
  <div class="require-acess">
    <mat-icon class="material-symbols-outlined">lock</mat-icon>
    <span>
      {{ 'common.map-controls.soilsample.requiredAccess' | translate }}
    </span>
  </div>
</ng-template>
