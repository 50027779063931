<app-chart-wrapper [showSpinner]="isLoading" loadingMessage="waterBalance.fetchingData">
  <app-square-button class="info-button" (click)="toggleInfo()">
    <app-icon class="info" icon="info_outline"></app-icon>
    {{ 'wetHoursChart.openInfo' | translate }}
  </app-square-button>

  <kendo-chart #chart [transitions]="true" [pannable]="{ lock: 'y' }" (render)="updateGraphLines()" data-testid="waterBalance.kendo-chart">
    <kendo-chart-zoomable [mousewheel]="{ lock: 'y' }" [selection]="{ lock: 'y' }"></kendo-chart-zoomable>

    <kendo-chart-legend position="bottom" orientation="horizontal" [item]="{ visual: legendItemVisual }"></kendo-chart-legend>

    <kendo-chart-panes>
      <kendo-chart-pane [name]="topPane" [height]="200"> </kendo-chart-pane>
      <kendo-chart-pane [name]="bottomPane" [height]="350"> </kendo-chart-pane>
    </kendo-chart-panes>

    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item
        [name]="topAxis"
        [pane]="topPane"
        [min]="precipitationValueAxis.min"
        [max]="precipitationValueAxis.max"
        [majorUnit]="precipitationValueAxis.majorUnit!"
        [title]="precipitationValueAxis.title!"
      >
      </kendo-chart-value-axis-item>
      <kendo-chart-value-axis-item
        [name]="bottomAxis"
        [pane]="bottomPane"
        [min]="waterBalanceValueAxis.min"
        [max]="waterBalanceValueAxis.max"
        [majorUnit]="waterBalanceValueAxis.majorUnit!"
        [labels]="waterBalanceValueAxis.labels!"
        [title]="waterBalanceValueAxis.title!"
      >
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>

    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        [name]="categoryAxis.name!"
        [baseUnit]="categoryAxis.baseUnit!"
        [type]="categoryAxis.type!"
        [min]="categoryAxis.min"
        [max]="categoryAxis.max"
        [majorGridLines]="categoryAxis.majorGridLines!"
        [maxDivisions]="categoryAxis.maxDivisions!"
        [labels]="categoryAxis.labels!"
        [pane]="categoryAxis.pane!"
      >
      </kendo-chart-category-axis-item>
      <kendo-chart-category-axis-item
        name="precipitationCategoryAxis"
        [baseUnit]="categoryAxis.baseUnit!"
        [type]="categoryAxis.type!"
        [min]="categoryAxis.min"
        [max]="categoryAxis.max"
        [majorGridLines]="categoryAxis.majorGridLines!"
        [maxDivisions]="categoryAxis.maxDivisions!"
        [labels]="categoryAxis.labels!"
        [visible]="false"
        pane="precipitationPane"
      >
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>

    <kendo-chart-series>
      <!-- precipitation -->
      <kendo-chart-series-item
        categoryAxis="precipitationCategoryAxis"
        name="{{ 'waterBalance.legends.precipitation' | translate }}"
        [data]="visibleData?.waterBalanceDayList ?? []"
        categoryField="dateDay"
        field="precipitation"
        [axis]="topAxis"
        [spacing]="0.2"
        [color]="barStyleFunction"
        type="column"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'waterBalance.legends.precipitation' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | unit: 'mm' }} {{ 'common.unitType.mm' | translate }}
            <app-icon *ngIf="value > manThatsALotOfRain" icon="info_outline"></app-icon>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>

      <!-- precipitation prognosis -->
      <kendo-chart-series-item
        *ngIf="visibleData?.waterBalanceDayPrognosisList?.length"
        categoryAxis="precipitationCategoryAxis"
        name="{{ 'waterBalance.legends.precipitationPrognosis' | translate }}"
        [data]="visibleData?.waterBalanceDayPrognosisList ?? []"
        categoryField="dateDay"
        field="precipitation"
        [axis]="topAxis"
        [opacity]="0.5"
        [color]="barStyleFunction"
        type="column"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'waterBalance.legends.precipitationPrognosis' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | unit: 'mm' }} {{ 'common.unitType.mm' | translate }}
            <app-icon *ngIf="value > manThatsALotOfRain" icon="info_outline"></app-icon>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>

      <!-- irrigation -->
      <kendo-chart-series-item
        categoryAxis="precipitationCategoryAxis"
        name="{{ 'waterBalance.legends.irrigation' | translate }}"
        [data]="visibleData?.waterBalanceDayList ?? []"
        [axis]="topAxis"
        color="#CFCCDA"
        categoryField="dateDay"
        field="irrigation"
        type="column"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'waterBalance.legends.irrigation' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | unit: 'mm' }} {{ 'common.unitType.mm' | translate }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>

      <!-- Water balance -->
      <kendo-chart-series-item
        name="{{ 'waterBalance.legends.waterBalance' | translate }}"
        [data]="visibleData?.waterBalanceDayList ?? []"
        [markers]="markersHidden"
        [axis]="bottomAxis"
        [style]="'smooth'"
        color="#005521"
        categoryField="dateDay"
        field="waterDeficit"
        [width]="4"
        type="line"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'waterBalance.legends.waterBalance' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | unit: 'mm' }} {{ 'common.unitType.mm' | translate }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>

      <!-- Water balance prognosis-->
      <kendo-chart-series-item
        *ngIf="visibleData?.waterBalanceDayPrognosisList?.length"
        name="{{ 'waterBalance.legends.waterBalance' | translate }} {{ 'waterBalance.legends.prognosis' | translate }}"
        [data]="visibleData?.waterBalanceDayPrognosisList ?? []"
        [markers]="markersHidden"
        [axis]="bottomAxis"
        [style]="'smooth'"
        categoryField="dateDay"
        field="waterDeficit"
        [width]="4"
        color="#005521"
        dashType="dash"
        type="line"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'waterBalance.legends.waterBalance' | translate }} {{ 'waterBalance.legends.prognosis' | translate }}
            <br />
            {{ category | dateFormat }}
            {{ value | unit: 'mm' }} {{ 'common.unitType.mm' | translate }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>

      <!-- Green -->
      <kendo-chart-series-item
        name="{{ 'waterBalance.legends.greenZone' | translate }}"
        [highlight]="highlightsHidden"
        [markers]="markersHidden"
        [data]="greenArea"
        [axis]="bottomAxis"
        color="#B4C5B0"
        [opacity]="0.8"
        type="rangeArea"
      >
      </kendo-chart-series-item>
      <!-- Yellow -->
      <kendo-chart-series-item
        name="{{ 'waterBalance.legends.yellowZone' | translate }}"
        [markers]="markersHidden"
        [data]="yellowArea"
        [highlight]="highlightsHidden"
        [axis]="bottomAxis"
        color="#FDF4C9"
        [opacity]="0.8"
        type="rangeArea"
      >
      </kendo-chart-series-item>
      <!-- Red -->
      <kendo-chart-series-item
        name="{{ 'waterBalance.legends.redZone' | translate }}"
        [markers]="markersHidden"
        [highlight]="highlightsHidden"
        [data]="redArea"
        [axis]="bottomAxis"
        color="#FDCBBE"
        [opacity]="0.8"
        type="rangeArea"
      >
      </kendo-chart-series-item>
      <!-- Grey -->
      <kendo-chart-series-item
        name="{{ 'waterBalance.legends.rootZoneCapacity' | translate }}"
        [markers]="markersHidden"
        [data]="rootCapacityArea"
        [highlight]="highlightsHidden"
        [axis]="bottomAxis"
        color="#EBEBEB"
        [opacity]="0.8"
        type="rangeArea"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>

  <div *ngIf="errorMessage && errorMessage.length" class="error-message">
    <p>{{ errorMessage }}</p>
  </div>
</app-chart-wrapper>
