import { Injectable } from '@angular/core';
import { Unit } from '@app/core/interfaces/unit.type';
import { LanguageService } from '@app/core/language/language.service';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { Decimals } from './decimals.class';

@Injectable({
  providedIn: 'root',
})
export class DecimalService {
  private readonly unitDecimals = new Map<Unit, Decimals>([
    ['g', new Decimals(0, 0, 0, 0, 1)],
    ['kg', new Decimals(0, 0, 0, 1, 1)],
    ['hkg', new Decimals(0, 0, 1, 2, 1)],
    ['kgn', new Decimals(0, 0, 1, 2, 1)],
    ['t', new Decimals(0, 0, 1, 2, 2)],
    ['ton', new Decimals(0, 0, 1, 2, 2)],
    ['l', new Decimals(0, 1, 2, 3, 3)],
    ['liter', new Decimals(0, 1, 2, 3, 3)],
    ['pakn', new Decimals(0, 0, 1, 2, 1)],
    ['unit', new Decimals(0, 0, 1, 2, 1)],
    ['mm', new Decimals(1, 1, 1, 1, 1)],
    ['kg/ha', new Decimals(0, 0, 0, 1, 1)],
    ['l/ha', new Decimals(0, 1, 2, 2, 2)],
    ['kr', new Decimals(2, 2, 2, 2, 2)],
    ['ha', new Decimals(2, 2, 2, 2, 2)],
    ['none', new Decimals(0, 0, 0, 0, 0)],
  ]);

  constructor(private languageService: LanguageService) {}

  public getNumberOfDecimals(value: string | number, unit: Unit): number | undefined {
    if (!unit) return 2;

    // remove empty spaces and convert to lower case
    unit = unit.toLocaleLowerCase().replace(/\s/g, '') as Unit;

    if (this.unitDecimals.has(unit)) return this.unitDecimals.get(unit)?.getDecimals(Number(value));

    // default
    return 2;
  }

  /**
   * Parses number value to locale dependent decimal separator
   * @param value The value to parse
   * @param unit Optional unit to parse to
   * @param decimals Optional number of decimals
   * Defaults to 2 decimals if unit or decimals is not provided
   * @returns Parsed locale string
   */
  public toLocaleString(value: string | number | undefined | null, unit?: string, decimals?: number): string {
    if (value === null || value === undefined || isNaN(+value)) return '';

    const trimmedUnit = unit?.toLowerCase().trimStart().split(' ')[0].trimEnd() as Unit;
    const lang = this.languageService.currentLanguage;

    if (decimals || decimals === 0) {
      return Number(value).toLocaleString(lang.longKey, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
    }

    if (trimmedUnit && this.unitDecimals.has(trimmedUnit)) {
      return Number(value).toLocaleString(lang.longKey, {
        minimumFractionDigits: this.unitDecimals.get(trimmedUnit)!.getDecimals(Number(value)),
        maximumFractionDigits: this.unitDecimals.get(trimmedUnit)!.getDecimals(Number(value)),
      });
    }

    return Number(value).toLocaleString(lang.longKey, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  /**
   * Parses number value to number type, locale invariant
   * @param value The value to parse
   * @returns Parsed number
   */
  public toNumber(value: string | number | undefined | null): number | null {
    if (isNullOrUndefined(value)) return null;

    const replacedString = value.toString().replace(',', '.');

    return Number(replacedString);
  }

  /**
   * Return a value with localized decimal. Useful for displaying a number in localized format in an  input field.
   * Does not add the thousand separator.
   * @param value Value to format
   */
  public toLocaleDecimal(value: string | number) {
    const lang = this.languageService.currentLanguage.shortKey;

    switch (lang) {
      case 'en':
        return value.toString().replace(',', '.');
      case 'da':
        return value.toString().replace('.', ',');
      default:
        return value.toString();
    }
  }

  /**
   * Format the number to given number of decimals. Defaults to 2 decimals
   * @param value The value to format
   * @param decimals Number of decimals
   */
  public format(value: number | null | undefined, decimals?: number): number | null;

  /**
   * Format the number to given number of decimals. Defaults to 2 decimals
   * @param value The value to format
   * @param decimals Number of decimals as Decimals class
   */
  public format(value: number | null | undefined, decimals: Decimals): number | null;

  /**
   * Format the number to have number of decimals from rules by Unit. Defaults to 2 decimals
   * @param value The value to format
   * @param unit Unit to apply decimal rule from
   */
  public format(value: number | null | undefined, unit: Unit): number | null;

  public format(value: number | null | undefined, formatter: Unit | Decimals | number = 2): number | null | undefined {
    if (isNullOrUndefined(value)) return null;

    let decimals: number = -1;

    const formatterDecimals = this.unitDecimals.get(formatter as Unit);

    if (formatter instanceof Decimals) decimals = formatter.getDecimals(value);

    if (typeof formatter === 'string') decimals = this.unitDecimals.get(formatter)?.getDecimals(value) ?? 2;
    if (typeof formatter === 'number') decimals = formatter;

    return Number(value.toFixed(decimals));
  }
}
