import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-map-field-focus',
  templateUrl: './field-focus.component.html',
  styleUrls: ['./field-focus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldFocusButtonComponent {
  @Output() public focusFields = new EventEmitter();

  public onFocus() {
    this.focusFields.emit();
  }
}
