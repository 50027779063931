import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-layer-legend-table',
  templateUrl: './layer-legend-table.component.html',
  styleUrls: ['./layer-legend-table.component.scss'],
})
export class LayerLegendTableComponent {
  @Input() form!: FormGroup;

  protected columns = ['color', 'value'];

  get legendData(): FormArray {
    return this.form.get('data') as FormArray;
  }

  protected isWhite(color: string): boolean {
    if (!color) return false;
    const normalizedColor = color.trim().toLowerCase();
    return (
      normalizedColor === '#fff' ||
      normalizedColor === '#ffffff' ||
      normalizedColor === 'white' ||
      normalizedColor === 'rgb(255, 255, 255)' ||
      normalizedColor === 'rgba(255, 255, 255, 1)'
    );
  }
}
