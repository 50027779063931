import { Injectable } from '@angular/core';
import { FieldService } from '@app/core/field/field.service';
import { latest } from '@app/shared/constants/rxjs-constants';
import { emissionTickDelay, mapAs } from '@app/shared/operators';
import { select } from '@ngneat/elf';
import { selectAllEntities, selectManyByPredicate } from '@ngneat/elf-entities';
import { joinRequestResult } from '@ngneat/elf-requests';
import { isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs';
import { PrescriptionMap } from '../../interfaces/prescription-map.interface';
import { ApiParamsService } from '../_api-params.service';
import { VraRepository } from '../vra.repository';
import { PrescriptionMapStore, Statistics } from './prescription-map.store';

@Injectable({ providedIn: 'root' })
export class PrescriptionMapQuery {
  constructor(
    private store: PrescriptionMapStore,
    private apiParams: ApiParamsService,
    private vraRepo: VraRepository,
    private fieldService: FieldService
  ) {}

  public anyOngoingRequests$ = new BehaviorSubject(false);
  public displaySatelliteImageSourcePicker$ = new BehaviorSubject(false);

  private readonly _store = this.store.store;
  private readonly _history = this.store.history;

  private readonly _activeTask$ = this.vraRepo.activeTask$;

  private readonly _storeData$ = this.apiParams.farmAndHarvestYear$.pipe(
    switchMap(([farmIds, harvestYear]) =>
      this._store.pipe(selectAllEntities(), joinRequestResult(['prescription-maps', farmIds, harvestYear]))
    ),
    shareReplay(latest)
  );

  public readonly unit$ = this._store.pipe(select(({ unit }) => unit));
  public readonly operationTypeGroup$ = this._store.pipe(select(({ operationTypeGroup }) => operationTypeGroup));

  private readonly _preLoading$ = this._store.pipe(map(({ preLoading }) => preLoading));
  public readonly loading$ = combineLatest([
    this._storeData$.pipe(select(({ isLoading }) => isLoading)),
    this._preLoading$,
    this.anyOngoingRequests$,
  ]).pipe(map(([a, b, c]) => a || b || c));
  public readonly status$ = this._storeData$.pipe(select(({ fetchStatus }) => fetchStatus));
  public readonly success$ = this._storeData$.pipe(select(({ isSuccess }) => isSuccess));

  public readonly limeSetting$ = this._store.pipe(select(({ cutoff, limeTypeNeed }) => ({ cutoff, limeTypeNeed })));
  public readonly soilSampleCorrectionIntervals$ = this._store.pipe(select(({ intervals }) => intervals));

  public readonly PotassiumSoilSampleCorrectionIntervals$ = this._store.pipe(select(({ potassiumIntervals }) => potassiumIntervals));

  public readonly satelliteImageSource$ = this._store.pipe(select(({ satelliteImageSource: imageSource }) => imageSource));

  public readonly hasWarnings$ = this._storeData$.pipe(
    map(({ data }) => data),
    map((maps) => maps.some((map) => map.warnings?.some((warning) => !warning.isInformation)))
  );

  public readonly prescriptionMaps$ = this._storeData$.pipe(
    map(({ data }) => data),
    shareReplay(latest)
  );

  public readonly prescriptionMapsByActiveTask$ = this._activeTask$.pipe(
    switchMap((task) =>
      this._store.pipe(
        selectManyByPredicate(
          (map) =>
            !!task?.fields
              .flatMap((x) => x.operationLines)
              .some((line) => map.taskId === line.taskId && map.operationTypeGroup === task.group)
        )
      )
    )
  );

  public getActivePrescriptionMapByFieldId(fieldId: string | number) {
    return this.vraRepo.activeTask$.pipe(
      filter((task) => !!task),
      map((task) => task!.fields.find((field) => field.fieldId === fieldId)?.featureId),
      filter((vraFeatureId) => !!vraFeatureId),
      switchMap((vraFeatureId) => this._store.pipe(selectManyByPredicate((map) => map.featureId === vraFeatureId))),
      map((maps) => maps[0])
    );
  }

  // if any of the maps is calculated, the state is calculated
  // else it is saved
  public readonly stateByActiveTask$ = this.prescriptionMapsByActiveTask$.pipe(
    startWith([] as PrescriptionMap[]),
    map((maps) => (maps.some((map) => map.state === 'calculated') ? 'calculated' : 'saved')),
    mapAs<PrescriptionMap['state']>(),
    shareReplay(latest)
  );

  public readonly hasPast$ = this.prescriptionMapsByActiveTask$.pipe(
    // ? for some reason the history is not updated at this time so a delay until next tick works
    // ? a clean example on stackblitz shows that it should work without the delay
    // ? so a solution or root of issue should be found
    emissionTickDelay(),
    map((maps) => maps.some((map) => this._history.hasPast(map.id)))
  );

  public readonly statistics$ = this._store.pipe(
    select(({ avg, total }) => ({ avg, total })),
    mapAs<Statistics>(),
    distinctUntilChanged((a, b) => isEqual(a, b))
  );

  public readonly isStatusChangedToSaved$ = this.vraRepo.activeTask$.pipe(
    map((x) => !!x),
    switchMap((hasActive) =>
      hasActive
        ? this.stateByActiveTask$.pipe(
            pairwise(),
            map(([last, curr]) => last !== 'saved' && curr === 'saved'),
            takeWhile((x) => x !== true, true)
          )
        : of(false)
    ),
    shareReplay(latest)
  );
}
