import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { LeafwetHoursDto, WetHourDto } from '@app/core/repositories/weather/leaf-wet-hours.interface';
import { WeatherDataObj } from '@app/core/repositories/weather/weather-data.interface';
import { WetHoursDataProviderStation } from '@app/shared/septoria/wet-hours-data-provider-station';
import { WetHoursDataProvider } from '@app/shared/septoria/wetHoursDataProvider';
import { DateTime } from 'luxon';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WeatherRepoService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService,
    private dataConnectionsService: DataConnectionsService
  ) {}

  public getRainData(
    farmId: number,
    harvestYear: number,
    featureId: number,
    startDate: DateTime,
    endDate: DateTime
  ): Observable<WeatherDataObj> {
    return this.httpClient.get<WeatherDataObj>(`${this.endpoints.foApi}/weatherdata/${farmId}/${harvestYear}`, {
      params: {
        featureId,
        dateFrom: startDate.toISO(),
        dateTo: endDate.toISO(),
        dataSources: [0, 1].toString(),
        interval: 1,
        dataParameters: 7,
      },
    });
  }

  public getDmiLeafWetHours(farmId: number, harvestYear: number, fieldId: number, diseaseType: number): Observable<LeafwetHoursDto> {
    return this.httpClient.get<LeafwetHoursDto>(`${this.endpoints.foApi}/leafwet/${farmId}/${harvestYear}/field/${fieldId}`, {
      params: {
        deceaseType: diseaseType,
      },
    });
  }

}
