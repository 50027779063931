<app-extra-module-dialog></app-extra-module-dialog>
<div class="site-wrapper" #wrapper *ngIf="hasPassedLogin">
  <div
    class="side-nav"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    [ngClass]="{ open: sideNavSmall.isMenuOpen, collapsed: (menuMinimized$ | async) }"
  >
    <app-header></app-header>
    <app-navigation></app-navigation>
    <app-footer [ngClass]="{ minimized: (menuMinimized$ | async) }"></app-footer>
  </div>
  <app-side-nav-toggle-button #sideNavSmall></app-side-nav-toggle-button>
  <div class="app-content-wrapper" [appResponsiveClass]="{ mobile: 'lt-Medium' }" [ngClass]="{ collapsed: (menuMinimized$ | async) }">
    <router-outlet></router-outlet>
  </div>
</div>
<app-environment-indicator></app-environment-indicator>
