import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { SoilSampleDTO } from '@app/core/repositories/soil-samples/soil-sample.interface';
import { ActiveFieldService } from '@app/map/features/vra/state/active-field.state';
import { filterNullOrEmpty, filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs';
import { MapControlSoilsampleService } from './map-control-soilsample.service';

export interface SoilSampleImageUrlSource {
  url: string;
  farmIds: number[];
  sampleDates: DateTime[];
  samples: SoilSampleDTO[];
  fields: string[];
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class SoilsampleUrlService {
  private readonly baseUrl = this.endpointService.foApi + '/maps/overlays/soilsamples/fields/{z}/{x}/{y}';
  constructor(
    private soilsampleService: MapControlSoilsampleService,
    private farmService: FarmStateService,
    private harvestYearService: HarvestYearStateService,
    private endpointService: EndpointsService,
    private activeFields: ActiveFieldService
  ) {
    const farms$ = this.farmService.selectedFarms$.pipe(filterNullOrEmpty());
    const harvestYear$ = this.harvestYearService.harvestYear$.pipe(filterNullish());
    const type$ = this.soilsampleService.selectedSoilsampleType$;
    const sampleDates$ = this.soilsampleService.selectedSoilSampleGroups$.pipe(map((groups) => groups?.map((group) => group.date)));
    const samples$ = this.soilsampleService.soilSamples$.pipe(filterNullOrEmpty());
    const activeFields$ = combineLatest([
      this.farmService.fields$.pipe(distinctUntilChanged()),
      this.activeFields.activeFieldsInTask$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([fields, activeTaskFields]) => {
        if (activeTaskFields) {
          return activeTaskFields.map((field) => field.fieldNumber);
        } else {
          return fields?.map((field) => field.number.toString());
        }
      })
    );
    combineLatest([farms$, type$, sampleDates$, samples$, activeFields$])
      .pipe(
        withLatestFrom(harvestYear$),
        map(([[farms, type, dates, samples, activeFields], year]) => {
          if (type === undefined || dates === undefined || dates === null || dates?.length === 0 || activeFields?.length === 0) {
            return { url: '', farmIds: [], sampleDates: [], samples: [], fields: [], type: '' } as SoilSampleImageUrlSource;
          }
          const fields = activeFields;
          const shorthandType = this.soilsampleService.getShortHandType(type);
          const url = `${this.baseUrl}?harvestYear=${year}&shortName=${shorthandType}`;
          return {
            url: url,
            farmIds: farms.map((farm) => farm.id),
            sampleDates: dates,
            samples: samples,
            fields: fields,
            type: this.soilsampleService.getShortHandType(type),
          } as SoilSampleImageUrlSource;
        }),
        distinctUntilChanged((prev, curr) => prev.url === curr.url),
        tap((url: SoilSampleImageUrlSource) => this.soilsampleUrlSubject_.next(url))
      )
      .subscribe();
  }

  private soilsampleUrlSubject_ = new BehaviorSubject<SoilSampleImageUrlSource | undefined>(undefined);
  public soilsampleUrl$ = this.soilsampleUrlSubject_.asObservable();
}
