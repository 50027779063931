import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { TDateISO } from '@app/core/types/iso-date.type';
import { NullableProp } from '@app/core/types/nullable.type';
import _ from 'lodash';
import { BasisLayerCategory } from '../../interfaces/basis-layer-category.interface';
import { CustomLevel } from '../../interfaces/custom-level.interface';
import {
  PotassiumSoilSampleCorrectionsDTO,
  PotassiumSSoilSampleInterval,
  SoilSampleCorrections,
  SoilSampleInterval,
} from '../../interfaces/phosphorus-samples.interface';
import { PrescriptionMap, PrescriptionMapCell, PrescriptionMapDto, SatelliteImageDate } from '../../interfaces/prescription-map.interface';
import { VraAllocationTypes } from '../../interfaces/vra-allocation-types.enum';
import { LimeSetting, LimeTypeNeed, VraSettings } from '../../interfaces/vra-lime-setting.interface';
import { VraTaskModel } from '../../interfaces/vra-task-model.interface';
import { VraTask } from '../../interfaces/vra-task.interface';
import { MinMax } from '../min-max.repository';
import { PotassiumSoilSampleCorrection } from '../potassium-soil-sample-corrections.repository';
import { SoilSampleAdjustment } from '../soil-sample-adjustments.repository';
import { SoilSampleCorrection } from '../soil-sample-corrections.repository';
export interface CreatePrescriptionMapBody {
  val: Array<PrescriptionMapRequest>;
}

export interface CreateLimePrescriptionMapBody {
  vraPrescriptionMapParameters: Array<PrescriptionMapRequest>;
  limeNeed: LimeSetting;
}

export interface CalculatePrescriptionMapBody {
  vraParameters: Array<PrescriptionMapRequest>;
  vraBasisLayerCategories: Array<BasisLayerCategory>;
  vraPrescriptionMapCollection: { vraPrescriptionMaps: Array<PrescriptionMap> };
  limeNeed?: LimeSetting;
  soilSampleCorrections?: SoilSampleCorrections;
  potassiumCorrections?: PotassiumSoilSampleCorrectionsDTO;
}

export interface UpdatePrescriptionMapBody {
  vraPrescriptionMaps: Array<NullableProp<PrescriptionMapDto, 'id'>>;
  soilSampleCorrections: SoilSampleCorrections;
  potassiumCorrections?: PotassiumSoilSampleCorrectionsDTO;
}

export interface SaveLimePrescriptionMapBody {
  vraPrescriptionMaps: Array<NullableProp<PrescriptionMapDto, 'id'>>;
  vraSettings: VraSettings;
}

export interface PrescriptionMapRequest {
  taskId: number;
  operationTypeGroup: OperationTypeGroupEnum;
  featureId?: number;
  satelliteImageDate?: SatelliteImageDate | null;
  modelId?: VraTaskModel['vraModelNormNumber'];
  solviFieldProjectId?: number;
  threshold?: number;
  adjustQuantityToPlannedAmount?: boolean;
  adjustPlannedAmount?: boolean;
  customPlannedAmount?: number | undefined;
  minAmount?: number;
  maxAmount?: number;
  vraCustomLevel?: CustomLevel[];
  soilSampleDate?: TDateISO;
  soilSampleAdjustments?: Omit<SoilSampleAdjustment, 'prescriptionMapId'>;
}

export interface OperationLinePatch {
  operationLineId: number;
  quantity: number;
  vraPrescriptionMapId: number | null;
}

export const createSaveRequestBody = (
  prescriptionMaps: PrescriptionMap[],
  intervals?: SoilSampleInterval[],
  potassiumIntervals?: PotassiumSSoilSampleInterval[]
): UpdatePrescriptionMapBody => {
  return {
    vraPrescriptionMaps: prescriptionMaps.map((map) => ({
      ...map,
      id: null,
      minAmount: map.minAmount ?? null,
      maxAmount: map.maxAmount ?? null,
    })),
    soilSampleCorrections: {
      intervals: intervals as SoilSampleInterval[],
    },
    potassiumCorrections: {
      Intervals: potassiumIntervals as PotassiumSSoilSampleInterval[],
    },
  };
};

export const createLimeSaveRequestBody = (
  prescriptionMaps: PrescriptionMap[],
  cutoff: LimeSetting['cutoff'],
  limeTypeNeed: LimeSetting['limeTypeNeed'],
  unit: VraSettings['unitText'],
  intervals?: SoilSampleInterval[]
): SaveLimePrescriptionMapBody => {
  return {
    vraPrescriptionMaps: prescriptionMaps.map((map) => ({
      ...map,
      id: null,
      minAmount: map.minAmount ?? null,
      maxAmount: map.maxAmount ?? null,
    })),
    vraSettings: {
      limeSetting: {
        cutoff: cutoff,
        limeTypeNeed: limeTypeNeed,
      },
      unitText: unit,
      soilSampleCorrections: {
        intervals: intervals as SoilSampleInterval[],
      },
    },
  };
};

export const createPrescriptionMapCalculateBody = (
  map: PrescriptionMap,
  categories: BasisLayerCategory[],
  cells: PrescriptionMapCell[],
  adjustPlannedAmount: boolean,
  minMax: MinMax[],
  levels?: CustomLevel[],
  limeSetting?: LimeSetting,
  soilSampleAdjustments?: SoilSampleAdjustment[],
  soilSampleCorrections?: SoilSampleCorrection[],
  potassiumSoilSampleCorrections?: PotassiumSoilSampleCorrection[]
): CalculatePrescriptionMapBody => {
  const patchedMap = {
    ...map,
    customLevels: levels || [],
    cells: replaceCells(map.cells, cells),
    adjustPlannedAmount: adjustPlannedAmount,
    minAmount: minMax.find((x) => x.prescriptionMapId === map.id)?.minAmount,
    minAmountDefault: minMax.find((x) => x.prescriptionMapId === map.id)?.minAmountDefault ?? map.minAmountDefault,
    maxAmount: minMax.find((x) => x.prescriptionMapId === map.id)?.maxAmount,
    maxAmountDefault: minMax.find((x) => x.prescriptionMapId === map.id)?.maxAmountDefault ?? map.maxAmountDefault,
    soilSampleAdjustments: {
      yieldCorrection: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.yieldCorrection as number,
      afterEffect: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.afterEffect as number,
      scaleAmount: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.scaleAmount as number,
    },
  };

  return {
    vraBasisLayerCategories: categories.filter((category) => category.priority),
    vraParameters: [
      {
        taskId: patchedMap.taskId,
        featureId: patchedMap.featureId,
        modelId: patchedMap.vraModelNormNumber,
        satelliteImageDate: patchedMap.satelliteImageDate?.date != null ? patchedMap.satelliteImageDate : null,
        operationTypeGroup: patchedMap.operationTypeGroup,
        vraCustomLevel: levels,
        adjustPlannedAmount: adjustPlannedAmount,
        minAmount: patchedMap.minAmount ?? undefined,
        maxAmount: patchedMap.maxAmount ?? undefined,
        soilSampleDate: patchedMap.soilSampleDate ?? undefined,
        soilSampleAdjustments: {
          yieldCorrection: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.yieldCorrection as number,
          afterEffect: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.afterEffect as number,
          scaleAmount: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.scaleAmount as number,
        },
      },
    ],
    vraPrescriptionMapCollection: { vraPrescriptionMaps: [patchedMap] },
    limeNeed: limeSetting,
    soilSampleCorrections: {
      intervals: soilSampleCorrections?.map((item: SoilSampleCorrection) => {
        const { correctionId, ...rest } = item;
        return rest as SoilSampleInterval;
      }) as SoilSampleInterval[],
    },
    potassiumCorrections: {
      Intervals: potassiumSoilSampleCorrections?.map((item: PotassiumSoilSampleCorrection) => {
        const { correctionId, ...rest } = item;
        return rest as PotassiumSSoilSampleInterval;
      }) as PotassiumSSoilSampleInterval[],
    },
  };
};

export const createPrescriptionMapsCalculateBody = (
  maps: PrescriptionMap[],
  categories: BasisLayerCategory[],
  cells: PrescriptionMapCell[],
  adjustPlannedAmount: boolean,
  minMax: MinMax[],
  levels?: CustomLevel[],
  limeSetting?: LimeSetting,
  soilSampleAdjustments?: SoilSampleAdjustment[],
  soilSampleCorrections?: SoilSampleCorrection[],
  potassiumSoilSampleCorrections?: PotassiumSoilSampleCorrection[],
  customPlannedAmount?: number | undefined
): CalculatePrescriptionMapBody => {
  const totalPlannedAmount = _.sum(maps.map((map) => map.totalQuantity));
  const patchedMaps = maps.map((map) => ({
    ...map,
    customLevels: levels || [],
    cells: replaceCells(map.cells, cells),
    adjustPlannedAmount: adjustPlannedAmount,
    minAmount: minMax.find((x) => x.prescriptionMapId === map.id)?.minAmount,
    minAmountDefault: minMax.find((x) => x.prescriptionMapId === map.id)?.minAmountDefault ?? map.minAmountDefault,
    maxAmount: minMax.find((x) => x.prescriptionMapId === map.id)?.maxAmount,
    maxAmountDefault: minMax.find((x) => x.prescriptionMapId === map.id)?.maxAmountDefault ?? map.maxAmountDefault,
    soilSampleAdjustments: {
      yieldCorrection: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.yieldCorrection as number,
      afterEffect: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.afterEffect as number,
      scaleAmount: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.scaleAmount as number,
    },
  }));
  return {
    vraBasisLayerCategories: categories.filter((category) => category.priority),
    vraParameters: patchedMaps.map((map) => {
      const customPlannedAmountForMap = customPlannedAmount ? (map.totalQuantity / totalPlannedAmount) * customPlannedAmount : undefined;
      return {
        taskId: map.taskId,
        featureId: map.featureId,
        modelId: map.vraModelNormNumber,
        satelliteImageDate: map.satelliteImageDate,
        operationTypeGroup: map.operationTypeGroup,
        vraCustomLevel: levels,
        adjustPlannedAmount: adjustPlannedAmount,
        customPlannedAmount: customPlannedAmountForMap,
        minAmount: map.minAmount ?? undefined,
        maxAmount: map.maxAmount ?? undefined,
        soilSampleDate: (map.soilSampleGroups?.[0]?.endDate as TDateISO) ?? undefined,
        soilSampleAdjustments: {
          yieldCorrection: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.yieldCorrection as number,
          afterEffect: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.afterEffect as number,
          scaleAmount: soilSampleAdjustments?.find((x) => x.prescriptionMapId === map.id)?.scaleAmount as number,
        },
      };
    }),
    vraPrescriptionMapCollection: { vraPrescriptionMaps: patchedMaps },
    limeNeed: limeSetting,
    soilSampleCorrections: {
      intervals: soilSampleCorrections?.map((item: SoilSampleCorrection) => {
        const { correctionId, ...rest } = item;
        return rest as SoilSampleInterval;
      }) as SoilSampleInterval[],
    },
    potassiumCorrections: {
      Intervals: potassiumSoilSampleCorrections?.map((item: PotassiumSoilSampleCorrection) => {
        const { correctionId, ...rest } = item;
        return rest as PotassiumSSoilSampleInterval;
      }) as PotassiumSSoilSampleInterval[],
    },
  };
};

export const createPrescriptionMapRequestBody = (task: VraTask): CreatePrescriptionMapBody => {
  const val = _(task.fields)
    .flatMap((field) => field.operationLines.map((line) => ({ ...line, featureId: field.featureId })))
    .map<PrescriptionMapRequest>((operationLine) => ({
      operationTypeGroup: task.group,
      featureId: operationLine.featureId,
      taskId: operationLine.taskId,
      adjustPlannedAmount:
        task.group === OperationTypeGroupEnum.PlantProtection ||
        task.group === OperationTypeGroupEnum.Seeding ||
        task.group === OperationTypeGroupEnum.Lime ||
        task.allocationType === VraAllocationTypes.PhosphorSoilSamples ||
        task.allocationType === VraAllocationTypes.CaliumSoilSamples ||
        task.allocationType === VraAllocationTypes.PotassiumSoilSamples
          ? true
          : false,
    }))
    .uniqBy('taskId')
    .value();

  return { val };
};

export const createTemporaryPotassiumPrescriptionMapsRequestBody = (task: VraTask): Array<PrescriptionMapRequest> => {
  return _(task.fields)
    .flatMap((field) => field.operationLines.map((line) => ({ ...line, featureId: field.featureId })))
    .map<PrescriptionMapRequest>((operationLine) => ({
      operationTypeGroup: task.group,
      featureId: operationLine.featureId,
      taskId: operationLine.taskId,
      adjustPlannedAmount:
        task.group === OperationTypeGroupEnum.PlantProtection || task.group === OperationTypeGroupEnum.Seeding ? true : false,
    }))
    .uniqBy('taskId')
    .value();
};

export const createDeletedPrescriptionMapRequestBody = (task: VraTask, deletedFields: number[]): CreatePrescriptionMapBody => {
  const val = _(task.fields)
    .filter((field) => deletedFields.includes(field.featureId))
    .flatMap((field) => field.operationLines.map((line) => ({ ...line, featureId: field.featureId })))
    .map<PrescriptionMapRequest>((operationLine) => ({
      operationTypeGroup: task.group,
      featureId: operationLine.featureId,
      taskId: operationLine.taskId,
      adjustPlannedAmount:
        task.group === OperationTypeGroupEnum.PlantProtection ||
        task.group === OperationTypeGroupEnum.Seeding ||
        task.group === OperationTypeGroupEnum.Lime ||
        task.allocationType === VraAllocationTypes.PhosphorSoilSamples
          ? true
          : false,
    }))
    .uniqBy('taskId')
    .value();

  return { val };
};

export const createLimeCreateRequestBody = (
  maps: PrescriptionMap[],
  cutoff: number,
  limeTypeNeed: LimeTypeNeed
): CreateLimePrescriptionMapBody => {
  const vraParameters = maps.map((map) => ({
    taskId: map.taskId,
    featureId: map.featureId,
    modelId: map.vraModelNormNumber,
    operationTypeGroup: map.operationTypeGroup,
    suggestion: map.modelSuggestion,
  }));

  return {
    vraPrescriptionMapParameters: vraParameters,
    limeNeed: {
      cutoff: cutoff ?? 0,
      limeTypeNeed: limeTypeNeed,
    },
  };
};

export const createOperationLinesPatchRequestBody = (task: VraTask, maps: PrescriptionMap[]): OperationLinePatch[] => {
  return _(task.fields)
    .flatMap((field) => field.operationLines.map((line) => ({ ...line, featureId: field.featureId })))
    .map<OperationLinePatch>((operationLine) => {
      const foundMap = maps.find((map) => map.featureId === operationLine.featureId);
      const totalQuantity = foundMap?.totalProductQuantity ?? 0;
      const area = foundMap?.area ?? 1; // Avoid division by 0 if area is undefined

      return {
        operationLineId: operationLine.id,
        quantity: totalQuantity / area,
        vraPrescriptionMapId: null,
      };
    })
    .uniqBy('operationLineId')
    .value();
};

function replaceCells(baseCells: PrescriptionMapCell[], replacements: PrescriptionMapCell[]): PrescriptionMapCell[] {
  return baseCells.map((cell) => {
    const replacement = replacements.find((rep) => rep.id === cell.id);
    return replacement || cell;
  });
}
