import { Injectable } from '@angular/core';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { OperationLine } from '@app/core/interfaces/operation-line.interface';
import { Operation } from '@app/core/interfaces/operation.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { ProduceNormsStorage } from '@app/core/interfaces/produce-norms-storage.interface';
import { QualityParameter } from '@app/core/interfaces/quality-parameter.interface';
import { Task, TaskDto } from '@app/core/interfaces/task.interface';
import { DateTime } from 'luxon';
import { DirectorateCropNorms } from '../enums/directorate-crop-norms.enum';
import { OperationTypes } from '../enums/operation-types.enum';
import { ProduceNormNumbers } from '../enums/produce-norm-numbers.enum';
import { ProduceNormUnits } from '../enums/produce-norm-units.enum';
import { YieldTypeGroups } from '../enums/yield-type-groups.enum';

export interface IBenchmarkDataService {
  produceNormsStorage: Array<ProduceNormsStorage>;
  findSelectedVarieties(field: Field): Array<ProduceNorm>;
  findValidVarieties(field: Field): Array<ProduceNorm>;
  findTotalYield(tasks: Array<Task>, farmId: number): number;
  findProduceNorm(produceNormNumber: string, farmId: number): ProduceNorm;
  findGreenMassProduceNorm(farmId: number, cropNormNumber: number): ProduceNorm | null;
  findFarmName(farmId: number, farms: Array<Farm>): string | undefined;
  findDecisionMethodQualityParameter(field: Field): number | null;
  findProduceNormsFromVarietyNumber(vareityGroupNormNumber: number, farmId: number): Array<ProduceNorm>;
  findMainCropProduceNorm(field: Field): ProduceNorm;
  findDryMatterYield(field: Field): number;
  findDryMatterUnit(farmId: number): ProduceNorm;
  findDryMatterPct(field: Field): number;
  setVarietyForMainCrop(field: Field): void;
  setDecisionMethodQualityParam(field: Field): void;
  setTotalYieldsForField(field: Field): void;
  setYieldQuantities(field: Field): void;
  setDryMatterPct(field: Field): void;
  isCorn(field: Field): boolean;
  hasMoreMainYields(tasks: Array<Task>): boolean;
  hasOneMainYieldAndIsRegistered(tasks: Array<Task>): boolean | undefined;
  hasMoreDryMatterYields(field: Field): boolean;
  getTaskIndex(tasks: Array<Task>): number;
  enrichFields(fields: Array<Field>, farms: Array<Farm>): void;
}

@Injectable({
  providedIn: 'root',
})
export class BenchmarkDataService implements IBenchmarkDataService {
  private readonly dryMatterProduceNormDummy = {
    isMainProduct: false,
    unitText: ProduceNormUnits.TON,
    number: ProduceNormNumbers.DryMatter,
  } as ProduceNorm;

  private readonly produceNormEmpty = {
    isMainProduct: false,
    unitText: undefined,
    number: '',
  } as ProduceNorm;

  public produceNormsStorage: Array<ProduceNormsStorage>;

  constructor() {
    this.produceNormsStorage = [];
  }

  /**
   * Determines whether the given set of tasks has more than one main yield operation line.
   * @param tasks Tasks to check for main yields.
   */
  public hasMoreMainYields(tasks: Array<TaskDto>): boolean {
    const mainProductsCount = this.countMainProducts(tasks);

    return mainProductsCount > 1;
  }

  /**
   * Determines whether the given set of tasks has one main yield operation line marked as registered.
   * Return true if tasks has more than one main yield because value on registered then has no sense
   * @param tasks Tasks to check for main yields and registered.
   */
  public hasOneMainYieldAndIsRegistered(tasks: Array<TaskDto>): boolean | undefined {
    const mainProductsCount = this.countMainProducts(tasks);
    if (mainProductsCount === 1) {
      const mainProductTaskIndex = this.getTaskIndex(tasks);
      if (mainProductTaskIndex >= 0) {
        return tasks[mainProductTaskIndex].registered;
      }
    }
    return mainProductsCount > 1 ? true : false;
  }

  /**
   * Find first Task marked as mainProduct in tasks array
   * return -1 if not found and index number if found
   * @param tasks Tasks to check for MainProduct.
   */
  public getTaskIndex(tasks: Array<TaskDto>): number {
    let taskindex = -1;
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      for (const operation of task.operations) {
        for (const line of operation.operationLines) {
          if (operation.operationTypeId === OperationTypes.Yield) {
            const produceNorm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, task.farmId);
            if (produceNorm.isMainProduct) {
              taskindex = i;
              break;
            }
          }
        }
        break;
      }
    }
    return taskindex;
  }

  /**
   * Sets the variety for the main crop of the given field.
   * @param field The field to set variety for.
   */
  public setVarietyForMainCrop(field: Field): void {
    let seedOperationsTaskIndex = -1;

    const { tasks, selectedVarieties } = field;

    for (const task of tasks!) {
      const operations: Operation[] = task.operations.filter((operation) => operation.operationTypeId === OperationTypes.Seed);

      if (operations.length) {
        seedOperationsTaskIndex = operations.length;
        for (const operation of operations) {
          for (const line of operation.operationLines) {
            line.produceNormNumber = selectedVarieties![0].number;
          }
        }
      }
    }

    // If there was no seed operation we have to make one.
    if (seedOperationsTaskIndex === -1) {
      this.addTask(field, selectedVarieties![0].number, OperationTypes.Seed);
    }
  }

  /**
   * Finds the farm name from a given farm id and set of farms.
   *
   * @param farmId The id of the farm to look for.
   * @param farms The array of famrs to look in.
   */
  public findFarmName(farmId: number, farms: Array<Farm>): string | undefined {
    for (const farm of farms) {
      if (farmId !== farm.id) continue;
      return farm.name;
    }
    return;
  }

  /**
   * Finds the current variety for a given field.
   * @param field The field to find the crop type for.
   */
  public findSelectedVarieties(field: Field): Array<ProduceNorm> {
    const varietyProduceNorms: Array<ProduceNorm> = [];

    const { tasks, farmId, crops } = field;

    for (const task of tasks!) {
      for (const operation of task.operations) {
        if (operation.operationTypeId === OperationTypes.Seed) {
          for (const line of operation.operationLines) {
            const produceNorm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, farmId);
            if (!produceNorm) {
              continue;
            }
            for (const crop of crops) {
              for (const valid of crop.validVarietyGroupNormNumbers) {
                if (produceNorm.varietyGroupNormNumber === valid) {
                  varietyProduceNorms.push(this.findProduceNorm(line.produceNormNumber, farmId));
                }
              }
            }
          }
        }
      }
    }
    return varietyProduceNorms;
  }

  /**
   * Finds the current variety for a given field.
   * @param tasks The tasks to find the crop type for.
   */
  public findSelectedVariety(tasks: Task[]): ProduceNorm | null {
    let varietyProduceNorm: ProduceNorm | null = null;

    for (const task of tasks) {
      for (const operation of task.operations) {
        if (operation.operationTypeId === OperationTypes.Seed) {
          for (const line of operation.operationLines) {
            if (!line.produceNorm) {
              continue;
            } else {
              varietyProduceNorm = line.produceNorm;
            }
          }
        }
      }
    }

    return varietyProduceNorm;
  }

  /**
   * Find the produce norm for the main product of the field.
   * @param field The field to find data for.
   */
  public findMainCropProduceNorm(field: Field): ProduceNorm {
    let mainProduceNorm: ProduceNorm | undefined;

    const { tasks, farmId, mainCropId } = field;

    const cropTasks: Array<TaskDto> = tasks!.filter((task: TaskDto) => {
      return task.cropId === mainCropId;
    });

    cropTasks.forEach((task: TaskDto) => {
      const yieldOperations = task.operations.filter((operation: Operation) => {
        return operation.operationTypeId === OperationTypes.Yield;
      });

      yieldOperations.forEach((operation: Operation) => {
        for (const line of operation.operationLines) {
          const produceNorm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, farmId);
          if (!produceNorm) {
            continue;
          }
          if (produceNorm.isMainProduct) {
            mainProduceNorm = produceNorm;
          }
        }
      });
    });

    if (!mainProduceNorm) {
      mainProduceNorm = this.produceNormEmpty;
    }

    return mainProduceNorm;
  }

  /**
   * Finds the varieties for a given field.
   * @param field The field to find the varieties for.
   */
  public findValidVarieties(field: Field): Array<ProduceNorm> {
    let varieties: Array<ProduceNorm> = [];

    const { crops, farmId, mainCropId } = field;

    const mainCrop: Crop = crops.filter((crop: Crop) => {
      return crop.id === mainCropId;
    })[0];

    mainCrop.validVarietyGroupNormNumbers.forEach((id: number) => {
      varieties = varieties.concat(this.findProduceNormsFromVarietyNumber(id, farmId));
    });

    return varieties;
  }

  /**
   * Finds produce norms based on variety group norm number.
   * @param varietyGroupNormNumber The group norm number to find produce norms for.
   * @param farmId The farm.
   */
  public findProduceNormsFromVarietyNumber(varietyGroupNormNumber: number, farmId: number): Array<ProduceNorm> {
    const produceNorms: Array<ProduceNorm> = this.findProduceNormsForFarm(farmId);

    return produceNorms.filter((produceNorm: ProduceNorm) => {
      return produceNorm.varietyGroupNormNumber === varietyGroupNormNumber;
    });
  }

  /**
   * Finds the producenorm matching the given produce norm number.
   * @param produceNormNumber The produce norm number to find.
   * @param farmId The farm fo find produce norm for.
   */
  public findProduceNorm(produceNormNumber: string, farmId: number): ProduceNorm {
    const produceNorms: Array<ProduceNorm> = this.findProduceNormsForFarm(farmId);
    for (const norm of produceNorms) {
      if (norm.number === produceNormNumber) {
        return norm;
      }
    }

    return this.dryMatterProduceNormDummy;
  }

  /**
   * Finds the greenmass producenorm matching the produceNormNumber and farId
   * @param farmId
   * @param harvestYear
   * @param cropNormNumber
   */
  public findGreenMassProduceNorm(farmId: number, cropNormNumber: number): ProduceNorm | null {
    for (const produceNorm of this.produceNormsStorage) {
      if (produceNorm.farmId === farmId) {
        for (const norm of produceNorm.produceNorms) {
          // eslint-disable-next-line eqeqeq
          if (
            norm.cropNormNumber === Number(cropNormNumber) &&
            norm.operationTypeGroupId === OperationTypes.Yield &&
            norm.operationTypeId === OperationTypes.Seed &&
            norm.yieldTypeGroupNormNumber === YieldTypeGroups.GreenMass
          ) {
            return norm;
          }
        }
      }
    }

    return null;
  }

  /**
   * Finds the harvest producenorm matching the produceNormNumber and farmId
   * @param farmId
   * @param cropNormNumber
   */
  public findHarvestProduceNorm(farmId: number, cropNormNumber: number): ProduceNorm | null {
    for (const produceNorm of this.produceNormsStorage) {
      if (produceNorm.farmId === farmId) {
        for (const norm of produceNorm.produceNorms) {
          if (norm.isHarvestedMainProduct && norm.cropNormNumber === Number(cropNormNumber)) {
            return norm;
          }
        }
      }
    }

    return null;
  }

  /**
   * Accumulates the total yield for the main product in a list of tasks.
   * @param tasks The list of tasks to accumulate yield for.
   * @param produceNorms The list of produce norms for the farm.
   */
  public findTotalYield(tasks: Array<TaskDto>, farmId: number): number {
    let totalYield = 0;
    tasks.forEach((task: TaskDto) => {
      task.operations.forEach((operation: Operation) => {
        if (operation.operationTypeId === OperationTypes.Yield) {
          operation.operationLines.forEach((line: OperationLine) => {
            const produceNorm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, farmId);
            if (produceNorm.isMainProduct) {
              totalYield += line?.quantity || 0;
            }
          });
        }
      });
    });

    return totalYield;
  }

  /**
   * Determines if the main crop for a given field is corn.
   * @param field The field to check.
   */
  public isCorn(field: Field): boolean {
    const mainCrop: Array<Crop> = field.crops.filter((crop: Crop) => {
      return crop.lawDK.directorateCropNormNumber === DirectorateCropNorms.Corn && field.mainCropId === crop.id;
    });

    return !!mainCrop.length;
  }

  /**
   * Counts the number of dry matter yield operation lines.
   * @param field The field to count dry matter lines for.
   */
  public findDryMatterYieldCount(field: Field): number {
    let count = 0;

    const { tasks } = field;

    tasks!.forEach((task: TaskDto) => {
      const yieldOperations = task.operations.filter((operation: Operation) => {
        return operation.operationTypeId === OperationTypes.Yield;
      });
      yieldOperations.forEach((operation: Operation) => {
        const dryMatterLines = operation.operationLines.filter((line: OperationLine) => {
          return line.produceNormNumber === ProduceNormNumbers.DryMatter;
        });
        count += dryMatterLines.length;
      });
    });

    return count;
  }

  /**
   * Finds the total dry matter yield for a field.
   * @param field The field to find dry matter yield for.
   */
  public findDryMatterYield(field: Field): number {
    if (!this.findDryMatterYieldCount(field)) {
      this.addTask(field, ProduceNormNumbers.DryMatter, OperationTypes.Yield);
    }

    let amount = 0;

    field.tasks!.forEach((task: TaskDto) => {
      const yieldOperations = task.operations.filter((operation: Operation) => {
        return operation.operationTypeId === OperationTypes.Yield;
      });

      yieldOperations.forEach((operation: Operation) => {
        const dryMatterLines = operation.operationLines.filter((line: OperationLine) => {
          return line.produceNormNumber === ProduceNormNumbers.DryMatter;
        });

        dryMatterLines.forEach((line: OperationLine) => {
          amount += line?.quantity || 0;
        });
      });
    });

    return amount;
  }

  /**
   * Finds the producenorm for dry matter.
   * @param farmId The selected farm.
   */
  public findDryMatterUnit(farmId: number): ProduceNorm {
    return this.findProduceNorm(ProduceNormNumbers.DryMatter, farmId);
  }

  /**
   * Finds the dry matter percentage in the quality parameters for a given field.
   * @param field: The field to find dry matter percentage for.
   */
  public findDryMatterPct(field: Field): number {
    let quality = 0;
    let qualityParamCount = 0;

    const { tasks, farmId, harvestYear } = field;

    tasks!.forEach((task: TaskDto) => {
      const yieldOperations = task.operations.filter((operation: Operation) => {
        return operation.operationTypeId === OperationTypes.Yield;
      });

      for (const yieldOp of yieldOperations) {
        const operation: Operation = yieldOp;

        const dryMatterLines = operation.operationLines.filter((line: OperationLine) => {
          return line.produceNormNumber === ProduceNormNumbers.DryMatter;
        });

        for (let j = 0; j < dryMatterLines.length; j++) {
          const line: OperationLine = operation.operationLines[j];

          let dryMatterQualityParams = line.qualityParameters.filter((param: QualityParameter) => {
            return param.normNumber === 1;
          });

          if (!dryMatterQualityParams.length) {
            line.qualityParameters.push({
              farmId: farmId,
              harvestYear: harvestYear,
              id: undefined,
              normNumber: 1,
              operationLineId: line.id,
              value: 0,
            });
          }

          dryMatterQualityParams = line.qualityParameters.filter((param: QualityParameter) => {
            return param.normNumber === 1;
          });

          for (const param of dryMatterQualityParams) {
            quality += param.value!;
            qualityParamCount++;
          }
        }
      }
    });

    return quality / qualityParamCount || 0;
  }

  /**
   * Finds the first (and best) quality parameter of yield operations for all tasks.
   * @param fields The fields to find quality parameter for.
   */
  public findDecisionMethodQualityParameter(field: Field): number | null {
    let qualityParam: number | null = null;

    const { tasks, farmId } = field;

    for (const task of tasks!) {
      for (const operation of task.operations) {
        if (operation.operationTypeId === OperationTypes.Yield) {
          for (const line of operation.operationLines) {
            const norm = this.findProduceNorm(line.produceNormNumber, farmId);
            if (!norm.isMainProduct) {
              break;
            }
            for (const param of line.qualityParameters) {
              if (param.normNumber === 6) {
                qualityParam = param.value || null;
              }
            }
            break;
          }
          break;
        }
      }
    }
    return qualityParam;
  }

  /**
   * Traverses the operation lines and sets the qualityparameter on each of them.
   * All existing parameters are overwritten with the new value.
   * @param field The field to modify quality parameter for.
   */
  public setDecisionMethodQualityParam(field: Field): void {
    const { tasks, harvestYear, farmId, qualityParam } = field;

    tasks!.forEach((task: TaskDto) => {
      task.operations.forEach((operation: Operation) => {
        if (operation.operationTypeId === OperationTypes.Yield) {
          operation.operationLines.forEach((line: OperationLine) => {
            const norm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, farmId);
            if (norm.isMainProduct) {
              const paramIndex = line.qualityParameters.findIndex((param) => param.normNumber === 6);

              if (paramIndex >= 0) {
                line.qualityParameters[paramIndex].value = qualityParam;
              } else {
                const newParam = {
                  id: null,
                  farmId: farmId,
                  operationLineId: line.id,
                  harvestYear: harvestYear,
                  normNumber: 6,
                  value: qualityParam,
                };
                line.qualityParameters.push(newParam);
              }
            }
          });
        }
      });
    });
  }

  /**
   * Finds and sets the total yield view value for a given field.
   * @param field The field to set yields for.
   */
  public setTotalYieldsForField(field: Field): void {
    let yieldTotal: number;

    if (this.isCorn(field)) {
      yieldTotal = Math.round((field.yieldTotal = this.findDryMatterYield(field) * 10)) / 10 || 0;
    } else {
      yieldTotal = Math.round(this.findTotalYield(field.tasks!, field.farmId) * 10) / 10 || 0;
    }

    let yieldPerHa = Math.round((yieldTotal / field.area) * 100) / 100;

    if (isFinite(yieldPerHa)) {
      field.yieldPerHa = yieldTotal;
    } else {
      field.yieldPerHa = Math.round(yieldTotal / field.area);
    }

    field.yieldTotal = Math.round(field.area * field.yieldPerHa * 100) / 100;
  }

  /**
   * Updates the single operation line with the user typed yield.
   * Only works if there is exactly one operation on the field that has main yields.
   * Handles both dry matter and main yield.
   * @param field The field to update yield for.
   */
  public setYieldQuantities(field: Field): void {
    if (!this.hasMoreMainYields(field.tasks!) && !this.isCorn(field)) {
      this.setMainYieldQuantity(field);
    } else if (!this.hasMoreDryMatterYields(field) && this.isCorn(field)) {
      this.setDryMatterQuantity(field);
    }
  }

  /**
   * Sets the dry matter percentage for fields that have corn and only one dry matter yield.
   * @param field The field to set percentage for.
   */
  public setDryMatterPct(field: Field): void {
    if (this.isCorn(field) && !this.hasMoreDryMatterYields(field)) {
      for (const task of field.tasks!) {
        for (const operation of task.operations) {
          if (operation.operationTypeId === OperationTypes.Yield) {
            for (const line of operation.operationLines) {
              if (line.produceNormNumber === ProduceNormNumbers.DryMatter) {
                for (const param of line.qualityParameters) {
                  if (param.normNumber === 1) {
                    param.value = field.dryMatter;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Determines if a given field has more than one dry matter yield operation line.
   * @param field The field to check.
   */
  public hasMoreDryMatterYields(field: Field): boolean {
    return this.findDryMatterYieldCount(field) > 1;
  }

  /**
   * Adds tasks to the corresponding field.
   */
  public mapTasksToField(fields: Array<Field>, tasks: Array<Task>): void {
    fields.forEach((field: Field) => {
      field.tasks = [];
      field.crops.forEach((crop: Crop) => {
        tasks.forEach((task: Task) => {
          if (task.cropId === crop.id) {
            field.tasks!.push(task);
          }
        });
      });
    });
  }

  /**
   * Enriches an array of fields with necessary data for the registrations view.
   * @param fields The array of fields to enrich.
   * @param farms The currently selected farms.
   */
  public enrichFields(fields: Array<Field>, farms: Array<Farm>): void {
    fields.forEach((field: Field) => {
      field.selectedVarieties = this.findSelectedVarieties(field);
      field.farmName = this.findFarmName(field.farmId, farms);
      field.qualityParam = this.findDecisionMethodQualityParameter(field);
      field.validVarieties = this.findValidVarieties(field);
      field.mainProductProduceNorm = this.findMainCropProduceNorm(field);
      field.dryMatter = 0;

      if (this.isCorn(field)) {
        field.mainProductProduceNorm = {
          unitText: 'ton',
          number: ProduceNormNumbers.DryMatter,
          isMainProduct: false,
        } as ProduceNorm;
      }

      this.setTotalYieldsForField(field);
      field.dryMatter = Math.round(this.findDryMatterPct(field) * 10) / 10;
    });
  }

  /**
   * Finds the produce norms related to a given farm id.
   *
   * @param farmId The farm to find produce norms for.
   * @param produceNorms The list of produce norms to search.
   */
  public findProduceNormsForFarm(farmId: number): Array<ProduceNorm> {
    if (this.produceNormsStorage) {
      for (const norm of this.produceNormsStorage) {
        if (norm.farmId === farmId) {
          return norm.produceNorms;
        }
      }
    }
    return [];
  }

  /**
   * Sets the quantity of the main products yield for a given field.
   * Should only be done if there is one or zero matching operation lines.
   * @param field The field to set yield quantity for.
   */
  public setMainYieldQuantity(field: Field): void {
    field.tasks!.forEach((task: TaskDto) => {
      task.operations.forEach((operation: Operation) => {
        if (operation.operationTypeId === OperationTypes.Yield) {
          operation.operationLines.forEach((line: OperationLine) => {
            const produceNorm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, field.farmId);
            if (produceNorm.isMainProduct) {
              line.quantity = field.yieldPerHa;

              task.registered = field.registered;
            }
          });
        }
      });
    });
  }

  /**
   * Sets the quantity of the dry matter yield for a given field.
   * This currently only works for corn.
   * @param field The for to set yield quantity for.
   */
  public setDryMatterQuantity(field: Field): void {
    field.tasks!.forEach((task: TaskDto) => {
      task.operations.forEach((operation: Operation) => {
        if (operation.operationTypeId === OperationTypes.Yield) {
          const dryMatterLines = operation.operationLines.filter((line: OperationLine) => {
            return line.produceNormNumber === ProduceNormNumbers.DryMatter;
          });

          dryMatterLines.forEach((line: OperationLine) => {
            line.quantity = field.yieldPerHa;
            task.registered = true;
          });
        }
      });
    });
  }

  /**
   * Adds a dry matter task to a field.
   * @param field The field to add a dry matter task to.
   */
  public addTask(field: Field, produceNormNumber: string, operationTypeId: number): void {
    const mainCrop: Crop = this.getCropById(field.mainCropId!, field.crops);

    field.tasks!.push({
      area: mainCrop.area,
      comment: undefined,
      cropId: mainCrop.id,
      date: DateTime.now(),
      executing: undefined,
      expenseBorneByField: undefined,
      farmId: field.farmId,
      harvestYear: mainCrop.harvestYear,
      hoursUsed: undefined,
      id: undefined,
      isMachineStationJob: undefined,
      operations: [
        {
          farmId: field.farmId,
          harvestYear: mainCrop.harvestYear,
          id: undefined,
          index: 1,
          issues: undefined,
          operationLines: [
            {
              comment: undefined,
              farmId: field.farmId,
              harvestYear: mainCrop.harvestYear,
              hoursUsed: undefined,
              id: undefined,
              index: 1,
              nutrientSupply: undefined,
              operationId: undefined,
              produceNormNumber,
              qualityParameters: [],
              quantity: 0,
              theTime: null,
              pricePerUnit: 0,
            },
          ],
          operationTypeId,
          taskId: null,
        },
      ],
      operatorId: null,
      registered: true,
      responsibleId: null,
    });
  }

  /**
   * Counts the number of main product yields on a field.
   * @param field The field to count yields for.
   */
  private countMainProducts(tasks: Array<TaskDto>): number {
    let numberOfYields = 0;

    if (tasks) {
      tasks.forEach((task: TaskDto) => {
        task.operations.forEach((operation: Operation) => {
          operation.operationLines.forEach((line: OperationLine) => {
            if (operation.operationTypeId === OperationTypes.Yield) {
              const produceNorm: ProduceNorm = this.findProduceNorm(line.produceNormNumber, task.farmId);
              if (produceNorm.isMainProduct) {
                numberOfYields++;
              }
            }
          });
        });
      });
    }

    return numberOfYields;
  }

  /**
   * Finds a crop object based on a given crop id in a given list of crops.
   * @param cropId Crop id to find.
   * @param crops List of crops to search in.
   */
  private getCropById(cropId: number, crops: Array<Crop>): Crop {
    return crops.filter((crop: Crop) => {
      return crop.id === cropId;
    })[0];
  }
}
