import { SoilSampleShortName } from '@app/map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-short-name.enum';
import { TranslateService } from '@ngx-translate/core';

// Utility to get the number of entries for a given soil sample type
export function getEntriesForSoilSampleType(soilSampleType?: SoilSampleShortName): number {
  switch (soilSampleType) {
    case SoilSampleShortName.Reaction:
      return 11;
    case SoilSampleShortName.Potassium:
      return 14;
    case SoilSampleShortName.Phosphor:
    case SoilSampleShortName.Magnesium:
    case SoilSampleShortName.SoilType:
      return 12;
    case SoilSampleShortName.Copper:
    case SoilSampleShortName.Boron:
      return 9;
    case SoilSampleShortName.ClayPercentage:
      return 16;
    case SoilSampleShortName.Humus:
      return 11;
    default:
      return 13;
  }
}

// Utility to get decimal override for a given soil sample type
export function getSoilSampleDecimalOverride(soilSampleType?: SoilSampleShortName): number | undefined {
  switch (soilSampleType) {
    case SoilSampleShortName.ClayPercentage:
    case SoilSampleShortName.SoilType:
    case SoilSampleShortName.NitrogenPercentage:
    case SoilSampleShortName.CarbonPercentage:
    case SoilSampleShortName.Humus:
      return 0;
    default:
      return 1;
  }
}

// Utility to get additional text values for soil sample legends based on ranges
export function getSoilSampleAdditionalTexts(
  soilSampleType: SoilSampleShortName,
  translateService: TranslateService
): string[] | undefined {
  const translations = {
    veryLow: translateService.instant('common.map-controls.legend.values.very-low'),
    low: translateService.instant('common.map-controls.legend.values.low'),
    medium: translateService.instant('common.map-controls.legend.values.medium'),
    high: translateService.instant('common.map-controls.legend.values.high'),
    veryHigh: translateService.instant('common.map-controls.legend.values.very-high'),
  };

  const ranges: Partial<Record<SoilSampleShortName, string[]>> = {
    [SoilSampleShortName.Phosphor]: ['0-1', '1-2', '2-3', '3-6', '6+'],
    [SoilSampleShortName.Magnesium]: ['0-2', '2-4', '4-8', '8-12', '12+'],
    [SoilSampleShortName.Copper]: ['0-1', '1-2', '2-5', '5-8', '8+'],
    [SoilSampleShortName.Boron]: ['0-1', '1-2', '2-5', '5-8', '8+'],
  };

  const sampleRanges = soilSampleType ? ranges[soilSampleType] : undefined;

  return sampleRanges ? sampleRanges.map((range, index) => `${range} ${Object.values(translations)[index]}`).reverse() : undefined;
}
