import { Injectable } from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { BasisLayer } from '@app/core/interfaces/basis-layer/basis-layer';
import { BasisLayerCategory } from '@app/core/interfaces/basis-layer/basis-layer-category';
import { BasisLayerDto } from '@app/core/interfaces/basis-layer/basis-layer-dto.interface';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { BasisLayerShownComponentEnum } from '@app/map/features/basis-layer/basis-layer-shown-component.enum';
import { BasisLayerSideDrawerComponent } from '@app/map/features/basis-layer/basis-layer-side-drawer/basis-layer-side-drawer.component';
import { BasisLayerStateService } from '@app/map/features/basis-layer/basis-layer-state.service';
import { BasisStyles } from '@app/map/helpers/styles/basis-styles';
import { WKTUtil } from '@app/map/helpers/utils/WKT-util';
import { FeatureUtil } from '@app/map/helpers/utils/feature-util';
import { OlLayerQuery } from '@app/map/services/layer/layer.query';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { filterNullish } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import Feature from 'ol/Feature';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BasisLayerLogicService {
  private sideDrawerRef?: SideDrawerRef<BasisLayerSideDrawerComponent, void, void>;
  private sideDrawerCloseSubscription = new Subscription();

  constructor(
    private mapService: OlMapService,
    private basisLayerStateService: BasisLayerStateService,
    private sideDrawerOverlayService: SideDrawerOverlayService,
    private screenSizeService: ScreenSizeService,
    private layerService: OlLayerService,
    private layerQuery: OlLayerQuery,
    private mapLayerControlService: MapLayerControlService
  ) {
    this.basisLayerStateService.basisLayers$.subscribe((basisLayers) => {
      const features = this.createBasisLayerFeatures(basisLayers);
      this.basisLayerStateService.basisLayerFeatures = features;
      this.addFeaturesToMap(features);
    });
  }

  public openSideDrawer() {
    if (this.sideDrawerRef && this.sideDrawerOverlayService.sideDrawerRefIsDefined()) {
      this.sideDrawerRef.show();
      this.basisLayerStateService.drawerWidth = SideDrawerConfig.widthAsOpened;
      this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsOpenedPx);
      return;
    }
    this.basisLayerStateService.drawerWidth = SideDrawerConfig.widthAsOpened;
    this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsOpenedPx);
    this.sideDrawerRef = this.sideDrawerOverlayService.openCustomSideDrawer(BasisLayerSideDrawerComponent, {
      panelClass: 'basis-layer-side-drawer',
      width: SideDrawerConfig.widthAsOpened,
      hasBackdrop: false,
    });
    this.sideDrawerCloseSubscription.add(
      this.sideDrawerRef.onClose.subscribe(() => {
        this.basisLayerStateService.drawerWidth = SideDrawerConfig.widthAsClosed;
        this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
      })
    );
  }

  public closeSideDrawer() {
    this.sideDrawerRef!.closeWithoutTimeout();
    this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  public hideSideDrawer() {
    this.basisLayerStateService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
    this.sideDrawerRef!.hide();
  }

  public destroySideDrawer() {
    this.sideDrawerRef?.closeWithoutTimeout();
    this.sideDrawerCloseSubscription.unsubscribe();
    this.sideDrawerRef = undefined;
    this.mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  public addSelectInteraction() {
    this.layerQuery.mapLayersByActivePageUnique$
      .pipe(
        map((layers) => layers.find((layer) => layer.id === LayerId.BASIS_LAYERS)),
        filter((layer) => !!layer),
        first(),
        switchMap((layer) => this.mapService.addSelectInteraction(true, [LayerId.BASIS_LAYERS])),
        first(),
        finalize(() => this.disableSelectInteraction()),
        withLatestFrom(this.basisLayerStateService.basisLayerCategories$)
      )
      .subscribe(([selectEvent, basisLayerCategories]) => {
        const selectedBasisLayer = selectEvent.selected[0].get('basisLayer');
        this.basisLayerStateService.selectedBasisLayer = selectedBasisLayer;

        this.basisLayerStateService.selectedBasisLayerCategory = basisLayerCategories.find((b) => b.id === selectedBasisLayer.categoryId)!;
        this.basisLayerStateService.setShownComponentState(BasisLayerShownComponentEnum.categoryComponent);
      });
  }

  public enableSelectInteraction() {
    this.mapService.enableSelectInteraction();
  }

  public disableSelectInteraction() {
    this.mapService.disableSelectInteraction();
  }

  public onCategorySelect(category: BasisLayerCategory) {
    this.screenSizeService
      .isMobile()
      .pipe(first())
      .subscribe((isMobile) => {
        this.basisLayerStateService.setShownComponentState(BasisLayerShownComponentEnum.categoryComponent);
        this.basisLayerStateService.selectedBasisLayerCategory = category;
        if (isMobile) {
          this.basisLayerStateService.drawerWidth = SideDrawerConfig.widthAsClosed;
          this.sideDrawerRef!.hide();
        }
      });
  }

  /**
   * Enables the drawing tool.
   * Drawing tool disables when drawing is completed.
   */
  public addDrawingInteraction(): Observable<Feature> {
    this.disableSelectInteraction();
    this.mapService.removeDrawingInteraction();

    return this.mapService.addDrawingInteraction(LayerId.BASIS_LAYERS, GeometryType.POLYGON, BasisStyles.DrawingStyle)!.pipe(
      first(),
      filterNullish(),
      finalize(() => this.removeDrawingInteraction()),
      withLatestFrom(this.screenSizeService.isMobile(), this.basisLayerStateService.selectedBasisLayerCategory$),
      map(([event, isMobile, category]) => {
        const feature = this.createBasisLayerFeatures([
          new BasisLayer(
            {
              geometry: WKTUtil.getWktFromFeature(event.feature!),
              farmId: category?.farmId,
              id: -1,
            } as BasisLayerDto,
            category
          ),
        ])[0];
        this.addFeaturesToMap([feature]);
        this.mapService.selectFeatures([feature]);
        if (isMobile) {
          this.openSideDrawer();
        }
        return feature;
      })
    );
  }

  public removeDrawingInteraction() {
    this.mapService.removeDrawingInteraction();
  }

  public redrawBasisLayer(feature?: Feature): Observable<Feature> {
    return this.screenSizeService.isMobile().pipe(
      first(),
      switchMap((isMobile) => {
        if (!!feature) {
          this.removeFeatureFromMap(feature);
        }
        if (isMobile) {
          this.hideSideDrawer();
        }
        return this.addDrawingInteraction();
      })
    );
  }

  public getFeatureGeometry(feature: Feature): string {
    return WKTUtil.getWktFromFeature(feature);
  }

  public removeFeatureFromMap(feature: Feature) {
    this.basisLayerStateService.basisLayerFeatures$.pipe(first()).subscribe((basisLayerFeatures) => {
      const modifiedBasisLayerFeatures = basisLayerFeatures.filter((currfeature) => currfeature !== feature);
      this.basisLayerStateService.basisLayerFeatures = modifiedBasisLayerFeatures;
      this.layerService.removeFeature(feature);
    });
  }
  /**
   * Create ol map features from basisLayers.
   * Color is set to default if @basisLayerCategories doesnt contain a category with id matching basisLayerDto.categoryId
   * @param basisLayers
   */
  private createBasisLayerFeatures(basisLayers: BasisLayer[]): Feature[] {
    return basisLayers.map((basisLayer) => {
      const { geometry, id } = basisLayer;

      const feature = FeatureUtil.getMapFeature(
        LayerId.BASIS_LAYERS,
        WKTUtil.getGeometryTypeFromWktString(geometry!),
        WKTUtil.getCoordinatesFromWktString(geometry!)
      );

      feature.set('basisLayer', basisLayer);
      feature.setId(id);

      return feature;
    });
  }

  private addFeaturesToMap(features: Feature[]) {
    this.basisLayerStateService.basisLayerFeatures$.pipe(first()).subscribe((basisLayerFeatures) => {
      this.layerService.createFeatureLayer(LayerId.BASIS_LAYERS, [...basisLayerFeatures, ...features]);
    });
  }
}
