import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { BasisLayerCategory } from '@app/core/interfaces/basis-layer/basis-layer-category';
import { BasisLayerCategoryDto } from '@app/core/interfaces/basis-layer/basis-layer-category-dto.interface';
import { BasisLayerFormvalues } from '@app/core/interfaces/basis-layer/basis-layer-formvalues';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { BasisLayerLogicService } from '@app/map/features/basis-layer/basis-layer-logic.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { filterNullish } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Feature } from 'ol';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { filter, first, map, skip, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { BasisLayerShownComponentEnum } from '../../basis-layer-shown-component.enum';
import { BasisLayerStateService } from '../../basis-layer-state.service';
import { BasisLayerSideDrawerComponent } from '../basis-layer-side-drawer.component';
import { CategoryService } from './category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryComponent implements OnInit, OnDestroy, AfterViewInit {
  public basislayerForm!: UntypedFormGroup;
  public farmSelectorForm!: UntypedFormGroup;

  public drawing$ = new BehaviorSubject<Feature | null>(null);
  public selectedBasisLayer$ = this.basisLayerStateService.selectedBasisLayer$.pipe(first());
  public loading$ = this.basisLayerStateService.loading$;
  private _selectedFarms!: Farm[];
  private disableFarmSelection = false;
  private initialCategoryValue?: BasisLayerCategory;

  public get selectedFarms(): Farm[] {
    return this._selectedFarms;
  }

  private subscriptions = new Subscription();

  private get isFormUpdated(): boolean {
    return this.basislayerForm.dirty;
  }

  private get basisLayerFormvalues(): BasisLayerFormvalues {
    return {
      seeding: this.basislayerForm.get('seeding')!.value,
      fertilization: this.basislayerForm.get('fertilization')!.value,
      weed: this.basislayerForm.get('weed')!.value,
      pest: this.basislayerForm.get('pest')!.value,
      disease: this.basislayerForm.get('disease')!.value,
      growthregulation: this.basislayerForm.get('growthregulation')!.value,
      limeadjustment: this.basislayerForm.get('limeadjustment')!.value,
    };
  }

  constructor(
    private sideDrawerRef: SideDrawerRef<BasisLayerSideDrawerComponent, void, void>,
    private basisLayerStateService: BasisLayerStateService,
    private formBuilder: UntypedFormBuilder,
    private categoryservice: CategoryService,
    private basisLayerLogicService: BasisLayerLogicService,
    private farmStateService: FarmStateService,
    private dirtyCheckService: DirtyCheckService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public ngOnInit() {
    const closeSideDrawerFunc = () => this.close();
    this.categoryservice.init(closeSideDrawerFunc);
    this.subscriptions.add(
      this.basisLayerStateService.selectedBasisLayerCategory$.pipe(first()).subscribe((category) => {
        if (!isNullOrUndefined(category)) {
          this.initialCategoryValue = category;
          this.basislayerForm = this.createNewBasisLayerForm(category);
        }
      })
    );

    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.pipe(skip(1)).subscribe(() => {
        this.onEditClick();
      })
    );
    this.subscriptions.add(this.markDirtyOnChanges());
  }

  public ngAfterViewInit() {
    this.subscriptions.add(
      this.selectedBasisLayer$.subscribe((selected) => {
        if (!!selected) {
          this.disableFarmSelection = true;
          this.subscriptions.add(
            this.basisLayerStateService.basisLayerFeatures$.pipe(first()).subscribe((basislayersfeatures) => {
              const featureForSelectedBasisLayer = basislayersfeatures.find((feature) => feature.getId() === selected.id);

              this.drawing$.next(featureForSelectedBasisLayer!);
            })
          );
        } else {
          this.disableFarmSelection = false;
          this.subscriptions.add(
            this.basisLayerLogicService
              .addDrawingInteraction()
              .pipe(first())
              .subscribe((drawingEvent) => {
                this.drawing$.next(drawingEvent);
              })
          );
        }
      })
    );
    this.subscriptions.add(
      this.farmStateService.selectedFarms$
        .pipe(first(), withLatestFrom(this.selectedBasisLayer$))
        .subscribe(([farms, selectedBasisLayer]) => {
          this._selectedFarms = farms;
          this.farmSelectorForm = this.createFarmStateServiceForm(!!selectedBasisLayer ? selectedBasisLayer.farmId : farms[0].id);
        })
    );
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.basisLayerStateService.selectedBasisLayer = undefined;
    this.basisLayerLogicService.addSelectInteraction();
    this.basisLayerLogicService.removeDrawingInteraction();
    this.dirtyCheckService.setIsFeatureDirty(this, false);
  }

  @HostListener('window:beforeunload', ['$event']) public beforeUnloadHander(event: any) {
    try {
      return !this.dirtyCheckService.isFeatureDirty(this);
    } catch (e) {
      return true;
    }
  }

  private close() {
    this.basisLayerStateService.hydrateBasisLayersAndCategories();
    this.basisLayerStateService.setShownComponentState(BasisLayerShownComponentEnum.categoryPickerComponent);
  }

  public onCloseClick() {
    if (this.dirtyCheckService.isFeatureDirty(this)) {
      this.dialogService
        .openDirtyCheckDialog()
        .pipe(
          filterNullish(),
          map((action) => action.isConfirmed),
          first(),
          filter((denied) => !!denied)
        )
        .subscribe(() => {
          this.close();
        });
    } else {
      this.close();
    }
  }

  public onHideClick() {
    this.basisLayerStateService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  public OnFarmSelectionChange(farmId: number) {
    const category = this.categoryservice.changeSelectedBasisLayerCategory(farmId);
    this.updateForm(category);
  }
  public onEditClick() {
    this.subscriptions.add(
      this.drawing$
        .pipe(
          filterNullish(),
          first(),
          switchMap((feature) => this.basisLayerLogicService.redrawBasisLayer(feature))
        )
        .subscribe((drawingEvent) => {
          this.drawing$.next(drawingEvent);
          this.basisLayerLogicService.removeDrawingInteraction();
        })
    );
  }

  public onSaveClick() {
    if (this.basislayerForm.valid) {
      this.subscriptions.add(
        this.drawing$.pipe(first(), withLatestFrom(this.selectedBasisLayer$)).subscribe(([feature, selectedBasisLayer]) => {
          if (!!feature) {
            const geometry = this.basisLayerLogicService.getFeatureGeometry(feature);
            this.dirtyCheckService.setIsFeatureDirty(this, false);
            const farmId = this.farmSelectorForm.get('farmId')!.value;
            this.categoryservice.save(this.basisLayerFormvalues, farmId, !selectedBasisLayer, this.isFormUpdated, geometry);
          } else {
            this.notificationService.showError('main.basis-layer.category.notification.polygon-required');
          }
        })
      );
    }
  }

  public onDeleteClick() {
    const farmId = this.farmSelectorForm.get('farmId')!.value;
    this.categoryservice.deleteSelectedBasisLayer(farmId);
  }

  private createFarmStateServiceForm(farmId?: number) {
    return this.formBuilder.group({
      farmId: { value: farmId, disabled: this.disableFarmSelection },
    });
  }

  private createNewBasisLayerForm(category: BasisLayerCategoryDto | BasisLayerCategory) {
    const {
      seedingAdjustment,
      fertilizerAdjustment,
      weedAdjustment,
      pestsAdjustment,
      diseaseAdjustment,
      growthRegulationAdjustment,
      limeAdjustment,
    } = category;

    // toString is used because values that equal 0 otherwise wont be shown
    return this.formBuilder.group({
      seeding: [seedingAdjustment?.toString(), [Validators.required, Validators.min(0), Validators.max(999)]],
      fertilization: [fertilizerAdjustment?.toString(), [Validators.required, Validators.min(0), Validators.max(999)]],
      weed: [weedAdjustment?.toString(), [Validators.required, zeroOrBetween(50, 150)]],
      pest: [pestsAdjustment?.toString(), [Validators.required, zeroOrBetween(50, 150)]],
      disease: [diseaseAdjustment?.toString(), [Validators.required, zeroOrBetween(50, 150)]],
      growthregulation: [growthRegulationAdjustment?.toString(), [Validators.required, zeroOrBetween(50, 150)]],
      limeadjustment: [limeAdjustment?.toString(), [Validators.required, Validators.min(0), Validators.max(999)]],
    });
  }
  private updateForm(category?: BasisLayerCategory) {
    if (category) {
      this.basislayerForm.setValue({
        seeding: category.seedingAdjustment,
        fertilization: category.fertilizerAdjustment,
        weed: category.weedAdjustment,
        pest: category.pestsAdjustment,
        disease: category.diseaseAdjustment,
        growthregulation: category.growthRegulationAdjustment,
        limeadjustment: category.limeAdjustment,
      });
    }
  }

  private markDirtyOnChanges() {
    return combineLatest([
      this.basislayerForm?.valueChanges.pipe(startWith(this.initialCategoryValue)),
      this.drawing$,
      this.basisLayerStateService.selectedBasisLayer$,
    ])
      .pipe(
        map(([form, feature, selectedLayer]) => {
          if (this.initialCategoryValue !== form) {
            return true;
          }
          if (!selectedLayer && feature) {
            return true;
          }
          if (selectedLayer && feature?.get('basisLayer') !== selectedLayer) {
            return true;
          }
          return false;
        })
      )
      .subscribe((change) => this.dirtyCheckService.setIsFeatureDirty(this, change));
  }
}

export function zeroOrBetween(min: number, max: number) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (isNullOrUndefined(control.value)) {
      return null;
    }
    const value = +control.value;
    return value === 0 || (value >= min && value <= max) ? null : { zeroOrBetween: true };
  };
}
