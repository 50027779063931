import { Injectable } from '@angular/core';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { NormDtoForFarm } from '@app/core/interfaces/norm-dto-for-farm';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { CropNormDTO } from '@app/core/repositories/crops/crop-norm.dto';
import { CropsRepoService } from '@app/core/repositories/crops/crops-repo.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { AsAppliedShownComponentEnum } from '@app/map/features/field-analysis/features/as-applied/as-applied-shown-component.enum';
import { VraOperationTypeGroupRepo } from '@app/map/features/vra/state/vra-operation-type-group-repo.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AsAppliedOperationTypeGroup } from './as-applied-group.class';
import { AsAppliedTask } from './as-applied-task.class';
import { AsAppliedFieldTaskListTableData } from './AsAppliedFieldTaskListTableData.interface';
import { ExecutedLocationCollection, ExecutedLocationCollectionDto } from './executed-task.class';
import { ExecutedArchiveFileUploadResponse, ShapefileAnalysisDto, ShapefileConfigInput } from './file-upload/metadata-parent';

@Injectable()
export class AsAppliedService {
  private _executedArchiveFileUpload!: ExecutedArchiveFileUploadResponse;
  private _shapeFileAnalysis!: ShapefileAnalysisDto;
  private _shapefileConfigInput!: ShapefileConfigInput;

  public get shownComponent$(): Observable<AsAppliedShownComponentEnum> {
    return this._shownComponent.asObservable();
  }

  public static getAsAppliedCoverFlowItem(name: string, isDisabled: boolean = false) {
    return new MapCoverFlowItem({
      mapCoverFlowLayersId: LayerId.AS_APPLIED,
      displayName: 'main.fieldmap.layers.asApplied',
      name: name,
      isVisible: true,
      isDisabled,
      layers: [
        {
          layerId: LayerId.AS_APPLIED,
          layerType: MapLayerType.VECTOR,
          zIndex: 1,
          isVisible: true,
        },
      ],
    });
  }

  private _shownComponent = new BehaviorSubject<AsAppliedShownComponentEnum>(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
  private _fileJustUploaded = new Subject<void>();
  private _configFormCanBeRemoved = new BehaviorSubject<boolean>(true);

  constructor(
    private farmStateService: FarmStateService,
    private vraOperationTypeGroupRepo: VraOperationTypeGroupRepo,
    private harvestYearStateService: HarvestYearStateService,
    private produceNormsService: ProduceNormsService,
    private cropsRepoService: CropsRepoService,
    private languageService: LanguageService,
    public globalStateService: GlobalStateService
  ) {}

  public loading(isLoading: boolean, message?: string) {
    if (message) {
      isLoading ? this.globalStateService.specificLoadingStarted(message) : this.globalStateService.specificLoadingCompleted(message);
    } else {
      isLoading ? this.globalStateService.loadingStarted() : this.globalStateService.loadingCompleted();
    }
  }

  public setShownComponent(component: AsAppliedShownComponentEnum) {
    this._shownComponent.next(component);
  }

  public getShownComponent(): AsAppliedShownComponentEnum {
    return this._shownComponent.getValue();
  }

  public updateTasksAndGroups() {
    this._fileJustUploaded.next();
  }

  public get fileJustUploaded() {
    return this._fileJustUploaded.asObservable();
  }

  public get executedArchiveFileUpload(): ExecutedArchiveFileUploadResponse {
    return this._executedArchiveFileUpload;
  }

  public set executedArchiveFileUpload(executedArchiveFileUpload: ExecutedArchiveFileUploadResponse) {
    this._executedArchiveFileUpload = executedArchiveFileUpload;
  }

  public get shapeFileAnalysis(): ShapefileAnalysisDto {
    return this._shapeFileAnalysis;
  }

  public set shapeFileAnalysis(shapeFileAnalysis: ShapefileAnalysisDto) {
    this._shapeFileAnalysis = shapeFileAnalysis;
  }

  public get shapefileConfigInput(): ShapefileConfigInput {
    return this._shapefileConfigInput;
  }

  public set shapefileConfigInput(shapeFileConfigs: ShapefileConfigInput) {
    this._shapefileConfigInput = shapeFileConfigs;
  }

  public set canBeRemoved(remove: boolean) {
    this._configFormCanBeRemoved.next(remove);
  }

  public get configFormCanBeRemoved$(): Observable<boolean> {
    return this._configFormCanBeRemoved.asObservable();
  }

  public getAsAppliedTasks(): Observable<AsAppliedOperationTypeGroup[]> {
    return combineLatest([
      this.farmStateService.selectedFarms$,
      this.harvestYearStateService.harvestYear$.pipe(distinctUntilChanged()),
      this.fileJustUploaded.pipe(startWith(null)),
    ]).pipe(
      filter(([farms, harvestYear]) => !!harvestYear && !!farms && !!farms.length),
      switchMap(([farms, harvestYear]) => {
        return this.getAsAppliedGroups(farms, harvestYear!);
      })
    );
  }

  public getAsAppliedGroups(farms: Farm[], harvestYear: number): Observable<AsAppliedOperationTypeGroup[]> {
    return combineLatest([
      this.vraOperationTypeGroupRepo
        .getAppliedOperationTypeGroupsWithFarmIds(
          farms.map((farm) => farm.id),
          harvestYear
        )
        .pipe(tap(() => this.loading(true, 'main.asApplied.loadingMessage'))),
      this.produceNormsService.getProduceNormsForFarms(
        farms.map((farm) => farm.id),
        harvestYear
      ),
      this.cropsRepoService.getCropsForFarms(
        farms.map((farm) => farm.id),
        harvestYear
      ),
    ]).pipe(
      distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
      map(([appliedOperationTypeGroups, produceNormsForFarms, cropNormsForFarms]) => {
        return appliedOperationTypeGroups.map((appliedOperationTypeGroup) => {
          return new AsAppliedOperationTypeGroup(appliedOperationTypeGroup, produceNormsForFarms, cropNormsForFarms, this.languageService);
        });
      }),
      finalize(() => this.loading(false, 'main.asApplied.loadingMessage'))
    );
  }

  public getExecutedTaskById(farmId: number, id: number) {
    return this.harvestYearStateService.harvestYear$.pipe(distinctUntilChanged()).pipe(
      filterNullish(),
      switchMap((harvestYear) => this.getExecutedTask(farmId, harvestYear, id))
    );
  }

  public getExecutedTasksByIds(farmId: number, ids: number[]) {
    return this.harvestYearStateService.harvestYear$.pipe(
      distinctUntilChanged(),
      filterNullish(),
      switchMap((harvestYear) => forkJoin(ids.map((id) => this.getExecutedTask(farmId, harvestYear, id))))
    );
  }

  public getExecutedTask(farmId: number, harvestYear: number, id: number): Observable<ExecutedLocationCollection> {
    return combineLatest([
      this.produceNormsService.getProduceNormsForFarms([farmId], harvestYear),
      this.cropsRepoService.getCropsForFarms([farmId], harvestYear),
    ]).pipe(
      tap(() => {
        this.loading(true, 'main.asApplied.loadingMessage');
      }),
      switchMap(([produceNormsForFarm, cropNormsForFarm]) =>
        this.getExecutedTaskAndNorms(farmId, harvestYear, id, produceNormsForFarm, cropNormsForFarm)
      ),
      map(
        ([executedLocationCollectionDto, produceNormsForFarm, cropNormsForFarm]) =>
          new ExecutedLocationCollection(executedLocationCollectionDto, produceNormsForFarm, cropNormsForFarm)
      ),
      finalize(() => {
        this.loading(false, 'main.asApplied.loadingMessage');
      })
    );
  }

  public mapAsAppliedTasksToTableData(
    asAppliedGroupsWithTasks: { name: string; tasks: AsAppliedTask[] }[]
  ): AsAppliedFieldTaskListTableData[] {
    return asAppliedGroupsWithTasks
      .reduce((prev: AsAppliedFieldTaskListTableData[], curr) => [...curr.tasks, ...prev] as AsAppliedFieldTaskListTableData[], [])
      .filter((task) => !!task)
      .sort((a: AsAppliedFieldTaskListTableData, b: AsAppliedFieldTaskListTableData) => {
        return CompareHelper.compare(a.date, b.date);
      });
  }

  public deleteExecutedTask(appliedTask: ExecutedLocationCollection) {
    return this.harvestYearStateService.harvestYear$.pipe(
      tap(() => this.loading(true, 'main.asApplied.taskDetails.deleteMessage')),
      filterNullish(),
      switchMap((harvestYear) =>
        this.vraOperationTypeGroupRepo.deleteExecutedTask([appliedTask.executedTask.farmId], harvestYear, appliedTask.executedTaskId)
      ),
      finalize(() => {
        this.loading(false, 'main.asApplied.taskDetails.deleteMessage');
      })
    );
  }

  public filterTasksForField(field: Field, asAppliedOperationTypeGroups: AsAppliedOperationTypeGroup[]) {
    return asAppliedOperationTypeGroups.map((group) => {
      return {
        name: group.name,
        tasks: group.tasks.filter((task) => task?.featureId === field.featureId),
      };
    });
  }

  public filterUnspecifiedTasksForField(field: Field, unspecifiedGroup: AsAppliedOperationTypeGroup) {
    return {
      name: unspecifiedGroup.name,
      tasks: unspecifiedGroup.tasks.filter((task) => task?.featureId === field.featureId),
    };
  }

  private getExecutedTaskAndNorms(
    farmId: number,
    harvestYear: number,
    id: number,
    produceNormsForFarm: NormDtoForFarm<ProduceNorm[]>[],
    cropNormsForFarm: NormDtoForFarm<CropNormDTO[]>[]
  ): Observable<[ExecutedLocationCollectionDto, NormDtoForFarm<ProduceNorm[]>[], NormDtoForFarm<CropNormDTO[]>[]]> {
    return this.vraOperationTypeGroupRepo
      .getExecutedTaskById([farmId], harvestYear, id)
      .pipe(
        map((executedLocationCollectionDto: ExecutedLocationCollectionDto) => [
          executedLocationCollectionDto,
          produceNormsForFarm,
          cropNormsForFarm,
        ])
      );
  }
}
