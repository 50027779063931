import {
  DataLogValueInfo,
  ExtendedGrid,
  ExtendedPartfield,
  ExtendedTimeLog,
  ExtendedTreatmentZone,
  GridGridTypeEnum,
  ISOXMLManager,
  Task,
  TimeLogInfo,
  TimeLogRecord,
  ValueInformation,
} from 'isoxml';

// The reason to keet it out of the store is to avoid non-serializable and too big data in the store
// No parts of this app should modify data in this ISOXMLManager

export type ExtendedTimeLogInfo = TimeLogInfo & { parsingErrors: string[]; filledTimeLogs?: TimeLogRecord[]; fileName?: string };

interface ISOXMLManagerInfo {
  isoxmlManager: ISOXMLManager;
  timeLogsCache: { [timeLogId: string]: ExtendedTimeLogInfo };
  timeLogsGeoJSONs: { [timeLogId: string]: any };
  timeLogsFilledGeoJSONs: { [timeLogId: string]: any };
  timeLogsRangesWithoutOutliers: { [timeLogId: string]: { minValue: number; maxValue: number }[] };
  partfieldGeoJSONs: { [partfieldId: string]: any };
}

let isoxmlManagerInfo: ISOXMLManagerInfo;

function findTimeLogById(timeLogId: string) {
  for (const task of isoxmlManagerInfo.isoxmlManager.rootElement.attributes.Task || []) {
    const timeLog = (task.attributes.TimeLog || []).find((timeLog) => timeLog.attributes.Filename === timeLogId) as ExtendedTimeLog;
    if (timeLog) {
      return timeLog;
    }
  }
  return null;
}

export const parseTimeLog = (timeLogId: string, fillMissingValues: boolean) => {
  let timeLog: any;

  if (!isoxmlManagerInfo.timeLogsCache?.[timeLogId]) {
    timeLog = findTimeLogById(timeLogId);
    const timeLogInfo = timeLog.parseBinaryFile();
    isoxmlManagerInfo.timeLogsCache[timeLogId] = {
      ...timeLogInfo,
      timeLogs: timeLogInfo.timeLogs.filter((timeLog: any) => timeLog.isValidPosition),
      parsingErrors: timeLog.parsingErrors,
    };
  }

  const timeLogInfo = isoxmlManagerInfo.timeLogsCache[timeLogId];
  if (fillMissingValues && !timeLogInfo.filledTimeLogs) {
    timeLog = timeLog || findTimeLogById(timeLogId);
    timeLogInfo.filledTimeLogs = timeLog.getFilledTimeLogs();
  }
};

export const parseAllTaskTimeLogs = (taskId: string, fillMissingValues: boolean) => {
  const task = isoxmlManagerInfo.isoxmlManager.getEntityByXmlId<Task>(taskId);
  if (!task) {
    return;
  }

  getTimeLogsWithData(task).forEach((timeLog) => {
    parseTimeLog(timeLog.attributes.Filename, fillMissingValues);
  });
};

export const getTimeLogGeoJSON = (timeLogId: string, fillMissingValues: boolean) => {
  if (!isoxmlManagerInfo) {
    return null;
  }

  const targetKey = fillMissingValues ? 'timeLogsFilledGeoJSONs' : 'timeLogsGeoJSONs';

  if (isoxmlManagerInfo?.[targetKey][timeLogId]) {
    return isoxmlManagerInfo[targetKey][timeLogId];
  }

  parseTimeLog(timeLogId, fillMissingValues);

  const timeLogs = fillMissingValues
    ? isoxmlManagerInfo.timeLogsCache[timeLogId].filledTimeLogs
    : isoxmlManagerInfo.timeLogsCache[timeLogId].timeLogs;

  const geoJSON = {
    type: 'FeatureCollection',
    features: timeLogs?.map((timeLogItem: any) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [timeLogItem.position.PositionEast, timeLogItem.position.PositionNorth],
      },
      properties: timeLogItem.values,
    })),
  };

  isoxmlManagerInfo[targetKey][timeLogId] = geoJSON;
  return geoJSON;
};

export const getMergedTimeLogGeoJSONs = (taskId: string, fillMissingValues: boolean, excludedTimeLogs: Record<string, boolean>) => {
  const task = isoxmlManagerInfo.isoxmlManager.getEntityByXmlId<Task>(taskId);
  if (!task) {
    return;
  }

  return getTimeLogsWithData(task)
    .filter((timeLog) => !excludedTimeLogs[timeLog.attributes.Filename])
    .map((timeLog) => ({
      timeLogId: timeLog.attributes.Filename,
      geoJSON: getTimeLogGeoJSON(timeLog.attributes.Filename, fillMissingValues),
    }));
};

export const getPartfieldGeoJSON = (partfieldId: string) => {
  if (!isoxmlManagerInfo) {
    return null;
  }

  if (!isoxmlManagerInfo?.partfieldGeoJSONs[partfieldId]) {
    const partfield = isoxmlManagerInfo.isoxmlManager.getEntityByXmlId<ExtendedPartfield>(partfieldId);
    isoxmlManagerInfo.partfieldGeoJSONs[partfieldId] = partfield.toGeoJSON();
  }

  return isoxmlManagerInfo.partfieldGeoJSONs[partfieldId];
};

export const getISOXMLManager = () => isoxmlManagerInfo?.isoxmlManager;
export const getTimeLogInfo = (timeLogId: string) => isoxmlManagerInfo?.timeLogsCache[timeLogId];

const unionBbox = (bbox1: TimeLogInfo['bbox'], bbox2: TimeLogInfo['bbox']) =>
  [
    Math.min(bbox1![0], bbox2![0]),
    Math.min(bbox1![1], bbox2![1]),
    Math.max(bbox1![2], bbox2![2]),
    Math.max(bbox1![3], bbox2![3]),
  ] as TimeLogInfo['bbox'];

const mergeValueInfo = (valueInfo1: DataLogValueInfo, valueInfo2: DataLogValueInfo) => {
  if (!valueInfo1) {
    return valueInfo2;
  }

  return {
    ...valueInfo2,
    minValue:
      valueInfo1.minValue !== undefined && valueInfo2.minValue !== undefined
        ? Math.min(valueInfo1.minValue, valueInfo2.minValue)
        : (valueInfo1.minValue ?? valueInfo2.minValue),
    maxValue:
      valueInfo1.maxValue !== undefined && valueInfo2.maxValue !== undefined
        ? Math.max(valueInfo1.maxValue, valueInfo2.maxValue)
        : (valueInfo1.maxValue ?? valueInfo2.maxValue),
  };
};

export const getMergedTimeLogInfo = (taskId: string): ExtendedTimeLogInfo | undefined => {
  const task = isoxmlManagerInfo.isoxmlManager.getEntityByXmlId<Task>(taskId);
  if (!task) {
    return;
  }

  let mergedBbox: [number, number, number, number] | undefined;
  const mergedValuesInfo: Record<string, DataLogValueInfo> = {};
  let parsingErrors: string[] = [];

  getTimeLogsWithData(task).forEach((timeLog) => {
    const timeLogInfo = getTimeLogInfo(timeLog.attributes.Filename);

    if (!mergedBbox) {
      mergedBbox = timeLogInfo.bbox;
    } else if (timeLogInfo.bbox) {
      mergedBbox = unionBbox(mergedBbox, timeLogInfo.bbox);
    }

    timeLogInfo.valuesInfo.forEach((valueInfo) => {
      mergedValuesInfo[valueInfo.valueKey] = mergeValueInfo(mergedValuesInfo[valueInfo.valueKey], valueInfo);
    });

    parsingErrors = [...parsingErrors, ...(timeLogInfo.parsingErrors?.map((error) => `${timeLog.attributes.Filename}: ${error}`) ?? [])];
  });

  return {
    bbox: mergedBbox,
    valuesInfo: Object.values(mergedValuesInfo),
    parsingErrors,
    // we never use TimeLogs from merged TimeLogInfo
    timeLogs: [],
    filledTimeLogs: [],
  };
};

const getRangeWithoutOutliers = (timeLogId: string, valueKey: string) => {
  const ranges = isoxmlManagerInfo.timeLogsRangesWithoutOutliers;

  if (!(timeLogId in ranges)) {
    const timeLog = findTimeLogById(timeLogId);
    ranges[timeLogId] = timeLog!.rangesWithoutOutliers();
  }

  const idx = isoxmlManagerInfo.timeLogsCache[timeLogId]?.valuesInfo.findIndex((info) => info.valueKey === valueKey);
  return idx >= 0 ? ranges[timeLogId][idx] : undefined;
};

export const getTimeLogValuesRange = (timeLogId: string, valueKey: string, excludeOutliers: boolean) => {
  if (excludeOutliers) {
    return getRangeWithoutOutliers(timeLogId, valueKey);
  } else {
    const timeLogInfo = getTimeLogInfo(timeLogId);
    const valueInfo = timeLogInfo.valuesInfo.find((info) => info.valueKey === valueKey);

    return valueInfo
      ? {
          minValue: valueInfo.minValue,
          maxValue: valueInfo.maxValue,
        }
      : undefined;
  }
};

export const getMergedTimeLogValuesRange = (taskId: string | null, valueKey: string, excludeOutliers: boolean) => {
  if (!taskId) return;
  const task = isoxmlManagerInfo.isoxmlManager.getEntityByXmlId<Task>(taskId);
  if (!task) {
    return;
  }

  return getTimeLogsWithData(task).reduce(
    (range, timeLog) => {
      const timeLogRange = getTimeLogValuesRange(timeLog.attributes.Filename, valueKey, excludeOutliers);
      return {
        minValue:
          range?.minValue !== undefined && timeLogRange?.minValue !== undefined
            ? Math.min(range?.minValue, timeLogRange?.minValue)
            : (range?.minValue ?? timeLogRange?.minValue),
        maxValue:
          range?.maxValue !== undefined && timeLogRange?.maxValue !== undefined
            ? Math.max(range?.maxValue, timeLogRange?.maxValue)
            : (range?.maxValue ?? timeLogRange?.maxValue),
      };
    },
    undefined as unknown as { minValue: number; maxValue: number }
  );
};

export function getTaskIdByTimeLogFilename(timeLogFilename: string) {
  for (const task of isoxmlManagerInfo.isoxmlManager.rootElement.attributes.Task || []) {
    const timeLog = (task.attributes.TimeLog || []).find((timeLog) => timeLog.attributes.Filename === timeLogFilename) as ExtendedTimeLog;
    if (timeLog) {
      return isoxmlManagerInfo.isoxmlManager.getReferenceByEntity(task).xmlId;
    }
  }
  return null;
}

export const setISOXMLManagerData = (isoxmlManager: ISOXMLManager) => {
  isoxmlManagerInfo = {
    isoxmlManager,
    timeLogsCache: {},
    timeLogsGeoJSONs: {},
    timeLogsFilledGeoJSONs: {},
    timeLogsRangesWithoutOutliers: {},
    partfieldGeoJSONs: {},
  };
};

export function calculateGridValuesRange(grid: ExtendedGrid, treatmentZones: ExtendedTreatmentZone[]): { min: number; max: number } {
  let min = +Infinity;
  let max = -Infinity;

  if (grid.attributes.GridType === GridGridTypeEnum.GridType1) {
    const zoneCodes = grid.getAllReferencedTZNCodes();

    zoneCodes.forEach((zoneCode) => {
      const zone = treatmentZones.find((z) => z.attributes.TreatmentZoneCode === zoneCode);
      const pdv = zone?.attributes.ProcessDataVariable?.[0];

      if (pdv) {
        const value = pdv.attributes.ProcessDataValue;

        if (value) {
          min = Math.min(min, value);
          max = Math.max(max, value);
        }
      }
    });
  } else {
    const nCols = grid.attributes.GridMaximumColumn;
    const nRows = grid.attributes.GridMaximumRow;
    const cells = new Int32Array(grid.binaryData.buffer);

    for (let idx = 0; idx < nRows * nCols; idx++) {
      const v = cells[idx];
      if (v) {
        min = Math.min(min, cells[idx]);
        max = Math.max(max, cells[idx]);
      }
    }
  }

  // if we don't update min value, then all the values in the grid were zeros
  if (min === +Infinity) {
    return { min: 0, max: 0 };
  }

  return { min, max };
}

export function getGridValue(grid: ExtendedGrid, x: number, y: number): number {
  const nCols = grid.attributes.GridMaximumColumn;
  const nRows = grid.attributes.GridMaximumRow;
  const cells = new Int32Array(grid.binaryData.buffer);

  return cells[(nRows - y - 1) * nCols + x];
}

export const formatValue = (value: number, valueDescription: ValueInformation): string => {
  const scaledValue = value * valueDescription.scale + valueDescription.offset;
  return `${scaledValue.toFixed(valueDescription.numberOfDecimals)} ${valueDescription.unit}`;
};

export function isMergedTimeLogId(timeLogId: string) {
  return timeLogId.startsWith('TSK');
}

export function getTimeLogsWithData(task: Task) {
  return (task.attributes.TimeLog || []).filter((timeLog: any) => {
    return timeLog.binaryData && timeLog.timeLogHeader;
  });
}
export function generateRandomId(): string {
  return Math.floor(Math.random() * 1000000000 + 1).toString();
}

export function constructPointGeometry(position: { PositionEast: number; PositionNorth: number }): string {
  return `POINT (${position.PositionEast} ${position.PositionNorth})`;
}

export function readFileAsArrayBuffer(file: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };

    reader.onerror = (error) => {
      reject(new Error('Error reading file: ' + error));
    };

    reader.readAsArrayBuffer(file);
  });
}

export function getIsoErrorTranslationString(error: Error) {
  const errorTranslations: { [key: string]: string } = {
    "Can't find end of central directory": 'main.fieldInspector.isoxml.data.error.no-directory',
    "ZIP file doesn't contain TASKDATA.XML": 'main.fieldInspector.isoxml.data.error.no-transfer-task',
    'Error reading file:': 'main.fieldInspector.isoxml.data.error.reading-file',
  };

  const translationKey = Object.keys(errorTranslations).find((key) => error.message.includes(key));

  // Return the translation string or a default message
  return translationKey ? errorTranslations[translationKey] : 'main.fieldInspector.isoxml.data.error.unknown';
}
