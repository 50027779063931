import times from 'lodash-es/times';
import Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

export const FieldStyles = {
  getFieldDrawingStyle: () => {
    return [
      new Style({
        zIndex: 3,
        image: new Circle({
          radius: 6,
          stroke: new Stroke({
            color: '#FFFFFF',
            width: 1,
          }),
          fill: new Fill({
            color: '#005521',
          }),
        }),
        stroke: new Stroke({
          color: '#FFFFFF',
          width: 2,
        }),
        fill: new Fill({
          color: '#cadee1',
        }),
      }),
    ];
  },
  getVRAFieldStyle: (zoom: number, feature: Feature): Style[] => {
    return [
      new Style({
        stroke: new Stroke({
          color: '#FFFFFF',
          width: zoom > 13 ? 2 : 4,
        }),
        fill: new Fill({
          color: [0, 0, 0, 0],
        }),
        text: FieldStyles.getTextStyle(zoom, feature),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      }),
    ];
  },
  getFieldStyle: (zoom: number, feature: Feature): Style[] => {
    return [
      new Style({
        stroke: new Stroke({
          color: feature.get('stroke'),
          width: 2,
        }),
        text: FieldStyles.getTextStyle(zoom, feature),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      }),
    ];
  },
  getBlightFieldStyle: (zoom: number, feature: Feature): Style[] => {
    return [
      new Style({
        stroke: new Stroke({
          color: '#ffffff',
          width: 2,
        }),
        fill: new Fill({
          color: [0, 0, 0, 0],
        }),
        text: FieldStyles.getBlightTextStyle(zoom, feature),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      }),
    ];
  },
  getDroneFieldStyle: (zoom: number, feature: Feature): Style[] => {
    return [
      new Style({
        stroke: new Stroke({
          color: '#3232EA',
          width: 2,
        }),
        text: FieldStyles.getTextStyle(zoom, feature),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      }),
    ];
  },
  fieldStyle: [
    new Style({
      stroke: new Stroke({
        color: '#ffffff',
        width: 3,
      }),
    }),
  ],
  getClusteredFieldStyle: (text: string): Style[] => {
    return [
      new Style({
        image: new Circle({
          radius: 10,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#3399CC',
          }),
        }),
        text: new Text({
          text: text,
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    ];
  },
  getSelectedFieldStyle: (zoom: number, feature: Feature): Style[] => {
    const styleArray: Style[] = times(5, Number).map(
      (i) =>
        new Style({
          stroke: new Stroke({
            color: [0, 0, 0, 0.08 * i],
            width: 17 - i * 2.5,
          }),
        })
    );

    styleArray.push(
      new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 1],
          width: 2,
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      })
    );

    return styleArray;
  },
  getSelectedBlightFieldStyle: (zoom: number, feature: Feature): Style[] => {
    const styleArray: Style[] = times(5, Number).map(
      (i) =>
        new Style({
          stroke: new Stroke({
            color: [0, 0, 0, 0.08 * i],
            width: 17 - i * 2.5,
          }),
        })
    );

    styleArray.push(
      new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 1],
          width: 2,
        }),
        fill: new Fill({
          color: [0, 0, 0, 0],
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      })
    );

    return styleArray;
  },
  getDroneSelectedFieldStyle: (zoom: number, feature: Feature): Style[] => {
    const styleArray: Style[] = times(5, Number).map(
      (i) =>
        new Style({
          stroke: new Stroke({
            color: [0, 0, 0, 0.08 * i],
            width: 17 - i * 2.5,
          }),
        })
    );

    styleArray.push(
      new Style({
        stroke: new Stroke({
          color: '#3232EA',
          width: 2,
        }),
        fill: new Fill({
          color: feature.get('fill'),
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      })
    );

    return styleArray;
  },
  getClusteredSelectedFieldStyle: (text: string) => {
    return [
      new Style({
        image: new Circle({
          radius: 10,
          stroke: new Stroke({
            color: '#fff',
            width: 1,
          }),
          fill: new Fill({
            color: '#3399CC',
          }),
        }),
        text: new Text({
          text: text,
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    ];
  },
  getGrowthCurveFieldStyle: (zoom: number, feature: Feature): Style[] => {
    const fieldColor: [number, number, number, number] = [78, 128, 141, 0];
    return [
      new Style({
        stroke: new Stroke({
          color: feature.get('stroke'),
          width: 2,
        }),
        fill: new Fill({
          color: fieldColor,
        }),
        text: FieldStyles.getTextStyle(zoom, feature),
      }),
    ];
  },
  getGrowthCurveSelectedFieldStyle: (zoom: number, feature: Feature): Style[] => {
    const fieldColor: [number, number, number, number] = [78, 128, 141, 0];
    return [
      new Style({
        stroke: new Stroke({
          color: feature.get('stroke'),
          width: 2,
        }),
        fill: new Fill({
          color: fieldColor,
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
      }),
    ];
  },
  getTextStyle: (zoom: number, feature: Feature, isSelected: boolean = false): Text => {
    const scale = isSelected ? 2.5 : 2;
    return new Text({
      overflow: true,
      text: feature.get('text'),
      scale: scale - (20 - zoom) / 10, // Max zoom is 20 which results in scale 1
      stroke: new Stroke({
        color: [0, 0, 0, 0.6],
        width: 2.5,
      }),
      fill: new Fill({
        color: [255, 255, 255, 1],
      }),
    });
  },
  getBlightTextStyle: (zoom: number, feature: Feature, isSelected: boolean = false): Text => {
    const scale = isSelected ? 2.5 : 2;
    return new Text({
      overflow: true,
      text: feature.get('text'),
      scale: scale - (20 - zoom) / 20, // Max zoom is 20 which results in scale 1
      stroke: new Stroke({
        color: [0, 0, 0, 0.6],
        width: 2.5,
      }),
      fill: new Fill({
        color: [255, 255, 255, 1],
      }),
    });
  },
  getPointsStyle: (f: Feature) => {
    return [
      new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: f.get('fill'),
          }),
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 2,
          lineDash: [4, 4],
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.5],
        }),
        zIndex: 1,
        geometry: function (feature) {
          if (feature.get('features')) {
            return feature.get('features')[0].getGeometry();
          } else {
            return feature.getGeometry();
          }
        },
      }),
    ];
  },
};
