<div class="my-focus-btn-container">
  <button
    mat-mini-fab
    color="secondary"
    aria-label="focus fields"
    (click)="onFocus()"
    onclick="this.blur()"
    matTooltip="{{ 'common.tooltips.show-fields' | translate }}"
    [matTooltipShowDelay]="1000"
    [matTooltipPosition]="'after'"
  >
    <mat-icon class="material-symbols-outlined">filter_center_focus</mat-icon>
  </button>
</div>
