<div class="expected-yield-root" *ngIf="yieldPrognosis">
  <div class="top">
    <h5>{{ 'main.yieldPrognosis.title' | translate }}</h5>
    <app-square-button class="info-button" (clicked)="openInfoDialog()">
      <app-icon class="info" icon="info_outline"></app-icon>
      {{ 'main.yieldPrognosis.infoBtn' | translate }}
    </app-square-button>
  </div>

  <p class="date">{{ 'main.yieldPrognosis.estimationDate' | translate }} {{ yieldPrognosis.date | dateFormat }}</p>

  <p class="disclaimer">{{ 'main.yieldPrognosis.disclaimer.section1' | translate }}</p>
  <p class="disclaimer">{{ 'main.yieldPrognosis.disclaimer.section2' | translate }}</p>
  <p class="disclaimer">
    {{ 'main.yieldPrognosis.disclaimer.section3' | translate }}
    <a href="mailto:{{ 'main.yieldPrognosis.disclaimer.mail' | translate }}">{{ 'main.yieldPrognosis.disclaimer.mail' | translate }}</a>
  </p>

  <div [ngSwitch]="selectedCropNormNumber">
    <div *ngSwitchCase="216" class="yield">
      {{ yieldPrognosis.quantity / 1000 / yieldPrognosis.area | unit: 'ton' : 2 }} {{ 'common.drymatter.ton' | translate }}
      {{ 'main.yieldPrognosis.maize.dryMatter' | translate }}&#47;{{ 'common.hectareShort' | translate }}
    </div>

    <div *ngSwitchCase="218" class="yield">
      {{ yieldPrognosis.quantity / 1000 / yieldPrognosis.area | unit: 'ton' : 2 }} {{ 'common.drymatter.ton' | translate }}
      {{ 'main.yieldPrognosis.maize.dryMatter' | translate }}&#47;{{ 'common.hectareShort' | translate }}
    </div>

    <div *ngSwitchCase="11" class="yield">
      {{ yieldPrognosis.quantity / 1000 / yieldPrognosis.area | unit: 'ton' : 2 }} {{ 'common.drymatter.ton' | translate }}
      {{ 'main.yieldPrognosis.wheat.grain' | translate }}&#47;{{ 'common.hectareShort' | translate }}
    </div>

    <div *ngSwitchCase="13" class="yield">
      {{ yieldPrognosis.quantity / 1000 / yieldPrognosis.area | unit: 'ton' : 2 }} {{ 'common.drymatter.ton' | translate }}
      {{ 'main.yieldPrognosis.wheat.grain' | translate }}&#47;{{ 'common.hectareShort' | translate }}
    </div>

    <div *ngSwitchDefault class="yield">
      {{ yieldPrognosis.quantity / 1000 / yieldPrognosis.area | unit: 'ton' : 2 }} {{ 'common.drymatter.ton' | translate }}&#47;{{
        'common.hectareShort' | translate
      }}
    </div>
  </div>

  <div class="total-yield">
    <span>{{ 'main.yieldPrognosis.totalYield' | translate }}</span>
    <strong>{{ yieldPrognosis.quantity / 1000 | unit: 'ton' : 2 }} tons</strong>
  </div>
</div>

<app-ol-cell-hover [mapLayerId]="hoverMapLayerId">
  <app-expected-yield-tooltip *olCellFeature="let feature" [feature]="feature"></app-expected-yield-tooltip>
</app-ol-cell-hover>
