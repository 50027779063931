<button mat-button class="generel-layers-btn" #generelLayer="matMenuTrigger" [matMenuTriggerFor]="generelLayerMenu">
  <div class="generel-layers-btn-content">
    <span>{{ 'common.map-controls.show' | translate }}</span>
    <mat-icon class="material-symbols-outlined">{{ generelLayer.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>

<mat-menu #generelLayerMenu="matMenu" class="generel-layer-dropdown-menu">
  <ng-template matMenuContent>
    <div class="generel-layer-menu-header">
      <b>{{ 'common.map-controls.show-options' | translate }}</b>
      <span class="spacer"></span>
      <u class="generel-layer-menu-hide-all" (click)="hideAll(); $event.stopPropagation()">{{
        'common.map-controls.hide-all' | translate
      }}</u>
    </div>
    <ng-container *ngIf="generelLayers$ | async as layers">
      <button *ngFor="let layer of layers" class="generel-layer-menu-item" mat-menu-item (click)="toggleHide(layer, $event)">
        <div class="generel-layer-menu-item-selection">
          <mat-checkbox class="generel-layer-menu-item-selection-checkbox" [checked]="layer.enabled">
            <span class="circle-icon" [ngStyle]="getCheckboxStyles(layer.uiColor)"></span>
            <span>{{ layer.translationKey | translate }}</span>
          </mat-checkbox>
        </div>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
