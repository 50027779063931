import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import { getScoreColor } from '@app/map/features/prognosis/growth-regulation/helpers/score-color.helper';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

export const GrowthRegulationStyles = {
  getFieldStyle: (zoom: number = 13, feature: Feature) => {
    const selected = Boolean(feature.get('selectedBinary')) === true;
    if (selected) {
      return GrowthRegulationStyles.getSelectedFieldStyle(zoom, feature);
    }
    const score: number = feature.get('growthRegulation') ? feature.get('growthRegulation').score : null;
    return [
      new Style({
        stroke: new Stroke({
          color: '#FFF',
          width: 2,
        }),
        fill: new Fill({
          color: getScoreColor(score),
        }),
        text: FieldStyles.getTextStyle(zoom, feature, false),
      }),
    ];
  },
  getSelectedFieldStyle: (zoom: number = 13, feature: Feature) => {
    const score: number = feature.get('growthRegulation') ? feature.get('growthRegulation').score : null;
    return [
      new Style({
        stroke: new Stroke({
          color: '#FFF',
          width: 4,
        }),
        fill: new Fill({
          color: getScoreColor(score),
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
      }),
    ];
  },
};
