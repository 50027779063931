import { NgModule } from '@angular/core';
import { TaskModule } from '@app/map/features/cultivation-journal/map-features/tasks/task/task.module';
import { TasksCropFilterComponent } from '@app/map/features/cultivation-journal/map-features/tasks/tasks-crop-filter/tasks-crop-filter.component';
import { TasksFilterComponent } from '@app/map/features/cultivation-journal/map-features/tasks/tasks-filter/tasks-filter.component';
import { TaskCardComponent } from '@app/map/features/cultivation-journal/map-features/tasks/tasks-overview/task-card/task-card.component';
import { TaskTableComponent } from '@app/map/features/cultivation-journal/map-features/tasks/tasks-overview/task-table/task-table.component';
import { TasksOverviewComponent } from '@app/map/features/cultivation-journal/map-features/tasks/tasks-overview/tasks-overview.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [SharedModule, TaskModule],
  declarations: [TasksFilterComponent, TasksCropFilterComponent, TasksOverviewComponent, TaskCardComponent, TaskTableComponent],
  exports: [TasksOverviewComponent],
})
export class TasksModule {}
