import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { SubscriptionsDialogComponent } from '@app/subscriptions/subscriptions-dialog/subscriptions-dialog.component';
import { from, Observable } from 'rxjs';

@Injectable()
export class SubscriptionsResolverService {
  private dialogRef!: MatDialogRef<SubscriptionsDialogComponent>;

  constructor(
    private router: Router,
    private dialogService: DialogService
  ) {}

  public resolve(): Observable<any> {
    if (!this.dialogRef) {
      this.dialogRef = this.dialogService.openCustomDialog(SubscriptionsDialogComponent, {
        panelClass: 'subscriptions-dialog',
        minWidth: '52.5vw',
        autoFocus: false,
        disableClose: true,
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null as any;
        return from(this.router.navigate(['map/cultivation-journal']));
      });
    }

    return from(this.router.navigate([]));
  }
}
