<div [appResponsiveClass]="{ mobile: 'lt-Medium' }"></div>
<div class="zoom-btns">
  <div class="zoom-in-btn-container">
    <button
      mat-mini-fab
      color="secondary"
      aria-label="zoom in on map"
      (click)="onZoomIn()"
      onclick="this.blur()"
      id="e2e-zoom-in"
      matTooltip="{{ 'common.tooltips.zoom-in' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'after'"
    >
      <mat-icon class="material-symbols-outlined">zoom_in</mat-icon>
    </button>
  </div>
  <div class="zoom-out-btn-container">
    <button
      mat-mini-fab
      color="secondary"
      aria-label="zoom out on map"
      (click)="onZoomOut()"
      onclick="this.blur()"
      matTooltip="{{ 'common.tooltips.zoom-out' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'after'"
    >
      <mat-icon class="material-symbols-outlined">zoom_out</mat-icon>
    </button>
  </div>
</div>
