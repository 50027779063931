import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FarmTasksOverviewFeatureToggleService {
  constructor(
    private _featureToggleService: FeatureToggleService,
    private _router: Router
  ) {}
  private readonly OVERVIEW_KEY = 'pla_cropmanager_farm_task_overview';
  private readonly _isFeatureOn$ = this._featureToggleService.get$(this.OVERVIEW_KEY);

  //? Possible adding subfeatures such as createTask/savedFilters etc.

  // Check if the feature toggle is on, if not redirect to the cultivation journal
  public checkFeatureToggleRedirect() {
    this._isFeatureOn$.pipe(first()).subscribe((isFeatureOn) => {
      if (!isFeatureOn) {
        void this._router.navigate(['map/cultivation-journal']);
      }
    });
  }
}
