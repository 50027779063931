import { FilterItem } from '@app/map/features/cultivation-journal/filter/filter-item';
import { ScopeItem } from '@app/map/features/cultivation-journal/filter/scope-item';
import { FilterHelper } from '@app/map/features/cultivation-journal/filter/util/filter-helper';
import { ColumnKey } from './column/column-key';

export abstract class Filterable<T> {
  public isFilterActive?: boolean;

  public setIsFilterActive(filterItems: FilterItem<T>[], columnKey: ColumnKey): void {
    this.isFilterActive = filterItems.some((filterItem) => FilterHelper.isEqual(filterItem, columnKey, this.getIdentifier(columnKey)));
  }

  public abstract getTitle(columnKey: ColumnKey): any;

  protected noMatchingTitle(columnKey: ColumnKey) {
    throw new Error(`No title handling implemented for column key: ${+columnKey}. To fix this, override getTitle for ${columnKey}`);
  }

  public abstract getIdentifier(columnKey: ColumnKey): any;

  protected noMatchingIdentitier(columnKey: ColumnKey) {
    throw new Error(
      `No identifier handling implemented for column key: ${+columnKey}. To fix this, override getIdentifier for ${columnKey}`
    );
  }

  /**
   * Returns true if the given filterItems identifier matches this identifier.
   * This should be overridden, if the implementing class has nested filterables.
   */
  public matchesFilterItem(filterItem: FilterItem<any>): boolean {
    return this.getIdentifier(filterItem.columnKey) === filterItem.identifier;
  }

  /**
   * This should be overridden if the implementing class contains nested filterables.
   */
  public getNestedFilterables(columnKey: ColumnKey): any | null {
    return null;
  }

  public asFilterItem(columnKey: ColumnKey, scope?: ScopeItem[]): FilterItem<T> {
    return new FilterItem<T>(columnKey, this, scope);
  }
}
