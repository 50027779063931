<div class="pdfForceUnicodeUsage">æøå</div>

<mat-drawer-container class="field-map-container">
  <app-ol-map #map [attr.data-testid]="(canvasReady$ | async) ? 'canvas' : 'pre-ready-canvas'"> </app-ol-map>
  <ng-container>
    <app-map-controls [map]="map" [hostElement]="hostElement" [showLayerControls]="true"></app-map-controls>
    <router-outlet></router-outlet>
  </ng-container>
</mat-drawer-container>

<router-outlet name="sideDrawer"></router-outlet>
