<app-management-action
  [icon]="'cf-profile'"
  [tooltip]="'footer.actions.profile' | translate"
  [minimized]="minimized"
  (actionClick)="toggleUserInfo()"
  id="user-info-toggle"
></app-management-action>

<div
  id="user-info"
  class="user-info"
  [hidden]="!showUserInfo"
  (clickOutside)="hideUserInfo()"
  [clickOutsideExclude]="'#user-info, #user-info-toggle, #language-picker'"
>
  <!-- My Profile -->
  <a
    class="user-info-item"
    [href]="myProfileUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="myProfile (click)"
    eventCategory="Administration"
  >
    <app-icon icon="person"></app-icon>
    <div class="text">{{ 'header.user.userInfo.myProfile' | translate }} ({{ currentUserFullName }})</div>
    <mat-icon class="material-symbols-outlined offsite-icon">open_in_new</mat-icon>
  </a>

  <!-- Subscriptions -->
  <div
    class="user-info-item"
    (click)="subscriptionsClicked()"
    appGTagEventTracker
    eventName="subscriptions (click)"
    eventCategory="Administration"
  >
    <app-icon icon="subscription"></app-icon>
    <div class="text">{{ 'header.user.userInfo.subscription' | translate }} ({{ formattedSubscriptionLevel$ | async }})</div>
  </div>

  <!-- Delegation -->
  <a
    class="user-info-item"
    [ngClass]="{ 'disabled-item': (hasDelegationAccess$ | async) === false }"
    [href]="delegationUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="delegations (click)"
    eventCategory="Administration"
  >
    <app-icon icon="supervisor_account"></app-icon>
    <div class="text">{{ 'header.user.userInfo.delegation' | translate }}</div>
    <mat-icon class="material-symbols-outlined offsite-icon">open_in_new</mat-icon>
  </a>

  <!-- Change password -->
  <!-- ! Outcommented for now. They would like the user to access the full profile when changing password, to remember to change other info as well. -->
  <!-- ! Once it is certain to remain this way, we can remove the code. 01-11-2024 -->
  <!-- <a
    class="user-info-item"
    [href]="changePasswordUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="changePassword (click)"
    eventCategory="Administration"
  >
    <app-icon icon="lock_open"></app-icon>
    <div class="text">{{ 'header.user.userInfo.changePassword' | translate }}</div>
    <mat-icon class="material-symbols-outlined offsite-icon">open_in_new</mat-icon>
  </a> -->

  <!-- Language -->
  <app-language-picker
    (onLanguageSelected)="hideUserInfo()"
    id="e2e-language-picker"
    data-testid="language-picker"
    config="languagePickerConfig"
    class="user-info-item language-picker"
    appGTagEventTracker
    eventName="language (click)"
    eventCategory="Administration"
  ></app-language-picker>

  <!-- Debug info  -->
  <a
    class="user-info-item"
    (click)="showDebugInformation()"
    appGTagEventTracker
    eventName="debugInfo (click)"
    eventCategory="Administration"
  >
    <app-icon icon="info_outline"></app-icon>
    <div class="text">{{ 'header.user.userInfo.debugInformation' | translate }}</div>
  </a>

  <!-- Log out -->
  <a
    class="user-info-item"
    id="e2e-logout"
    data-testid="logout"
    (click)="logOff()"
    appGTagEventTracker
    eventName="logOut (click)"
    eventCategory="Administration"
  >
    <app-icon icon="exit_to_app"></app-icon>
    <div class="text">{{ 'header.user.userInfo.logout' | translate }}</div>
  </a>
</div>
