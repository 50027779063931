import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { NdviEnvelope } from '@app/core/interfaces/ndvi-envelope.interface';
import { NdviHistoryEnvelope } from '@app/core/interfaces/ndvi-history-envelope.interface';
import { FieldNDVIDates } from '@app/core/ndvis/field-ndvi-dates.interface';
import {
  NdviImageDateDto,
  SatelliteImageDate,
} from '@app/map/features/field-analysis/features/ndvi-feature/interfaces/ndvi-image-date.interface';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { HarvestYearStateService } from '../../state/services/harvest-year/harvest-year-state.service';
import { TDateISO } from '../types/iso-date.type';

export interface NdvisMessages {
  getError: string;
}

// Cloud coverage number used to indicate that cloudCoverageNumber is irrelevant for satellite images from ClearSky
// The number is used in the NDVI calendar component (ndvi-date-img.component.ts).
export const CLOUDLESS_FAKE_CLOUD_COVERAGE_NUMBER = -1;

@Injectable({
  providedIn: 'root',
})
export class NdviRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public getNDVI(farmIds: number[]): Observable<NdviEnvelope> {
    const options: any = {
      withCredentials: true,
    };

    return this.http.get<NdviEnvelope>(`${this.endpoints.foApi}/farms/${farmIds}/ndvis`, options);
  }

  public getNDVIHistory(farmIds: number[], harvestYear: number): Observable<NdviHistoryEnvelope> {
    const options: any = {
      withCredentials: true,
    };

    return this.http.get<NdviHistoryEnvelope>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/ndvihistory`, options);
  }

  public getSatelliteImageDates(farmIds: number[]): Observable<NdviImageDateDto[] | null> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      switchMap((year) => {
        const options: any = {
          withCredentials: true,
        };
        const postBody = {
          val: farmIds,
        };
        return this.http.post<NdviImageDateDto[], { val: number[] }>(
          `${this.endpoints.bffApi}/farms/${year}/satelliteimagedates`,
          postBody,
          options
        );
      })
    );
  }

  public getCloudlessImageDates(farmIds: number[]): Observable<SatelliteImageDate[] | null> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      switchMap((year) => {
        const options: any = {
          withCredentials: true,
          headers: new HttpHeaders().append('Content-Type', 'application/json'),
          params: { harvestYear: year },
        };
        const postBody = {
          Val: farmIds,
        };
        return this.http.post<TDateISO[], { Val: number[] }>(
          `${this.endpoints.bffApi}/farms/${year}/satellitecloudlessimagedates`,
          postBody,
          options,
          false
        );
      }),
      map((satelliteImageDates) => {
        if (!satelliteImageDates) return null;
        return satelliteImageDates.map<SatelliteImageDate>((imgDto) => ({
          date: DateTime.fromISO(imgDto),
          cloudCoveragePct: CLOUDLESS_FAKE_CLOUD_COVERAGE_NUMBER,
        }));
      })
    );
  }

  public getNDVIImageDateForFields(fieldIds: number[]): Observable<FieldNDVIDates | null> {
    const options: any = {
      withCredentials: true,
    };

    const postBody = {
      val: fieldIds,
    };

    return this.http.post<FieldNDVIDates, { val: number[] }>(`${this.endpoints.bffApi}/farms/ndvifieldimagedatesstddev`, postBody, options);
  }
}
