import { Injectable } from '@angular/core';
import { FileService } from '@app/core/file/file.service';
import { HotspotGroupRelation } from '@app/core/interfaces/hotspot-group-relation.interface';
import { Hotspot, HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { ImageItem } from '@app/core/interfaces/image-item.interface';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { MapControlHotspotsService } from '@app/shared/map-layer-controls/map-layer-control-hotspots/map-layer-control-hotspots.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { Observable, of } from 'rxjs';
import { HotspotService } from '../shared/hotspot.service';

@Injectable({
  providedIn: 'root',
})
export class HotspotDetailsService {
  public readonly selectedFarms$ = this.farmStateService.selectedFarms$;

  constructor(
    private dialogService: DialogService,
    private hotspotService: HotspotService,
    private farmStateService: FarmStateService,
    private mapControlHotspotsService: MapControlHotspotsService
  ) {}

  public deleteHotspot(hotspot: HotspotDto) {
    if (hotspot.id) {
      // Update hotspot map control
      this.mapControlHotspotsService.hotspotFeaturesChangeSubject.next({
        typeId: hotspot.hotspotTypeId || -1,
        subTypeIds: hotspot.hotspotSubTypeIds,
        action: 'remove',
      });

      return this.hotspotService.delete(hotspot.id);
    }

    return;
  }

  public saveHotspot(hotspot: HotspotDto, imagesToDeleted: ImageItem[], imagesToSave: File[]) {
    // Update hotspot map control
    this.mapControlHotspotsService.selectSpecificHotspotSubject.next({
      hotspotId: hotspot.hotspotTypeId || -1,
      hotspotSubTypeIds: hotspot.hotspotSubTypeIds,
    });
    this.mapControlHotspotsService.hotspotFeaturesChangeSubject.next({
      typeId: hotspot.hotspotTypeId || -1,
      subTypeIds: hotspot.hotspotSubTypeIds,
      action: 'add',
    });

    return hotspot.id
      ? this.hotspotService.update(hotspot, imagesToDeleted, imagesToSave)
      : this.hotspotService.post(hotspot, imagesToSave);
  }

  public updateRelations(hotspot: Hotspot, relations: HotspotGroupRelation[]): Observable<HotspotDto | null> {
    const { farmId, id } = hotspot;
    const groupIds = relations.map((relation) => relation.hotspotGroupId);

    if (!farmId || !id) return of(null);

    return this.hotspotService.updateSingleHotspotGroupRelations(farmId, id, groupIds);
  }

  public onSideDrawerChangeRequest(isDirty: boolean, ignore?: boolean) {
    return new Observable<boolean>((observer) => {
      if (ignore) {
        observer.next(false);
      } else {
        if (isDirty) {
          this.dialogService.openDirtyCheckDialog().subscribe((action) => {
            if (action?.isConfirmed) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          });
        } else {
          observer.next(true);
        }
      }
    });
  }

  public isValidImageUploadFormat(files: any, acceptedFileFormats: string[]) {
    const filesToSave: File[] = [];
    for (const file of files) {
      filesToSave.push(file);
    }

    return FileService.isValidFormats(acceptedFileFormats, filesToSave);
  }

  public openConfirmDeleteDialog() {
    return this.dialogService.openConfirmDialog({
      title: 'main.fieldmap.hotspotInfo.deleteTitle',
      text: 'main.fieldmap.hotspotInfo.deleteText',
      confirmText: 'main.fieldmap.hotspotInfo.deleteActionsConfirm',
    });
  }
}
