import { animate, state, style, transition, trigger } from '@angular/animations';
import { formatNumber } from '@angular/common';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { ScaleLegendItem } from '@app/core/interfaces/scale-legend-item.interface';
import { Unit } from '@app/core/interfaces/unit.type';
import { LanguageService } from '@app/core/language/language.service';
import { ArrayHelper } from '@app/helpers/arrays/array-helpers';
import { DateValidators } from '@app/helpers/validators/forms/date-validators';
import {
  MetadataChild,
  MetadataParent,
  MetadataParentInterface,
} from '@app/map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { FieldAnalysisSideDrawerService } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.service';
import { LegendData } from '@app/map/services/legend/legend.model';
import { LegendService } from '@app/map/services/legend/legend.service';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { DateTime } from 'luxon';

export abstract class MetadataView {
  public legendSettings: ScaleLegendSettings = {
    title: '',
    items: [],
    visible: true,
    unit: 'kg',
  };

  public static readonly FLY_IN_OUT = trigger('flyInOut', [
    state('in', style({ transform: 'translateX(0)' })),
    transition('void => *', [style({ transform: 'translateX(-100%)' }), animate(150)]),
    transition('* => void', [animate(150, style({ transform: 'translateX(100%)' }))]),
  ]);
  public readonly MAX_DATE = DateTime.now();
  public metadataParent!: MetadataParent;
  public parentForm!: UntypedFormGroup;

  // If there the sum of metadata children geometries exceeds this number, they will be initialized as invisible, in the name of performance
  public readonly DEFAULT_POINT_THRESHOLD = 8000;

  protected constructor(
    private formBuilderInjection: UntypedFormBuilder,
    private languageServiceInjection: LanguageService,
    private legendServiceInjection: LegendService,
    private fieldAnalysisSideDrawerServiceInjection: FieldAnalysisSideDrawerService,
    private readonly disableForms = false
  ) {}

  public get childForms(): AbstractControl[] {
    return this.childrenFormArray.controls;
  }

  public addChildForm(metadataChild?: MetadataChild, showPoints = true) {
    this.childrenFormArray.push(this.createChildFormGroup(metadataChild, showPoints));
  }

  protected setLegendSettings(legend: LegendColorValue[], units: string) {
    const legendSettings = {
      ...this.legendSettings,
      title: 'As Applied',
      items: legend.map((li) => {
        return {
          text: formatNumber(Number(li.value), this.languageServiceInjection.currentLanguage.shortKey, '1.1-1'),
          color: li.color,
        } as ScaleLegendItem;
      }),
      operationTypeGroupId: 1,
    };

    const legendData: LegendData[] = legendSettings.items.map((item) => {
      return {
        color: item.color,
        value: item.text,
        unit: units as Unit,
        unitText: units,
      };
    });

    this.legendServiceInjection.updateAsAppliedLegend(legendData);
  }

  /**
   * Returns a string representing a unique list of units from @units, separated by a comma
   */
  protected joinUniqueUnits(units: string[]): string {
    return ArrayHelper.unique(units)
      .filter((unit) => !!unit)
      .join(', ');
  }

  protected populateChildrenFormArray(metadataChildren: MetadataChild[]) {
    const showPoints = this.pointsVisibleByDefault(metadataChildren);
    metadataChildren.forEach((metadataChild) => this.addChildForm(metadataChild, showPoints));
    this.parentForm.markAsPristine();
  }

  protected get childrenFormArray(): UntypedFormArray {
    return this.parentForm.get('children') as UntypedFormArray;
  }

  private pointsVisibleByDefault(metadataChildren: MetadataChild[]) {
    const filter = metadataChildren.filter((x) => x.geometries != undefined);
    return filter.reduce((accumulator, metadataChild) => accumulator + metadataChild.geometries.length, 0) < this.DEFAULT_POINT_THRESHOLD;
  }

  private createChildFormGroup(metadataChild?: MetadataChild, showPoints = true): UntypedFormGroup {
    return this.formBuilderInjection.group({
      appliedDate: new UntypedFormControl({ value: metadataChild?.date ?? '', disabled: this.disableForms }, [
        Validators.required,
        DateValidators.maxDate(this.MAX_DATE),
      ]),
      // If the element has a fieldId, it should not be editable.
      // This is because BE cant handle a change in field, if the element is already matched to a specific fieldYearId.
      fieldNumber: new UntypedFormControl(
        { value: metadataChild?.fieldName ?? '', disabled: this.disableForms || !!metadataChild?.fieldYearId },
        Validators.required
      ),
      fieldYearId: new UntypedFormControl({ value: metadataChild?.fieldYearId ?? '', disabled: this.disableForms }),
      processId: new UntypedFormControl({ value: metadataChild?.processId ?? '', disabled: this.disableForms }),
      metadataTask: new UntypedFormControl({ value: metadataChild?.metadataTask?.taskId, disabled: this.disableForms }),
      indexId: new UntypedFormControl(metadataChild?.indexId),
      showPoints: new UntypedFormControl(showPoints),
      operationTypeGroup: new UntypedFormControl({ value: metadataChild?.OperationTypeGroup, disabled: this.disableForms }),
      taskId: new UntypedFormControl({ value: metadataChild?.taskId, disabled: this.disableForms }),
      unit: new UntypedFormControl({ value: metadataChild?.unit ?? '', disabled: this.disableForms }, Validators.required),
    });
  }

  protected createParentForm(metadataInput: MetadataParentInterface) {
    this.parentForm = this.formBuilderInjection.group({
      fileName: new UntypedFormControl({ value: metadataInput?.name ?? '', disabled: true }, Validators.required),
      farm: new UntypedFormControl({ value: metadataInput?.farm?.name ?? '', disabled: true }, Validators.required),
      harvestYear: new UntypedFormControl({ value: metadataInput?.harvestYear ?? '', disabled: true }, Validators.required),
      children: this.formBuilderInjection.array([], Validators.minLength(1)),
    });
  }
}
