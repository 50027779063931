import { NgModule } from '@angular/core';
import { NotificationService } from '@app/core/notification/notification.service';
import { SecureUrlService } from '@app/core/secure-url/secure-url.service';
import { FooterComponent } from '@app/layouts/footer/footer.component';
import { PartnersComponent } from '@app/layouts/footer/partners/partners.component';
import { SharedModule } from '@app/shared/shared.module';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CustomerCentreButtonComponent } from './customer-centre-button/customer-centre-button.component';
import { FarmPickerModalCreateFarmComponent } from './farm-picker/farm-picker-modal/create/create.component';
import { FarmPickerModalEditFarmComponent } from './farm-picker/farm-picker-modal/edit/edit.component';
import { FarmCardComponent } from './farm-picker/farm-picker-modal/farm-card/farm-card.component';
import { FarmPickerModalComponent } from './farm-picker/farm-picker-modal/farm-picker-modal.component';
import { FarmPickerSearchService } from './farm-picker/farm-picker-modal/farm-picker-search-service/farm-picker-search.service';
import { FarmPickerComponent } from './farm-picker/farm-picker.component';
import { ManagementActionComponent } from './management-action/management-action.component';
import { ReminderButtonComponent } from './reminder-button/reminder-button.component';
import { UserInfoComponent } from './user-info/user-info.component';

@NgModule({
  imports: [SharedModule],
  exports: [FooterComponent],
  declarations: [
    FooterComponent,
    PartnersComponent,
    CookiePolicyComponent,
    UserInfoComponent,
    ReminderButtonComponent,
    ManagementActionComponent,
    FarmPickerComponent,
    FarmPickerModalComponent,
    FarmPickerModalCreateFarmComponent,
    FarmPickerModalEditFarmComponent,
    FarmCardComponent,
    CustomerCentreButtonComponent,
  ],
  providers: [NotificationService, SecureUrlService, FarmPickerSearchService],
})
export class FooterModule {}
