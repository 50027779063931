import { Component, OnDestroy, OnInit } from '@angular/core';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { AppLayoutService } from '@app/core/layout/app-layout.service';
import { Subscription } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public menuMinimized$ = this.layoutService.menuMinimized$;
  public hasPassedLogin = false;
  private subscription = new Subscription();

  constructor(
    private layoutService: AppLayoutService,
    private bffAuthService: BffAuthService
  ) {}

  public ngOnInit() {
    this.subscription.add(
      this.bffAuthService.isAuthenticated$
        .pipe(
          filter((isAuthenticated) => {
            return isAuthenticated === true;
          }),
          first(),
          tap(() => {
            this.hasPassedLogin = true;
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
