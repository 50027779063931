import { Component, OnDestroy } from '@angular/core';
import { OlMapComponent } from '@app/map/ol-map/ol-map.component';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { FieldAnalysisSideDrawerService } from '../../field-analysis-side-drawer/field-analysis-side-drawer.service';

@Component({
  selector: 'app-soil-sample-feature',
  templateUrl: './soil-sample-feature.component.html',
  styleUrls: ['./soil-sample-feature.component.scss'],
})
export class SoilSampleMapFeatureComponent implements OnDestroy {
  public sideDrawerWidth$ = this.fieldAnalysisSideDrawerService.drawerWidth$;

  public get map(): OlMapComponent {
    return this.mapService.mapComponent;
  }

  constructor(
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private mapService: OlMapService
  ) {}

  public ngOnDestroy(): void {
    this.mapService.deselectFeatures();
  }
}
