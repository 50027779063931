import { NgModule } from '@angular/core';
import { HeaderComponent } from '@app/layouts/header/header.component';
import { SharedModule } from '@app/shared/shared.module';
import { SubscriptionsModule } from '@app/subscriptions/subscriptions.module';

@NgModule({
  imports: [SharedModule, SubscriptionsModule],
  exports: [HeaderComponent],
  declarations: [HeaderComponent],
  providers: [],
})
export class HeaderModule {}
