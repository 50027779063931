import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { FeatureService } from '@app/core/feature/feature.service';
import { FieldService } from '@app/core/field/field.service';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { LocalState } from '@app/helpers/local-state';
import { FieldStyles } from '@app/map/helpers/styles/field-styles';
import { WKTUtil } from '@app/map/helpers/utils/WKT-util';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import { toLonLat } from 'ol/proj';
import Vector from 'ol/source/Vector';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DrawnFieldPolygons } from './interfaces/drawn-field-polygons.class';
import { ShownComponentEnum } from './shown-component-in-side-drawer.enum';

@Injectable({
  providedIn: 'root',
})
export class FieldPlanSideDrawerService {
  public readonly isLoadingFieldPlanFeatures$ = new BehaviorSubject<boolean>(false);
  public IsLoadingFieldPlanFeaturesMessage$ = new BehaviorSubject<string>('');

  public featureIsFromFieldBlock!: boolean;
  private _drawnPolygons$ = new BehaviorSubject<DrawnFieldPolygons | null>({
    fieldPolygon: null,
    polygonToSubtract: null,
  });

  private _isFieldPolygonValid$ = new BehaviorSubject<boolean>(false);
  private _editedField: Field | null = null;
  private _editedFieldCUD = false;

  private drawFieldPolygonSubscription?: Subscription = new Subscription();
  private modifyFieldSubscription? = new Subscription();

  private subscriptions: (Subscription | undefined)[] = [
    this.drawFieldPolygonSubscription,
    this.modifyFieldSubscription!,
    this.modifyFieldSubscription!,
  ];

  private shownComponentState = new LocalState<{
    shownComponent: ShownComponentEnum;
    feature: Feature | null;
  }>({
    shownComponent: ShownComponentEnum.fieldAdministrationComponent,
    feature: null,
  });
  public shownComponent$ = this.shownComponentState.changes$;

  constructor(
    private mapService: OlMapService,
    private layerService: OlLayerService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private fieldService: FieldService,
    private featureService: FeatureService,
    private router: Router
  ) {}

  public setShownComponentState(shownComponent: ShownComponentEnum, feature: Feature | null = null) {
    this.shownComponentState.setState({
      shownComponent: shownComponent,
      feature: feature,
    });
  }

  public get editedField(): Field | null {
    return this._editedField;
  }
  public set editedField(field: Field | null) {
    this._editedField = field;
  }

  public get editedFieldCUD(): boolean {
    return this._editedFieldCUD;
  }
  public set editedFieldCUD(wasCUD: boolean) {
    this._editedFieldCUD = wasCUD;
  }

  public get isEditingField() {
    return !!this.editedField;
  }

  public destroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
    this.removeFieldPolygon();
    this._removePolygonToSubtract();
    this.mapService.removeModifyInteraction();
    this.mapService.removeDrawingInteraction();
  }

  public get olMapService() {
    return this.mapService;
  }

  public startDrawingPolygons() {
    this.drawFieldPolygonSubscription?.unsubscribe();

    const elementWithDrawingInteraction = this._addDrawingInteraction();
    this.drawFieldPolygonSubscription = elementWithDrawingInteraction
      ?.pipe(
        map((drawEvent) => drawEvent.feature),
        withLatestFrom(this.drawnPolygons$)
      )
      .subscribe(([polygon, currentPolygons]) => this._onPolygonDrawn(currentPolygons, polygon));
  }

  public startEditingPolygon(editedFeature: Feature) {
    const originalFeature = this.layerService
      .getFeaturesFromLayer(LayerId.FIELDS)
      .find((feature) => feature.get('field').id === editedFeature.get('field').id);

    this.layerService.removeFeature(originalFeature!, LayerId.FIELDS);
    this.layerService.removeFeature(originalFeature!, LayerId.FIELD_FILL);

    this.startDrawingPolygons();

    this.drawnPolygons$.pipe(first()).subscribe((polygons) => this._onPolygonDrawn(polygons, editedFeature));
  }

  public disableModifyInteraction() {
    this.mapService.removeModifyInteraction();
  }

  public stopDrawingPolygons() {
    this.mapService.removeDrawingInteraction();
  }

  public resetDrawnPolygons(originalPolygon?: Feature | null) {
    this.removeFieldPolygon(originalPolygon!);
    this._removePolygonToSubtract();
    this.mapService.removeModifyInteraction();
  }

  public removeFieldPolygon(originalPolygon?: Feature) {
    this.drawnPolygons$.pipe(first()).subscribe((drawnPolygons) => {
      this.layerService.removeFeature(drawnPolygons!.fieldPolygon, LayerId.FIELD_PLANS);
      if (originalPolygon) {
        this.updateFieldInStore(originalPolygon.get('field'));
      }

      this._setDrawnPolygons(new DrawnFieldPolygons(null, drawnPolygons?.polygonToSubtract));
      this._setFieldPolygonValid(false);
    });
  }

  public setIsLoadingFieldPlanFeatures(isLoading: boolean, message?: string) {
    this.isLoadingFieldPlanFeatures$.next(isLoading);
    this.IsLoadingFieldPlanFeaturesMessage$.next(
      message || this.languageService.getText('main.fieldAdministration.createField.loadingFieldFromBlock')
    );
  }

  public drawFieldOnMapAndMakeFieldReadyToBeModified(geometryString: string) {
    const feature = WKTUtil.getFeatureFromWkt(geometryString);
    this.setIsLoadingFieldPlanFeatures(false);
    this._addNewFieldFromFieldBlocks(feature);
    this.mapService.addModifyInteractionForFeature(feature);

    this._setDrawnPolygons(new DrawnFieldPolygons(feature, null));
    this._setFieldPolygonValidity(true);
    this.showFieldBlockLayerOnMap(false);
    this.startDrawingPolygons();
  }

  public getClickMapEvent$() {
    return this.mapService.getMapEvent$('click');
  }

  public getFieldBlockPolygon(ev: MapBrowserEvent<PointerEvent>, harvestYear: number) {
    this.setIsLoadingFieldPlanFeatures(true);
    const longitudeLatitude = toLonLat(ev.coordinate as [number, number]);

    return this.farmStateService.selectedFarms$.pipe(
      first(),
      switchMap((farms) => this.fieldService.getFieldBlockPolygon(harvestYear, longitudeLatitude[1], longitudeLatitude[0], farms))
    );
  }

  public showFieldBlockLayerOnMap(blockLayerIsVisible: boolean) {
    this.layerService.setLayerVisibility(LayerId.FIELD_BLOCKS, blockLayerIsVisible);
  }

  public removeDrawInteraction() {
    this.mapService.removeDrawingInteraction();
    this.mapService.enableSelectInteraction();
  }

  public setFieldPolygon(fieldFeature: Feature) {
    this._setDrawnPolygons(new DrawnFieldPolygons(fieldFeature));
  }

  public get drawnPolygons$() {
    return this._drawnPolygons$.asObservable();
  }

  public get isFieldPolygonValid$() {
    return this._isFieldPolygonValid$.asObservable();
  }

  public updateFieldInStore(field: Field) {
    const fieldLayers = this.featureService.getFieldsLayers([field]);
    this.farmStateService.fieldUpdated(fieldLayers);
  }

  public createFieldInStore(field: Field) {
    const fieldLayer = this.featureService.getFieldsLayers([field]);
    this.farmStateService.fieldCreated(fieldLayer);
  }

  public async goToCultivatonJournal() {
    await this.router.navigate(['map/cultivation-journal'], {
      queryParamsHandling: 'preserve',
    });
  }

  private _setFieldPolygonValid(isValid: boolean) {
    this._isFieldPolygonValid$.next(isValid);
  }

  private _setDrawnPolygons(drawnPolygons: DrawnFieldPolygons) {
    this._drawnPolygons$.next(drawnPolygons);
  }

  private _removePolygonToSubtract() {
    this.drawnPolygons$.pipe(first()).subscribe((drawnPolygons) => {
      this.layerService.removeFeature(drawnPolygons!.polygonToSubtract, LayerId.FIELD_PLANS);
      this._setDrawnPolygons(new DrawnFieldPolygons(drawnPolygons!.fieldPolygon, null));
    });
  }

  private _addNewFieldFromFieldBlocks(polygonFeature: Feature) {
    this._setDrawnFeatureStyleAndProperties(polygonFeature);
    polygonFeature.set('layerId', 'fields');
    this.layerService.addFeature(polygonFeature, LayerId.FIELD_PLANS);
  }

  private _setFieldPolygonValidity(isValid: boolean) {
    this._isFieldPolygonValid$.next(isValid);
  }

  private _onPolygonDrawn(currentPolygons: DrawnFieldPolygons | null, polygon?: Feature) {
    // field polygon exists. The drawn polygon should be subtracted.
    if (currentPolygons!.fieldPolygon && currentPolygons!.fieldPolygon !== polygon) {
      this._setAndSubtractPolygonFromField(polygon!, currentPolygons!);
    } else {
      // field does not exist. The drawn polygon is the field base.
      this._validateAndSetFieldPolygon(polygon!, currentPolygons!);
    }
  }

  private _setAndSubtractPolygonFromField(polygon: Feature, currentPolygons: DrawnFieldPolygons) {
    this._setDrawnFeatureStyleAndProperties(polygon);
    const modifiedPolygons = new DrawnFieldPolygons(currentPolygons.fieldPolygon, polygon);
    this._removePolygonToSubtract();
    this._setDrawnPolygons(modifiedPolygons);
    this._subtractPolygons();
  }

  private _validateAndSetFieldPolygon(polygon: Feature, currentPolygons: DrawnFieldPolygons) {
    this.validateDrawnPolygon(polygon).subscribe((isPolygonValid) => {
      if (!isPolygonValid) {
        this.layerService.removeFeature(polygon, LayerId.FIELD_PLANS);
        return;
      }
      this._setDrawnFeatureStyleAndProperties(polygon);
      this._setFieldPolygonValidity(isPolygonValid);
      const modifiedPolygons = new DrawnFieldPolygons(polygon, currentPolygons.polygonToSubtract);
      this._setDrawnPolygons(modifiedPolygons);
      this._listenForFieldPolygonChanges(modifiedPolygons);
    });
  }

  private _addDrawingInteraction() {
    // Remove potential existing drawing interaction efficiently
    this.mapService.removeDrawingInteraction();

    // Disable select interaction to prevent selection of features while drawing
    this.mapService.disableSelectInteraction();

    // Add the new drawing interaction
    const mapWithDrawInteraction = this.mapService.addDrawingInteraction(
      LayerId.FIELD_PLANS,
      GeometryType.POLYGON,
      FieldStyles.getFieldDrawingStyle()
    );

    return mapWithDrawInteraction?.pipe(filter((event) => !!event.feature));
  }

  private _subtractPolygons() {
    this.notificationService.dismiss();

    this.farmsAndHarvestYear$
      .pipe(
        withLatestFrom(this.drawnPolygons$),
        switchMap(([[farms, harvestYear], polygons]) => {
          const fieldWkt = polygons ? WKTUtil.getWktFromFeature(polygons.fieldPolygon) : null;
          const subtractWkt = polygons ? WKTUtil.getWktFromFeature(polygons.polygonToSubtract) : null;
          const id = this.isEditingField ? this.editedField!.id : null;

          return this.fieldService.subtractPolygon(fieldWkt, subtractWkt, farms, id, harvestYear);
        }),
        withLatestFrom(this.drawnPolygons$)
      )
      .subscribe(
        ([fieldPolygon, polygons]) => {
          if (fieldPolygon && polygons) return this._onSubtractPolygonSuccess(fieldPolygon, polygons);
        },
        (error) => this._removePolygonToSubtract()
      );
  }

  private _onSubtractPolygonSuccess(fieldPolygon: string, oldPolygons: DrawnFieldPolygons) {
    const fieldPolygonFeature = WKTUtil.getFeatureFromWkt(fieldPolygon);

    const newPolygons = new DrawnFieldPolygons(fieldPolygonFeature, null);

    this._removePolygonsFromMap(oldPolygons);

    if (!this.isEditingField) {
      this._setDrawnPolygons(newPolygons);
    }

    this._setFieldPolygonValidity(true);
    this._addPolygonToMap(fieldPolygonFeature);

    this._listenForFieldPolygonChanges(oldPolygons);
  }

  private _addPolygonToMap(fieldPolygon: Feature) {
    if (this.isEditingField) {
      this.editedField!.geometry = WKTUtil.getWktFromFeature(fieldPolygon);

      this.updateFieldInStore(this.editedField!);

      const featureToEdit = this._findEditedFeatureInlayer();

      const originalFeature = this.layerService
        .getFeaturesFromLayer(LayerId.FIELDS)
        ?.find((feature) => feature.get('field').id === featureToEdit?.get('field').id);

      if (originalFeature) {
        this.layerService.removeFeature(originalFeature, LayerId.FIELDS);
        this.layerService.removeFeature(originalFeature, LayerId.FIELD_FILL);
      }

      if (featureToEdit) this._setDrawnFeatureStyleAndProperties(featureToEdit);
      this._setDrawnPolygons(new DrawnFieldPolygons(featureToEdit));
    } else {
      this.layerService.addFeature(fieldPolygon, LayerId.FIELD_PLANS);
    }
  }

  private _removePolygonsFromMap(drawnPolygons: DrawnFieldPolygons) {
    this.layerService.removeFeature(drawnPolygons.fieldPolygon, LayerId.FIELD_PLANS);
    this.layerService.removeFeature(drawnPolygons.polygonToSubtract, LayerId.FIELD_PLANS);
  }

  private _listenForFieldPolygonChanges(oldPolygons: DrawnFieldPolygons) {
    this.drawnPolygons$.pipe(first()).subscribe((polygons) => {
      this.modifyFieldSubscription?.unsubscribe();
      this.modifyFieldSubscription = this.mapService
        ?.addModifyInteractionForFeature(polygons!.fieldPolygon)
        ?.subscribe((fieldPolygon: any) => this._onFieldPolygonChange(polygons!, fieldPolygon));
    });
  }

  private _findEditedFeatureInlayer() {
    const layer = this.mapService.getLayerFromMap(LayerId.FIELD_PLANS);
    const source = layer?.getSource() as Vector;

    const featureToEdit = source.getFeatures().find((feat) => feat.get('field').id === this.editedField?.id);

    return featureToEdit;
  }

  private _onFieldPolygonChange(currentPolygons: DrawnFieldPolygons, fieldPolygon: Feature) {
    this.validateDrawnPolygon(fieldPolygon);
    const modifiedPolygons = new DrawnFieldPolygons(fieldPolygon, currentPolygons.polygonToSubtract);
    this._setDrawnPolygons(modifiedPolygons);
  }

  private _setDrawnFeatureStyleAndProperties(feature: Feature) {
    feature.setId(feature.getRevision());
    feature.set('fieldId', -1);
    feature.set('newlyCreatedField', true);

    if (this.isEditingField) {
      const fieldFeature = this.featureService.getFieldsLayers([this.editedField!])[0];

      feature.set('fill', fieldFeature.fill);
      feature.set('stroke', fieldFeature.stroke);

      // selected style
      feature.setStyle(FieldStyles.getSelectedFieldPlanStyle(this.mapService.zoomLevel, feature));
    } else {
      // unselected style
      feature.setStyle(FieldStyles.getFieldPlanStyle(feature));
    }
  }

  public validateDrawnPolygon(fieldPolygon: Feature) {
    return this.farmsAndHarvestYear$.pipe(
      switchMap(([farms, harvestYear]) =>
        this.fieldService.validatePolygon(
          farms,
          WKTUtil.getWktFromFeature(fieldPolygon),
          this.isEditingField ? this.editedField!.id : null,
          harvestYear
        )
      ),
      tap((isValid) => {
        if (!isValid) {
          this.notificationService.showError('main.fieldAdministration.createField.fieldPolygonOverlap');
        }
      })
    );
  }

  private get farmsAndHarvestYear$() {
    return this.farmStateService.selectedFarms$.pipe(first(), withLatestFrom(this.harvestYearStateService.harvestYear$));
  }
}
