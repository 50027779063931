import { Component, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { GeolocationService } from './geolocation.service';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss'],
})
export class GeolocationComponent implements OnDestroy {
  @Input() isNewMapComponent = false;
  public tracking$: Observable<boolean>;

  constructor(private geolocation: GeolocationService) {
    this.tracking$ = this.geolocation.isTracking$;
  }

  public ngOnDestroy(): void {
    this.geolocation.destroy();
  }

  public toggleTracking() {
    this.geolocation.toggleTracking(this.isNewMapComponent);
  }
}
