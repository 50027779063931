import { Policy } from './policies';
import { Resource } from './resources';

/**
 * A map of all the resources and the policies that have access to them.
 *
 * ! Note: Hierarchical order of subscriptions is assumed handled elsewhere
 * @see [access-control.service.ts](./services/access-control.service.ts)
 */
export const RESOURCE_POLICIES: Readonly<Record<Resource, readonly Policy[]>> = {
  field_plan_information: ['free'],
  field_plan_water_balance: ['free'],
  field_plan_cultivation_journal_read: ['free'],
  field_plan_cultivation_journal_write: ['basic'],
  field_plan_hay_harvest_prognosis: ['free'],
  field_plan_grazings_prognosis: ['free'],
  field_plan_corn_harvest_prognosis: ['free'],
  field_plan_eyespot: ['free'],
  field_plan_growth_stage: ['free'],
  field_plan_septoria: ['alerts_pack'],
  field_plan_n_tools: ['premium'],
  field_plan_potato_blight: ['alerts_pack'],
  field_plan_edit_fields: ['free'],

  field_analysis_as_applied: ['vra'],
  field_analysis_biomass: ['free', 'naesgaard'],
  field_analysis_biomass_benchmark: ['free'],
  field_analysis_soil_samples: ['basic'],
  field_analysis_drone_images: ['free'],
  field_analysis_isoxml: ['vra', 'naesgaard'], // ? might change

  vra_seeding: ['vra', 'naesgaard'],
  vra_fertilization_nitrogen: ['vra', 'naesgaard'],
  vra_fertilization_potassium: ['vra'],
  vra_fertilization_phosphor: ['vra'],
  vra_plant_protection: ['vra', 'naesgaard'], //! Component still accesible for FREE as long as they have spot_spray, but can not choose other models but spot spray.
  vra_plant_protection_spot_spray: ['free'], //? Free until 15/12/2023
  vra_lime: ['vra'],
  vra_send_to_machine: ['vra'],
  vra_save_to_database: ['free'],
  vra_naesgaard_save: ['naesgaard'],
  vra_download: ['free', 'vra', 'naesgaard'], //? Free until 15/12/2023
  vra_basis_layer: ['vra', 'naesgaard'],

  prognosis_corn: ['basic'],
  prognosis_growth_regulation: ['alerts_pack'],
  prognosis_yield_prognosis: ['basic'],
  prognosis_potato_blight: ['alerts_pack'],

  basis_layer: ['vra', 'naesgaard'],

  hotspots_import_shape_file: ['basic'],

  economy: ['free'],
  economy_price_suggestion: ['basic'],

  yield_benchmark: ['basic'],

  settings_data_connections: ['free'],
  settings_transfer: ['basic'],

  delegations: ['basic'],
  reminders: ['free'], // ? might change

  map_generate_pdf: ['free'],
  map_harvestyear_picker: ['free'],

  farm_tasks_overview: ['free'],

  naesgaard_specific_information: ['naesgaard'],
} as const;
