import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { LayerIDHelper } from '@app/shared/map-layer-controls/helpers/Layer-id-helper';
import { BiomassImageSource } from '@app/shared/map-layer-controls/map-layer-control-biomass/map-control-biomass.service';
import { TranslateService } from '@ngx-translate/core';
import { Layer } from 'ol/layer';
import { LayerBundle, LayerId, PagesWithLayer } from '../../services/layer/layer.store';

const getAttributionString = (year: number) => ({
  GEO_DK: `© <a href="https://geodanmark.dk" target="_blank">GEO-DK </a>`, // GeoDanmark
  SDFI: `© <a href="https://sdfi.dk" target="_blank">SDFI </a>`, // Styrelsen for Dataforsyning og Infrastruktur
  KOMM: `© <a href="https://kl.dk" target="_blank">KOMM </a>`, // Kommunerne
  SLKS: `© <a href="https://slks.dk" target="_blank">SLKS </a>`, // Slots- og Kulturstyrelsen
  MST: `© <a href="https://mst.dk" target="_blank">MST </a>`, // Miljøstyrelsen
  LBST: `© <a href="https://lbst.dk/" target="_blank">LBST </a>`, // Landbrugsstyrelsen
  CLEARSKY: `© Contains modified Copernicus Sentinel data ${year}, processed by <a href="https://clearsky.vision/" target="_blank">ClearSky Vision </a>`, // ClearSky Vision
  SENTINEL2: `© Contains modified Copernicus Sentinel data ${year}, processed by <a href="https://sentinel-hub.com/" target="_blank">Sentinel Hub </a>`, // Sentinel-2
});

export class LayerUtil {
  /**
   * Get the settings for a permanent layer
   * @param layerSource - The source of the layer
   */
  public static getPermLayerSettings(layerSources: Layer[]): LayerBundle {
    const id = layerSources.first()!.getProperties()['id'];
    const order = layerSources.first()!.getZIndex() || 0;
    return {
      id: id,
      layerObjects: layerSources,
      opacity: 100,
      order: 0,
      group: 'permanent',
      enabled: true,
      adjustable: false,
      allowedPages: ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(id),
      uiColor: LayerUtil.getUIColorFromLayerId(id),
    };
  }

  /**
   * Get the settings for a vector layer
   * @param layerSources - The source(s) of the layer
   * @param allowedPages - The allowed pages for the layer
   */
  public static getFieldLayerSettings(
    layerSources: Layer | Layer[],
    allowedPages?: PagesWithLayer[],
    adjustable: boolean = true
  ): LayerBundle[] {
    const constLayerArray = Array.isArray(layerSources) ? layerSources : [layerSources];

    return constLayerArray.map((layerSource, index) => ({
      id: layerSource.getProperties()['id'],
      layerObjects: [layerSource],
      opacity: 100,
      order: layerSource.getZIndex() || index,
      group: 'field',
      enabled: true,
      adjustable: adjustable,
      allowedPages: allowedPages ?? ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(layerSource.getProperties()['id']),
      uiColor: LayerUtil.getUIColorFromLayerId(layerSource.getProperties()['id']),
    }));
  }

  /**
   * Get the settings for a vector layer
   * @param layerSources - The source(s) of the layer
   * @param allowedPages - The allowed pages for the layer
   */
  public static getCellDrawLayerSettings(
    layerSources: Layer | Layer[],
    allowedPages?: PagesWithLayer[],
    adjustable: boolean = true
  ): LayerBundle[] {
    const constLayerArray = Array.isArray(layerSources) ? layerSources : [layerSources];

    return constLayerArray.map((layerSource, index) => ({
      id: layerSource.getProperties()['id'],
      layerObjects: [layerSource],
      opacity: 100,
      order: layerSource.getZIndex() || index,
      group: 'cell-draw',
      enabled: true,
      adjustable: adjustable,
      allowedPages: allowedPages ?? ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(layerSource.getProperties()['id']),
      uiColor: LayerUtil.getUIColorFromLayerId(layerSource.getProperties()['id']),
    }));
  }

  /**
   * Get the settings for hotspotlayers
   * @param layerSource - The source of the layers
   */
  public static getHotspotLayerSettings(layerSources: Layer[]): LayerBundle {
    const id = LayerId.HOTSPOTS;
    const order = layerSources.first()!.getZIndex() || 0;
    return {
      id: id,
      layerObjects: layerSources,
      opacity: 100,
      order: order,
      group: 'overlay',
      enabled: true,
      adjustable: true,
      allowedPages: ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(id),
      uiColor: LayerUtil.getUIColorFromLayerId(id),
    };
  }

  /**
   * Get the settings for a WFS layer
   * @param layerSource - The source of the layer
   */
  public static getWfsLayerSettings(layerSources: Layer[]): LayerBundle {
    const id = layerSources.first()!.getProperties()['id'];
    const order = layerSources.first()!.getZIndex() || 0;
    return {
      id: id,
      layerObjects: layerSources,
      opacity: 100,
      order: order,
      group: 'generel',
      enabled: false,
      adjustable: true,
      allowedPages: ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(id),
      uiColor: this.getUIColorFromLayerId(id),
    };
  }
  /**
   * Get the settings for a vector layer
   * @param layerSources - The source(s) of the layer
   * @param allowedPages - The allowed pages for the layer
   */
  public static getOverlayLayerSettings(layerSources: Layer[], allowedPages?: PagesWithLayer[], adjustable: boolean = true): LayerBundle {
    const id = layerSources.first()!.getProperties()['id'];
    const order = layerSources.first()!.getZIndex() || 0;
    return {
      id: id,
      layerObjects: layerSources,
      opacity: 100,
      order: order,
      group: 'overlay',
      enabled: true,
      adjustable: adjustable,
      allowedPages: allowedPages ?? ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(id),
      uiColor: LayerUtil.getUIColorFromLayerId(id),
    };
  }

  /**
   * Get the settings for a WFS layer
   * @param layerSource - The source of the layer
   */
  public static getTileLayerSettings(layerSources: Layer[]): LayerBundle {
    const id = layerSources.first()!.getProperties()['id'];
    const order = layerSources.first()!.getZIndex() || 0;
    return {
      id: id,
      layerObjects: layerSources,
      opacity: 100,
      order: order,
      group: 'overlay',
      enabled: false,
      adjustable: true,
      allowedPages: ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(id),
      uiColor: LayerUtil.getUIColorFromLayerId(id),
      disableRemovalOnPage: LayerUtil.getDisableRemovalOnPageFromId(id),
    };
  }

  /**
   * Get the settings for a WFS layer
   * @param layerSource - The source of the layer
   */
  public static getWMSLayerSettings(layerSources: Layer[]): LayerBundle {
    const id = layerSources.first()!.getProperties()['id'];
    const order = layerSources.first()!.getZIndex() || 0;
    return {
      id: id,
      layerObjects: layerSources,
      opacity: 100,
      order: order,
      group: 'generel',
      enabled: false,
      adjustable: true,
      allowedPages: ['all'],
      translationKey: LayerIDHelper.getDefaultLayerLabelFromId(id),
      uiColor: LayerUtil.getUIColorFromLayerId(id),
    };
  }

  /**
   * Get UI color for a layer
   * @param layerId - The layer id to get the color for
   */
  public static getUIColorFromLayerId(layerId: LayerId): string {
    switch (layerId) {
      case LayerId.GLM_MEMORIES:
        return '#932092';
      case LayerId.GLM_LAKES:
        return '#0432FF';
      case LayerId.BES_NATURE:
        return '#FF9200';
      case LayerId.BES_WATERCOURSES:
        return '#0432FF';
      case LayerId.BES_STONES:
        return '#FF2600';
      case LayerId.BUFFER_MARGINS:
        return '#FEFB00';
      case LayerId.BNBO_STATUS:
        return '#AA7941';
      case LayerId.FIELD_SHRUBS:
        return '#000080';
      case LayerId.SHADOW_MAP:
        return '#b8b8b8';
      case LayerId.HEIGHT_MAP:
        return '#77563a';
      case LayerId.SOILSAMPLE:
        return '#5c4033';
      case LayerId.FIELD_BLOCKS:
        return '#FEFB00';
      case LayerId.HOTSPOTS:
        return '#FF0000';
      case LayerId.BIOMASS:
        return '#009aa6';
      case LayerId.GEO_SOIL:
        return '#A16A03';
      case LayerId.HUMUS:
        return '#FFEABF';
      case LayerId.DEXTER:
        return '##99F6C9';
      case LayerId.CLAY:
        return '#21A0E7';
      default:
        return '';
    }
  }

  public static getDisableRemovalOnPageFromId(layerId: LayerId): PagesWithLayer[] {
    switch (layerId) {
      case LayerId.SOILSAMPLE:
        return ['soil-sample'];
      case LayerId.BIOMASS:
        return ['biomass'];
      default:
        return [];
    }
  }
  /**
   * Get the initial z-index for a layer
   * @param layerId - The layer id to get the initial z-index for
   */
  public static getInitialZIndex(layerId: LayerId): number {
    switch (layerId) {
      case LayerId.OSM:
        return 0;
      case LayerId.AIRPHOTO_DK:
        return 0;
      case LayerId.AIRPHOTO_FOREIGN:
        return 0;
      case LayerId.FIELD_FILL:
        return 1;
      case LayerId.SHADOW_MAP:
        return 2;
      case LayerId.HEIGHT_MAP:
        return 3;
      case LayerId.BES_NATURE:
        return 4;
      case LayerId.BES_WATERCOURSES:
        return 5;
      case LayerId.BES_STONES:
        return 6;
      case LayerId.BNBO_STATUS:
        return 7;
      case LayerId.GLM_LAKES:
        return 8;
      case LayerId.GLM_MEMORIES:
        return 9;
      case LayerId.BUFFER_MARGINS:
        return 10;
      case LayerId.FIELD_SHRUBS:
        return 11;
      case LayerId.BIOMASS:
        return 12;
      case LayerId.SOILSAMPLE:
        return 13;
      case LayerId.SOILSAMPLE_LABELS:
        return 13;
      case LayerId.GEO_SOIL:
        return 14;
      case LayerId.BASIS_LAYERS:
      case LayerId.ISOXML_POINTS_NODATA:
        return 14;
      case LayerId.VRA_CELLS:
      case LayerId.AS_APPLIED:
      case LayerId.AS_APPLIED_POINTS:
      case LayerId.ISOXML:
      case LayerId.ISOXML_POINTS:
        return 15;
      case LayerId.DEXTER:
        return 16;
      case LayerId.CLAY:
        return 17;
      case LayerId.HUMUS:
        return 18;
      case LayerId.CELL_HOVER:
        return 19;
      case LayerId.FIELD_BLOCKS:
        return 100;
      case LayerId.CELL_DRAW:
        return 101;
      case LayerId.CORN_PROGNOSIS:
        return 102;
      case LayerId.GROWTH_REGULATION:
        return 103;
      case LayerId.YIELD_PROGNOSIS:
        return 104;
      case LayerId.VRA_PROGNOSIS:
        return 105;
      case LayerId.BLIGHT_POLYGONS:
        return 106;
      case LayerId.BLIGHT_FIELD_POLYGONS:
        return 107;
      case LayerId.BLIGHT_FIELDS:
        return 108;
      case LayerId.FIELDS:
        return 109;
      case LayerId.FIELD_PLANS:
        return 110;
      case LayerId.FIELD_LABELS:
        return 111;
      case LayerId.FIELD_MARKERS:
        return 112;
      case LayerId.HOTSPOTS:
        return 113;
      case LayerId.HOTSPOT_MARKERS:
        return 114;
      case LayerId.GEO_LOCATION:
        return 115;
      case LayerId.HIGH_RES_FIELD_MARKERS:
        return 116;
      case LayerId.HIGH_RES_POTATO_MARKERS:
        return 117;
      //120 reserved for cellhover!
      default:
        return 99;
    }
  }

  /**
   * Returns array of legend items for map legends containg a crop color and name each.
   * @param fields fields for which to generate legend
   */
  public static getLegendCrops(fields: Field[], translateService: TranslateService): CropColor[] {
    const cropColors: CropColor[] = [];

    // Find and use only main crops
    fields.forEach((field: Field | null) => {
      field?.crops.forEach((crop: Crop) => {
        if (crop.successionNo === 1) {
          if (!cropColors.find((cropColor: CropColor) => cropColor.color === crop.cropColor && cropColor.name === crop.cropName)) {
            cropColors.push({
              name: crop.cropName,
              color: crop.cropColor,
            });
          }
        }
      });
    });

    // Sort by name and return
    const sortedCropColors = cropColors.sort((cropNameA, cropNameB): number => {
      if (cropNameA.name > cropNameB.name) {
        return 1;
      }
      if (cropNameA.name < cropNameB.name) {
        return -1;
      }
      return 0;
    });

    if (fields.some((field) => field?.crops.length === 0)) {
      sortedCropColors.push({
        name: translateService.instant('main.fieldmap.crops.noCrop'),
        color: '#FFFFFF',
      });
    }
    return sortedCropColors;
  }

  /**
   * Get the attribution string for a layer, to give credit to source.
   * @param layerId - The layer id to get the attribution for
   */
  public static getAttributionByLayerId(layerId: LayerId, year: number = 2024, biomassSource?: BiomassImageSource): string {
    switch (layerId) {
      case LayerId.AIRPHOTO_DK:
        return getAttributionString(year).GEO_DK;
      case LayerId.HEIGHT_MAP:
        return getAttributionString(year).SDFI;
      case LayerId.SHADOW_MAP:
        return getAttributionString(year).SDFI;
      case LayerId.BES_NATURE:
        return getAttributionString(year).KOMM;
      case LayerId.BES_WATERCOURSES:
        return getAttributionString(year).GEO_DK;
      case LayerId.BES_STONES:
        return getAttributionString(year).SLKS;
      case LayerId.BNBO_STATUS:
        return getAttributionString(year).MST;
      case LayerId.GLM_LAKES:
        return getAttributionString(year).LBST;
      case LayerId.GLM_MEMORIES:
        return getAttributionString(year).LBST;
      case LayerId.BUFFER_MARGINS:
        return getAttributionString(year).LBST;
      case LayerId.FIELD_SHRUBS:
        return getAttributionString(year).LBST;
      case LayerId.BIOMASS:
        if (biomassSource === BiomassImageSource.clearsky) return getAttributionString(year).CLEARSKY;
        else return getAttributionString(year).SENTINEL2;
      case LayerId.FIELD_BLOCKS:
        return getAttributionString(year).LBST;
      default:
        return '';
    }
  }
}
