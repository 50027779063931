import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'environments/environment';
import { EnviromentBranchSelectorComponent } from '../enviroment-branch-selector/enviroment-branch-selector.component';

@Component({
  selector: 'app-environment-indicator',
  templateUrl: './environment-indicator.component.html',
  styleUrls: ['./environment-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentIndicatorComponent {
  private _bottomSheet = inject(MatBottomSheet);

  public environment = environment.env;
  public visible = this.environment !== 'prod';
  openBottomSheet(): void {
    this._bottomSheet.open(EnviromentBranchSelectorComponent);
  }
  constructor() {}
}
