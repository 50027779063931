import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FilterItem } from '@app/map/features/cultivation-journal/filter/filter-item';
import { FilterStateService } from '@app/map/features/cultivation-journal/filter/filter-state.service';
import { ScopeItem } from '@app/map/features/cultivation-journal/filter/scope-item';
import { Observable } from 'rxjs';
import { ColumnKey } from '../column/column-key';

@Component({
  selector: 'app-filter-chip-container',
  templateUrl: './filter-chip-container.component.html',
  styleUrls: ['./filter-chip-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterChipContainerComponent<T> implements OnInit {
  @Input() public scope!: ScopeItem;
  @Input() public customTemplates!: Map<ColumnKey, TemplateRef<any>>;
  public filterItems$!: Observable<FilterItem<T>[]>;

  constructor(private filterStateService: FilterStateService) {}

  public ngOnInit(): void {
    this.filterItems$ = this.filterStateService.getFilterItems$(this.scope);
  }

  public hasCustomTemplate(columnKey: ColumnKey) {
    return this.customTemplates?.has(columnKey);
  }

  public onRemove(filterItem: FilterItem<T>) {
    this.filterStateService.removeFilterItem(filterItem);
  }

  public onRemoveAll() {
    this.filterStateService.removeAllFilters(this.scope);
  }
}
