import { Injectable } from '@angular/core';
import { MultiPolygonCoordinates, PolygonCoordinates } from '@app/core/interfaces/geometry-coordinates.types';
import { WKTUtil } from '@app/map/helpers/utils/WKT-util';
import { FeatureUtil } from '@app/map/helpers/utils/feature-util';
import { LayerId } from '@app/map/services/layer/layer.store';
import { closestTo } from '@app/shared/utils/utils';
import { combineLatest, map } from 'rxjs';
import { CellRepository } from './cell.repository';
import { LegendService } from './legend.state';

@Injectable({ providedIn: 'root' })
export class FeatureService {
  private readonly _cells$ = this.repo.cells$;

  private readonly _colors$ = this.legendService.colors$;

  public features$ = combineLatest([this._cells$, this._colors$]).pipe(
    map(([cells, legendColorValues]) =>
      cells.map((cell) => {
        const isMultiPolygon = cell.geometry.startsWith('MULTIPOLYGON');
        const legendValues = legendColorValues.filter((x) => x.customLevel === false).flatMap((x) => x.value);

        const cellLegendValue = closestTo(legendValues, cell.quantity);
        const cellLegendColor = legendColorValues.find((legendValue) => legendValue.value === cellLegendValue)?.color;

        const cellColor = cell.color || cellLegendColor;
        const cellColorOrig = cellLegendColor;

        const coordinates = isMultiPolygon
          ? WKTUtil.getMultiPolygonCoordinatesFromWKTString(cell.geometry)
          : WKTUtil.getPolygonCoordinatesFromWKTString(cell.geometry);

        const feature = isMultiPolygon
          ? FeatureUtil.createMultiPolygonFeature(LayerId.VRA_CELLS, coordinates as MultiPolygonCoordinates)
          : FeatureUtil.createPolygonFeature(LayerId.VRA_CELLS, coordinates as PolygonCoordinates);

        feature.setId(cell.id);
        feature.set('quantityProduct', cell.quantityProduct);
        feature.set('quantityOrig', cell.quantity);
        feature.set('quantity', cell.quantity);
        feature.set('colorOrig', cellColorOrig);
        feature.set('color', cellColor);

        return feature;
      })
    )
  );
  public readonly vraCellColors$ = this.features$.pipe(
    map((features) => Array.from(new Set(features.map((features) => features.get('color')))))
  );

  constructor(
    private repo: CellRepository,
    private legendService: LegendService
  ) {}
}
