import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { DataConnectionType } from '@app/core/interfaces/data-connection-type.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { JdOrganization } from '@app/settings/data-connections/select-jd-organization-dialog/select-jd-organization-dialog.component';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AgriRouterConnectionSettingsDTO } from './interfaces/agrirouter-connection-settings.interface';
import { JDConnectionSettingsDTO } from './interfaces/jd-connection-settings.interface';

export interface DataConnectionMessages {
  getTypesError: string;
  getSettingsError: string;
  postSettingError: string;
  putSettingError: string;
  deleteSettingError: string;
}

export interface IDataConnectionsRepo {
  getTypes(): Observable<Array<DataConnectionType>>;
  getSettings(): Observable<Array<DataConnectionSettingDTO>>;
  getSettingById(id: number): Observable<DataConnectionSettingDTO | null>;
  createUsernamePasswordSetting(connectionSetting: DataConnectionSettingDTO): Observable<DataConnectionSettingDTO | null>;
  updateUsernamePasswordSetting(connectionSetting: DataConnectionSettingDTO): Observable<DataConnectionSettingDTO | null>;
  deleteUsernamePasswordSetting(connectionSettingId: number): Observable<number | null>;
  createOrUpdateAgriRouterSettings(settings: AgriRouterConnectionSettingsDTO): Observable<string | null>;
  revokeAgriRouterSetting(id: number): Observable<number | null>;
  revokeJohnDeereSettings(state: string): Observable<number | null>;
  getJdOrganizations(): Observable<JdOrganization[]>;
  saveJdOrganization(id: number): Observable<any>;
  deleteJdUser(): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class DataConnectionsRepo implements IDataConnectionsRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService,
    private notificationService: NotificationService,
    private languageService: LanguageService
  ) {}

  public getTypes(): Observable<DataConnectionType[]> {
    const options: any = { withCredentials: true };
    return this.http.get<DataConnectionType[]>(`${this.endpoints.foApi}/dataConnectionTypes`, options);
  }

  public getSettings(): Observable<DataConnectionSettingDTO[]> {
    const options: any = { withCredentials: true };
    return this.http.get<DataConnectionSettingDTO[]>(`${this.endpoints.foApi}/v2/dataConnectionSettings`, options);
  }

  getSettingById(id: number): Observable<DataConnectionSettingDTO | null> {
    const options: any = { withCredentials: true };
    const response = this.http.get<DataConnectionSettingDTO>(`${this.endpoints.foApi}/v2/dataConnectionSettings/${id}`, options).pipe(
      map((setting: DataConnectionSettingDTO) => {
        // decode connection code if it exists from base64
        if (setting.connectionCode) setting.connectionCode = atob(setting.connectionCode);
        return setting;
      })
    );

    return response;
  }

  public createOrUpdateJDSettings(settings: JDConnectionSettingsDTO) {
    return this.http.post<string, JDConnectionSettingsDTO>(`${this.endpoints.dataExchangeApi}/deere/getloginurl`, settings);
  }

  public createOrUpdateAgriRouterSettings(settings: AgriRouterConnectionSettingsDTO): Observable<string | null> {
    return this.http.post<string, AgriRouterConnectionSettingsDTO>(`${this.endpoints.agrirouterManagerApi}/getloginurl`, settings);
  }

  public revokeJohnDeereSettings(state: string): Observable<number | null> {
    return this.http.delete<number>(`${this.endpoints.dataExchangeApi}/deere/revoke?state=${state}`);
  }

  public revokeAgriRouterSetting(id: number): Observable<number | null> {
    return this.http.delete<number>(`${this.endpoints.agrirouterManagerApi}/revoke?id=${id}`).pipe(
      catchError((error: HttpErrorResponse) => {
        // We delete the AgriRouter connection in CropManger if we get a status code 400 from AgriRouter (bad reqest) or 401.
        // This is because the error may occur when the connection is already deleted in AgriRouter
        switch (error.status) {
          case 400:
            this.notificationService.showInfo(this.languageService.getText('messages.common.errorCodes.AgriRouterValidationError'), 15000);
            return observableThrowError(error);
          case 401:
            this.notificationService.showInfo(this.languageService.getText('messages.common.errorCodes.AgriRouterUnauthorized'), 15000);
            return observableThrowError(error);
          case 404:
            this.notificationService.showInfo(this.languageService.getText('messages.common.errorCodes.AgriRouterUserNotFound'), 15000);
            return observableThrowError(error);
          default:
            this.notificationService.showError(
              this.languageService.getText('messages.common.errorCodes.AgrirouterRevokeUnknownError'),
              15000
            );
            return observableThrowError(error);
        }
      })
    );
  }

  /**
   * Deletes a setting based on username and password
   * Used to delete settings for Trimble, Claas
   * @param connectionSettingId unique id opf the setting to delete
   */
  public deleteUsernamePasswordSetting(connectionSettingId: number): Observable<number | null> {
    return this.http.delete(`${this.endpoints.foApi}/v2/dataConnectionSettings/${connectionSettingId}`);
  }

  /**
   * Creates a setting based on username and password
   * Used to create settings for Trimble, Claas
   * @param connectionSetting contains connectionId used as username and connectionCode used as password
   */
  public createUsernamePasswordSetting(connectionSetting: DataConnectionSettingDTO): Observable<DataConnectionSettingDTO | null> {
    return this.http.post<DataConnectionSettingDTO, DataConnectionSettingDTO>(
      `${this.endpoints.foApi}/v2/dataConnectionSettings`,
      connectionSetting
    );
  }

  /**
   * Updates a setting based on username and password
   * Used to update settings for Trimble, Claas and Ranch
   * @param connectionSetting overrides the existing setting with matching id, id parameter is used to match the existing element
   */
  public updateUsernamePasswordSetting(connectionSetting: DataConnectionSettingDTO): Observable<DataConnectionSettingDTO | null> {
    return this.http.put<DataConnectionSettingDTO, DataConnectionSettingDTO>(
      `${this.endpoints.foApi}/v2/dataConnectionSettings/${connectionSetting.id}`,
      connectionSetting
    );
  }

  public getJdOrganizations(): Observable<JdOrganization[]> {
    return this.http.get<JdOrganization[]>(`${this.endpoints.foApi}/v2/dataConnectionSettings/jd/organizations`);
  }

  public saveJdOrganization(id: number) {
    return this.http.post(`${this.endpoints.foApi}/v2/dataConnectionSettings/jd/organization/${id}`, null);
  }

  public deleteJdUser() {
    return this.http.delete(`${this.endpoints.foApi}/v2/dataConnectionSettings/jd/user`);
  }

  public deleteCnhCompany() {
    return this.http.delete(`${this.endpoints.bffCnhApi}/company`);
  }
}
