<app-map-cover-flow
  [mapCoverFlowItems]="mapCoverFlowItems"
  [isVisible]="isSettingsVisible"
  (onVisibilityChanged)="onSettingsToggle($event)"
  (onSettingChanged)="onSettingToggle($event)"
>
</app-map-cover-flow>
<app-ol-map-feature-select (event)="onFeatureSelected($event)" [layers]="selectableLayers"></app-ol-map-feature-select>

<div *ngIf="queryParams$ | async as queryParams">
  <div class="edit-field-btn-container">
    <button
      mat-fab
      extended
      color="secondary"
      aria-label="zoom in on map"
      (click)="onEditFieldsClick(queryParams)"
      [disabled]="disableEditFields$ | async"
      id="e2e-edit-fields-btn"
      data-testid="edit-fields-btn"
      class="edit-fields-btn"
    >
      <mat-icon class="material-symbols-outlined">edit_outline</mat-icon>
      <span> {{ 'main.cultivationJournal.editFields' | translate }}</span>
    </button>
  </div>
</div>
<app-fab size="lg" *ngIf="showOpenSideDrawerbutton | async" icon="expand" (click)="openSideDrawer()"></app-fab>
