import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SoilSampleMapFeatureComponent } from './soil-sample-feature.component';
import { SoilSampleFeatureService } from './soil-sample-feature.service';
import { SoilSampleRadioLabelComponent } from './soil-sample-side-drawer/soil-sample-radio-label/soil-sample-radio-label.component';
import { SoilSampleSideDrawerComponent } from './soil-sample-side-drawer/soil-sample-side-drawer.component';

@NgModule({
  declarations: [SoilSampleMapFeatureComponent, SoilSampleSideDrawerComponent, SoilSampleRadioLabelComponent],
  exports: [SoilSampleMapFeatureComponent],
  providers: [SoilSampleFeatureService],
  imports: [SharedModule],
})
export class SoilSampleMapFeatureModule {}
