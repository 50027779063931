import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScaleLegendService } from '@app/shared/scale-legend/service/scale-legend.service';
import { AppPipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [CommonModule, AppPipesModule],
  providers: [ScaleLegendService],
})
export class ScaleLegendModule {}
