import { NToolsTaskStatus } from '@app/core/enums/n-tools-task-Status.enum';
import { NToolsTableSubLineDto } from '@app/core/interfaces/n-tools/n-tools-table-sub-line-dto.interface';
import { Unit } from '@app/core/interfaces/unit.type';
import { Filterable } from '@app/map/features/cultivation-journal/filter/filterable';
import { CropNutrientAvailability } from '@app/shared/n-tool/CropNutrientAvailabilityGraphDataPointDTO';
import { DateTime } from 'luxon';
import { ColumnKeyNTool } from '../../../filter/column/column-key-n-tool';
import { NumberFormater } from '../util/number-formater';
import { generator } from '../util/tableLineIdGenerator';

export class NToolsTableSubLine extends Filterable<NToolsTableSubLine> {
  public date: Date;
  public product: string;
  public amount: number;
  public amountUnit: Unit;
  public nStatus: number;
  public nStatusUnit: string;
  public nZeroDate: Date | null;
  public status: NToolsTaskStatus;
  public cropNutrientAvailability: CropNutrientAvailability;
  public operationLineId: number;
  public taskId: number;
  public tableLineId: number;
  public productNormNumber!: string;
  public fieldId: number;
  public nTotalNeed: number;
  public editable: boolean;

  constructor(dto: NToolsTableSubLineDto) {
    super();
    this.date = DateTime.fromISO(dto.date).toJSDate();
    this.product = dto.product;
    this.amount = dto.amount;
    this.amountUnit = dto.amountUnit;
    this.nStatus = dto.nStatus;
    this.nStatusUnit = dto.nStatusUnit;
    this.nZeroDate = dto.nZeroDate ? DateTime.fromISO(dto.nZeroDate).toJSDate() : null;
    this.status = dto.status;
    this.cropNutrientAvailability = dto.cropNutrientAvailability;
    this.operationLineId = dto.operationLineId;
    this.taskId = dto.taskId;
    this.tableLineId = generator.next().value as number;
    this.fieldId = dto.fieldId;
    this.nTotalNeed = dto.nTotalNeed;

    this.editable = true;
  }

  public get produceNormNumber() {
    return this.product;
  }

  public getIdentifier(columnKey: ColumnKeyNTool) {
    switch (columnKey) {
      case ColumnKeyNTool.productName:
        return this.produceNormNumber;
      default:
        return super.noMatchingIdentitier(columnKey);
    }
  }

  public getTitle(columnKey: ColumnKeyNTool) {
    switch (columnKey) {
      case ColumnKeyNTool.productName:
        return this.product;
      default:
        return super.noMatchingTitle(columnKey);
    }
  }

  public get amountWithSuffix() {
    return `${NumberFormater.Thousands(Number(this.amount.toFixed(0)))} ${this.amountUnit}`;
  }

  public get NStatusWithSuffix() {
    return `${NumberFormater.Thousands(Math.round(this.nStatus))} ${this.nStatusUnit}`;
  }
}
