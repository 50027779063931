import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterChipContainerComponent } from '@app/map/features/cultivation-journal/filter/filter-chip-container/filter-chip-container.component';
import { SharedModule } from '@app/shared/shared.module';
import { ChipModule } from '@progress/kendo-angular-buttons';

@NgModule({
  declarations: [FilterChipContainerComponent],
  imports: [CommonModule, ChipModule, SharedModule],
  exports: [FilterChipContainerComponent],
})
export class FilterModule {}
