import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { LayerId } from '@app/map/services/layer/layer.store';

export class MapCoverFlowItem {
  public static findLayerSettings(mapCoverFlowItems: MapCoverFlowItem[], mapLayerId: LayerId) {
    return mapCoverFlowItems
      .map((item) => item.layers)
      .reduce((prev, cur) => [...prev, ...cur], [])
      .find((layerSetting) => layerSetting.layerId === mapLayerId);
  }

  public static findLayerSettingsForItem(mapCoverFlowItem: MapCoverFlowItem, mapLayerId: LayerId) {
    return mapCoverFlowItem.layers.find((layerSetting) => layerSetting.layerId === mapLayerId);
  }

  public mapCoverFlowLayersId: LayerId | undefined;
  public displayName: string | undefined;
  public layers: MapLayerSetting[];
  public isDisabled?: boolean;
  public tooltip: string | undefined;
  public name: string | undefined;

  /**
   *
   */
  constructor(obj: Partial<MapCoverFlowItem>) {
    this.mapCoverFlowLayersId = obj.mapCoverFlowLayersId;
    this.displayName = obj.displayName;
    this.layers = obj.layers ? obj.layers : [];
    this.isVisible = obj.isVisible ? obj.isVisible : this.isVisible;
    this.isDisabled = obj.isDisabled !== undefined ? obj.isDisabled : this.isDisabled;
    this.tooltip = obj.tooltip;
    this.name = obj.name;
  }

  public get isVisible(): boolean {
    return this.layers.every((layer) => layer.isVisible);
  }
  public set isVisible(isVisible: boolean) {
    this.layers.map((layer) => (layer.isVisible = isVisible));
  }
}
