<div class="header-close">
  <h4>{{ 'septoriaChart.info.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <ul class="browser-default">
    <li>{{ 'septoriaChart.info.section1' | translate }}</li>
    <li>{{ 'septoriaChart.info.section2' | translate }}</li>
    <li>{{ 'septoriaChart.info.section3' | translate }}</li>
    <li>{{ 'septoriaChart.info.section4' | translate }}</li>
    <li>{{ 'septoriaChart.info.section5' | translate }}</li>
    <li>{{ 'septoriaChart.info.section6' | translate }}</li>
    <li>{{ 'septoriaChart.info.section6A' | translate }}</li>
    <li>{{ 'septoriaChart.info.section7' | translate }}</li>
    <li>
      {{ 'septoriaChart.info.section7A' | translate }}
      <a
        href="https://www.landbrugsinfo.dk/Planteavl/Afgroeder/Korn/Vinterhvede/Sygdomme/Sider/pl_19_2439_Svampebekaempelse_i_vinterhvede.aspx"
        target="_blank"
        >{{ 'septoriaChart.info.section7B' | translate }}</a
      >
      {{ 'septoriaChart.info.section7C' | translate }}
    </li>
  </ul>
  <h5>{{ 'septoriaChart.info.headline1' | translate }}</h5>
  <div class="pre">
    {{ 'septoriaChart.info.section8' | translate }}
    <ul class="browser-default">
      <li>{{ 'septoriaChart.info.item1' | translate }}</li>
      <li>{{ 'septoriaChart.info.item2' | translate }}</li>
      <li>{{ 'septoriaChart.info.item3' | translate }}</li>
      <li>{{ 'septoriaChart.info.item4' | translate }}</li>
      <li>{{ 'septoriaChart.info.item5' | translate }}</li>
      <li>{{ 'septoriaChart.info.item6' | translate }}</li>
      <li>{{ 'septoriaChart.info.item7' | translate }}</li>
      <li>{{ 'septoriaChart.info.item8' | translate }}</li>
      <li>{{ 'septoriaChart.info.item9' | translate }}</li>
      <li>{{ 'septoriaChart.info.item10' | translate }}</li>
      <li>{{ 'septoriaChart.info.item11' | translate }}</li>
      <li>{{ 'septoriaChart.info.item12' | translate }}</li>
      <li>{{ 'septoriaChart.info.item13' | translate }}</li>
      <li>{{ 'septoriaChart.info.item14' | translate }}</li>
      <li>{{ 'septoriaChart.info.item15' | translate }}</li>
      <li>{{ 'septoriaChart.info.item16' | translate }}</li>
      <li>{{ 'septoriaChart.info.item17' | translate }}</li>
    </ul>
    {{ 'septoriaChart.info.section9' | translate }}
    <br />
    <br />
    {{ 'septoriaChart.info.section10' | translate }}
  </div>
</mat-dialog-content>
