import { Injectable } from '@angular/core';
import { latest } from '@app/shared/constants/rxjs-constants';
import { emissionTickDelay } from '@app/shared/operators';
import { createStore, select } from '@ngneat/elf';
import {
  deleteEntitiesByPredicate,
  selectAllEntities,
  setEntities,
  updateAllEntities,
  updateEntitiesByPredicate,
  withEntities,
} from '@ngneat/elf-entities';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { TranslateService } from '@ngx-translate/core';
import { DataLogValueInfo, ExtendedTask, ISOXMLManager, Task } from 'isoxml';
import { DateTime } from 'luxon';
import { MetadataGeometry } from '../../as-applied/file-upload/metadata-parent';
import { IsoXMLFile } from '../interfaces/Iso-xml-file.interface';
import { DDI, Profile } from '../interfaces/profile.interface';
import { ApiParamsService } from './_api-params.service';
import { IsoMapService } from './iso-map.service';
import {
  ExtendedTimeLogInfo,
  constructPointGeometry,
  generateRandomId,
  getIsoErrorTranslationString,
  getMergedTimeLogValuesRange,
  getTaskIdByTimeLogFilename,
  getTimeLogInfo,
  getTimeLogsWithData,
  parseTimeLog,
  readFileAsArrayBuffer,
  setISOXMLManagerData,
} from './utils';

export interface DataOption extends DataLogValueInfo {
  id: string;
  fromFile: boolean;
  checked: boolean;
  selected: boolean;
  valueKey: string;
  noData: boolean;
}

export class TaskWithId extends ExtendedTask {
  id?: string;
}

export interface TaskDesignator {
  id: string;
  taskDesignator: string;
  startTime?: DateTime;
  endTime: DateTime;
}

@Injectable({ providedIn: 'root' })
export class IsoRepository {
  constructor(
    private apiParams: ApiParamsService,
    private translateService: TranslateService,
    private isoMapService: IsoMapService,
    private decimalService: DecimalService,
    private notificationService: NotificationService
  ) {}

  public readonly _loading$ = new BehaviorSubject<boolean>(false);

  public fillMissingValues$ = new BehaviorSubject(false);
  public excludeOutliers$ = new BehaviorSubject(true);
  public onOverview$ = new BehaviorSubject(true);

  public readonly _apiParams = {
    init: this.apiParams.farmAndHarvestYear$.pipe(
      emissionTickDelay(),
      map(([farmIds, harvestYear]) => [farmIds, harvestYear] as const),
      shareReplay(latest)
    ),
  };

  public addToStore(files: IsoXMLFile[]) {
    this._store.update(setEntities(files));
  }

  private readonly _store = createStore({ name: 'isoxml' }, withEntities<IsoXMLFile>());
  private readonly _storeData = this._store.pipe(distinctUntilChanged(), selectAllEntities(), shareReplay(latest));
  private readonly _profileStore = createStore({ name: 'profile' }, withEntities<Profile, 'ID'>({ idKey: 'ID' }));
  private readonly _profileStoreData = this._profileStore.pipe(distinctUntilChanged(), selectAllEntities(), shareReplay(latest));
  private readonly _dataOptionsStore = createStore({ name: 'dataOptions' }, withEntities<DataOption>());
  private readonly _dataOptionsStoreData = this._dataOptionsStore.pipe(distinctUntilChanged(), selectAllEntities(), shareReplay(latest));

  public readonly files$ = this._storeData.pipe(
    select((data) => data),
    shareReplay(latest)
  );

  // public readonly gridsInfo$ = this._storeData.pipe(
  //   select((data) =>
  //     data.map((file) => {
  //       const tasks = file.manager.rootElement.attributes.Task;
  //       const manager = file.manager;
  //       let gridsInfo: GridsInfoValues = {};

  //       tasks?.forEach((task) => {
  //         const grid = task.attributes.Grid?.[0] as ExtendedGrid;

  //         // Only process grids with binary data
  //         if (grid && grid.binaryData) {
  //           const taskXmlId = manager.getReferenceByEntity(task).xmlId;
  //           const gridValuesDescription = (task as ExtendedTask).getGridValuesDescription();

  //           gridsInfo[taskXmlId] = {
  //             ...gridValuesDescription[0],
  //           };
  //         }
  //       });

  //       return gridsInfo;
  //     })
  //   ),
  //   // Cache the latest emitted value for new subscribers.
  //   shareReplay(1)
  // );

  public readonly TAGS$ = this._storeData.pipe(
    select((data) =>
      data.map((file) =>
        // Extract the attributes as key-value pairs
        Object.entries(file.manager.rootElement.attributes)
      )
    ),
    // Cache the latest emitted value for new subscribers.
    shareReplay(latest)
  );

  public readonly noFiles$ = this._storeData.pipe(
    select((data) => data.length === 0),
    shareReplay(latest)
  );

  public readonly taskDesignators$ = this._storeData.pipe(
    map((data) =>
      data.flatMap((file) => {
        setISOXMLManagerData(file.manager);

        return file.manager.rootElement.attributes.Task?.flatMap((task) => {
          const timelogs = getTimeLogsWithData(task);
          timelogs.flatMap((timeLog) => parseTimeLog(timeLog.attributes.Filename, false));

          // Avoid processing timeLogs if there are none.
          if (timelogs.length === 0) {
            return [];
          }

          const timeLogArray = timelogs.flatMap((log) => {
            const filename = log?.attributes.Filename;
            return filename ? [getTimeLogInfo(filename)] : [];
          })[0].timeLogs;

          const firstTime = timeLogArray.first()?.time;
          const lastTime = timeLogArray.last()?.time;

          return {
            id: (task as TaskWithId).id,
            taskDesignator: task.attributes.TaskDesignator,
            startTime: firstTime ? DateTime.fromJSDate(firstTime) : undefined,
            endTime: lastTime ? DateTime.fromJSDate(lastTime) : undefined,
          } as TaskDesignator;
        });
      })
    ),
    shareReplay(latest)
  );

  public selectedDesignator$: BehaviorSubject<TaskDesignator | undefined> = new BehaviorSubject<TaskDesignator | undefined>(undefined);

  public setSelectedDesignator(designator: TaskDesignator | undefined) {
    if (!designator) return;
    if (designator === this.selectedDesignator$.value) return;
    this.isoMapService.cleanIsoXmlLayers();
    this.selectedDesignator$.next(designator);
  }

  public unselectedDesignator(designator: TaskDesignator | undefined) {
    if (!designator) return;
    this.selectedDesignator$.next(undefined);
  }

  public selectedTask$ = combineLatest([this._storeData, this.selectedDesignator$.pipe()]).pipe(
    map(([data, designator]) => {
      for (let file of data) {
        const foundTask = file.Task?.find((task) => (task as TaskWithId).id === designator?.id);
        if (foundTask) return foundTask as TaskWithId;
      }
      return undefined; // Explicitly return undefined if no task is found
    })
  );

  public readonly timelogs$ = this._storeData.pipe(
    map((data) => data.flatMap((file) => file.Task?.flatMap((task) => getTimeLogsWithData(task)) || [])),
    // Cache the latest emitted value for new subscribers.
    shareReplay(latest)
  );

  private readonly _timelogsInfo$ = combineLatest([this._storeData, this.selectedTask$]).pipe(
    map(([data, selectedTask]) => {
      if (!selectedTask?.id) return [];

      // Use reduce to accumulate only the relevant logs, reducing nested loops
      return data.reduce<ExtendedTimeLogInfo[]>((acc, file) => {
        if (!file.Task) return acc;

        // Setting manager data once per file
        setISOXMLManagerData(file.manager);

        // Filter tasks here to avoid the flatMap inside a flatMap
        const relevantTasks = file.Task.filter((task) => (task as TaskWithId).id === selectedTask.id);

        // Process only relevant tasks
        relevantTasks.forEach((task) => {
          const timelogs = getTimeLogsWithData(task);

          // Parse time logs once
          timelogs.forEach((timeLog) => parseTimeLog(timeLog.attributes.Filename, false));

          // Map timelogs to their info and push to the accumulator
          timelogs.forEach((log) => {
            const filename = log?.attributes.Filename;
            if (!filename) return;

            // Get time log info once and reuse it
            const timeLogInfo = getTimeLogInfo(filename);
            timeLogInfo.fileName = filename;

            acc.push(timeLogInfo);
          });
        });

        return acc;
      }, []);
    }),
    shareReplay(latest)
  );

  public readonly valuesInfo$ = this._timelogsInfo$.pipe(
    map((timelogsInfos) => {
      // Use Sets for quick lookup
      const uniqueDDINumbers = new Set(
        timelogsInfos.flatMap((info) => info?.valuesInfo.map((vi) => vi.DDINumber)).filter((n) => n != null)
      );

      // Convert DDI keys to numbers once, filter, and store them in a Set for quick access
      const numericKeysSet = new Set(
        Object.keys(DDI)
          .map((x) => parseInt(x))
          .filter((x) => !isNaN(x) && !uniqueDDINumbers.has(x))
      );

      // Map through valuesInfos only once
      const fromFiles = timelogsInfos.flatMap(
        (info) =>
          info?.valuesInfo.map((valueInfo) => ({
            id: generateRandomId(),
            fromFile: true,
            checked: false,
            selected: false,
            ...valueInfo,
          })) || []
      ) as DataOption[];

      // Generate fromProfiles without filtering through fromFiles each time
      const fromProfiles = Array.from(numericKeysSet).map((key) => ({
        id: generateRandomId(),
        fromFile: false,
        checked: false,
        selected: false,
        DDEntityName: this.translateService.instant('main.fieldmap.isoxml.ddi.' + key),
        DDINumber: key,
        noData: true,
      })) as DataOption[];

      // Combine and update store once
      const combinedOptions = [...fromFiles, ...fromProfiles];
      this._dataOptionsStore.update(setEntities(combinedOptions));

      return combinedOptions;
    }),
    shareReplay(latest)
  );

  public readonly selectedProfile$ = this._profileStoreData.pipe(select((data) => data[0]));

  public readonly dataOptions$ = this._dataOptionsStoreData.pipe(
    select((data) => data),
    shareReplay(latest)
  );

  public readonly checkedDataOptions$ = this.dataOptions$.pipe(
    map((data) => data.filter((dataOption) => dataOption.checked)),
    shareReplay(latest)
  );

  public readonly selectedDataOption$ = this.dataOptions$.pipe(
    map((data) => data.find((dataOption) => dataOption.selected)),
    shareReplay(latest)
  );

  public readonly metaDataGeometries$: Observable<MetadataGeometry[]> = combineLatest([
    this.selectedDataOption$.pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)),
    this.excludeOutliers$.pipe(distinctUntilChanged()),
    this.selectedProfile$.pipe(distinctUntilChanged()),
  ]).pipe(
    debounceTime(100),
    switchMap(([dataOption, excludeOutliers, selectedProfile]) =>
      this._timelogsInfo$.pipe(
        map((timelogs) => {
          let legendColor: LegendColorValue[];
          let minMax: { min: number; max: number };

          if (dataOption) {
            const taskIdValueRanges = timelogs
              .map((timelog) =>
                timelog?.fileName
                  ? getMergedTimeLogValuesRange(getTaskIdByTimeLogFilename(timelog.fileName), dataOption.valueKey, excludeOutliers)
                  : null
              )
              .filter(Boolean); // Remove falsy values

            minMax = {
              min: Math.min(...taskIdValueRanges.map((range) => (range ? range.minValue * (dataOption.scale ?? 1) : Infinity))),
              max: Math.max(...taskIdValueRanges.map((range) => (range ? range.maxValue * (dataOption.scale ?? 1) : -Infinity))),
            };

            legendColor = this.isoMapService.getIsoLegendColorValues(minMax.min, minMax.max, dataOption.numberOfDecimals);
            this.isoMapService.updateLegend(legendColor, dataOption, !!selectedProfile);
          }

          return timelogs.flatMap(
            (timelog) =>
              timelog?.timeLogs.map((timeLog) => {
                if (!dataOption) {
                  return new MetadataGeometry({
                    geometry: constructPointGeometry(timeLog.position),
                    quantity: undefined,
                    type: 'Point',
                    color: '#ffffff',
                  });
                }

                if (!timelog?.fileName) return;

                const quantity = timeLog.values[dataOption.valueKey] * (dataOption.scale ?? 1);

                if ((quantity === undefined || isNaN(quantity)) && !this.fillMissingValues$.getValue()) return;

                return new MetadataGeometry({
                  geometry: constructPointGeometry(timeLog.position),
                  quantity,
                  type: 'Point',
                  unitText: dataOption.unit,
                  color: this.isoMapService.getIsoFeatureColor(quantity, legendColor),
                  decimals: dataOption.numberOfDecimals,
                  quantityText: this.decimalService.toLocaleString(quantity, undefined, dataOption.numberOfDecimals ?? 0),
                  ddiName: dataOption.DDEntityName,
                  minMax,
                  time: timeLog.time && DateTime.fromJSDate(timeLog.time),
                });
              }) || []
          );
        })
      )
    ),
    map((items) => items.filter(Boolean) as MetadataGeometry[])
  );

  public readonly mapLayerId$ = this.metaDataGeometries$.pipe(
    map((metadataGeometries) => {
      return this.isoMapService.getMapLayerId(metadataGeometries[0]?.type);
    })
  );

  public populateMapSideEffects$ = this.metaDataGeometries$.pipe(
    distinctUntilChanged(),
    tap((metadataGeometries) => {
      this.isoMapService.addMetadataGeometriesToMap(metadataGeometries);
    })
  );

  public cleanMapSideEffects$ = this.selectedTask$.pipe(
    distinctUntilChanged(),
    tap((task) => {
      if (task === undefined) {
        this.isoMapService.cleanIsoXmlLayers();
      }
    })
  );

  public deleteFile(fileId: string) {
    this._store.update(deleteEntitiesByPredicate((entity) => entity.id === fileId));
  }

  public setDataOptionChecked(checked: boolean, id: any) {
    this.dataOptions$
      .pipe(
        first(),
        tap((options) => {
          const option = options.find((o) => o.id === id);

          if (!option) return;

          this._dataOptionsStore.update(
            updateEntitiesByPredicate(
              (entity) => entity.id === option.id,
              (entity) => ({ ...entity, checked: checked })
            )
          );
        })
      )
      .subscribe();
  }

  public setDataOptionSelected(id: any) {
    this.dataOptions$
      .pipe(
        first(),
        tap((options) => {
          const option = options.find((o) => o.id === id);

          if (!option) return;

          this.clearSelectedDataOptions();
          this._dataOptionsStore.update(
            updateEntitiesByPredicate(
              (entity) => entity.id === option.id,
              (entity) => ({ ...entity, selected: true })
            )
          );
        })
      )
      .subscribe();
  }

  public setProfile(profile: Profile) {
    this._profileStore.update(setEntities([profile]));
    // remove selected and checked from all data options
    this.clearCheckedDataOptions();
    this.clearSelectedDataOptions();
  }

  public async onFileSelected(event: any): Promise<void> {
    const files = (event.target as HTMLInputElement).files;

    if (files && files.length > 0) {
      try {
        const results = await Promise.all(Array.from(files).map((file) => this.processFile(file)));
        this.addToStore(results);
      } catch (error) {
        console.error(error);
        this.notificationService.showTranslatedError(getIsoErrorTranslationString(error as Error));
      }
    }
  }

  public reset() {
    this._store.reset();
    this._profileStore.reset();
    this._dataOptionsStore.reset();
  }

  public clearProfile() {
    this._profileStore.reset();
    // remove selected and checked from all data options
    this.clearCheckedDataOptions();
    this.clearSelectedDataOptions();
  }

  public clearCheckedDataOptions() {
    this._dataOptionsStore.update(updateAllEntities((entity) => ({ ...entity, checked: false })));
  }

  public clearSelectedDataOptions() {
    this._dataOptionsStore.update(updateAllEntities((entity) => ({ ...entity, selected: false })));
  }

  private async processFile(file: File): Promise<IsoXMLFile> {
    const isoxmlManager = new ISOXMLManager();
    const buffer = await readFileAsArrayBuffer(file);
    const array = new Uint8Array(buffer);

    await isoxmlManager.parseISOXMLFile(array, 'application/zip');

    isoxmlManager.getWarnings().forEach((warning) => console.warn(warning));

    const attributes = isoxmlManager.rootElement.attributes;

    return {
      id: Math.floor(Math.random() * 1000000000 + 1).toString(), // ! Temp ID until backend is added
      name: file.name,
      transferDate: DateTime.now(),
      manager: isoxmlManager,
      AttachedFile: attributes.AttachedFile,
      CulturalPractice: attributes.CulturalPractice,
      Device: attributes.Device,
      ExternalFileReference: attributes.ExternalFileReference,
      Farm: attributes.Farm,
      Partfield: attributes.Partfield,
      Product: attributes.Product,
      Task: attributes.Task?.map((task) => {
        (task as TaskWithId).id = generateRandomId();
        return task;
      }) as Task[],
      ValuePresentation: attributes.ValuePresentation,
    } as IsoXMLFile;
  }
}
