import { Injectable } from '@angular/core';
import { AppLayoutService } from '@app/core/layout/app-layout.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FarmTasksOverviewService {
  private _initialMenuState: 'open' | 'closed' = 'open';

  constructor(private _layoutService: AppLayoutService) {}

  public setInitialMenuState() {
    this._layoutService.menuMinimized$.pipe(first()).subscribe((menuMinimized) => {
      if (menuMinimized) {
        this._initialMenuState = 'closed';
      } else {
        this._layoutService.toggleMenuMimized();
        this._initialMenuState = 'open';
      }
      this._layoutService.hideMenuToggleSubject.next(true);
    });
  }

  public resetMenuState() {
    if (this._initialMenuState === 'open') {
      this._layoutService.toggleMenuMimized();
    }
    this._layoutService.hideMenuToggleSubject.next(false);
  }
}
