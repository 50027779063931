@if (enableLegend$ | async) {
  <div class="control-legend" [ngStyle]="{ 'right.px': (distanceFromEdge | async) }">
    <div class="toggle-control-legend-btn-container">
      <button
        mat-mini-fab
        color="secondary"
        aria-label="zoom out on map"
        (click)="toggleLayerLegends()"
        onclick="this.blur()"
        data-testid="map-legend"
        matTooltip="{{ 'common.tooltips.legend' | translate }}"
        [matTooltipShowDelay]="1000"
        [matTooltipPosition]="'below'"
        [matTooltipDisabled]="showLayerLegends()"
      >
        <mat-icon class="material-symbols-outlined">legend_toggle</mat-icon>
      </button>
    </div>

    @if (showLayerLegends()) {
      <div class="control-legend-wrapper pdfLegendOpen">
        <div class="control-legend-header">
          <div class="layer-control-text" data-testid="legend-header">
            {{ 'common.map-controls.legend.names.' + form.controls['name'].value | translate }}
            @if (showOutliersCheckBox$ | async) {
              <div class="outliers-box">
                <h5>{{ 'main.fieldInspector.isoxml.legend.exclude' | translate }}</h5>
                <mat-checkbox type="checkbox" (change)="toggleOutliers()" [checked]="excludeOutliers$ | async"></mat-checkbox>
              </div>
            }
          </div>
          <div class="layer-control-desc" data-testid="legend-description">{{ form.controls['description'].value }}</div>
        </div>

        <mat-form-field class="custom-select-legend pdf-hide" appearance="outline">
          <mat-select [value]="chosenLegendName$ | async" (selectionChange)="chooseLegend($event.value)">
            <mat-option *ngFor="let legend of choosableLegends$ | async" [value]="legend.name">
              {{ 'common.map-controls.legend.names.' + legend.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <app-layer-legend-table [form]="form"></app-layer-legend-table>

        <div class="control-legend-additional">
          @if (form.controls['totalAmount'].value && form.controls['averageAmount'].value) {
            <div class="layer-control-sums" data-testid="legend-total">
              {{ 'common.map-controls.legend.total' | translate }}
              {{ form.controls['totalAmount'].value | unit: form.controls['unit'].value }} {{ form.controls['unit'].value }}
            </div>
            <div class="layer-control-sums" data-testid="legend-average">
              {{ 'common.map-controls.legend.average' | translate }}
              {{ form.controls['averageAmount'].value | unit: form.controls['unit'].value }}
              {{ form.controls['unitText'].value }}
            </div>
          }
          @if (form.controls['additionalText'].value) {
            <div *ngFor="let additionalText of form.controls['additionalText']?.value" class="layer-control-additional-text">
              {{ additionalText }}
            </div>
          }
        </div>
      </div>
    }
  </div>
}
