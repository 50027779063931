import { Injectable } from '@angular/core';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldService } from '@app/core/field/field.service';
import { Field } from '@app/core/interfaces/field.interface';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { TaskCropVM } from '@app/map/features/cultivation-journal/map-features/tasks/task/field-selector/task-crop-vm';
import { Observable } from 'rxjs';

@Injectable()
export class FieldSelectorService {
  constructor(private fieldService: FieldService) {}

  public getFieldsWithSimilarTask(farmIds: number[], harvestYear: number, taskId: number): Observable<Field[]> {
    // Returns an observable containing an empty array, if invalid taskId is provided
    return this.fieldService.getFieldsWithSimilarTask(farmIds, harvestYear, taskId);
  }

  public mapCropsWithSimilarTask(fieldsWithSimilarTask: Field[], selectedCrops: TaskCropVM[]) {
    return fieldsWithSimilarTask
      .reduce(
        (allCrops, field) => [...allCrops, ...field.crops.map((crop) => new TaskCropVM(field, crop, 'similar-task'))],
        [] as TaskCropVM[]
      )
      .filter((taskFieldVm) => !selectedCrops.find((tfv) => tfv.crop.id === taskFieldVm.crop.id))
      .sort((a, b) => CompareHelper.compareFieldNumbers(a.number, b.number));
  }

  public filterCropsWithSimilarCrop(fieldsWithSimilarCrop: Field[], cropsWithSimilarTask: TaskCropVM[], selectedCrops: TaskCropVM[]) {
    return fieldsWithSimilarCrop
      .reduce(
        (allCrops, field) => [...allCrops, ...field.crops.map((crop) => new TaskCropVM(field, crop, 'similar-crop'))],
        [] as TaskCropVM[]
      )

      .filter((taskFieldVm) => !cropsWithSimilarTask.find((tfv) => tfv.crop.id === taskFieldVm.crop.id))
      .filter((taskFieldVm) => !selectedCrops.find((tfv) => tfv.crop.id === taskFieldVm.crop.id))
      .sort((a, b) => CompareHelper.compareFieldNumbers(a.number, b.number));
  }

  public filterOtherCrops(
    fieldFeatures: FieldFeatures,
    cropsWithSimilarCrop: TaskCropVM[],
    cropsWithSimilarTask: TaskCropVM[],
    selectedCrops: TaskCropVM[],
    currentCrop: TaskCropVM
  ) {
    return fieldFeatures.fieldFeatures
      .map((feature) => feature.field)
      .reduce((allCrops, field) => [...allCrops, ...field!.crops.map((crop) => new TaskCropVM(field, crop, 'none'))], [] as TaskCropVM[])
      .filter((taskFieldVm) => taskFieldVm.crop.id !== currentCrop.crop.id)
      .filter((taskFieldVm) => !selectedCrops.find((tfv) => tfv.crop.id === taskFieldVm.crop.id))
      .filter((taskFieldVm) => {
        return (
          !cropsWithSimilarCrop.find((tfv) => tfv.crop.id === taskFieldVm.crop.id) &&
          !cropsWithSimilarTask.find((tfv) => tfv.crop.id === taskFieldVm.crop.id)
        );
      })
      .sort((a, b) => CompareHelper.compareFieldNumbers(a.number, b.number));
  }
}
