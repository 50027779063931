<button mat-button class="biomass-btn" #biomass="matMenuTrigger" [matMenuTriggerFor]="biomassMenu">
  <div class="biomass-btn-content">
    <span>{{ 'common.map-controls.biomass.title' | translate }}</span>
    <mat-icon class="material-symbols-outlined">{{ biomass.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>

<mat-menu #biomassMenu="matMenu" class="biomass-menu" (click)="$event.stopPropagation()">
  <ng-template matMenuContent>
    <div class="biomass-menu-content">
      <div class="biomass-layer-menu-header">
        <b>{{ 'common.map-controls.biomass.title' | translate }}</b>
        <span class="spacer"></span>
        <u class="biomass-layer-menu-hide-all" (click)="onClearClick(); $event.stopPropagation()">{{
          'common.map-controls.hide-all' | translate
        }}</u>
      </div>
      <div class="biomass-ai-selection">
        <button
          type="button"
          mat-button
          class="image-source"
          [ngClass]="(imagesource$ | async) === sourceType.clearsky ? 'active' : 'inactive'"
          [color]="(imagesource$ | async) === sourceType.clearsky ? 'primary' : 'disabled'"
          (click)="onClearSkyBtnClick(); $event.stopPropagation()"
          [disabled]="isNaesgaard$ | async"
        >
          {{ 'common.map-controls.biomass.clearsky' | translate }}
        </button>
        <button
          type="button"
          mat-button
          class="image-source standard"
          [ngClass]="(imagesource$ | async) === sourceType.standard ? 'active' : 'inactive'"
          [color]="(imagesource$ | async) === sourceType.standard ? 'primary' : 'disabled'"
          (click)="onStandardBtnClick(); $event.stopPropagation()"
        >
          {{ 'common.map-controls.biomass.standard' | translate }}
        </button>
      </div>
    </div>
    <div class="date-selection" (click)="$event.stopPropagation()">
      <form class="biomass-form" [formGroup]="form">
        <mat-form-field class="custom-select" appearance="outline">
          <mat-select formControlName="year">
            @for (year of years$ | async; track year) {
              <mat-option class="option" [value]="year">{{ year }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="custom-select" appearance="outline">
          <mat-select formControlName="month" [placeholder]="'common.map-controls.biomass.pickmonth' | translate">
            @for (month of months$ | async; track month) {
              <mat-option class="option" [value]="month">{{ 'datePicker.months.' + month | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="custom-select" appearance="outline">
          <mat-select formControlName="date" [placeholder]="'common.map-controls.biomass.pickdate' | translate">
            <mat-option class="option" [value]="undefined"
              ><mat-icon></mat-icon>{{ 'common.map-controls.biomass.clear' | translate }}</mat-option
            >
            @if ((imagesource$ | async) === sourceType.clearsky) {
              @for (date of dates$ | async; track date.date) {
                <mat-option class="option" [value]="date.date"
                  ><mat-icon class="material-symbols-outlined option-icon-sat">satellite_alt</mat-icon
                  >{{ date.date.toISODate() | date: 'dd/MM/yyyy' }}</mat-option
                >
              }
            } @else {
              @for (date of dates$ | async; track date.date) {
                <mat-option class="option" [value]="date.date"
                  ><img class="option-icon" [src]="date.imgsrc" />{{ date.date.toISODate() | date: 'dd/MM/yyyy' }}</mat-option
                >
              }
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </ng-template>
</mat-menu>
