import { Injectable } from '@angular/core';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { Unit } from '@app/core/interfaces/unit.type';
import { LayerId } from '@app/map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { emissionTickDelay, filterNullOrEmpty } from '@app/shared/operators';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { ScaleLegendOptions, ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { ScaleLegendService } from '@app/shared/scale-legend/service/scale-legend.service';
import { createStore, select, withProps } from '@ngneat/elf';
import _ from 'lodash';
import { NEVER, combineLatest, map, merge, of, shareReplay, switchMap, tap } from 'rxjs';
import { createLegendStatistics, getDelimiter } from '../domain/legend';
import { CellRepository } from './cell.repository';
import { ColorService } from './color.state';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

interface LegendProps {
  legend: ScaleLegendSettings | null;
  colors: LegendColorValue[];
}

@Injectable({ providedIn: 'root' })
export class LegendService {
  constructor(
    private pmQuery: PrescriptionMapQuery,
    private colorService: ColorService,
    private scaleLegendService: ScaleLegendService,
    private cellRepo: CellRepository,
    private decimalService: DecimalService
  ) {}

  private readonly _store = createStore({ name: 'vra-legend' }, withProps<LegendProps>({ legend: null, colors: [] }));

  private readonly _quantities$ = this.cellRepo.cells$.pipe(
    map((cells) =>
      cells
        .filter((cell) => cell.customLevel === false)
        .flatMap((cell) => cell.quantity)
        .filter((quantity): quantity is number => quantity !== undefined)
    )
  );

  private readonly _prescriptionMapStatistics$ = this.pmQuery.statistics$;
  private readonly _operationTypeGroup$ = this.pmQuery.operationTypeGroup$;

  private readonly _statistics$ = combineLatest([
    this._quantities$.pipe(filterNullOrEmpty()),
    this._prescriptionMapStatistics$,
    this._operationTypeGroup$,
  ]).pipe(map(([quantities, statistics, operationTypeGroup]) => createLegendStatistics(quantities, statistics, operationTypeGroup)));

  public readonly legend$ = this._store.pipe(select(({ legend }) => legend));
  public readonly colors$ = this._store.pipe(select(({ colors }) => colors));

  private readonly _populateColors$ = combineLatest([
    this.colorService.colors$,
    this._statistics$,
    this._operationTypeGroup$,
    this.cellRepo.customLevels$,
  ]).pipe(
    emissionTickDelay(),
    map(([colors, { min, max }, operationTypeGroup, cells]) => {
      const showOneLegendEntry = Math.abs(max - min) <= 0.1;
      const steps = showOneLegendEntry ? 1 : colors.length - 1;
      const range = showOneLegendEntry ? 1 : colors.length;

      const interval = (max - min) / steps;

      const gradiation = _.range(range).map<LegendColorValue>((i) => ({
        color: colors[i],
        value: this.decimalService.format(max - interval * i)!, // start high, end low
        customLevel: false,
        delimiter: getDelimiter(i, operationTypeGroup),
      }));

      const customLevels = cells.map<LegendColorValue>((cell) => ({
        color: cell.color!,
        value: this.decimalService.format(cell.quantity)!,
        customLevel: true,
      }));

      return [...gradiation, ...customLevels];
    }),
    map((colors) => colors.sort((a, b) => b.value - a.value)),
    tap((colors) => this._store.update((state) => ({ ...state, colors }))),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private readonly _populateLegend$ = combineLatest([
    this._statistics$,
    this.colors$,
    this.pmQuery.prescriptionMapsByActiveTask$,
    this.pmQuery.unit$,
    this.pmQuery.operationTypeGroup$,
  ]).pipe(
    emissionTickDelay(),
    // short circuit if there are no prescription maps
    switchMap((args) => {
      const prescriptionMaps = args[2];

      return prescriptionMaps.isEmpty() ? NEVER : of(args);
    }),
    map(([{ avg, total }, colors, maps, unit, operationTypeGroup]) => {
      const mapCoverFlowItem: MapCoverFlowItem = {
        isVisible: true,
        mapCoverFlowLayersId: LayerId.VRA_CELLS,
        isDisabled: false,
        displayName: 'Vra',
        layers: [],
        tooltip: '',
        name: '',
      };

      const options: ScaleLegendOptions = {
        selectedAvgQuantity: avg,
        selectedLegend: colors,
        selectedTotalQuantity: total,
        selectedPrescriptionMaps: maps,
      };

      const settings = this.scaleLegendService.getScaleLegendSettings(
        mapCoverFlowItem,
        options,
        operationTypeGroup,
        unit?.trimStart() as Unit
      );

      return settings;
    }),
    tap((legend) => this._store.update((state) => ({ ...state, legend }))),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private readonly _init$ = merge(this._populateColors$, this._populateLegend$).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  public init() {
    return this._init$;
  }

  public reset() {
    this._store.reset();
  }
}
