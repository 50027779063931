import { Resource } from '@app/shared/access-control/resources';

export enum VraAllocationTypes {
  noRedistribution = 0, // TODO - There is no difference between no redistribution with n and with k. Naesgaard does not have any form of soil samples, so it is not possible for them, but not locked off in FE.
  NitrogenNdvi = 1,
  PotassiumSoilSamples = 2,
  // There is no allocation type 3
  LimeSoilSample = 4,
  PhosphorSoilSamples = 5,
  CaliumSoilSamples = 6,
}

// TODO: Place this somewhere else, probably in the access control module

/**
 * Maps VraAllocationTypes to Resource for Access Control
 * @param id Allocation type id
 * @returns Resource
 */
export const mapToVraTaskResource = (id: VraAllocationTypes): Resource | Resource[] | null => {
  switch (id) {
    case VraAllocationTypes.noRedistribution:
      return null;
    case VraAllocationTypes.NitrogenNdvi:
      //! For as long as spot spray is available to free users, they need to have access to tasks with NitrogenNDVI allocation type
      return ['vra_fertilization_nitrogen', 'vra_plant_protection_spot_spray'];
    case VraAllocationTypes.PotassiumSoilSamples:
      return 'vra_fertilization_potassium';
    case VraAllocationTypes.LimeSoilSample:
      return 'vra_lime';
    default:
      return null;
  }
};
