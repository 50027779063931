import { Injectable } from '@angular/core';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { LanguageService } from '@app/core/language/language.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';

@Injectable({
  providedIn: 'root',
})
export class SoilSampleFeatureService {
  constructor(private languageService: LanguageService) {}

  public getSoilSamplesMapCoverFlowItem(isDisabled = false) {
    const soilSamplesMapCoverFlowItem = new MapCoverFlowItem({
      mapCoverFlowLayersId: LayerId.SOILSAMPLE,
      displayName: 'main.fieldmap.layers.soilSamples',
      name: this.languageService.getText('main.fieldmap.layers.soilSamples'), // for language specific sorting
      isVisible: true,
      isDisabled,
      layers: [
        {
          layerId: LayerId.SOILSAMPLE,
          layerType: MapLayerType.TILE,
          zIndex: 2,
          useAuthHeaders: true,
          isVisible: true,
          url: undefined,
        },
        {
          layerId: LayerId.SOILSAMPLE_LABELS,
          layerType: MapLayerType.VECTOR,
          zIndex: 3,
          isVisible: true,
        },
      ],
    });

    return soilSamplesMapCoverFlowItem;
  }
}
