import { Injectable } from '@angular/core';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { CultivationJournalOptions } from '@app/map/features/cultivation-journal/interfaces/map/cultivation-journal-options.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { MapStateService } from '@app/state/services/map/map-state.service';
import { UserStateService } from '@app/state/services/user/user-state.service';
import { Coordinate } from 'ol/coordinate';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class FieldAnalysisFeaturesSelector {
  public readonly mapOptions$: Observable<CultivationJournalOptions | null> = this.mapStateService.fieldInspectorMapOptions$;
  public readonly mapCoverFlowItems$: Observable<MapCoverFlowItem[] | null> = this.mapStateService.mapCoverFlowItems$;
  public readonly selectedMapCoverFlowItemIdx$: Observable<number | null> = this.mapStateService.selectedMapCoverFlowItemIdx$;
  public readonly clickedCoords$: Observable<Coordinate | null> = this.mapStateService.clickedCoords$;
  public readonly subscriptionLevel$: Observable<SubscriptionLevel | null> = this.userStateService.subscriptionLevel$;
  public readonly harvestYear$: Observable<number | undefined> = this.harvestYearStateService.harvestYear$;

  public readonly selectedMapCoverFlowItem$ = combineLatest([this.selectedMapCoverFlowItemIdx$, this.mapCoverFlowItems$]).pipe(
    filter(([idx, mapCoverFlowItems]) => idx !== null && mapCoverFlowItems !== null),
    filter(([idx, mapCoverFlowItems]) => {
      return mapCoverFlowItems!.length > 0;
    }),
    map(([idx, mapCoverFlowItems]) => {
      return mapCoverFlowItems![idx!];
    })
  );

  constructor(
    private harvestYearStateService: HarvestYearStateService,
    private mapStateService: MapStateService,
    private userStateService: UserStateService
  ) {}
}
