import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MapService } from '@app/core/map/map.service';
import { PROJECTION } from '@app/map/helpers/constants/projection-consts';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const SEGES_DARK_BLUE = '#005521';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private subs: Subscription[] = [];
  public isTracking$: BehaviorSubject<boolean>;
  public geolocation!: Geolocation;
  private accuracyFeature: Feature;
  private positionFeature: Feature;
  private mapWasDragged = false;
  private isTracking = false;
  private _isNewMapComponent = false;
  private _myPosition$ = new BehaviorSubject(null);

  constructor(
    private mapService: MapService,
    private olMapService: OlMapService,
    private layerService: OlLayerService,
    private router: Router
  ) {
    this.accuracyFeature = new Feature();
    this.positionFeature = new Feature();
    this.accuracyFeature.setStyle(this.getAccuracyStyle());
    this.positionFeature.setStyle(this.getPositionStyle());
    this.isTracking$ = new BehaviorSubject(false);

    this.subs = [
      this.mapService.mapReady$.subscribe(() => {
        this.subs.push(
          this.mapService
            .getMap()
            .getMapEvent$('pointerdrag')
            .subscribe(() => this.handleMapMoved())
        );
        this.subs.push(this.router.events.pipe(filter((e: any) => e instanceof NavigationStart)).subscribe(() => this.handleNavigation()));
      }),
    ];
  }

  public destroy() {
    this.isTracking$.next(false);
    this.subs.forEach((s) => s.unsubscribe());
  }

  public toggleTracking(isNewMapComponent: boolean) {
    this._isNewMapComponent = isNewMapComponent;
    if (!this.geolocation) {
      this.setupGeolocation();
    }

    this.isTracking = !this.isTracking;
    this.isTracking$.next(this.isTracking);
    this.geolocation.setTracking(this.isTracking);

    // new map logic
    if (isNewMapComponent) {
      if (this.isTracking) {
        this.layerService.createFeatureLayer(LayerId.GEO_LOCATION, [this.positionFeature, this.accuracyFeature]);
        return;
      }
      this.layerService.removeLayers(LayerId.GEO_LOCATION);
      this._myPosition$.next(null);
      this.mapWasDragged = false;
      return;
    }
  }

  private setupGeolocation() {
    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
        timeout: 3600,
      },
      projection: PROJECTION.FEATURE,
      tracking: false,
    });

    this.geolocation.on('change:position', () => {
      const pos = this.geolocation.getPosition();
      if (pos) {
        if (!this.mapWasDragged) {
          this._isNewMapComponent // switch functionality depending on map component
            ? this.olMapService.slideMapToPoint(pos, 18, false)
            : this.mapService.getMap().slideMapToPoint(pos, 18, false);
        }
        this.positionFeature.setGeometry(new Point(pos));
      }
    });

    this.geolocation.on('change:accuracyGeometry', () => {
      this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry()!);
    });
  }

  private handleNavigation() {
    if (this.isTracking) {
      this.toggleTracking(false);
    }
  }

  private handleMapMoved() {
    if (this.isTracking) {
      this.isTracking$.next(false);
      this.mapWasDragged = true;
    }
  }

  private getPositionStyle(): Style {
    return new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: SEGES_DARK_BLUE,
        }),
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
      }),
    });
  }

  private getAccuracyStyle(): Style {
    return new Style({
      stroke: new Stroke({
        color: SEGES_DARK_BLUE,
      }),
      fill: new Fill({
        color: [255, 255, 255, 0.4],
      }),
    });
  }
}
