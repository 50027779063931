import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { OlLayerQuery } from '@app/map/services/layer/layer.query';
import { map, skip } from 'rxjs';
import { CropFilterService } from './map-crop-filter-control/crop-filter.service';

@Component({
  selector: 'app-map-layer-controls',
  templateUrl: './map-layer-controls.component.html',
  styleUrls: ['./map-layer-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLayerControlsComponent implements OnInit {
  protected isNaesgaard$ = this._authService.isCurrentUserFromNaesgaard$;
  protected isMobile$ = this._screenSizeService.isMobile();

  protected hideBiomassSelector$ = this._layerQuery.activePage$.pipe(map((page) => page === 'biomass'));
  protected hideSoilsampleSelector$ = this._layerQuery.activePage$.pipe(map((page) => page === 'soil-sample'));

  constructor(
    private _authService: BffAuthService,
    private _screenSizeService: ScreenSizeService,
    private _cropFilterService: CropFilterService,
    private _layerQuery: OlLayerQuery
  ) {}

  protected hideCropFilter$ = this._layerQuery.activePage$.pipe(
    skip(1),
    map((page) => page !== 'cultivation-journal' && page !== 'field-plan')
  );

  ngOnInit(): void {
    this.hideCropFilter$.subscribe((hideFilter) => {
      if (hideFilter) {
        this._cropFilterService.resetCropFilter();
      }
    });
  }
}
