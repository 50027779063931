import { Component, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { LegendService } from '@app/map/services/legend/legend.service';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Feature } from 'ol';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, skip, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { PrognosisShownComponent } from '../../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisSideDrawerComponent } from '../../prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisService } from '../../prognosis.service';
import { YieldPrognosis } from '../interfaces/yield-prognosis';
import { YieldPrognosisService } from '../yield-prognosis.service';

@Component({
  selector: 'app-yield-prognosis-details',
  templateUrl: './yield-prognosis-details.component.html',
  styleUrls: ['./yield-prognosis-details.component.scss'],
})
export class YieldPrognosisDetailsComponent implements OnInit, OnDestroy {
  public sideDrawerWidth = SideDrawerConfig.widthAsOpened;

  // If the screensize is mobile, and the sidedrawer is not hidden, the legend should not be visible
  public hideLegend$ = combineLatest([
    this.screenSizeService.isMobile(),
    this.sideDrawerRef.onHide.pipe(startWith({ hidden: false })),
  ]).pipe(map(([mobile, hidden]) => (mobile ? !hidden.hidden : false)));

  public selectedFeatures$ = this.yieldPrognosisService.selectedFieldFeature$.pipe(map((feature) => (feature ? [feature] : [])));
  public yieldPrognosis$ = new BehaviorSubject<YieldPrognosis>({} as YieldPrognosis);
  public legend$ = new BehaviorSubject<ScaleLegendSettings>({} as ScaleLegendSettings);

  private destroy$ = new ReplaySubject(1);

  constructor(
    private yieldPrognosisService: YieldPrognosisService,
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private harvestYearStateService: HarvestYearStateService,
    private _mapService: OlMapService,
    private _layerService: OlLayerService,
    private screenSizeService: ScreenSizeService,
    private legendService: LegendService
  ) {}

  public ngOnInit(): void {
    this.initFeature();
    this.harvestYearStateService.harvestYear$.pipe(takeUntil(this.destroy$), skip(1)).subscribe(this.onCloseClick);
  }

  public ngOnDestroy() {
    this.yieldPrognosisService.setSelectedFieldFeature(null as unknown as Feature);
    this._layerService.removeLayers(LayerId.VRA_PROGNOSIS);
    this._mapService.deselectFeatures();
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }

  public onCloseClick() {
    this.prognosisService?.setShownComponent(PrognosisShownComponent.YieldPrognosis);
  }

  public onHideClick() {
    this.sideDrawerRef.hide();
  }

  /*
  The prognosis for the given field is fetched.
  */
  private getPrognosisForField(field: Field): Observable<YieldPrognosis> {
    return this.harvestYearStateService.harvestYear$.pipe(
      take(1),
      switchMap((year) => this.yieldPrognosisService.getYieldPrognosis(field, year as number))
    );
  }

  /*
  Fetches and sets the prognosis from the feature selected on initialization.
  If no features are selected, nothing happens.
  */
  private initFeature() {
    this.selectedFeatures$
      .pipe(
        filter((features) => !!features.length),
        take(1),
        switchMap((features) => this.getPrognosisForField(features[0].get('field')))
      )
      .subscribe((yieldPrognosis) => {
        this.updatePrognosis(yieldPrognosis);
      });
  }

  /*
  Fetches and adds features for the given YiedPrognosis to the map.
  Updates the internal yieldPrognosis$ with the given YieldPrognosis.
  Updates the legend.
   */
  private updatePrognosis(yieldPrognosis: YieldPrognosis) {
    this.addCellFeaturesToMap(this.yieldPrognosisService.getPrognosisCellFeatures(yieldPrognosis));
    this.yieldPrognosis$.next(yieldPrognosis);
    this.legend$.next(this.yieldPrognosisService.getLegendFromPrognosis(yieldPrognosis));
    this.yieldPrognosisService.selectedFieldFeature$.pipe(take(1)).subscribe((feature) => {
      this._mapService.selectFeatures([feature]);
      this.yieldPrognosisService.fitMapToFeature(feature);
    });
  }

  /*
  Adds the given features to the map as cells.
   */
  private addCellFeaturesToMap(features: Feature[]) {
    this._layerService.removeLayers(LayerId.VRA_PROGNOSIS);
    setTimeout(() => {
      this._layerService.createFeatureLayer(LayerId.VRA_PROGNOSIS, []);
      features.forEach((feat) => {
        this._layerService.addFeature(feat, LayerId.VRA_PROGNOSIS);
      });

      this._layerService.setLayerVisibility(LayerId.VRA_PROGNOSIS, true);
      this._mapService.magicReRender();
    }, 500);
    //we need to eventually look at this and figure out what is triggering before, that require a wait
  }
}
