export enum HotspotTypes {
  Weed = 1,
  Bug = 2,
  Disease = 3,
  Well = 4,
  Drain = 5,
  Stone = 6,
  Other = 7,
  SprayingArea = 8,
  Start = 9,
  Enter = 10,
  Exit = 11,
}
