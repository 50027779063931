<mat-form-field>
  <input
    type="text"
    [formControl]="hotspotSubTypeIdsControl"
    matInput
    placeholder="{{ 'main.fieldmap.hotspotInfo.chooseSubTypes' | translate }}"
    aria-label="State"
    [matAutocomplete]="auto"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    [displayWith]="autoCompleteDisplay"
    (optionSelected)="onAddHotspotSubType($event)"
  >
    @for (option of filteredOptions | async; track option) {
      <mat-option [value]="option">{{ option.name }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
<mat-error *ngIf="hotspotSubTypeIdsControl.hasError('invalidHotspot') && !isInputFocused">
  <span>{{ 'main.fieldmap.hotspotInfo.select-error' | translate }}</span>
</mat-error>

<div *ngIf="isAnySubTypeSelected">
  <app-hotspot-subtype-item
    [canRemove]="true"
    *ngFor="let subType of selectedHotspotSubTypes; trackBy: trackByFn"
    (onRemove)="onRemoveSubType(subType)"
    >{{ subType?.name }}</app-hotspot-subtype-item
  >
</div>
