import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FileFormats } from '@app/core/file/file-formats.enum';
import { Farm } from '@app/core/interfaces/farm.interface';
import { HotspotGroupRelation } from '@app/core/interfaces/hotspot-group-relation.interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { SIDE_DRAWER_ANIMATION_SETTING } from '@app/map/features/side-drawer-const';
import { MapControlHotspotsService } from '@app/shared/map-layer-controls/map-layer-control-hotspots/map-layer-control-hotspots.service';
import { filterNullish } from '@app/shared/operators';
import { DateTime } from 'luxon';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, first, map, startWith, take, tap } from 'rxjs/operators';
import { HotspotsService } from '../../hotspots.service';
import { HotspotGroupsService } from '../hotspot-groups/hotspot-groups.service';
import { ShownComponentEnum } from '../hotspots-sidedrawer-showncomponent.enum';
import { HotspotGroup, HotspotGroupWithRelations } from '../interfaces/hotspot-group.interface';
import { HotspotService } from '../shared/hotspot.service';
import { AssignHotspotGroupRelation } from './interfaces/assign-hotspot-group-relation.interface';
import { CardProjection } from './interfaces/card-projection.interface';
import { ShapeFileImportStatusMessages } from './interfaces/shape-file-import-response.interface';
import { ShapeFile } from './interfaces/shape-file.interface';
import { ShapeFilesService } from './service/shape-files.service';
import { UtmCardProjectionService } from './service/utm-card-projection.service';

@Component({
  selector: 'app-shape-file-import',
  templateUrl: './shape-file-import.component.html',
  styleUrls: ['./shape-file-import.component.scss'],
  animations: SIDE_DRAWER_ANIMATION_SETTING,
  providers: [UtmCardProjectionService],
})
export class ShapeFileImportComponent implements OnInit, OnDestroy {
  @Input() public hotspotTypes: HotspotType[] = [];
  @Output() public onShapeImported = new EventEmitter<HotspotDto[]>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter<boolean>();

  public hotspotGroupOptions: HotspotGroupWithRelations[] | HotspotGroup[] = [];
  public selectedGroups: HotspotGroupWithRelations[] = [];
  public fileFormats = FileFormats;
  public readonly selectedFarms$ = this.shapeFileService.selectedFarms$;

  public formGroup = this.fb.group({
    noteColumn: this.fb.control(null),
    farm: this.fb.control('', Validators.required),
    hotspotType: this.fb.control(null, Validators.required),
    hotspotGroups: this.fb.control([]),
  });

  public uploadForm = this.fb.group({
    projection: this.fb.control(null, Validators.required),
  });

  public selectedFarm?: Farm;
  public selectedColumnId?: number;
  public selectedSrid?: number;
  public selectedHotspotTypeId?: number;
  public shapeFileId?: number;
  public registeredDate?: DateTime;
  public filteredHotSpotTypes$?: Observable<HotspotType[]>;
  public importErrorMessages: ShapeFileImportStatusMessages[] = [];
  public columns: any = [];
  public projections = this.utmCardProjectionService.getCardProjections();
  public uploadEnabled = false;

  public isImporting = false;
  public isLoadingShapeFile = false;

  private subscriptions: Subscription[] = [];
  private groupSub!: Subscription;

  protected filteredOptions: Observable<CardProjection[]> | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private shapeFileService: ShapeFilesService,
    private hotspotService: HotspotService,
    private hotspotsService: HotspotsService,
    private hotspotGroupsService: HotspotGroupsService,
    private utmCardProjectionService: UtmCardProjectionService,
    private mapControlHotspotsService: MapControlHotspotsService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => this.onFormValueChange(value)),
      this.selectedFarms$.subscribe((farms) => this.onSelectedFarmsChange(farms)),
      this.uploadForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => this.onUploadFormValueChanged(value))
    );
    this.filteredOptions = this.uploadForm.controls['projection'].valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.projection;
        return name ? this._filter(name as string) : this.projections.slice();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  displayFn(projection: CardProjection): string {
    return projection && projection.projection ? projection.projection : '';
  }

  private _filter(name: string): CardProjection[] {
    const filterValue = name.toLowerCase();

    return this.projections.filter((option) => option.projection.toLowerCase().includes(filterValue));
  }

  public onSelectedFarmsChange(farms: Farm[]) {
    if (farms.length > 0) {
      this.farmControl!.setValue(farms[0]);
      this.populateHotspotGroups(farms[0].id);
    }
  }

  public onFarmSelect(farm: Farm) {
    this.populateHotspotGroups(farm.id);
  }

  public onFileInputChange(files: File[]) {
    if (files && files.length) {
      this.isLoadingShapeFile = true;

      if (this.selectedSrid)
        this.shapeFileService
          .createShapeFile(files[0], this.selectedSrid)
          .pipe(
            take(1),
            finalize(() => (this.isLoadingShapeFile = false)),
            filterNullish()
          )
          .subscribe((shapeFile) => this.onShapeFileSaved(shapeFile));
    } else {
      // Notes comes from shapeFile, thus need to be cleared with it
      this.notesControl!.setValue(null);
    }
  }

  public onShapeFileSaved(shapeFile: ShapeFile) {
    this.registeredDate = DateTime.now();
    this.columns = shapeFile.fields.split(';').map((columnName, index) => ({ value: index, label: columnName }));

    this.shapeFileId = shapeFile.id;
  }

  public onUploadFormValueChanged({ projection }: { projection: { srid: number; projection: string } }) {
    if (projection) {
      this.selectedSrid = projection.srid;
      this.uploadEnabled = true;
    }
  }

  public onFormValueChange({
    farm,
    noteColumn,
    hotspotType,
    hotspotGroups,
  }: {
    farm: Farm;
    noteColumn: {
      value: number;
      label: string;
    };
    hotspotType: {
      id: number;
      name: string;
      geometryType: number;
      displayIndex: number;
    };
    hotspotGroups: {
      id?: number;
      farmId: number;
      name: string;
      hotspotGroupRelations: HotspotGroupRelation[];
    }[];
  }) {
    this.selectedFarm = farm;
    this.selectedColumnId = noteColumn ? noteColumn.value : undefined;
    this.selectedHotspotTypeId = hotspotType ? hotspotType.id : undefined;
    this.selectedGroups = hotspotGroups ? hotspotGroups : [];
    setTimeout(() => {
      this.hotspotsService.isDirty = this.formGroup.dirty;
    });
  }

  public onCloseClick() {
    this.shapeFileService
      .onSideDrawerCloseRequest(this.hotspotsService.isDirty)
      .pipe(
        first(),
        filter((isCloseAllowed) => isCloseAllowed),
        tap(() => {
          this.hotspotsService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
          this.close.emit();
        })
      )
      .subscribe();
  }

  public onImportClick() {
    this.isImporting = true;

    if (!this.shapeFileId || !this.selectedFarm || !this.selectedHotspotTypeId) return;

    this.shapeFileService
      .importShapeFileWithHotspots(this.shapeFileId, this.selectedFarm.id, this.selectedHotspotTypeId)
      .pipe(finalize(() => (this.isImporting = false)))
      .subscribe((hotspots: HotspotDto[]) => this.onShapeFileImported(hotspots));
  }

  public onShapeFileImported(hotspots: HotspotDto[]) {
    if (hotspots.length > 0) {
      // If groups selected, add them after import
      if (this.selectedGroups.length && this.selectedFarm) {
        const groupIds = this.selectedGroups
          .map((selectedGroup) => {
            if (selectedGroup.id !== undefined) {
              return selectedGroup.id;
            }
            return null;
          })
          .filter((id): id is number => id !== null);

        const hotspotIds = hotspots
          .map((hotspot) => {
            if (hotspot.id !== undefined) {
              return hotspot.id;
            }
            return null;
          })
          .filter((id): id is number => id !== null);

        const assignHotspotGroupRelation: AssignHotspotGroupRelation = {
          farmId: this.selectedFarm.id,
          groupIds: groupIds,
          addHotspotIds: hotspotIds,
        };

        this.hotspotService.updateHotspotGroupRelations(assignHotspotGroupRelation).pipe(first()).subscribe();
      }
      this.onShapeImported.emit(hotspots);
      hotspots.forEach((hotspot) => {
        this.mapControlHotspotsService.hotspotFeaturesChangeSubject.next({
          typeId: hotspot.hotspotTypeId || -1,
          subTypeIds: hotspot.hotspotSubTypeIds,
          action: 'add',
        });
      });
    }
  }

  public showLoadingSpinner() {
    return this.isImporting || this.isLoadingShapeFile;
  }

  public getShapeFileImportErrorMsg(errorCode: number) {
    return this.shapeFileService.getShapeFileImportErrorMsg(errorCode);
  }

  get farmControl() {
    return this.formGroup.get('farm');
  }
  get hotspotGroupsControl() {
    return this.formGroup.get('hotspotGroups');
  }
  get notesControl() {
    return this.formGroup.get('noteColumn');
  }

  private populateHotspotGroups(farmId: number) {
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }

    this.groupSub = this.hotspotGroupsService.getGroups(farmId).subscribe((hotspotGroup) => {
      this.hotspotGroupOptions = hotspotGroup;
    });
  }
}
