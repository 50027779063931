import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureBranchRepoService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getFoFeatureBranches(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.endpoints.foApi}/featurebranches`);
  }

  public getCmFeatureBranches(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.endpoints.cmApi}/featurebranches`);
  }

  public getVraFeatureBranches(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.endpoints.bffApi}/prescriptionmaps/featurebranches`);
  }
}
