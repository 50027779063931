import { Component, Input } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';

@Component({
  selector: 'app-customer-centre-button',
  templateUrl: './customer-centre-button.component.html',
  styleUrls: ['customer-centre-button.component.scss'],
})
export class CustomerCentreButtonComponent {
  private _customerCentreUrl = this.endpoints.customerCentre;

  @Input() public minimized = false;

  constructor(private endpoints: EndpointsService) {}

  public openCustomerCentre() {
    window.open(this._customerCentreUrl, '_blank');
  }
}
