import { Component, OnDestroy, OnInit } from '@angular/core';
import { FarmTasksOverviewFeatureToggleService } from '../services/farm-tasks-feature-toggle.service';
import { FarmTasksOverviewService } from '../services/farm-tasks-overview.service';

@Component({
  selector: 'app-overview',
  templateUrl: './farm-tasks-overview.component.html',
  styleUrls: ['farm-tasks-overview.component.scss'],
})
export class FarmTasksOverviewComponent implements OnInit, OnDestroy {
  constructor(
    private _featureToggleService: FarmTasksOverviewFeatureToggleService,
    private _overviewService: FarmTasksOverviewService
  ) {}

  public ngOnInit() {
    this._featureToggleService.checkFeatureToggleRedirect();
    setTimeout(() => {
      this._overviewService.setInitialMenuState();
    });
  }

  public ngOnDestroy() {
    this._overviewService.resetMenuState();
  }
}
