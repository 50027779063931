import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ColumnKey } from '@app/map/features/cultivation-journal/filter/column/column-key';
import { Filterable } from '@app/map/features/cultivation-journal/filter/filterable';
import { ColumnSorter } from '@app/map/features/cultivation-journal/n-tools/n-tools-table/util/ColumnSorter';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-kendo-sort-header',
  templateUrl: './kendo-header.component.html',
  styleUrls: ['./kendo-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoHeaderComponent<T extends number | string | Date | boolean> {
  @Output() public sortChange = new EventEmitter();
  @Output() public filterChange = new EventEmitter<T>();
  @Input() public columnSorter?: ColumnSorter<T>;
  @Input() public title?: string;
  @Input() public columnKey?: ColumnKey;
  @Input() public filterables$?: Observable<Filterable<T>[]>;
  @Input() public customTemplate?: TemplateRef<any>;
  // The filter icon is only displayed, if the number of filterable items is larger than the threshold
  @Input() public filterHideThreshold = -1;

  public isDropdownActive = false;

  public onFilterClick(event: MouseEvent) {
    this.isDropdownActive = !this.isDropdownActive;
    // if Propagation is not stopped, the popups clickOutside event is triggered and it is immediately closed again
    event.stopPropagation();
  }

  public onFilterChange(item: T) {
    this.filterChange.emit(item);
  }
}
