import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { ActiveFieldService } from '@app/map/features/vra/state/active-field.state';
import { CreateLayerUtil } from '@app/map/helpers/utils/create-layer-util';
import { applyCropFilter, CropFilterService } from '@app/shared/map-layer-controls/map-crop-filter-control/crop-filter.service';
import { BiomassUrlService } from '@app/shared/map-layer-controls/map-layer-control-biomass/biomass-url.service';
import { SoilsampleUrlService } from '@app/shared/map-layer-controls/map-layer-control-soilsample/soilsample-url.service';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { deleteEntitiesByPredicate, updateEntities, updateEntitiesByPredicate, upsertEntities } from '@ngneat/elf-entities';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { combineLatest, distinctUntilChanged, filter, first, map, switchMap, withLatestFrom } from 'rxjs';
import { OlLayerQuery } from './layer.query';
import { LayerBundle, LayerId, LayerState, OlLayerStore, PagesWithLayer } from './layer.store';

@Injectable({
  providedIn: 'root',
})
export class OlLayerService {
  private _store = this._layerStore.store;
  private _airPhotoDKUrl = `${this._endpoints.foApi}/mapproxies/kortforsyningen/orto_foraar_webm/{z}/{x}/{y}`;
  private biomassUrl$ = this._biomassUrlService.biomassUrl$.pipe(distinctUntilChanged());
  private farmFieldFeatures$ = combineLatest([
    this._farmStateService.fields$.pipe(distinctUntilChanged()),
    this._activeFields.activeFieldsInTask$.pipe(distinctUntilChanged()),
  ]).pipe(
    withLatestFrom(this._farmStateService.selectedFarms$),
    switchMap(([[_, activeTaskFields], farms]) =>
      this._farmStateService.fieldFeatures$.pipe(
        filterNullish(),
        first(),
        map((fieldFeatures) => {
          if (activeTaskFields) {
            return {
              fieldFeatures: fieldFeatures.fieldFeatures.filter((fieldFeature) =>
                activeTaskFields.some((activeField) => activeField.fieldId === fieldFeature.fieldId)
              ),
              extent: fieldFeatures.extent,
            };
          } else {
            return fieldFeatures;
          }
        }),
        map((ff) => ({ fieldFeatures: ff as FieldFeatures, farms: farms as Farm[] }))
      )
    )
  );

  private _soilsampleUrl$ = this._soilsampleUrlService.soilsampleUrl$.pipe(distinctUntilChanged());

  constructor(
    private _layerStore: OlLayerStore,
    private _layerQuery: OlLayerQuery,
    private _endpoints: EndpointsService,
    private _farmStateService: FarmStateService,
    private _biomassUrlService: BiomassUrlService,
    private _soilsampleUrlService: SoilsampleUrlService,
    private _activeFields: ActiveFieldService,
    private _cropFilter: CropFilterService
  ) {}
  /** Initializes the layer service and creates initial layers. */
  public init() {
    this._createCommonLayers();
    this._createFieldMarkerLayer();
  }

  /**
   * Clears all layers from the store.
   */
  public clear() {
    this._store.reset();
  }

  /**
   * Updates the specified layer in the store.
   * @param  layer - The layer to update.
   */
  public update(layer: Partial<LayerBundle> & Pick<LayerBundle, 'id'>) {
    this._store.update(updateEntities(layer.id, layer));
  }

  /**
   * Updates or inserts the speficied layer in store.
   * @param  layer - The layer to update.
   */
  public upsert(layer: Partial<LayerBundle> & Pick<LayerBundle, 'id'>) {
    this._store.update(upsertEntities(layer));
  }

  /**
   * Sets the active page the user is on, which is used to determine visibility of layers.
   * @param page - The page to set as active.
   */
  public setActivePage(page: PagesWithLayer) {
    this._layerStore.activePageSubject.next(page);
  }

  /**
   * Sets the visibility of the specified layer.
   * @param id - The ID of the layer.
   * @param visible - Whether the layer should be visible.
   */
  public setLayerVisibility(id: LayerId, visible: boolean) {
    this._store.update(
      updateEntitiesByPredicate(
        (bundle) => bundle.id === id,
        (bundle) => {
          bundle.enabled = visible;
          bundle.layerObjects.forEach((layer) => {
            layer.setVisible(visible);
          });
          return bundle;
        }
      )
    );
  }

  /**
   * Toggles the visibility of the specified layer.
   * @param id - The ID of the layer.
   */
  public toggleLayerVisibility(id: LayerId) {
    this._store.update(
      updateEntitiesByPredicate(
        (bundle) => bundle.id === id,
        (bundle) => {
          bundle.layerObjects.forEach((layer) => {
            layer.setVisible(!bundle.enabled);
          });
          bundle.enabled = !bundle.enabled;
          return bundle;
        }
      )
    );
  }

  /** Disables the visibility of all general layers and resets opacity to 100. */
  public resetAllGeneralAndOverlayLayers() {
    this._layerQuery.generalAndOverlayLayers$
      .pipe(first(), withLatestFrom(this._layerQuery.activePage$))
      .subscribe(([layers, activePage]) => {
        layers.forEach((layer) => {
          if (layer.adjustable && !layer.disableRemovalOnPage?.includes(activePage)) {
            this.setLayerOpacity(layer.id, 100);
            this.setLayerVisibility(layer.id, false);
          }
        });
      });
  }

  /**
   * Sets the opacity of the specified layer.
   * @param  id - The ID of the layer.
   * @param  opacity - The opacity value to set (0-100).
   */
  public setLayerOpacity(id: LayerId, opacity: number) {
    this._store.update((state) => {
      const bundle = state.entities[id];
      if (bundle && bundle.layerObjects) {
        bundle.opacity = opacity;
        //opacity is between 0 and 1 here therefore take 0.01
        bundle.layerObjects.forEach((layer) => {
          layer.setOpacity(opacity * 0.01);
        });
      }
      return state;
    });
  }

  /**
   * Moves a layer from its current position to a new position specified by another layer.
   * ! Some opacity magic happens, beware. Should hopefully not be needed when OL-WebGL is more stable.
   * @param id - The ID of the layer to move.
   * @param toLayerId - The ID of the layer to move the specified layer to.
   */
  public moveLayerOrder(id: LayerId, toLayerId: LayerId) {
    this._store.update((state) => {
      const movedLayer = state.entities[id];
      const toLayer = state.entities[toLayerId];
      if (!movedLayer || !toLayer) return state;

      // Sort layers based on their current order
      const layers = Object.values(state.entities).sort((a, b) => a.order - b.order);
      const currentOrder = movedLayer.order.valueOf();
      const targetOrder = toLayer.order.valueOf();

      if (currentOrder < targetOrder) {
        // Move layer down: Increment order of layers between current and target (inclusive of target)
        layers.filter((l) => l.order > currentOrder && l.order <= targetOrder).forEach((l) => (l.order -= 1));
      } else if (currentOrder > targetOrder) {
        // Move layer up: Decrement order of layers between target and current (inclusive of target)
        layers.filter((l) => l.order >= targetOrder && l.order < currentOrder).forEach((l) => (l.order += 1));
      }

      // Set the moved layer's order to the target order
      movedLayer.order = targetOrder;

      this._ensureUniqueLayerOrders(state);

      // Store current opacities of all layers
      const currentOpacities = layers.map((bundle) => ({
        layerId: bundle.id,
        opacities: bundle.layerObjects.map((layerObject) => layerObject.getOpacity().valueOf()),
      }));

      // Temporarily set opacity of all layers to 100 to prevent webGl rendering issues
      layers.forEach((bundle) => bundle.layerObjects.forEach((layerObject) => layerObject.setOpacity(1)));

      // Run zIndex change after making sure opacity has updated
      setTimeout(() => {
        this._recalculateZIndexOfLayers(state);
        // Reset layer opacities if they had a temp value before recalculate.
        currentOpacities.forEach(({ layerId, opacities }) => {
          const bundle = layers.find((layer) => layer.id === layerId);
          bundle?.layerObjects.forEach((layerObject, index) => {
            layerObject.setOpacity(opacities[index]);
          });
        });
      }, 50);

      return state;
    });

    this._layerQuery.nextReRenderSubject();
  }

  /**
   * Creates a new layer with the specified ID and optional features.
   * @param layerId - The ID of the layer to create.
   * @param features - The features to add to the layer.
   */
  public createFeatureLayer(layerId: LayerId, features?: Feature<Geometry>[], zIndex?: number) {
    const layers = CreateLayerUtil.createFeatureLayer(layerId, zIndex, features);
    if (!layers) return;

    this._inheritLayerProperties(layers);
    this._store.update(upsertEntities(layers));

    return layers;
  }

  /**
   * Creates a new layer with the specified ID and optional features.
   * @param layerId - The ID of the layer to create.
   * @param layerItem  - The features to add to the layer based on FieldLayerItem interface.
   */
  public createFieldLayerItemLayer(layerId: LayerId, layerItem?: FieldLayerItem[]) {
    this._layerQuery.highestZIndex$.pipe(first()).subscribe((zIndex) => {
      const layers = CreateLayerUtil.createFieldLayerItemLayer(layerId, zIndex, layerItem);
      if (!layers) return;
      this._inheritLayerProperties(layers);
      this._store.update(upsertEntities(layers));
    });
  }

  /**
   * Creates a hotspot layers.
   * @param hotspotFeatures - Features for the hotspot layer.
   * @param hotspotMarkerFeatures  - Features for the hotspot marker layer.
   */
  public createHotspotLayers(hotspotFeatures: Feature<Geometry>[], hotspotMarkerFeatures: Feature<Geometry>[]) {
    const bundle = CreateLayerUtil.createHotspotLayers(hotspotFeatures, hotspotMarkerFeatures);
    if (!bundle) return;
    this._store.update(upsertEntities(bundle));
  }

  /** Creates a new cell hover highlight layer */
  public createHighlightLayer() {
    let layer = CreateLayerUtil.createFeatureLayer(LayerId.CELL_HOVER, 120);
    if (Array.isArray(layer)) {
      layer = layer.first();
    }
    this._inheritLayerProperties(layer as LayerBundle);
    this._store.update(upsertEntities(layer as LayerBundle));
    return layer as LayerBundle;
  }

  public createCommonBiomassLayer(): LayerBundle[] {
    const layers: (LayerBundle | undefined)[] = [];
    layers.push(CreateLayerUtil.createBiomassTileLayer(this.biomassUrl$, this.farmFieldFeatures$));

    return layers.filter((layer) => layer !== undefined) as LayerBundle[];
  }

  // Uses same layer id as common biomass layer, but overrides the url due to different possible tile types.
  // TODO: can we find a way to have it managed through mapcontrolbiomassservice entirely, even with different tile types?
  public createBiomassAnalysisLayer(layerSetting: MapLayerSetting) {
    const bundle = CreateLayerUtil.createBiomassAnalysisTileLayer(layerSetting);
    if (!bundle) return;
    this._inheritLayerProperties(bundle);

    bundle.enabled = true;

    this._store.update(upsertEntities(bundle));
  }

  /**
   * Updates the specified layer bundles in the store.
   * @param layerBundles - The layer bundles to update.
   */
  public updateLayerBundles(layerBundles: LayerBundle[]) {
    this._store.update(upsertEntities(layerBundles));
  }

  /**
   * Removes one or more layers from the store based on their IDs.
   * @param layerIds - The ID(s) of the layer(s) to remove.
   */
  public removeLayers(layerIds: LayerId[] | LayerId) {
    this._store.update(
      deleteEntitiesByPredicate((layer) => (Array.isArray(layerIds) ? layerIds.includes(layer.id) : layer.id === layerIds))
    );
  }

  /**
   * Adds a feature to a layer, if layer is not specified, it will attempt to retrieve bundleId from feature.
   * @param feature - The feature to add.
   * @param bundleId - Optional ID of the layer to add the feature to.
   * @param layerId - Optional ID of the layerObject in the bundle to add the feature to.
   * @returns
   */
  public addFeature(feature: Feature | null, bundleId?: LayerId, layerId?: LayerId) {
    if (!feature) return;

    this._store.update((state) => {
      const bundle = state.entities[bundleId ?? (feature.get('layerId') as LayerId)];
      if (bundle && bundle.layerObjects) {
        let layerSource = bundle.layerObjects.first()!.getSource() as VectorSource;
        if (layerId) {
          layerSource = bundle.layerObjects.find((l) => l.get('id') === layerId)?.getSource() as VectorSource;
        }

        layerSource?.addFeature(feature);
      }
      return state;
    });
  }

  public addFeatures(features: Feature[], bundleId: LayerId, layerId?: LayerId) {
    features.forEach((feature) => {
      this.addFeature(feature, bundleId, layerId);
    });
  }

  /**
   * Removes a feature from a layer, if layer is not specified, it will attempt to retrieve nundleId from feature.
   * @param feature - The feature to remove.
   * @param bundleId - Optional ID of the layer to remove the feature from.
   * @param layerId - Optional ID of the layerObject in the bundle to remove the feature from.
   */
  public removeFeature(feature: Feature | null, bundleId?: LayerId, layerId?: LayerId) {
    if (!feature) return;

    this._store.update((state) => {
      const layer = state.entities[bundleId ?? (feature.get('layerId') as LayerId)];
      let layerSource = (layer?.layerObjects.first()!.getSource() as VectorSource) ?? null;
      if (layer && layer.layerObjects) {
        if (layerId) {
          layerSource = (layer.layerObjects.find((l) => l.get('id') === layerId)?.getSource() as VectorSource) ?? null;
        }
        layerSource?.removeFeature(feature);
      }
      return state;
    });
  }

  public removeFeatures(features: Feature[], bundleId: LayerId, layerId?: LayerId) {
    features.forEach((feature) => {
      this.removeFeature(feature, bundleId, layerId);
    });
  }

  public filterFeaturesInLayerByPredicate(layerId: LayerId, predicate: (feature: Feature) => boolean) {
    const features = this.getFeaturesFromLayer(layerId);
    features.forEach((feature) => {
      if (!predicate(feature)) {
        this.removeFeature(feature, layerId);
      }
    });
  }

  /**
   * Retrieves the features from a layer.
   * Needs both bundleId and layerId to get the correct layer if bundle has more than one layer.
   * @param bundleId - LayerId of the bundle
   * @param layerId - LayerId of layerObject in bundle (optional)
   */
  public getFeaturesFromLayer(bundleId: LayerId, layerId?: LayerId): Feature[] {
    const layer = this._store.getValue().entities[bundleId];
    if (!layer) return [];
    if (!layerId) layerId = bundleId;

    return (layer.layerObjects.find((l) => l.get('id') === layerId)?.getSource() as VectorSource)?.getFeatures() ?? [];
  }

  /**
   * Recalculates the Z-index of all layers based on their order.
   * Does not change the Z-index of permanent layers, which are always Z-index 0.
   */
  private _recalculateZIndexOfLayers(state: LayerState): LayerState {
    const bundles = Object.values(state.entities);

    bundles.forEach((bundle) => {
      const isPermanent = bundle.group === 'permanent';
      const firstLayer = bundle.layerObjects.first();

      if (firstLayer) {
        const shouldSetOrder = !isPermanent && firstLayer.getZIndex() !== 0;
        const newZIndex = shouldSetOrder ? bundle.order : 0;
        bundle.layerObjects.forEach((layer) => {
          if (layer.getZIndex() !== newZIndex) {
            layer.setZIndex(newZIndex);
          }
        });
      }
    });

    return state;
  }

  /**
   * Ensures there are no duplicate orders among layers.
   * Adjusts the order of layers to make them unique while maintaining relative order.
   */
  private _ensureUniqueLayerOrders(state: LayerState) {
    const layers = Object.values(state.entities);

    // Check for duplicate orders
    const orderCounts = layers.reduce(
      (acc, layer) => {
        acc[layer.order] = (acc[layer.order] || 0) + 1;
        return acc;
      },
      {} as { [key: number]: number }
    );

    const hasDuplicates = Object.values(orderCounts).some((count) => count > 1);

    if (!hasDuplicates) {
      // No duplicates found, return early
      return;
    }

    const sortedLayers = [...layers].sort((a, b) => a.order - b.order);
    let currentOrder = 0;

    sortedLayers.forEach((layer, index) => {
      if (index === 0) {
        currentOrder = layer.order;
      } else {
        currentOrder += layer.layerObjects.length;
        if (layer.order <= currentOrder) {
          layer.order = currentOrder;
        } else {
          currentOrder = layer.order;
        }
      }
    });

    sortedLayers.forEach((layer) => {
      state.entities[layer.id].order = layer.order;
    });
  }

  /** Creates common layers for all pages and adds them to the store. */
  private _createCommonLayers() {
    const osmAndAirPhotoLayers = this._createOsmAndAirPhotoLayers();
    const wfsLayers = this._createWFSLayers();
    const wmsLayers = this._createWMSLayers();
    const biomassLayer = this.createCommonBiomassLayer();
    const soilsampleLayer = this._createSoilsampleLayer();
    const layers = [...osmAndAirPhotoLayers, ...biomassLayer, ...soilsampleLayer, ...wmsLayers, ...wfsLayers];

    this._store.update(upsertEntities(layers));
  }

  private _createOsmAndAirPhotoLayers() {
    const layers: (LayerBundle | undefined)[] = [];

    layers.push(CreateLayerUtil.createPermLayer(LayerId.OSM, undefined));
    layers.push(CreateLayerUtil.createPermLayer(LayerId.AIRPHOTO_DK, this._airPhotoDKUrl));
    layers.push(CreateLayerUtil.createPermLayer(LayerId.AIRPHOTO_FOREIGN, undefined));

    return layers.filter((layer) => layer !== undefined) as LayerBundle[];
  }

  private _createWFSLayers() {
    const layers: (LayerBundle | undefined)[] = [];

    layers.push(CreateLayerUtil.createWFSLayer(LayerId.BES_NATURE));
    layers.push(CreateLayerUtil.createWFSLayer(LayerId.BES_WATERCOURSES));
    layers.push(CreateLayerUtil.createWFSLayer(LayerId.BNBO_STATUS));
    layers.push(CreateLayerUtil.createWFSLayer(LayerId.GLM_LAKES));
    layers.push(CreateLayerUtil.createWFSLayer(LayerId.GLM_MEMORIES));
    layers.push(CreateLayerUtil.createWFSLayer(LayerId.BUFFER_MARGINS));
    layers.push(CreateLayerUtil.createWFSLayer(LayerId.FIELD_SHRUBS));

    //! In progress - not yet implemented
    //layers.push(CreateLayerUtil.createWFSLayer(LayerId.BES_STONES));

    return layers.filter((layer) => layer !== undefined) as LayerBundle[];
  }

  private _createSoilsampleLayer() {
    const layers: (LayerBundle | undefined)[] = [];
    layers.push(CreateLayerUtil.createSoilsampleTileLayer(this._soilsampleUrl$));
    return layers.filter((layer) => layer !== undefined) as LayerBundle[];
  }

  private _createWMSLayers() {
    const layers: (LayerBundle | undefined)[] = [];
    layers.push(CreateLayerUtil.createWMSLayer(LayerId.SHADOW_MAP));
    layers.push(CreateLayerUtil.createWMSLayer(LayerId.HEIGHT_MAP));
    layers.push(CreateLayerUtil.createWMSLayer(LayerId.GEO_SOIL));

    layers.push(CreateLayerUtil.createTileLayer(LayerId.FIELD_BLOCKS));
    layers.push(CreateLayerUtil.createTileLayer(LayerId.HUMUS));
    layers.push(CreateLayerUtil.createTileLayer(LayerId.DEXTER));
    layers.push(CreateLayerUtil.createTileLayer(LayerId.CLAY));

    return layers.filter((layer) => layer !== undefined) as LayerBundle[];
  }

  private _createFieldMarkerLayer() {
    combineLatest([this._farmStateService.fieldFeatures$, this._cropFilter.getDisabledCrops()])
      .pipe(
        filter(([fieldFeatures, _]) => !!fieldFeatures),
        applyCropFilter()
      )
      .subscribe((features) => {
        const fieldFeatures = features.fieldFeatures;
        this.createFieldLayerItemLayer(LayerId.FIELD_MARKERS, fieldFeatures);
      });
  }

  /**
   * Inherit the order and opacity of the existing layer.
   * @param newLayers - The new layers to inherit properties to.
   */
  private _inheritLayerProperties(newLayers: LayerBundle | LayerBundle[]) {
    newLayers = Array.isArray(newLayers) ? newLayers : [newLayers];
    const existingLayer = this._store.getValue().entities[newLayers[0].id];

    if (!existingLayer) return;

    newLayers[0].order = existingLayer.order;
    newLayers[0].opacity = existingLayer.opacity;
    newLayers[0].layerObjects.forEach((layer) => {
      layer.setZIndex(existingLayer.order);
      layer.setOpacity(existingLayer.opacity * 0.01);
    });
  }
}
