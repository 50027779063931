<app-side-drawer-wrapper>
  <app-side-drawer-header
    cancelIconName="back"
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
    [showMinimizeButton]="true"
    title="{{ 'main.fieldmap.layers.growthRegulation' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <app-spinner *ngIf="loadingState.isLoading$ | async" [hasBackdrop]="false" [message]="(loadingState.message$ | async)!"></app-spinner>
    <ng-container *ngIf="!(loadingState.isLoading$ | async)">
      <app-field-info
        [field]="field"
        [growthRegulation]="growthRegulation"
        [isGrowthRegulationsEmpty]="isGrowthegulationsEmpty"
      ></app-field-info>
      <app-insurance-button (insuranceClick)="onInsuranceClick()"></app-insurance-button>
    </ng-container>
  </app-side-drawer-body>
</app-side-drawer-wrapper>

<app-fields [growthRegulations]="(growthRegulationVm$ | async)!"></app-fields>
