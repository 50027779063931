import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { FieldChangeCropResponse } from '@app/core/interfaces/FieldChangeCropResponse';
import { Field, FieldCollection, FieldDetails } from '@app/core/interfaces/field.interface';
import { CreateFieldDTO } from '@app/map/features/field-plan/field-plan-side-drawer/interfaces/create-field-dto.interface';
import { UpdateFieldDTO } from '@app/map/features/field-plan/field-plan-side-drawer/interfaces/update-field-dto.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SoilTypeDTO } from './soil-types.dto';

export interface FieldsMessages {
  getError: string;
}

export interface IFieldsRepo {
  getField(farmId: number, harvestYear: number, fieldId: number): Observable<Field>;
  getAllFields(farmIds: number[], year: number): Observable<Field[] | null>;
  getFieldsWithMetadata(farmIds: number[], year: number): Observable<FieldCollection>;
  getValidFields(farmId: number, year: number): Observable<Field[]>;
  getFieldDetails(farmId: number, year: number, fieldId: number): Observable<FieldDetails>;
  getFieldsWithSimilarTask(farmIds: number[], harvestYear: number, taskId: number): Observable<Field[]>;
  getFieldsWithSimilarCrop(farmIds: number[], harvestYear: number, fieldId: number, cropNormNumber?: string): Observable<Field[]>;
  getFieldBlockPolygon(harvestYear: number, latitude: number, longitude: number, farmIds: number[]): Observable<string | null>;
  getFieldPreCrops(farmId: number, year: number, fieldMultiYearId: number, amountOfYears: number): Observable<Field[]>;
  getSoilTypes(): Observable<SoilTypeDTO[]>;
  getDeterminedSoilSample(polygon: string): Observable<number | null>;
  validateFieldNumber(farmId: number, harvestYear: number, fieldNumber: string, fieldId: number | null): Observable<boolean>;
  createField(field: CreateFieldDTO): Observable<Field | null>;
  updateField(field: UpdateFieldDTO, deleteTasks?: boolean): Observable<Field | null>;
  subtractPolygon(
    farmId: number,
    fieldPolygon: string | null,
    polygonToSubtract: string | null,
    fieldId: number | null,
    harvestYear?: number
  ): Observable<string | null>;
  validatePolygon(farmId: number, polygon: string, fieldId: number | null, harvestYear?: number): Observable<boolean | null>;
  getPolygonShapefile(farmId: number, polygonIds: number[], harvestYear: number): Observable<HttpResponse<Blob>>;
  getFieldPolygonArea(wktString: string): Observable<number | null>;
  delete(farmId: number, fieldId: number): Observable<number>;
  getFieldsNotPresentNextYear(farmIds: number[], year: number): Observable<Field[] | null>;
  changeCropTypeForFields(
    farmIds: number[],
    harvestYear: number,
    updateFieldDtos: UpdateFieldDTO[]
  ): Observable<FieldChangeCropResponse | null>;
  copyFieldsToNextHarvestYear(fieldIds: number[], farmIds: number[], harvestYear: number): Observable<Field[] | null>;
}

@Injectable({
  providedIn: 'root',
})
export class FieldsRepo implements IFieldsRepo {
  private soilTypesCache = this.cacheService.create<SoilTypeDTO[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    public httpClient: HttpClient,
    private endpoints: EndpointsService,
    private cacheService: CacheService
  ) {}

  public getField(farmId: number, harvestYear: number, fieldId: number): Observable<Field> {
    return this.httpClient.get<Field>(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldId}`);
  }

  public getAllFields(farmIds: number[], year?: number): Observable<Field[] | null> {
    const data = {
      Val: farmIds,
    };
    return this.httpClient.post<Field[], { Val: number[] }>(`${this.endpoints.foApi}/farms/${year}/fields`, data);
  }

  public getFieldsWithMetadata(farmIds: number[], year?: number): Observable<FieldCollection> {
    return this.httpClient.get<FieldCollection>(`${this.endpoints.bffApi}/farms/${farmIds}/${year}/fields/withmetadata`);
  }

  public getValidFields(farmId: number | number[], year: number): Observable<Field[]> {
    return this.httpClient.get<Field[]>(`${this.endpoints.foApi}/farms/${farmId}/${year}/fields/naerCropShortList`);
  }

  public getFieldDetails(farmId: number, year: number, fieldId: number): Observable<FieldDetails> {
    return this.httpClient.get<FieldDetails>(`${this.endpoints.foApi}/farms/${farmId}/${year}/field/${fieldId}/details`);
  }

  public getFieldsWithSimilarTask(farmIds: number[], harvestYear: number, taskId: number): Observable<Field[]> {
    return this.httpClient.get<Field[]>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/fieldswithsimilarsimpletasks/${taskId}`);
  }

  public getFieldsWithSimilarCrop(farmIds: number[], harvestYear: number, fieldId: number, cropNormNumber?: string): Observable<Field[]> {
    return this.httpClient.get<Field[]>(
      `${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/fields/withsimilarcrop/${fieldId}`,
      {
        params: {
          cropNormNumber,
        },
      }
    );
  }

  public getFieldBlockPolygon(harvestYear: number, latitude: number, longitude: number, farmIds: number[]): Observable<string | null> {
    return this.httpClient.post<string, { val: number[] }>(
      `${this.endpoints.foApi}/farms/fields/fieldblockpolygon`,
      {
        val: farmIds,
      },
      {
        params: {
          harvestYear,
          latitude,
          longitude,
        },
      }
    );
  }

  public getFieldPreCrops(farmId: number, year: number, fieldMultiYearId: number, amountOfYears: number): Observable<Field[]> {
    return this.httpClient.get<Field[]>(
      `${this.endpoints.foApi}/farms/${farmId}/${year}/fields/${fieldMultiYearId}/precrops?amountOfYears=${amountOfYears}`
    );
  }

  public getSoilTypes(): Observable<SoilTypeDTO[]> {
    const key = 'soiltypes';

    const getValue = () => {
      return this.httpClient.get<SoilTypeDTO[]>(`${this.endpoints.foApi}/farms/fields/jb`);
    };

    return this.soilTypesCache.getOrSetAsync(key, () => getValue());
  }

  public getDeterminedSoilSample(polygon: string): Observable<number | null> {
    // Force content-type to json
    const headersWithContentType = new HttpHeaders().append('Content-Type', 'application/json');
    const stringifiedPolygon = JSON.stringify(polygon);

    return this.httpClient.post<number, string>(`${this.endpoints.foApi}/farms/fields/jbmatch`, stringifiedPolygon, {
      headers: headersWithContentType,
    });
  }

  public validateFieldNumber(farmId: number, harvestYear: number, fieldNumber: string, fieldId: number | null): Observable<boolean> {
    const options = {
      params: this.getParams(
        {
          farmId,
          harvestYear,
          fieldNumber,
        },
        fieldId
      ),
    };

    if (options.params.fieldId === undefined) {
      delete options.params.fieldId;
    }

    return this.httpClient.get<boolean>(`${this.endpoints.foApi}/farms/fields/validatefieldnumber`, options);
  }

  public createField(field: CreateFieldDTO): Observable<Field | null> {
    return this.httpClient.post<Field, CreateFieldDTO>(`${this.endpoints.foApi}/farms/fields/withprecrop`, field);
  }

  public updateField(field: UpdateFieldDTO, deleteTasks?: boolean): Observable<Field | null> {
    return this.httpClient.put<Field, UpdateFieldDTO>(`${this.endpoints.foApi}/farms/fields/withcrop`, field, {
      params: {
        fieldId: field.id,
        deleteTasks,
      },
    });
  }

  public subtractPolygon(
    farmId: number,
    fieldPolygon: string | null,
    polygonToSubtract: string | null,
    fieldId: number | null,
    harvestYear?: number
  ): Observable<string | null> {
    if (!fieldPolygon || !polygonToSubtract) return of(null);

    return this.httpClient.post<string, { fieldPolygon: string; polygonToSubtract: string }>(
      `${this.endpoints.foApi}/farms/fields/polygonvalidatesubtract`,
      {
        fieldPolygon,
        polygonToSubtract,
      },
      {
        params: this.getParams({ farmId, harvestYear }, fieldId),
      }
    );
  }

  public validatePolygon(farmId: number, polygon: string, fieldId: number | null, harvestYear?: number): Observable<boolean | null> {
    // Force content-type to json
    const headersWithContentType = new HttpHeaders().append('Content-Type', 'application/json');

    const options = {
      headers: headersWithContentType,
      params: this.getParams({ farmId, harvestYear }, fieldId),
    };
    const stringifiedPolygon = JSON.stringify(polygon);
    return this.httpClient.post<boolean, string>(`${this.endpoints.foApi}/farms/fields/polygonvalidate`, stringifiedPolygon, options);
  }

  public getPolygonShapefile(farmId: number, polygonIds: number[], harvestYear: number): Observable<HttpResponse<Blob>> {
    const url = `${this.endpoints.foApi}/farm/${farmId}/${harvestYear}/fields/shape/download`;

    return this.httpClient.postWithResponse<Blob, number[]>(url, polygonIds, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  public getFieldPolygonArea(wktString: string): Observable<number | null> {
    return this.httpClient.post<number, { polygon: string }>(`${this.endpoints.foApi}/farms/fields/polygonarea`, {
      polygon: wktString,
    });
  }

  public delete(farmId: number, fieldId: number): Observable<number> {
    const options: any = { withCredentials: true };
    return this.httpClient.delete(`${this.endpoints.foApi}/farms/${farmId}/${fieldId}`, options).pipe(map(() => 200));
  }

  public getFieldsNotPresentNextYear(farmIds: number[], year: number): Observable<Field[] | null> {
    return this.httpClient.post<Field[], number[]>(
      `${this.endpoints.foApi}/farms/fields/GetFieldsWithCropNotInNextHarvestYear?harvestYear=${year}`,
      farmIds
    );
  }
  public changeCropTypeForFields(
    farmIds: number[],
    harvestYear: number,
    updateFieldDtos: UpdateFieldDTO[]
  ): Observable<FieldChangeCropResponse | null> {
    return this.httpClient.post<FieldChangeCropResponse, UpdateFieldDTO[]>(
      `${this.endpoints.foApi}/farms/fields/ChangeCropTypeForFields?harvestYear=${harvestYear}`,
      updateFieldDtos
    );
  }
  public copyFieldsToNextHarvestYear(fieldIds: number[], farmIds: number[], harvestYear: number): Observable<Field[] | null> {
    return this.httpClient.post<Field[], { farmIds: number[]; fieldIds: number[] }>(
      `${this.endpoints.foApi}/farms/fields/CreateFieldsInNextYear?harvestYear=${harvestYear}`,
      {
        farmIds: farmIds,
        fieldIds: fieldIds,
      }
    );
  }

  private getParams(params: any, fieldId: number | null): any {
    return fieldId ? { ...params, fieldId } : params;
  }
}
