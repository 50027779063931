import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldService } from '@app/core/field/field.service';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field, FieldDetails } from '@app/core/interfaces/field.interface';
import {
  FeatureCoordinates,
  LineStringCoordinates,
  MultiPolygonCoordinates,
  PointCoordinates,
  PolygonCoordinates,
} from '@app/core/interfaces/geometry-coordinates.types';
import { MapConstants } from '@app/core/map/map.constants';
import { IMapService } from '@app/core/map/map.service.interface';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { getCoordinatesFromWktString, getGeometryTypeFromWktString } from '@app/helpers/map/map-helper';
import { MapPadding } from '@app/map/config/map-padding';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { MapStateService } from '@app/state/services/map/map-state.service';
import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { Map, MapBrowserEvent } from 'ol';
import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import WKT from 'ol/format/WKT';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { toLonLat, transform } from 'ol/proj';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { FieldLayerItem } from '../feature/field-layer-item.interface';

@Injectable({
  providedIn: 'root',
})
export class MapService implements IMapService {
  private fieldFeatures$: Observable<FieldFeatures | null> = this.farmStateService.fieldFeatures$;

  public vraMapToggle$ = new BehaviorSubject<boolean>(true);
  public mapReadySubject: Subject<void> = new ReplaySubject(1);
  public mapReady$ = this.mapReadySubject.asObservable();
  private fieldFeaturesChangeSubject: Subject<boolean> = new ReplaySubject(1);
  public fieldFeaturesChanged$ = this.fieldFeaturesChangeSubject.asObservable();
  public airPhotoIsVisible$ = new BehaviorSubject<boolean>(false);

  public zoomEditThreshold: Readonly<number> = 0;

  private _map!: OpenLayersMapComponent;

  constructor(
    private fieldService: FieldService,
    private endpoints: EndpointsService,
    private screenSizeService: ScreenSizeService,
    private translateService: TranslateService,
    private farmStateService: FarmStateService,
    private mapStateService: MapStateService
  ) {}

  public getTileLayerUrl(type: MapLayerId): string {
    return this.buildLayerUrl(type.toString());
  }

  public onFieldFeaturesChange(
    featureModels: FieldLayerItem[],
    featuresExtent: [number, number, number, number],
    openlayersMapComponent: OpenLayersMapComponent
  ) {
    this.createFieldFeaturesForMapFieldLayer(featureModels, openlayersMapComponent);
  }

  public getMap(): OpenLayersMapComponent {
    return this._map;
  }

  public getMapComponent(): Map {
    return this._map.getMap();
  }

  public setMap(map: OpenLayersMapComponent) {
    this._map = map;
  }

  public showLayer(id: MapLayerId) {
    this._map.showLayer(id);
  }

  public hideLayer(id: MapLayerId) {
    this._map.hideLayer(id);
  }

  public disableFeatureSelection() {
    this._map.disableSelectInteraction();
  }

  public enableFeatureSelection() {
    this._map.enableSelectInteraction();
  }

  public deselectFeatures() {
    this._map.deselectFeatures();
  }

  public vraToggleMap(): void {
    this.vraMapToggle$.next(!this.vraMapToggle$.value);
  }

  /**
   Fits the map to the currently displayed field features
   */
  public fitMapToFieldFeatures() {
    combineLatest([this.fieldFeatures$, this.screenSizeService.isMobile()])
      .pipe(take(1))
      .subscribe(([fieldFeatures, mobile]) => {
        // If the screen size isnt mobile, the side padding makes up for the side drawer.
        const padding = mobile ? MapPadding.MOBILE_MAP_PADDING : MapPadding.OLD_NON_MOBILE_MAP_PADDING;
        this.getMap().slideMapToExtent(fieldFeatures!.extent!, padding);
      });
  }

  /**
   Fits the map to the given extend displayed field features
   boolean newSideDrawer is used to determine if the new side drawer is used
   */
  public fitMapToExtentWithPadding(extent: Coordinate, newSideDrawer: boolean = false) {
    if (!this.isValidExtend(extent)) return;
    this.screenSizeService
      .isMobile()
      .pipe(take(1))
      .subscribe((mobile) => {
        // If the screen size isnt mobile, the side padding makes up for the side drawer.
        const padding = mobile
          ? MapPadding.MOBILE_MAP_PADDING
          : newSideDrawer
            ? MapPadding.NEW_NON_MOBILE_MAP_PADDING
            : MapPadding.OLD_NON_MOBILE_MAP_PADDING;
        this.getMap().slideMapToExtent(extent, padding);
      });
  }

  /**
   * Returns the center of a feature as a point geometry
   * @param feature The feature to get the center of
   */
  public getCenterOfFeature(feature: Feature): PointCoordinates {
    return feature.get('centerCoords') ? feature.get('centerCoords') : getCenter(feature.getGeometry()!.getExtent());
  }

  /**
   * Get details for the field
   */
  public getFieldDetails(field: Field): Observable<FieldDetails> {
    return this.fieldService.getFieldDetails(field.farmId, field.harvestYear, field.id);
  }

  public addHoverInteraction() {
    return this.getMap().addPointerMoveInteraction();
  }

  public getEventPixel(event: UIEvent) {
    return this.getMap().getMap().getEventPixel(event);
  }

  public showPointerOnMapFeature(ev: MapBrowserEvent<PointerEvent>, mapFeature: MapLayerId[]) {
    if (!ev || !ev.map) {
      return;
    }

    const pointerOverFieldFeature = ev.map.forEachFeatureAtPixel(ev.pixel, (feature: any, layer: any) => {
      if (!layer) {
        return;
      }

      const currentMapLayerId = layer.get('id');
      if (mapFeature?.includes(currentMapLayerId)) {
        if (feature instanceof Feature) {
          return feature;
        }
      }

      return;
    });

    let cursorStyle = '';

    if (pointerOverFieldFeature) {
      cursorStyle = 'pointer';
    }

    const vpElement = ev.map.getViewport();
    const vpHtmlElement = vpElement as HTMLElement;
    vpHtmlElement.style.cursor = cursorStyle;
  }

  /**
   * Returns array of legend items for map legends containg a crop color and name each.
   * @param fields fields for which to generate legend
   */
  public getLegendCrops(fields: Field[]): CropColor[] {
    const cropColors: CropColor[] = [];

    // Find and use only main crops
    fields.forEach((field: Field | null) => {
      field?.crops.forEach((crop: Crop) => {
        if (crop.successionNo === 1) {
          if (!cropColors.find((cropColor: CropColor) => cropColor.color === crop.cropColor && cropColor.name === crop.cropName)) {
            cropColors.push({
              name: crop.cropName,
              color: crop.cropColor,
            });
          }
        }
      });
    });

    // Sort by name and return
    const sortedCropColors = cropColors.sort((cropNameA, cropNameB): number => {
      if (cropNameA.name > cropNameB.name) {
        return 1;
      }
      if (cropNameA.name < cropNameB.name) {
        return -1;
      }
      return 0;
    });

    if (fields.some((field) => field?.crops.length === 0)) {
      sortedCropColors.push({
        name: this.translateService.instant('main.fieldmap.crops.noCrop'),
        color: '#FFFFFF',
      });
    }
    return sortedCropColors;
  }

  /**
   * @param featureType type of feature
   * @param geometryType type of geometry
   * @param coordinates
   */
  public getMapFeature(featureType: MapLayerId, geometryType: GeometryType, coordinates?: FeatureCoordinates): Feature {
    const feature = new Feature();
    switch (geometryType) {
      case GeometryType.POINT:
        const point = new Point(coordinates as PointCoordinates);
        feature.setGeometry(this.transformGeometryToMap(point));
        feature.set('centerCoords', getCenter(point.getExtent()));
        break;
      case GeometryType.LINE:
        const lineString = new LineString(coordinates as LineStringCoordinates);
        feature.setGeometry(this.transformGeometryToMap(lineString));
        feature.set('centerCoords', lineString.getCoordinateAt(0.5));
        break;
      case GeometryType.MULTIPOLYGON:
        const multiPolygon = new MultiPolygon(coordinates as MultiPolygonCoordinates);
        feature.setGeometry(this.transformGeometryToMap(multiPolygon));
        feature.set('centerCoords', multiPolygon.getInteriorPoints().getCoordinates());
        break;

      default:
        const polygon = new Polygon(coordinates as PolygonCoordinates);
        feature.setGeometry(this.transformGeometryToMap(polygon));
        feature.set('centerCoords', polygon.getInteriorPoint().getCoordinates());
        break;
    }

    feature.set('layerId', featureType);
    return feature;
  }

  public getGeometryTypeFromFeature(feature: Feature): GeometryType | undefined {
    const olGeometryType = feature.getGeometry()!.getType();
    return this.getGeometryType(olGeometryType);
  }

  public getCoordinatesFromFeature(feature: Feature): number[] | number[][] {
    const geometry = feature.getGeometry();

    let coordinates: number[] | number[][] = [];

    if (geometry instanceof Point) {
      coordinates = this.getCoordinatesFromPoint(geometry);
    } else if (geometry instanceof LineString) {
      coordinates = this.getCoordinatesFromLineString(geometry);
    } else if (geometry instanceof Polygon) {
      coordinates = this.getCoordinatesFromPolygon(geometry);
    }

    return coordinates;
  }

  private getCoordinatesFromPoint(geom: Point): number[] {
    let coordinates = geom.getCoordinates();

    coordinates = this.transformCoordinateFromMap(coordinates);

    return coordinates;
  }

  private getCoordinatesFromLineString(geom: LineString): number[][] {
    let coordinates = geom.getCoordinates();

    coordinates = coordinates.map((coordinate) => this.transformCoordinateFromMap(coordinate));

    return coordinates;
  }

  private getCoordinatesFromPolygon(geom: Polygon): number[][] {
    let coordinates = geom.getCoordinates()[0];

    coordinates = coordinates.map((coordinate) => this.transformCoordinateFromMap(coordinate));

    return coordinates;
  }

  public getFeatureFromWktString(geometryString: string): Feature {
    const wkt = new WKT();

    return wkt.readFeature(geometryString, {
      dataProjection: MapConstants.dataProjection,
      featureProjection: MapConstants.mapProjection,
    });
  }

  public getLongitudeAndLatitudeFromCoordinate(coordinate: [number, number]) {
    return toLonLat(coordinate);
  }

  public getPolygonCoordinatesFromWKTString(wktString: string): PolygonCoordinates | null {
    const coordinates = getCoordinatesFromWktString(wktString);
    return coordinates ? (coordinates as PolygonCoordinates) : null;
  }

  public getMultiPolygonCoordinatesFromWKTString(wktString: string): MultiPolygonCoordinates | null {
    const coordinates = getCoordinatesFromWktString(wktString);
    return coordinates ? (coordinates as MultiPolygonCoordinates) : null;
  }

  /**
   * Function return wkt representation of the provided feature geometry
   * @param feature Feature to get wkt representation from
   */
  public getWktFromFeature(feature: Feature | null): string {
    const wkt = new WKT();
    return wkt.writeFeature(feature!, {
      dataProjection: MapConstants.dataProjection,
      featureProjection: MapConstants.mapProjection,
    });
  }

  public getFeatureFromWkt(wktString: string) {
    const wkt = new WKT();
    return wkt.readFeature(wktString, {
      dataProjection: MapConstants.dataProjection,
      featureProjection: MapConstants.mapProjection,
    });
  }

  public createFieldFeaturesForMapFieldLayer(featureModels: FieldLayerItem[], map: OpenLayersMapComponent) {
    this.createFieldsLayer(featureModels, map);
    this.createHighResFieldMarkers(featureModels, map);
  }

  public createPolygonFeature(layerId: MapLayerId, coordinates: PolygonCoordinates): Feature {
    const feature = new Feature();
    feature.setGeometry(this.transformGeometryToMap(new Polygon(coordinates)));
    feature.set('layerId', layerId);
    return feature;
  }

  public createMultiPolygonFeature(layerId: MapLayerId, coordinates: MultiPolygonCoordinates): Feature {
    const feature = new Feature();
    feature.setGeometry(this.transformGeometryToMap(new MultiPolygon(coordinates)));
    feature.set('layerId', layerId);
    return feature;
  }

  public getGeometryTypeFromWktString(geometryString: string) {
    return getGeometryTypeFromWktString(geometryString);
  }

  public getCoordinatesFromWktString(geometryString: string) {
    return getCoordinatesFromWktString(geometryString);
  }

  public getFeaturesFromLayer(layerId: MapLayerId, map: OpenLayersMapComponent) {
    return map.getFeaturesFromLayer(layerId);
  }

  /**
   * Adds field markers layer which is displayed at map resolution 200 (zoom level < ~10) or above.
   * This is to prevent an exception in Edge where the polygons become too small to draw.
   * The field markers layer instead adds a Point for each field.
   * At map resolution lower than 200 the actual field polygons are displayed.
   * The need to call this method should be deprecated in OpenLayers v5.3.0
   * @param featureModels
   * @param map OpenLayersMapComponent
   */
  private createHighResFieldMarkers(featureModels: FieldLayerItem[], map: OpenLayersMapComponent) {
    const fieldMarkers: Feature[] = featureModels.map((featureModel) => {
      const coordinates = featureModel.coordinates as LineStringCoordinates;

      const feature = this.getMapFeature(
        MapLayerId.HIGH_RES_FIELD_MARKERS,
        GeometryType.POINT,
        coordinates[0][0] as unknown as FeatureCoordinates
      );
      const cropModels = featureModel.field?.crops?.flatMap((crop) => crop.models);
      feature.set('crop-models', cropModels);
      feature.set('fill', featureModel.fill);
      feature.set('stroke', featureModel.stroke);

      return feature;
    });

    map.addOrUpdateLayerToMap(
      {
        clustered: true,
        isVisible: true,
        layerId: MapLayerId.HIGH_RES_FIELD_MARKERS,
        layerType: MapLayerType.POINTS,
        zIndex: 100,
      },
      fieldMarkers
    );
  }

  /**
   * Adds field polygons (ol Polygon) to the map.
   * @param featureModels
   * @param map OpenLayersMapComponent
   */
  private createFieldsLayer(featureModels: FieldLayerItem[], map: OpenLayersMapComponent) {
    const fieldFeatures: Feature[] = featureModels.map((featureModel) => {
      const feature = this.getMapFeature(MapLayerId.FIELDS, GeometryType.POLYGON, featureModel.coordinates);
      return this.getFieldFeature(feature, featureModel);
    });

    const exsistingFieldLayer = map.getLayerFromMap(MapLayerId.FIELDS);
    const visible = exsistingFieldLayer?.getVisible() ?? true;
    map.addOrUpdateLayerToMap(
      {
        clustered: true,
        isVisible: visible,
        layerId: MapLayerId.FIELDS,
        layerType: MapLayerType.VECTOR,
        zIndex: 3,
      },
      fieldFeatures
    );
  }

  private getFieldFeature(featureArg: Feature, featureModel: FieldLayerItem) {
    const feature = cloneDeep(featureArg);
    const fieldColorTransparent = 'rgba(0,0,0,0)';
    feature.set('fill', fieldColorTransparent);
    feature.set('stroke', '#ffffff');
    feature.set('text', featureModel.text);
    feature.set('field', featureModel.field);
    feature.setId(featureModel.fieldId + featureModel.fieldBlockSubDivision);
    return feature;
  }

  /**
   * Function transforms coordinate from map projection to projection used in data from server
   * @param coordinate Coordinate to be transformed
   */
  private transformCoordinateFromMap(coordinate: Coordinate): Coordinate {
    return transform(coordinate, MapConstants.mapProjection, MapConstants.dataProjection) as Coordinate;
  }

  /**
   * Transforms geometry with correct projection
   * @param geometry geametry to transform
   */
  private transformGeometryToMap(geometry: Geometry): Geometry {
    return geometry.transform(MapConstants.dataProjection, MapConstants.mapProjection);
  }

  private getGeometryType(geometry: string): GeometryType | undefined {
    // get expected geometrytype from geometry
    switch (geometry) {
      case 'Polygon':
        return GeometryType.POLYGON;
      case 'LineString':
        return GeometryType.LINE;
      case 'Point':
        return GeometryType.POINT;
      default:
        return;
    }
  }

  /**
   * Returns data source url for open layers tile layer based on layer type
   * @param type type of desired layer
   * @param projectId
   */
  private buildLayerUrl(type: string): string {
    switch (type) {
      case 'rededgendvi':
        return `${this.endpoints.foApi}/maps/overlays/rededgendvi/{z}/{x}/{y}`;
      case 'clearsky':
        return `${this.endpoints.foApi}/maps/clearsky/gettiles/{z}/{x}/{y}/fields`;
      default:
        return `${this.endpoints.foApi}/maps/overlays/${type}/{z}/{x}/{y}`;
    }
  }

  private isValidExtend(extent: Coordinate) {
    if (!extent || extent.length < 2) {
      // extent is null, undefined, or does not have 2 or more elements
      return false;
    }

    // check if each element in extent is a finite number
    for (const coordinate of extent) {
      if (!Number.isFinite(coordinate)) {
        // one of the elements is not a finite number
        return false;
      }
    }

    // all checks passed; extent is valid
    return true;
  }

  public clickedCoordsChanged(coords: Coordinate) {
    return (this.mapStateService.clickedCoords = coords);
  }

  //? Only relevant for PlayWright e2e tests. Data-testid='canvas' in map.component.html template is delayed
  // to ensure the canvas element is clickable after the component is rendered.
  // we may be able to get rid of this method, if we refactor hotspots
  public setCanvasReady(isReady: boolean) {
    setTimeout(() => {
      this.fieldFeaturesChangeSubject.next(isReady);
    }, 1000);
  }
}
