import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { LanguageService } from '@app/core/language/language.service';
import { HotspotRepo } from '@app/map/features/hotspots/hotspot-side-drawer/shared/hotspot-repo/hotspot-repo.service';
import { HotspotsService } from '@app/map/features/hotspots/hotspots.service';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { filterNullOrEmpty } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, first, map, shareReplay, switchMap } from 'rxjs';
import { HotSpotMenuDataItem, HotSpotVisibilityState, HotspotFeature } from './map-layer-control-hotspots.types';
@Injectable({
  providedIn: 'root',
})
export class MapControlHotspotsService {
  /**
   * A `BehaviorSubject` used to track additions or removals of hotspots, ensuring the form remains updated.
   * To use, call `.next()` with update object.
   */
  public hotspotFeaturesChangeSubject = new BehaviorSubject<{
    typeId: number;
    subTypeIds: number[] | undefined;
    action: 'add' | 'remove';
  } | null>(null);

  /** A `BehaviorSubject` used to track whether all hotspots should be selected. */
  public selectAllHotspotsSubject = new BehaviorSubject<boolean>(false);

  /**
   * A `BehaviorSubject` used to track the selection of a specific hotspot.
   * To use, call `.next()` with the hotspot ID and hotspot sub-type ID or undefined.
   */
  public selectSpecificHotspotSubject = new BehaviorSubject<{ hotspotId: number; hotspotSubTypeIds: number[] | undefined } | null>(null);

  public hotspotsAndTypesAndSubtypes$ = this.farmService.selectedFarmIds$.pipe(
    filterNullOrEmpty(),
    switchMap(() => this.hotspotsService.getHotspotsTypesAndSubTypes$.pipe(filterNullOrEmpty())),
    shareReplay(1)
  );

  public hotspotsDtos$ = this.farmService.selectedFarmIds$.pipe(
    filterNullOrEmpty(),
    switchMap((farmIds) => this.hotspotsRepo.get(farmIds)),
    shareReplay(1)
  );

  // The actual hotspot features tracked in hotspot service.
  public hotspotFeatures$ = this.hotspotsService.hotspotFeaturesSubject.asObservable();

  // Hotspot data from repo.
  public hotspots$ = this.hotspotsAndTypesAndSubtypes$.pipe(map(([hotspots]) => hotspots));
  public hotspotTypes$ = this.hotspotsAndTypesAndSubtypes$.pipe(map(([, types]) => types));
  public hotspotSubtypes$ = this.hotspotsAndTypesAndSubtypes$.pipe(map(([, , subtypes]) => subtypes));

  constructor(
    private farmService: FarmStateService,
    private hotspotsService: HotspotsService,
    private hotspotsRepo: HotspotRepo,
    private layerService: OlLayerService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private authService: BffAuthService,
    private fb: FormBuilder
  ) {
    this.authService.isCurrentUserFromNaesgaard$.pipe(first()).subscribe((isNaesgaard) => {
      if (isNaesgaard) return;

      this.hotspotsDtos$.pipe(filterNullOrEmpty(), takeUntilDestroyed()).subscribe((hotspots) => {
        const hotspotFeatures = this.hotspotsService.createHotspotFeatures(hotspots);
        const hotspotMarkerFeatures = this.hotspotsService.createHotspotMarkerFeatures(hotspotFeatures);

        this.layerService.createHotspotLayers(hotspotFeatures, hotspotMarkerFeatures);
      });
    });
  }

  public getUnspecifiedId(hotspotTypeId: number): number {
    return hotspotTypeId * -100;
  }

  public createGroups(data: HotSpotMenuDataItem[]): FormGroup {
    return this.fb.group({
      anySelected: [false],
      hoveredItem: [null],
      groups: this.fb.array(
        data.map((item) => {
          return this.fb.group({
            id: [item.id],
            type: this.fb.group({
              id: [item.type.id],
              name: [this.getNameBasedOnLanguage(item.type)],
              icon: [this.getIconNameById(item.type.id)],
              selected: [false],
              partiallySelected: [false],
            }),
            subTypes: this.fb.array(
              item.subTypes.map((subType) => {
                return this.fb.group({
                  id: [subType.id],
                  name: [this.getNameBasedOnLanguage(subType)],
                  hotspotTypeId: [subType.hotspotTypeId],
                  selected: [false],
                });
              })
            ),
          });
        })
      ),
    });
  }

  public updateHotspotVisibility(state: HotSpotVisibilityState) {
    const anySelected = state.visibleHotspots.typeIds.length > 0 || state.visibleHotspots.subTypeIds.length > 0;
    if (anySelected) {
      this.layerService.setLayerVisibility(LayerId.HOTSPOTS, true);
    } else {
      this.layerService.setLayerVisibility(LayerId.HOTSPOTS, false);
    }

    const hotspotFeatures = this.layerService.getFeaturesFromLayer(LayerId.HOTSPOTS) as HotspotFeature[];
    const hotspotMarkerFeatures = this.layerService.getFeaturesFromLayer(LayerId.HOTSPOTS, LayerId.HOTSPOT_MARKERS) as HotspotFeature[];

    // Handle visible features
    const visibleHotspotFeatures = hotspotFeatures.filter((feature) => {
      const typeId = feature.get('hotspotTypeId');
      const subTypeIds = feature.get('hotspotSubTypeIds') || [];
      const isTypePartiallySelected = state.partiallySelectedTypes.typeIds.includes(typeId);
      const unspecifiedSubTypeIds = [this.getUnspecifiedId(typeId)];

      return (
        state.visibleHotspots.typeIds.includes(typeId) ||
        (subTypeIds.length === 0 &&
          state.visibleHotspots.subTypeIds.some((id) => unspecifiedSubTypeIds.includes(id)) &&
          isTypePartiallySelected) ||
        (subTypeIds.some((id) => state.visibleHotspots.subTypeIds.includes(id)) && isTypePartiallySelected)
      );
    });

    // Handle hidden features
    const hiddenHotspotFeatures = hotspotFeatures.filter((feature) => {
      const typeId = feature.get('hotspotTypeId');

      const isTypeHidden = state.hiddenHotspots.typeIds.includes(typeId);

      return isTypeHidden;
    });

    // Handle visible marker features
    const visibleMarkerFeatures = hotspotMarkerFeatures.filter((marker) => {
      const typeId = marker.get('hotspotTypeId');
      const subTypeIds = marker.get('hotspotSubTypeIds') ?? [];
      const isTypePartiallySelected = state.partiallySelectedTypes.typeIds.includes(typeId);
      const unspecifiedSubTypeIds = [this.getUnspecifiedId(typeId)];

      return (
        state.visibleHotspots.typeIds.includes(typeId) ||
        (subTypeIds.length === 0 &&
          state.visibleHotspots.subTypeIds.some((id) => unspecifiedSubTypeIds.includes(id)) &&
          isTypePartiallySelected) ||
        (subTypeIds.some((id) => state.visibleHotspots.subTypeIds.includes(id)) && isTypePartiallySelected)
      );
    });

    // Handle hidden marker features
    const hiddenMarkerFeatures = hotspotMarkerFeatures.filter((marker) => {
      const typeId = marker.get('hotspotTypeId');

      const isTypeHidden = state.hiddenHotspots.typeIds.includes(typeId);

      return isTypeHidden;
    });

    const visibleFeatures = [...visibleHotspotFeatures, ...visibleMarkerFeatures];
    const hiddenFeatures = [...hiddenHotspotFeatures, ...hiddenMarkerFeatures];

    hiddenFeatures.forEach((feature) => {
      feature.set('visible', false);
    });

    visibleFeatures.forEach((feature) => {
      feature.set('visible', true);
    });
  }

  /** Creates the menu data for the hotspots. It filters out the types and subtypes that are not set up for the farm. */
  public mapToMenuData(types: HotspotType[], subtypes: HotspotSubType[], hotspots: HotspotDto[]): HotSpotMenuDataItem[] {
    const usedTypeSubtypePairs = new Set(
      hotspots.flatMap((hotspot) => hotspot.hotspotSubTypeIds?.map((subtypeId) => `${hotspot.hotspotTypeId}:${subtypeId}`))
    );

    const usedTypeIds = new Set(hotspots.map((h) => h.hotspotTypeId));

    // Group hotspots by type ID to check if all hotspots of a type have subtypes
    const hotspotsByType = hotspots.reduce(
      (acc, hotspot) => {
        if (!acc[hotspot.hotspotTypeId!]) {
          acc[hotspot.hotspotTypeId!] = [];
        }
        acc[hotspot.hotspotTypeId!].push(hotspot);
        return acc;
      },
      {} as { [key: number]: HotspotDto[] }
    );

    // Filter and map while maintaining original order
    return types
      .filter((type) => usedTypeIds.has(type.id))
      .map((type) => {
        const typeHotspots = hotspotsByType[type.id] || [];
        const allHotspotsHaveSubtypes = typeHotspots.every((hotspot) => hotspot.hotspotSubTypeIds && hotspot.hotspotSubTypeIds.length > 0);

        const filteredSubtypes = subtypes.filter(
          (subtype) => subtype.hotspotTypeId === type.id && usedTypeSubtypePairs.has(`${type.id}:${subtype.id}`)
        );

        // Only add unspecified if there are subtypes and not all hotspots have subtypes
        const shouldIncludeUnspecified = !allHotspotsHaveSubtypes;

        const unspecifiedOption: HotspotSubType = {
          id: this.getUnspecifiedId(type.id),
          name: this.translateService.instant('common.operationTypeGroup.unspecified'),
          nameEn: this.translateService.instant('common.operationTypeGroup.unspecified'),
          nameDa: this.translateService.instant('common.operationTypeGroup.unspecified'),
          nameDe: this.translateService.instant('common.operationTypeGroup.unspecified'),
          hotspotTypeId: type.id,
        };

        return {
          id: type.id,
          type,
          subTypes: shouldIncludeUnspecified ? [unspecifiedOption, ...filteredSubtypes] : filteredSubtypes,
        };
      });
  }

  public categorizeGroups(groups: FormGroup[]) {
    return groups.reduce(
      (acc, group) => {
        const typeGroup = group.get('type') as FormGroup;
        const subTypesArray = group.get('subTypes') as FormArray;
        const isTypeSelected = typeGroup.get('selected')?.value;
        const isTypePartiallySelected = typeGroup.get('partiallySelected')?.value;

        const selectedSubTypes = this.getSelectedSubTypes(subTypesArray);

        if (isTypeSelected) {
          acc.visible.types.push(group);
        } else {
          acc.hidden.types.push(group);
        }
        if (isTypePartiallySelected) {
          acc.partiallySelected.types.push(group);
        }
        acc.visible.subTypes.push(...selectedSubTypes);
        acc.hidden.subTypes.push(...this.getUnselectedSubTypes(subTypesArray));

        return acc;
      },
      {
        visible: { types: [] as FormGroup[], subTypes: [] as AbstractControl[] },
        partiallySelected: { types: [] as FormGroup[] },
        hidden: { types: [] as FormGroup[], subTypes: [] as AbstractControl[] },
      }
    );
  }

  public getNameBasedOnLanguage(type: HotspotSubType | HotspotType): string {
    const currentLanguage = this.languageService.currentLanguage.shortKey;
    switch (currentLanguage) {
      case 'da':
        return type.nameDa ?? type.name;
      case 'de':
        return type.nameDe ?? type.name;
      default:
        return type.nameEn ?? type.name;
    }
  }

  public getIconNameById(id: number): string {
    switch (id) {
      case 1:
        return 'cf-hotspot-weeds';
      case 2:
        return 'cf-hotspot-pests';
      case 3:
        return 'cf-hotspot-diseases';
      case 4:
        return 'cf-hotspot-well';
      case 5:
        return 'cf-hotspot-drain';
      case 6:
        return 'cf-hotspot-stone';
      case 7:
        return 'cf-hotspot-miscellaneous';
      case 8:
        return 'cf-hotspot-spraywindow';
      case 9:
        return 'cf-hotspot-start';
      case 10:
        return 'cf-hotspot-fieldenter';
      case 11:
        return 'cf-hotspot-fieldexit';
      default:
        return '';
    }
  }

  public getVisibilityState(groups: FormGroup[]) {
    const { visible, partiallySelected, hidden } = this.categorizeGroups(groups);

    return {
      visibleHotspots: {
        typeIds: this.extractIds(visible.types),
        subTypeIds: this.extractIds(visible.subTypes),
      },
      partiallySelectedTypes: {
        typeIds: this.extractIds(partiallySelected.types),
      },
      hiddenHotspots: {
        typeIds: this.extractIds(hidden.types),
        subTypeIds: this.extractIds(hidden.subTypes),
      },
    };
  }

  public updateParentTypeStatus(typeGroup: FormGroup, subTypesArray: FormArray) {
    // Get all selected states of subtypes
    const subTypeStates = subTypesArray.controls.map((control) => control.get('selected')?.value);

    // Check if all subtypes are selected
    const allSelected = subTypeStates.every((state) => state === true);

    //? For some reason, despite being an array, subTypeIds is always a single-element array
    const parentTypeIdOfSubTypes = subTypesArray.controls[0]?.get('hotspotTypeId')?.value;
    const typeIdOfType = typeGroup.get('id')?.value;

    // Check if some subtypes are selected
    const someSelected = subTypeStates.some((state) => state === true) && parentTypeIdOfSubTypes === typeIdOfType;

    // Update type status
    typeGroup.patchValue({
      selected: allSelected,
      partiallySelected: !allSelected && someSelected,
    });
  }

  public updateVisibility(state: HotSpotVisibilityState) {
    this.updateHotspotVisibility(state);
  }

  private getSelectedSubTypes(subTypesArray: FormArray) {
    return subTypesArray.controls.filter((subType) => subType.get('selected')?.value);
  }

  private getUnselectedSubTypes(subTypesArray: FormArray) {
    return subTypesArray.controls.filter((subType) => !subType.get('selected')?.value);
  }

  private extractIds(controls: AbstractControl[]) {
    return controls.map((control) => control.get('id')?.value).filter(Boolean);
  }
}
