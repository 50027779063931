import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { cmShortcuts } from '@app/shared/directives/shortcut-key/cm-shortcuts.const';
import { Subscription } from 'rxjs';
import { FarmPickerService } from './farm-picker.service';

@Component({
  selector: 'app-farm-picker',
  templateUrl: './farm-picker.component.html',
  styleUrls: ['farm-picker.component.scss'],
})
export class FarmPickerComponent implements OnInit, OnDestroy {
  public readonly numberOfSelectedFarms$ = this.farmPickerService.numberOfSelectedFarms$;
  private readonly subscriptions: Subscription[] = [];
  public shortcuts = cmShortcuts;
  @Input() public minimized = false;

  constructor(private farmPickerService: FarmPickerService) {}

  public ngOnInit() {
    this.initSubscriptions();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public openFarmPickerModal() {
    this.farmPickerService.openFarmPickerModal();
  }

  private initSubscriptions() {
    this.subscriptions.push(this.farmPickerService.subscribeToFarmPickerData(), this.farmPickerService.subscribeToConsents());
  }
}
