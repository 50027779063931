import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { LayerId } from '@app/map/services/layer/layer.store';
import { filterNullOrEmpty } from '@app/shared/operators';
import { BehaviorSubject, Subscription, filter, map, pairwise, startWith, tap } from 'rxjs';
import { LayerOpacityChange, LayerOrderChange, LayerOrderForm } from './layer-order-form';

@Component({
  selector: 'app-layer-order-table',
  templateUrl: './layer-order-table.component.html',
  styleUrls: ['./layer-order-table.component.scss'],
})
export class LayerOrderTableComponent implements OnChanges, OnDestroy {
  @Input() form: FormArray<FormGroup<LayerOrderForm>> | null = null;

  @Input() actionTitle: string = '';

  @Input() id: string = '';

  @Input() connectedTo: string[] = [''];

  @Output() opacityChange = new EventEmitter<LayerOpacityChange>();

  @Output() orderChange = new EventEmitter<LayerOrderChange>();

  @Output() removeLayer = new EventEmitter<LayerId>();

  @ViewChild(MatTable) table?: MatTable<typeof this.form>;

  protected columns = ['handle', 'layer', 'opacity', 'remove'];

  protected dragDisabled = true;
  protected changeStateDisabledSubject = new BehaviorSubject<boolean>(false);

  private _sub: Subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['form'] && this.form) {
      this._sub.unsubscribe();

      this._sub = this.form.valueChanges
        .pipe(
          startWith(this.form.value), // Ensure the initial value is included in pairwise
          pairwise(),
          tap(() => this.table?.renderRows()),
          map(([prev, curr]) => this.getChangedForms(prev, curr)),
          filterNullOrEmpty(),
          filter((changes) => {
            const change = changes.first();
            if (!change) return false;

            const { prevValue, currValue } = change;
            return prevValue?.layerId === currValue?.layerId && (currValue.opacity ?? 100) !== (prevValue.opacity ?? 100);
          })
        )
        .subscribe((changes) => {
          const change = changes.first();
          if (change?.currValue && change?.prevValue) {
            this.opacityChange.emit({
              layerId: change.currValue.layerId,
              opacity: change.currValue.opacity,
            });
          }
        });
    }
  }

  private getChangedForms(prev: any, curr: any) {
    const changedForms = [];
    for (let i = 0; i < prev.length; i++) {
      if (JSON.stringify(prev[i]) !== JSON.stringify(curr[i])) {
        changedForms.push({ index: i, prevValue: prev[i], currValue: curr[i] });
      }
    }
    return changedForms;
  }
  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  protected onDrop(event: CdkDragDrop<FormArray<FormGroup<LayerOrderForm>>>) {
    // disable drag and drop for half a second to prevent spam drags
    this.changeStateDisabledSubject.next(true);
    setTimeout(() => this.changeStateDisabledSubject.next(false), 500);

    const fromContainer = event.previousContainer.data;
    const toContainer = event.container.data;
    const element = fromContainer.at(event.previousIndex);

    // mutate drop event arrays
    const replacedElement = toContainer.at(event.currentIndex);

    fromContainer.removeAt(event.previousIndex);
    toContainer.insert(event.currentIndex, element);

    if (element.value.layerId && replacedElement.value.layerId) {
      this.orderChange.emit({ layerId: element.value.layerId, replacedLayerId: replacedElement.value.layerId });
    }
  }
}
