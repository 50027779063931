import { Injectable } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { SampleResult, SoilSampleDTO } from '@app/core/repositories/soil-samples/soil-sample.interface';
import { SoilSamplesRepoService } from '@app/core/repositories/soil-samples/soil-samples-repo.service';
import { DateTime } from 'luxon';
import { SoilSampleParameterIds } from './soil-samples-analysis-parameter-ids.enum';

@Injectable({
  providedIn: 'root',
})
export class SoilSampleSideDrawerService {
  constructor(private soilSamplesRepo: SoilSamplesRepoService) {}

  public getSoilSampleGroups(farms: Farm[]) {
    const farmIds = farms ? farms.map((farm) => farm.id) : [];
    return this.soilSamplesRepo.getSoilSampleGroups(farmIds);
  }

  public getSoilSamples(farms: Farm[], dates: DateTime[]) {
    const farmIds = farms ? farms.map((farm) => farm.id) : [];
    return this.soilSamplesRepo.getSoilSamples(farmIds, dates);
  }

  public getNumberOfMeasurements(soilSamples: SoilSampleDTO[]) {
    const allResults: SampleResult[] = soilSamples.reduce(
      (previous: SampleResult[], current) => [...previous, ...current.sampleResults],
      []
    );
    return {
      reaction: this.filterResultsById(allResults, SoilSampleParameterIds.ReactionNumber).length,
      potassium: this.filterResultsById(allResults, SoilSampleParameterIds.PotassiumNumber).length,
      magnesium: this.filterResultsById(allResults, SoilSampleParameterIds.MagnesiumNumber).length,
      phosphor: this.filterResultsById(allResults, SoilSampleParameterIds.PhosphorNumber).length,
      clayPct: this.filterResultsById(allResults, SoilSampleParameterIds.ClayPercentage).length,
      humus: this.filterResultsById(allResults, SoilSampleParameterIds.Humus).length,
      copper: this.filterResultsById(allResults, SoilSampleParameterIds.CopperNumber).length,
      boron: this.filterResultsById(allResults, SoilSampleParameterIds.BoronNumber).length,
      nitrogen: this.filterResultsById(allResults, SoilSampleParameterIds.NitrogenPercentage).length,
      carbon: this.filterResultsById(allResults, SoilSampleParameterIds.CarbonPercentage).length,
      orgCarbon: this.filterResultsById(allResults, SoilSampleParameterIds.OrganicCarbon).length,
      texture: soilSamples.length,
    };
  }

  private filterResultsById(allResults: SampleResult[], id: SoilSampleParameterIds) {
    return allResults.filter((result) => result.parameterId === id);
  }
}
