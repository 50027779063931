import { Injectable } from '@angular/core';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { latest } from '@app/shared/constants/rxjs-constants';
import { filterNullOrEmpty } from '@app/shared/operators';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntities, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import { BehaviorSubject, combineLatest, map, shareReplay, tap } from 'rxjs';
import { PotassiumSSoilSampleInterval } from '../interfaces/phosphorus-samples.interface';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

interface ValidProps {
  valid: boolean;
}

interface LoadingProps {
  loading: boolean;
}

export interface PotassiumSoilSampleCorrection {
  correctionId: string;
  ktStart: number | null;
  ktEnd: number | null;
  ktValue: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class PotassiumSoilSampleCorrectionsRepository {
  constructor(private pmQuery: PrescriptionMapQuery) {}

  private readonly _store = createStore(
    { name: 'soil-sample-correction' },
    withEntities<PotassiumSoilSampleCorrection, 'correctionId'>({ idKey: 'correctionId' }),
    withProps<ValidProps>({ valid: true }),
    withProps<LoadingProps>({ loading: true })
  );

  private readonly _history = entitiesStateHistory(this._store);
  private readonly _storeHistory = stateHistory(this._store);

  public readonly soilSampleCorrection$ = this._store.pipe(selectAllEntities(), shareReplay(1));

  public readonly loading$ = combineLatest([this.pmQuery.loading$, this._store]).pipe(
    map(([pmLoading, { loading }]) => pmLoading || loading),
    shareReplay(latest)
  );

  public readonly hasPast$ = new BehaviorSubject<boolean>(false);

  public isValid$ = this._store.pipe(
    select(({ valid }) => valid),
    shareReplay(latest)
  );

  private readonly _init$ = combineLatest([
    this.pmQuery.prescriptionMapsByActiveTask$,
    this.pmQuery.PotassiumSoilSampleCorrectionIntervals$.pipe(filterNullOrEmpty()),
  ]).pipe(
    filterNullOrEmpty(),
    tap(([maps]) => maps.some((map) => map.state === 'saved' && this.clearHistory())),
    tap(() => this._history.pause()),
    tap(() => this._store.update((state) => ({ ...state, loading: true }))),
    map(([, corrections]) => corrections.map((correction) => this._createSoilSampleCorrection(correction))),
    tap((corrections) => {
      this._setStore(corrections);
    }),
    tap(() => this._store.update((state) => ({ ...state, loading: false }))),
    tap(() => this.hasPast$.next(false)),
    tap(() => this._history.resume()),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public update(corrections: (Partial<PotassiumSoilSampleCorrection> & Pick<PotassiumSoilSampleCorrection, 'correctionId'>)[]) {
    this.hasPast$.next(true);
    const storeEntities = this._store.getValue().entities;
    corrections.forEach((correction) => {
      // only update if there is a difference between the old and new value
      if (CompareHelper.compareObjectValues(storeEntities[correction.correctionId], correction)) return;

      // TODO: This is a hack to fix the issue with the history not being updated when the user changes the value of a field the first time..
      if (!this.hasHistoryOnEntity(correction.correctionId)) this._store.update(updateEntities(correction.correctionId, correction));

      this._store.update(updateEntities(correction.correctionId, correction));
    });
  }

  public updateValidity(valid: boolean) {
    this._store.update((state) => ({ ...state, valid }));
  }

  public resetHistoryOnEntity(id?: string | null) {
    if (id === null || id === undefined) return;
    this._history.jumpToPast(0, id);
    this.hasPast$.next(true);
  }

  public hasHistoryOnEntity(id?: string | null) {
    if (id === null || id === undefined) return false;
    return this._history.hasPast(id);
  }

  public resetHistory() {
    this._history.jumpToPast(0);
    this.hasPast$.next(false);
  }

  public clearHistory() {
    this._history.clearPast();
    this._history.clearFuture();

    this._storeHistory.clear((state) => ({ ...state, past: [], future: [] }));
    this._storeHistory.clear((state) => ({ ...state, past: [], future: [] }));

    this.hasPast$.next(false);
  }

  public reset() {
    this._store.reset();
    this.clearHistory();

    this.hasPast$.next(false);
  }

  private _setStore(pNeed: (PotassiumSoilSampleCorrection & Pick<PotassiumSoilSampleCorrection, 'correctionId'>)[]) {
    this._store.update(setEntities(pNeed));
  }

  private _createSoilSampleCorrection(intervals: PotassiumSSoilSampleInterval): PotassiumSoilSampleCorrection {
    return {
      correctionId: `${intervals.ktStart}-${intervals.ktEnd}`,
      ktStart: intervals.ktStart,
      ktEnd: intervals.ktEnd,
      ktValue: intervals.ktValue,
    };
  }
}
