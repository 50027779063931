import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldPlanMapSideDrawerActions } from '@app/map/features/field-plan/field-plan-side-drawer/field-plan-map-side-drawer-actions.service';
import { FieldPlanSideDrawerService } from '@app/map/features/field-plan/field-plan-side-drawer/field-plan-side-drawer.service';
import { FieldPlanService } from '@app/map/features/field-plan/field-plan.service';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { cmShortcuts } from '@app/shared/directives/shortcut-key/cm-shortcuts.const';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Subscription } from 'rxjs';
import { ShownComponentEnum } from '../../shown-component-in-side-drawer.enum';
import { FieldPlanContentService } from '../field-plan-content.service';

@Component({
  selector: 'app-create-field',
  templateUrl: './create-field.component.html',
  styleUrls: ['./create-field.component.scss'],
})
export class CreateFieldComponent implements OnDestroy, OnInit {
  public readonly harvestYear$ = this.harvestYearStateService.harvestYear$;
  private drawInteractionSubscription = new Subscription();
  private subscriptions: Subscription[] = [this.drawInteractionSubscription];
  public shortcuts = cmShortcuts;

  constructor(
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    private fieldPlanMapSideDrawerActionDispatchers: FieldPlanMapSideDrawerActions,
    private fieldPlanService: FieldPlanService,
    private fieldPlanContentService: FieldPlanContentService,
    private harvestYearStateService: HarvestYearStateService,
    private layerService: OlLayerService
  ) {}

  public ngOnInit() {
    this.fieldPlanContentService.stopWhenComponentIsDestroyed = false;
    this.fieldPlanSideDrawerService.olMapService.disableSelectInteraction();
    this.fieldPlanMapSideDrawerActionDispatchers.setMapCoverFlowItems([this.fieldPlanService.getFieldBlockCoverFlowLayersItem()]);
    //this.layerService.createTileLayer(LayerId.FIELD_BLOCKS);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onCloseClick() {
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(false);
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.fieldAdministrationComponent);
    this.fieldPlanSideDrawerService.olMapService.enableSelectInteraction();
    this.fieldPlanSideDrawerService.destroy();
  }

  public onDrawFieldClick() {
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(false);
    this.fieldPlanContentService.unSubscribeClickFromMap();
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.createFieldFormComponent);
    this.fieldPlanSideDrawerService.startDrawingPolygons();
    this.fieldPlanSideDrawerService.featureIsFromFieldBlock = false;
  }

  public onSelectFieldClick() {
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(true);
    this.fieldPlanContentService.subscribeClickOnMap();
    this.fieldPlanSideDrawerService.featureIsFromFieldBlock = true;
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.createFieldFormComponent);
  }
}
