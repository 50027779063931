<app-side-drawer-wrapper>
  <app-side-drawer-header
    cancelIconName="back"
    [showMinimizeButton]="true"
    (action)="onClose()"
    (onHide)="onHide()"
    title="{{ 'main.growthRegulation.insurance.info.header' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <app-farm-selector (farmSelected)="onFarmSelect($event)"></app-farm-selector>
    <app-insurance-info></app-insurance-info>
    <app-field-table [growthRegulation]="(growthRegulationVm$ | async)!" [farm]="selectedFarm"></app-field-table>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button (clicked)="onProceedToOrderClick()"
      >{{ 'main.growthRegulation.insurance.proceedToOrder' | translate }}
    </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</app-side-drawer-wrapper>

<app-fields [growthRegulations]="(growthRegulationVm$ | async)!"></app-fields>
