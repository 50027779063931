import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { ChartService } from '@app/core/chart/chart.service';
import { DataConnectionTypes } from '@app/core/data-connections/data-connection-types.enum';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { PrognosisDataSource } from '@app/core/enums/prognosis-data-source.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { LeafwetHoursDto, WetHourDto } from '@app/core/repositories/weather/leaf-wet-hours.interface';
import { WeatherRepoService } from '@app/core/repositories/weather/weather-repo.service';
import { DiseaseTypes } from '@app/core/repositories/weather/wet-hours-disease-types.enum';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { WetHoursDataProvider } from '@app/shared/septoria/wetHoursDataProvider';
import { WindowStateService } from '@app/state/services/window/window-state.service';
import { TranslateService } from '@ngx-translate/core';
import { CategoryAxis } from '@progress/kendo-angular-charts';
import { DateTime, Duration } from 'luxon';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { SeptoriaChartInfoComponent } from './septoria-chart-info/septoria-chart-info.component';
import { SeptoriaChartData } from './septoria-chart.interface';

@Injectable({
  providedIn: 'root',
})
export class SeptoriaChartService {
  public screenSize$ = this.windowSelector.screenSize$;
  private leafWetHoursCache = this.cacheService.create<LeafwetHoursDto>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    private weatherRepo: WeatherRepoService,
    private cacheService: CacheService,
    private languageService: LanguageService,
    private windowSelector: WindowStateService,
    private chartService: ChartService,
    private dialogService: DialogService,
    private dataConnectionsService: DataConnectionsService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}



  public getDmiLeafWetHours(farmId: number, harvestYear: number, fieldId: number): Observable<SeptoriaChartData> {
    const key = `${farmId}-${harvestYear}-${fieldId}`;
    const getValue = this.weatherRepo.getDmiLeafWetHours(farmId, harvestYear, fieldId, DiseaseTypes.Septoria);

    return this.leafWetHoursCache
      .getOrSetAsync(key, () => getValue)
      .pipe(
        map((data: LeafwetHoursDto) => {
          const dur = Duration.fromMillis(864000000);
          const observed = data.leafwetHours.filter((wetHour) => wetHour.dataSource === PrognosisDataSource.Observed);
          return {
            aggregated: data.leafwetHours,
            observed: observed,
            forecast: data.leafwetHours.filter((wetHour) => wetHour.dataSource === PrognosisDataSource.Forecast),
            colorBands: data.leafwetRanges.map((range) => ({
              color: range.color,
              from: range.min <= 0 ? 0 : range.min,
              to: range.max >= 30 ? 30 : range.max,
              startDate: range.startDate && DateTime.fromISO(range.startDate),
            })),

            // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
            protectionPeriods: data.pesticideNamesAndDatesAndQuantities.map((pesticideWrapper) => ({
              from: DateTime.fromISO(pesticideWrapper.date),
              to: DateTime.fromISO(pesticideWrapper.date)?.plus(dur),
              pesticides: pesticideWrapper.namesAndQuantities.map(
                (pesticide) => `${pesticide.name} (${+pesticide.quantity.toFixed(2)} ${pesticide.unitText})`
              ),
            })),
            labelData: {
              wetHours: observed.length > 0 ? observed.slice(-1)[0].accumulatedWetHours + '' : '0',
              dateString:
                observed.length > 0 ? DateTime.fromISO(observed.slice(-1)[0].dateDay)?.toLocaleString(DateTime.DATETIME_SHORT) : '',
            },
          } as SeptoriaChartData;
        })
      );
  }

  public getValueAxistitle() {
    return this.languageService.getText('wetHoursChart.value-axis.title');
  }

  public calculateNumberOfStepsByScreenSize(screenSize: ScreenSize, aggregatedData: WetHourDto[], categoryAxis: CategoryAxis) {
    return this.chartService.calculateNumberOfStepsByScreenSize<WetHourDto>(screenSize, aggregatedData, categoryAxis);
  }

  public showInfoModal() {
    this.dialogService.openCustomDialog(SeptoriaChartInfoComponent, {
      maxWidth: '900px',
    });
  }


}
