import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import {
  ExecutedArchiveFileUploadResponse,
  MetadataChild,
  MetadataFileDto,
  MetadataParent,
  MetadataParentDto,
  ShapefileAnalysisDto,
  ShapefileConfigInput,
  TemporalShapefileConfigInput,
} from '@app/map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { MetadataTaskDto } from '@app/map/features/field-analysis/features/as-applied/metadata-task.class';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AsAppliedFileUploadRepositoryService {
  constructor(
    public httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public uploadAsAppliedFile(farmId: number, harvestYear: number, file: File): Observable<ExecutedArchiveFileUploadResponse | null> {
    const options = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<ExecutedArchiveFileUploadResponse, FormData>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/archive/upload`,
      formData,
      options
    );
  }

  public getExecutedArchiveData(farmId: number, harvestYear: number, id: number): Observable<MetadataParentDto | null> {
    return this.httpClient.post<MetadataParentDto, null>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/archive/${id}/suggestions`,
      null
    );
  }

  public setExecutedArchiveData(
    farmId: number,
    harvestYear: number,
    id: number,
    configs: ShapefileConfigInput
  ): Observable<MetadataParentDto | null> {
    const options = {
      withCredentials: true,
    };

    return this.httpClient.post<MetadataParentDto, any>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/archive/${id}/suggestions`,
      configs.configsInput,
      options
    );
  }

  public postMetadataChildren(
    farmId: number,
    metadataParentId: number,
    metadataChildren: MetadataChild[],
    configsInput: TemporalShapefileConfigInput[] | null,
    harvestYear?: number
  ) {
    const metadataChildPostBody = metadataChildren.map((child) => new MetadataChildPostBody(child)) as any;
    var body = {
      taskInputs: metadataChildPostBody,
      configInputs: configsInput,
    };

    return this.httpClient.post<any, any>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/archive/${metadataParentId}/save`,
      body
    );
  }

  public getShapefileAnalysis(farmId: number, harvestYear: number, id: number): Observable<ShapefileAnalysisDto> {
    const options = {
      withCredentials: true,
    };

    return this.httpClient.get<ShapefileAnalysisDto>(
      `${this.endpoints.foApi}/farm/${farmId}/${harvestYear}/executed/archive/${id}/shapefile`,
      options
    );
  }

  public getMetadataFile(selectedFarmIds: number[], metadataParent: MetadataParent, harvestYear?: number): Observable<MetadataFileDto> {
    const options = {
      withCredentials: true,
    };

    return this.httpClient.get<MetadataFileDto>(
      `${this.endpoints.foApi}/farms/${selectedFarmIds.join(',')}/${harvestYear}/executed/archive/${metadataParent.id}`,
      options
    );
  }

  public getMetadataParents(selectedFarmIds: number[], harvestYear?: number): Observable<MetadataParentDto[]> {
    const options = {
      withCredentials: true,
    };

    return this.httpClient.get<MetadataParentDto[]>(
      `${this.endpoints.foApi}/farms/${selectedFarmIds.join(',')}/${harvestYear}/executed/archive`,
      options
    );
  }

  public deleteMetadataParent(metadataParentId: number, farmId: number, harvestYear: number) {
    const options = {
      withCredentials: true,
    };
    return this.httpClient.delete(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/archive/${metadataParentId}`, options);
  }

  public getTaskSuggestions(farmId: number, harvestYear: number): Observable<MetadataTaskDto[]> {
    const options = {
      withCredentials: true,
    };
    return this.httpClient.get<MetadataTaskDto[]>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/suggestion/tasks`,
      options
    );
  }

  public downloadZipFile(farmId: number, harvestYear: number, parentId: number): Observable<Blob> {
    const options = {
      withCredentials: true,
      responseType: 'blob',
    };
    return this.httpClient.get<Blob>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/executed/archive/${parentId}/download`,
      options
    );
  }
}

class MetadataChildPostBody {
  public processId: number;
  public fieldName: string;
  public date: DateTime;
  public taskId?: number;
  public featureId!: number;
  public operationTypeGroup?: number;
  public fieldYearId?: number;
  public unit: string;

  constructor(metadataChild: MetadataChild) {
    this.processId = metadataChild.processId;
    this.fieldName = metadataChild.fieldName;
    this.date = metadataChild.date;
    this.operationTypeGroup = metadataChild.metadataTask?.operationTypeGroup?.id;
    this.taskId = metadataChild.metadataTask?.taskId;
    this.fieldYearId = metadataChild.fieldYearId;
    this.unit = metadataChild.unit;
  }
}
