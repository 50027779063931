import { Pipe, PipeTransform } from '@angular/core';
import { DecimalService } from './decimal.service';

@Pipe({
  name: 'unit',
  pure: false,
})
/**
 * Use in combination with the number pipe to convert between danish and english
 */
export class AppUnitPipe implements PipeTransform {
  constructor(private decimal: DecimalService) {}

  public transform(value: string | number, unit?: string, decimals?: number, forceString: boolean = false): string {
    const parsedValue = parseFloat(value as string);
    if (isNaN(parsedValue) || forceString) return value?.toString() ?? '';

    if (!unit) {
      unit = 'kg';
    }

    if (isNaN(parsedValue)) return '';

    return this.decimal.toLocaleString(parsedValue, unit, decimals);
  }
}
