@if (!(isNaesgaard$ | async)) {
  <app-farm-picker [minimized]="(menuMinimized$ | async) ?? false" data-testid="farm-picker"></app-farm-picker>
  <app-customer-centre-button
    [minimized]="(menuMinimized$ | async) ?? false"
    data-testid="customer-centre-button"
  ></app-customer-centre-button>
  <app-user-info [minimized]="(menuMinimized$ | async) ?? false" data-testid="user-info-toggle"></app-user-info>
  <app-reminder-button [minimized]="(menuMinimized$ | async) ?? false" data-testid="reminder-button"></app-reminder-button>
}

<app-icon
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  *ngIf="!(hideMenuToggle$ | async)"
  [ngClass]="{ expand: menuMinimized$ | async }"
  icon="expand"
  class="expand-icon"
  (click)="toggleMenu()"
></app-icon>

<img
  src="assets/images/logos/SEGES_Innovation_logo_RGB_crop.svg"
  class="seges-logo"
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  [ngClass]="{ hidden: menuMinimized$ | async }"
  (click)="goToPage('https://www.seges.dk/')"
  alt="SEGES Innovation Logo"
/>
<span
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  [ngClass]="{ hidden: menuMinimized$ | async }"
  class="partners"
  (click)="openSponsorModal()"
>
  {{ 'footer.title' | translate }}
</span>
