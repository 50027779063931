<div class="header-container" [ngClass]="{ minimized: (menuMinimized$ | async) }">
  <!-- logo -->
  <img
    class="cropmanager-logo"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    src="assets/images/logos/cm_logo_green.svg"
    [ngClass]="{ 'no-click': (isNaesgaard$ | async) }"
    (click)="navigate()"
  />

  <!-- title -->
  <img
    src="assets/images/logos/cropmanager-logo.svg"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    class="big-logo"
    [ngClass]="{ 'hide-logo': (menuMinimized$ | async), 'no-click': (isNaesgaard$ | async) }"
    (click)="navigate()"
  />
</div>
