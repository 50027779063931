import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { IProduceNormItemGroup } from '@app/core/produce-norms/produce-norms.service';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { OperationLineForm } from '../../task-form.service';

@Component({
  selector: 'app-operation-line',
  templateUrl: './operation-line.component.html',
  styleUrls: ['./operation-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationLineComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public produceNormGroups: IProduceNormItemGroup[] = [];
  @Input() public operationLineForm!: FormGroup<OperationLineForm>;
  @Input() public task!: SimpleTask;
  @Input() public disabled: boolean | null = false;
  @Input() public tooltipText = '';
  @Input() public tooltipPosition: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' = 'below';

  @Output() public remove = new EventEmitter<typeof this.operationLineForm.value>();

  public operationSeedingId = OperationTypeGroupEnum.Seeding;

  public produceNorm?: ProduceNorm;

  private _quantitySub = new Subscription();
  private _totalQuantitySub = new Subscription();
  private _subs = new Subscription();

  normForm: FormGroup;

  options: ProduceNorm[] = [];

  filteredOptions: Observable<ProduceNorm[]> | undefined;
  constructor(private fb: FormBuilder) {
    this.normForm = this.fb.group({
      norm: [''],
    });

    this.normForm.controls['norm'].valueChanges.subscribe((c) => {
      if (typeof c === 'object' && c !== null) {
        this.operationLineForm?.controls['produceNormNumber'].setValue(c.number);
        this.operationLineForm?.controls['produceNormNumber'].markAsTouched();
      } else {
        this.operationLineForm?.controls['produceNormNumber'].setValue(null);
      }
    });
  }

  public ngOnInit() {
    this.initTotalQuantitySub();
    this.initQuantitySub();
    this.setProduceNormOnNumberChange();

    if (this.produceNorm) {
      this.normForm.controls['norm'].setValue(this.produceNorm);
    }

    const startingValue = this.produceNorm?.name ?? '';
    this.filteredOptions = this.normForm.controls['norm'].valueChanges.pipe(
      startWith(startingValue),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      })
    );
    const pNorms = this.produceNormGroups.flatMap((group) => group.produceNorms);
    this.options = pNorms;
  }

  displayFn(norm: ProduceNorm): string {
    return norm && norm.name ? norm.name : '';
  }

  private _filter(name: string): ProduceNorm[] {
    const filterValue = name.toLowerCase();

    return this.options.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  public ngOnChanges(changes: SimpleChanges) {
    const { task, produceNormGroups } = changes;
    if (this.produceNorm && task && task.previousValue.area !== task.currentValue.area) {
      this.updateTotalQuantity(this.operationLineForm.controls.quantity.value!);
    }

    if (produceNormGroups && produceNormGroups.currentValue.length) {
      this.produceNorm = this.getProduceNorm(this.produceNormNumberControl.value!);
    }
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public onRemoveClick() {
    this.remove.emit(this.operationLineForm.value);
  }

  /**
   * Initializes a subscription for changes on the quantity form-control.
   * The subscription is removed at the beginning, to ensure that we dont
   * get duplicate subscriptions.
   */
  public initQuantitySub() {
    this._subs.remove(this._quantitySub);

    this._quantitySub = this.operationLineForm.controls.quantity.valueChanges.subscribe(this.updateTotalQuantity.bind(this));

    this._subs.add(this._quantitySub);
  }

  /**
   * Initializes a subscription for changes on the totalQuantity form-control.
   * The subscription is removed at the beginning, to ensure that we dont
   * get duplicate subscriptions.
   */
  private initTotalQuantitySub() {
    this._subs.remove(this._totalQuantitySub);

    this._totalQuantitySub = this.operationLineForm.controls.totalQuantity.valueChanges.subscribe(this.updateQuantity.bind(this));

    this._subs.add(this._totalQuantitySub);
  }

  private setProduceNormOnNumberChange() {
    const subscription = this.produceNormNumberControl.valueChanges.subscribe((value: string | null) => {
      this.produceNorm = this.getProduceNorm(value!);
      this.operationLineForm.controls.operationTypeId.setValue(this.produceNorm?.operationTypeId);
      this.operationLineForm.controls.quantity.enable();
      this.operationLineForm.controls.totalQuantity.enable();
    });

    this._subs.add(subscription);
  }

  /**
   * Updates the value of the total-quantity form-control,
   * without updating the quantity form-control.
   */
  private updateTotalQuantity(value: number | string | null) {
    this._totalQuantitySub.unsubscribe();

    const totalQuantityControl = this.operationLineForm.controls.totalQuantity;
    totalQuantityControl.setValue(this.calculateTotalQuantity(value));

    this.initTotalQuantitySub();
  }

  /**
   * Updates the value of the quantity form-control,
   * without updating the total-quantity form-control.
   */
  private updateQuantity(value: number | string | null) {
    this._quantitySub.unsubscribe();

    const quantityControl = this.operationLineForm.controls.quantity;
    quantityControl.setValue(this.calculateQuantity(value));

    this.initQuantitySub();
  }

  /**
   * Calculates total quantity from area and quantity.
   * Returns 0 if quantity of area is null or undefined,
   * or if quantity equals 0
   */
  private calculateTotalQuantity(quantity: number | string | null): number | null {
    if (quantity === null) return null;

    const parsedQuantity = Number(quantity);

    if (!this.task.area) return null;
    if (isNaN(parsedQuantity)) return null;

    const totalQuantity = parsedQuantity * this.task.area;

    // ! We do not round number for now since it causes issue calculation issues with total vs. quantity
    //const formatted = this.decimalService.format(totalQuantity);

    // cut to high precision for now to avoid floating point round-off errors
    return Number(totalQuantity.toPrecision(5));
  }

  /**
   * Calculates quantity from area and total quantity.
   * Returns 0 if quantity of area is null or undefined,
   * or if quantity equals 0
   */
  private calculateQuantity(totalQuantity: number | string | null): number | null {
    if (totalQuantity === null) return null;

    const parsedTotalQuantity = Number(totalQuantity);

    if (!this.task.area) return null;
    if (isNaN(parsedTotalQuantity)) return null;

    const quantity = parsedTotalQuantity / this.task.area;

    // ! We do not round number for now since it causes issue calculation issues with total vs. quantity
    //const formatted = this.decimalService.format(quantity, this.produceNorm.unitText);

    // cut to high precision for now to avoid floating point round-off errors
    return Number(quantity.toPrecision(5));
  }

  public get produceNormNumberError() {
    return this.produceNormNumberControl.invalid && this.produceNormNumberControl.touched ? 'editTask.messages.productRequired' : '';
  }

  public get totalQuantityControl() {
    return this.operationLineForm.controls.totalQuantity;
  }

  public get quantityControl() {
    return this.operationLineForm.controls.quantity;
  }

  public get produceNormNumberControl() {
    return this.operationLineForm.controls.produceNormNumber;
  }

  public get taskAreaControl() {
    return this.operationLineForm.parent!.parent!.parent!.parent!.get('area');
  }

  private getProduceNorm(number: string): ProduceNorm | undefined {
    // Possible runtime change?
    const allNorms: ProduceNorm[] = this.produceNormGroups.reduce(
      (all: ProduceNorm[], norms: IProduceNormItemGroup) => all.concat(norms.produceNorms),
      []
    );

    return allNorms.find((norm) => norm.number === number);
  }
}
