<div class="control-order" [ngStyle]="{ 'right.px': (distanceFromEdge | async) }">
  <div class="toggle-control-order-btn-container">
    <button
      mat-mini-fab
      color="secondary"
      aria-label="zoom out on map"
      (click)="toggleControlOrder()"
      onclick="this.blur()"
      matTooltip="{{ 'common.tooltips.order' | translate }}"
      [matTooltipShowDelay]="1000"
      matTooltipPosition="below"
      [matTooltipDisabled]="showLayerControl()"
    >
      <mat-icon class="material-symbols-outlined">stacks</mat-icon>
    </button>
  </div>

  @if (showLayerControl()) {
    <div class="control-order-wrapper">
      <div class="control-order-header">
        <span class="layer-control-text">{{ 'main.map.layerControl.layerControl' | translate }}</span>
        <u class="layer-control-clear" (click)="removeAllGeneralLayers()">{{ 'main.map.layerControl.clear' | translate }} </u>
      </div>
      <app-layer-order-table
        data-testid="layer-order"
        [form]="form"
        (orderChange)="onOrderChange($event)"
        (opacityChange)="onOpacityChange($event)"
        (removeLayer)="onRemoveLayer($event)"
      ></app-layer-order-table>
    </div>
  }
</div>
