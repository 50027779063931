import { times } from 'lodash-es';
import Feature, { FeatureLike } from 'ol/Feature';
import { Circle } from 'ol/style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { WebGLStyle } from 'ol/style/webgl';

export const FieldStyles = {
  getFieldStyle: (): WebGLStyle[] => {
    const isSelected = ['==', ['get', 'selectedBinary'], 1];

    const blurryStyles = Array.from({ length: 3 }, (v, i) => ({
      'stroke-color': ['case', isSelected, `rgba(0, 0, 0, ${0.12 * (3 - i)})`, 'transparent'],
      'stroke-miter-limit': 4,
      'stroke-width': ['case', isSelected, 2, 0],
      'stroke-offset': ['case', isSelected, Math.min(4 + i, 8), 0], // Limits the offset to a maximum value to prevent long shadow edges
    }));

    return [
      {
        'stroke-color': '#ffffff',
        'stroke-width': ['case', isSelected, 4, 2],
        'stroke-miter-limit': 4,
        'fill-color': 'rgba(175, 175, 175, 0.1)', // needs fill to be selectable
      },
      ...blurryStyles,
    ];
  },

  getFieldPlanStyle: (feature: Feature | FeatureLike): Style[] => {
    return [
      new Style({
        stroke: new Stroke({
          color: '#ffffff',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.1)',
        }),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      }),
    ];
  },

  getSelectedFieldPlanStyle: (zoom: number, feature: Feature): Style[] => {
    const styleArray: Style[] = times(5, Number).map(
      (i) =>
        new Style({
          stroke: new Stroke({
            color: [0, 0, 0, 0.08 * i],
            width: 17 - i * 2.5,
          }),
        })
    );

    styleArray.push(
      new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 1],
          width: 2,
        }),
        fill: new Fill({
          color: [0, 0, 0, 0],
        }),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      })
    );

    return styleArray;
  },

  getFieldFillStyle: (): WebGLStyle => {
    return {
      'fill-color': ['get', 'fill'],
    };
  },

  getFieldDrawingStyle: () => {
    return [
      new Style({
        zIndex: 3,
        image: new Circle({
          radius: 6,
          stroke: new Stroke({
            color: '#FFFFFF',
            width: 1,
          }),
          fill: new Fill({
            color: '#005521',
          }),
        }),
        stroke: new Stroke({
          color: '#FFFFFF',
          width: 2,
        }),
        fill: new Fill({
          color: '#cadee1',
        }),
      }),
    ];
  },

  getTextStyle: (feature: Feature | FeatureLike): Style[] => {
    return [
      new Style({
        text: new Text({
          overflow: true,
          text: feature.get('text'),
          scale: 1.5,
          stroke: new Stroke({
            color: [0, 0, 0, 0.6],
            width: 2.5,
          }),
          fill: new Fill({
            color: [255, 255, 255, 1],
          }),
        }),
      }),
    ];
  },

  getPointsStyle: (): WebGLStyle => {
    return {
      'circle-radius': 16,
      'circle-fill-color': ['color', ['get', 'red'], ['get', 'green'], ['get', 'blue']],
      // @ts-ignore
      'circle-opacity': ['get', 'alpha'] ?? 1,
    };
  },
};
