import { CornPrognosisStyles } from '@app/helpers/map/map-styles/corn-prognosis.map.styles';
import { FieldStyles as OldFieldStyle } from '@app/helpers/map/map-styles/fields.map.styles';
import { GrowthRegulationStyles } from '@app/helpers/map/map-styles/growth-regulation.map.styles';
import { PointStyles } from '@app/helpers/map/map-styles/point.map.styles';
import { SoilSampleLabelsStyle } from '@app/helpers/map/map-styles/soil-sample.map.styles';
import { VraStyles } from '@app/helpers/map/map-styles/vra.map.styles';
import { YieldPrognosisStyles } from '@app/helpers/map/map-styles/yield-prognosis.map.styles';
import { LayerId } from '@app/map/services/layer/layer.store';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Style } from 'ol/style';
import { FieldStyles } from './field-styles';
import { HotspotStyles } from './hotspots-styles';

export function getStyle(feature: Feature | FeatureLike, zoom: number = 13): Style[] {
  switch (feature.get('layerId')) {
    case LayerId.FIELDS:
    case LayerId.FIELD_LABELS:
    case LayerId.CORN_PROGNOSIS:
      return CornPrognosisStyles.getFieldStyle(zoom, feature as Feature);
    case LayerId.GROWTH_REGULATION:
      return GrowthRegulationStyles.getFieldStyle(zoom, feature as Feature);
    case LayerId.FIELD_PLANS:
      return FieldStyles.getFieldPlanStyle(feature);
    case LayerId.SOILSAMPLE:
    case LayerId.SOILSAMPLE_LABELS:
      return SoilSampleLabelsStyle.getLabelStyle(feature as Feature);
    case LayerId.BASIS_LAYERS:
    case LayerId.HOTSPOTS:
      return HotspotStyles.getHotSpotStyle(feature.get('hotspotTypeId'));
    case LayerId.HOTSPOT_MARKERS:
      return HotspotStyles.getIconStyle(feature.get('hotspotTypeId'));
    case LayerId.VRA_PROGNOSIS:
      return VraStyles.getVraCellStyle(feature as Feature);
    case LayerId.YIELD_PROGNOSIS:
      return YieldPrognosisStyles.getFieldStyle(zoom, feature as Feature);
    case LayerId.BLIGHT_FIELDS:
      return OldFieldStyle.getBlightFieldStyle(zoom, feature as Feature);
    case LayerId.BLIGHT_POLYGONS:
      return [];
    case LayerId.BLIGHT_FIELD_POLYGONS:
      return [];
    case LayerId.HIGH_RES_POTATO_MARKERS:
      return PointStyles.getPointStyle(feature as Feature);
    case LayerId.HIGH_RES_FIELD_MARKERS:
      return OldFieldStyle.getPointsStyle(feature as Feature);
    default:
      return [];
  }
}

export function getSelectedStyle(feature: Feature | FeatureLike): Style[] {
  switch (feature.get('layerId')) {
    case LayerId.GROWTH_REGULATION:
      return GrowthRegulationStyles.getSelectedFieldStyle(13, feature as Feature);
    case LayerId.FIELDS:
    case LayerId.FIELD_LABELS:
    case LayerId.BASIS_LAYERS:
    default:
      return [];
  }
}
