import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'findReplaceDdiNames',
})
export class FindReplaceDdiNamesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  private replacements: { [key: string]: string } = {
    'Actual Mass Per Area Application Rate': 'main.fieldInspector.isoxml.ddi-names.ddi7',
    'Actual Volume Per Area Application Rate': 'main.fieldInspector.isoxml.ddi-names.ddi2',
    'Application Total Mass': 'main.fieldInspector.isoxml.ddi-names.ddi81',
    'Application Total Volume': 'main.fieldInspector.isoxml.ddi-names.ddi80',
    'Actual application of Ammonium': 'main.fieldInspector.isoxml.ddi-names.ddi402',
    'Actual application of Dry Matter': 'main.fieldInspector.isoxml.ddi-names.ddi405',
    'Actual Application Rate of Ammonium': 'main.fieldInspector.isoxml.ddi-names.ddi437',
    'Actual Volume per distance Application Rate': 'main.fieldInspector.isoxml.ddi-names.ddi634',
  };

  transform(value: string): string {
    if (!value) return value;

    // First, remove any "in [anyunit]" from the string
    value = value.replace(/ in \[[^\]]+\]/g, '').trim();

    return this.replaceWithTranslations(value);
  }

  private replaceWithTranslations(value: string): string {
    Object.keys(this.replacements).forEach((key) => {
      const index = value.lastIndexOf(key);
      if (index !== -1) {
        // Translate the replacement value
        const translationKey = this.replacements[key];
        const translated = this.translateService.instant(translationKey);

        // Replace only the last occurrence of the term
        value = value.substring(0, index) + translated + value.substring(index + key.length);
      }
    });

    return value;
  }
}
