<table
  data-testid="layer-order-table"
  *ngIf="form"
  mat-table
  cdkDropList
  #dropList="cdkDropList"
  [id]="id"
  [cdkDropListData]="form"
  [cdkDropListConnectedTo]="connectedTo"
  [dataSource]="form.controls"
  (cdkDropListDropped)="onDrop($event)"
  class="mat-elevation-z0"
>
  <!-- Handle Column -->
  <ng-container matColumnDef="handle">
    <th mat-header-cell *matHeaderCellDef></th>
    <td attr.data-testid="layer-order-drag-handle-{{ index }}" mat-cell *matCellDef="let index = index">
      @if (form.controls.length > 1) {
        <mat-icon
          cdkDragHandle
          (touchstart)="dragDisabled = false"
          (touchend)="dragDisabled = true"
          (mousedown)="dragDisabled = false"
          (mouseup)="dragDisabled = true"
          class="material-symbols-outlined drag-handle"
        >
          drag_indicator
        </mat-icon>
      } @else {
        <mat-icon class="material-symbols-outlined drag-handle disabled">drag_indicator</mat-icon>
      }
    </td>
  </ng-container>

  <!-- Layer Name Column -->
  <ng-container matColumnDef="layer">
    <td attr.data-testid="layer-order-table-layer-{{ index }}" mat-cell *matCellDef="let formGroup; let index = index">
      <div class="layer-row-layername" [ngClass]="{ 'row-disabled': !formGroup.controls.layerWithinZoom.value }">
        <div
          class="layer-color-indicator"
          [ngClass]="{ 'indicator-disabled': !formGroup.controls.layerWithinZoom.value }"
          [ngStyle]="{ 'background-color': formGroup.controls.uiColor.value, border: formGroup.controls.uiColor.value && 'unset' }"
        ></div>
        <span class="layer-row-layername-text">
          {{ formGroup.controls.layerName.value | translate }}
        </span>
        <span class="filler"></span>
      </div>
    </td>
  </ng-container>

  <!-- Opacity Column -->
  <ng-container matColumnDef="opacity">
    <th mat-header-cell *matHeaderCellDef></th>
    <td attr.data-testid="layer-order-table-opacity-{{ index }}" mat-cell *matCellDef="let formGroup; let index = index">
      <div class="layer-row-opacity">
        <button
          mat-button
          class="opacity-btn"
          [ngClass]="{ 'row-disabled': !formGroup.controls.layerWithinZoom.value }"
          #opacityBtn="matMenuTrigger"
          [matMenuTriggerFor]="opacitymenu"
          [matMenuTriggerData]="{ formControl: formGroup.controls.opacity }"
        >
          <div class="btn-content">
            <mat-icon class="material-symbols-outlined icon">opacity</mat-icon>
            <span class="layer-row-opacity-text">
              {{ 'main.map.layerControl.opacity' | translate }} {{ formGroup.controls.opacity.value }} %
            </span>
            <mat-icon class="material-symbols-outlined icon expander-icon">{{ opacityBtn.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
          </div>
        </button>
      </div>
    </td>
  </ng-container>

  <!-- Remove Column -->
  <ng-container matColumnDef="remove">
    <td attr.data-testid="layer-order-table-remove-{{ index }}" mat-cell *matCellDef="let formGroup; let index = index">
      <div class="layer-row-remove">
        @if (formGroup.controls.removable.value) {
          <button
            type="button"
            class="remove-button"
            mat-icon-button
            disableRipple="true"
            aria-label="Remove Layer"
            (click)="removeLayer.emit(formGroup.controls.layerId.value)"
          >
            <mat-icon class="material-symbols-outlined">close</mat-icon>
          </button>
        }
      </div>
    </td>
  </ng-container>

  <mat-menu #opacitymenu="matMenu" class="opacity-dropdown-menu">
    <ng-template matMenuContent let-control="formControl">
      <div class="opacity-menu-content">
        <mat-slider disableRipple min="0" max="100" class="opacity-slider">
          <input aria-label="opacity slider" matSliderThumb [formControl]="control" />
        </mat-slider>
      </div>
    </ng-template>
  </mat-menu>

  <!-- Data rows -->
  <ng-container *matRowDef="let formGroup; columns: columns">
    <tr
      mat-row
      cdkDrag
      cdkDragLockAxis="y"
      [cdkDragDisabled]="dragDisabled || (changeStateDisabledSubject | async)"
      (cdkDragReleased)="dragDisabled = true"
      matTooltip="{{ 'main.map.layerControl.zoomIn' | translate }}"
      matTooltipPosition="above"
      [matTooltipDisabled]="formGroup.controls.layerWithinZoom.value"
    >
      <td *cdkDragPlaceholder [attr.colspan]="columns.length"></td>
    </tr>
  </ng-container>

  <!-- No data row -->
  <tr *matNoDataRow>
    <td class="no-data-row" [attr.colspan]="columns.length">
      {{ 'main.map.layerControl.noLayer' | translate }}
    </td>
  </tr>
</table>
