<button mat-button class="hotspots-btn" #hotspots="matMenuTrigger" [matMenuTriggerFor]="hotspotsMenu">
  <div class="hotspots-btn-content">
    <span>{{ 'Hotspots' }}</span>
    <mat-icon class="material-symbols-outlined">{{ hotspots.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>
<mat-menu #hotspotsMenu="matMenu" class="hotspots-menu" (click)="$event.stopPropagation()">
  <ng-container *ngIf="formGroup">
    <ng-container *ngIf="!(noHotspots$ | async); else noHotspots">
      <ng-template [formGroup]="formGroup" matMenuContent class="tester" *ngIf="hasAccess$ | async; else noAccess">
        <div class="hotspots-menu-content">
          <div class="hotspots-layer-menu-header">
            <b>{{ 'common.map-controls.hotspots.title' | translate }}</b>
            <mat-icon
              class="material-symbols-outlined info-icon"
              matTooltip="{{ 'common.map-controls.hotspots.info-text-1' | translate }}

              {{ 'common.map-controls.hotspots.info-text-2' | translate }}"
              >info</mat-icon
            >
            <span class="spacer"></span>
            <u
              *ngIf="formGroup.get('anySelected')?.value"
              class="hotspots-layer-menu-hide-all"
              (click)="onClearClick(); $event.stopPropagation()"
            >
              {{ 'common.map-controls.hide-all' | translate }}
            </u>
            <u
              *ngIf="!formGroup.get('anySelected')?.value"
              class="hotspots-layer-menu-select-all"
              (click)="onSelectAllClick(); $event.stopPropagation()"
            >
              {{ 'common.map-controls.select-all' | translate }}
            </u>
          </div>
        </div>
        <!-- Main Menu Items -->
        <ng-container *ngFor="let item of groups!.controls; let i = index">
          <button
            class="hotspots-menu-item"
            mat-menu-item
            [matMenuTriggerFor]="getSubTypesFormArray(item).length > 1 ? subMenuTemp : null"
            #menuTrigger="matMenuTrigger"
            (click)="toggleTypeAndSubTypes(item.get('type')?.value, item.get('subTypes')?.value, $event, menuTrigger)"
          >
            <div class="hotspots-menu-item-selection">
              <mat-icon fontSet="cropfont-icons" [fontIcon]="item.get('type')?.get('icon')?.value"></mat-icon>
              <mat-checkbox
                class="hotspots-menu-item-selection-checkbox"
                [checked]="item.get('type')?.get('selected')?.value"
                [indeterminate]="item.get('type')?.get('partiallySelected')?.value"
              >
                <span>{{ item.get('type')?.get('name')?.value }}</span>
              </mat-checkbox>
              <mat-icon
                *ngIf="getSubTypesFormArray(item).length > 1"
                class="material-symbols-outlined right-arrow"
                (click)="foldOutSubTypes($event, menuTrigger)"
              >
                chevron_right
              </mat-icon>
            </div>
          </button>

          <!-- Submenu for the subtypes -->
          <mat-menu #subMenuTemp="matMenu" class="hotspots-submenu">
            <ng-container *ngFor="let subType of getSubTypesFormArray(item)">
              <button class="hotspots-menu-item" mat-menu-item (click)="toggleSubType(subType.value, $event)">
                <div class="hotspots-menu-item-selection">
                  <mat-checkbox [checked]="subType.get('selected')?.value">
                    <span>{{ subType.get('name')?.value }}</span>
                  </mat-checkbox>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>
<ng-template #noAccess matMenuContent>
  <div class="require-access">
    <mat-icon class="material-symbols-outlined">lock</mat-icon>
    <span>{{ 'common.map-controls.hotspots.requiredAccess' | translate }}</span>
  </div>
</ng-template>
<ng-template #noHotspots matMenuContent>
  <div class="no-hotspots">
    <mat-icon class="material-symbols-outlined">info</mat-icon>
    <span>{{ 'common.map-controls.hotspots.no-hotspots' | translate }}</span>
  </div>
</ng-template>
