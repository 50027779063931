<app-spinner *ngIf="(loading$ | async)?.isLoading" [message]="(loading$ | async)!.message" [hasBackdrop]="true"></app-spinner>

<div *ngIf="errorMessage && errorMessage.length" class="error-message">
  <p>{{ errorMessage }}</p>
</div>

<form [formGroup]="formGroup!">
  <div #fieldListWrapper>
    <kendo-grid
      #fieldList
      *ngIf="!errorMessage && errorMessage.length === 0"
      [data]="gridView!"
      [kendoGridInCellEditing]="createFormGroup"
      [inCellEditingTabNavigation]="createFormGroup"
      (keydown)="onKeyDown($event)"
      (cellClick)="editHandler($event)"
      (cellClose)="cellCloseHandler($event)"
      [rowClass]="rowCallback"
      [sortable]="{
        allowUnsort: true,
        mode: 'multiple',
      }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [selectable]="true"
      [navigable]="true"
      [height]="gridHeight!"
      kendoGridSelectBy="id"
      [selectedKeys]="selectedField"
    >
      <kendo-grid-column field="number" [headerStyle]="{ width: '25%' }" [style]="{ width: '25%' }">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ 'main.fieldAdministration.fieldList.headerColumns.fieldNumber' | translate }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <app-field-number-input kendoGridFocusable [formControl]="formGroup.controls['number']"></app-field-number-input>
          <div *ngIf="!formGroup.controls['number'].valid" #anchor>
            <kendo-popup [anchor]="anchor" [animate]="false" *ngIf="isInvalidFieldNumber || isFieldNumberInUse">
              <div class="errors-validation">
                <div *ngIf="isInvalidFieldNumber">{{ 'main.fieldAdministration.createField.fieldNoError' | translate }}</div>
                <div *ngIf="isFieldNumberInUse">{{ 'main.fieldAdministration.createField.invalidFieldNumber' | translate }}</div>
                <div *ngIf="isValidating">Validere</div>
              </div>
            </kendo-popup>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name" [headerStyle]="{ width: '60%' }" [style]="{ width: '60%' }">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ 'main.fieldAdministration.fieldList.headerColumns.fieldName' | translate }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
          <kendo-textbox
            [maxlength]="20"
            kendoGridFocusable
            [formControl]="formGroup.get(column.field)"
            placeholder="{{ 'main.fieldAdministration.fieldList.edit.placeholder.fieldName' | translate }}"
          >
            <ng-template kendoTextBoxSuffixTemplate>
              <app-icon icon="Luk" (click)="cancelCurrentCellInput()"></app-icon>
            </ng-template>
          </kendo-textbox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [class]="{ enabledColumn: true }" [headerStyle]="{ width: '10%' }" [style]="{ width: '10%' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-icon icon="edit_outline" class="edit-icon" (click)="selectedFieldClick(dataItem, true)"></app-icon>
          cd..
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridNoRecordsTemplate>
        <!-- empty message -->
      </ng-template>
    </kendo-grid>
  </div>
</form>
