<div class="my-location-btn-container">
  <button
    mat-mini-fab
    color="secondary"
    aria-label="show geoposition"
    (click)="toggleTracking()"
    onclick="this.blur()"
    matTooltip="{{ (tracking$ | async) ? ('common.tooltips.hide-location' | translate) : ('common.tooltips.show-location' | translate) }}"
    [matTooltipShowDelay]="1000"
    [matTooltipPosition]="'after'"
  >
    <mat-icon class="material-symbols-outlined" [ngClass]="{ 'active-location-btn': tracking$ | async }">my_location</mat-icon>
  </button>
</div>
