import { Injectable } from '@angular/core';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldService } from '@app/core/field/field.service';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field, FieldCollection } from '@app/core/interfaces/field.interface';
import { MapConstants } from '@app/core/map/map.constants';
import { getCoordinatesFromWktString } from '@app/helpers/map/map-helper';
import { FeatureUtil } from '@app/map/helpers/utils/feature-util';
import { LayerId } from '@app/map/services/layer/layer.store';
import { CropFilterService } from '@app/shared/map-layer-controls/map-crop-filter-control/crop-filter.service';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import _ from 'lodash';
import { isEqual } from 'lodash-es';
import Feature from 'ol/Feature';
import { asArray } from 'ol/color';
import { Extent } from 'ol/extent';
import { WKT } from 'ol/format';
import Geometry from 'ol/geom/Geometry';
import { getArea as getSphereArea } from 'ol/sphere';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { GeometryType } from '../enums/hotspot-geometry-type.enum';
import { FieldLayerItem } from './field-layer-item.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  public static PointEmpty = 'POINT EMPTY';

  constructor(
    private farmStateService: FarmStateService,
    private fieldService: FieldService,
    private harvestYearStateService: HarvestYearStateService,
    private globalStateService: GlobalStateService,
    private decimalService: DecimalService,
    private _cropFilterService: CropFilterService
  ) {}

  public subscribeFieldFeatures() {
    return combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$])
      .pipe(
        filter(([farms, harvestYear]) => !!farms && farms.length > 0 && !!harvestYear),
        withLatestFrom(this.farmStateService.fieldFeatures$),
        distinctUntilChanged((prev, cur) => {
          return isEqual(prev[0][0], cur[0][0]) && isEqual(prev[0][1], cur[0][1]);
        }),
        map(([farmAndHYear, fieldFeatures]) => farmAndHYear),
        tap(() => this.globalStateService.loadingStarted()),
        switchMap(([farms, harvestYear]) =>
          this.fieldService.getFieldsWithMetadata(
            farms.map((farm) => farm.id),
            harvestYear
          )
        ),
        switchMap((fieldsCollections: FieldCollection) => {
          return this.getFieldFeaturesFromFieldsCollection(fieldsCollections);
        }),
        tap((fieldFeatures) => {
          this.globalStateService.loadingCompleted();
          this.farmStateService.fieldFeatures = fieldFeatures;
        })
      )
      .subscribe();
  }

  /**
   * Event handler for when fields are ready. Function updates hotspots in store
   */
  public getFieldFeaturesFromFieldsCollection(fieldsCollection: FieldCollection): Observable<FieldFeatures> {
    const fields: Field[] = fieldsCollection ? fieldsCollection.fields : [];
    const fieldsLayers = this.getFieldsLayers(fields);
    const cropsArea = this._cropFilterService.getCropAreas(_.uniqBy(fields, 'number') ?? []);
    return of({
      fieldFeatures: fieldsLayers,
      cropsArea: cropsArea,
      extent: this.getExtentFromGeometry(fieldsCollection.envelope),
    });
  }

  public getFieldsLayers(fields: Field[]): FieldLayerItem[] {
    const fieldsLayers: FieldLayerItem[] = [];
    for (const field of fields) {
      if (!field || (!field.geometry && (!field.fieldBlocks || field.fieldBlocks.length === 0))) {
        continue;
      }
      const crop = this.findCurrentCrop(field.crops);
      const olColor: [number, number, number, number] =
        crop && crop.cropColor ? (asArray(crop.cropColor).slice() as [number, number, number, number]) : [255, 255, 255, 1];

      if (field.geometry) {
        fieldsLayers.push({
          coordinates: getCoordinatesFromWktString(field.geometry),
          stroke: '#FFFFFF',
          fill: olColor,
          text: `${field.number} \n ${this.decimalService.toLocaleString(field.area, 'ha')} ha`,
          fieldId: field.id,
          fieldNumber: field.number,
          isFieldBlock: false,
          fieldBlockSubDivision: '',
          field: field,
          featureId: field.featureId,
        });
      } else {
        for (const fieldBlock of field.fieldBlocks!) {
          if (!fieldBlock.geometry) {
            continue;
          }

          fieldsLayers.push({
            coordinates: getCoordinatesFromWktString(fieldBlock.geometry),
            stroke: '#FFFFFF',
            fill: olColor,
            text: field.number + fieldBlock.subDivision,
            fieldId: field.id,
            fieldNumber: field.number,
            isFieldBlock: true,
            fieldBlockSubDivision: fieldBlock.subDivision,
            field: field,
            featureId: fieldBlock.featureId,
          });
        }
      }
    }
    return fieldsLayers;
  }

  public getFieldFeatures(featureModels: FieldLayerItem[], layerId: LayerId): Feature[] {
    return featureModels.map((featureModel) => {
      const feature: Feature = FeatureUtil.getMapFeature(layerId, GeometryType.POLYGON, featureModel.coordinates);
      feature.setProperties({
        stroke: featureModel.stroke,
        fill: featureModel.fill,
        selectedBinary: 0,
        text: featureModel.text,
        field: featureModel.field,
        id: featureModel.fieldId + featureModel.fieldBlockSubDivision,
      });
      return feature;
    });
  }

  public createFeature(properties: { [key: string]: any }, id: number, geometry?: Geometry): Feature | null {
    const feature = new Feature(geometry);

    feature.setProperties(properties);
    feature.setId(id);

    return feature;
  }

  public getArea(feature: Feature) {
    if (!feature || !feature.getGeometry()) {
      return 0;
    }

    return getSphereArea(feature.getGeometry()!);
  }

  /**
   * Function returns the extent from a given geometry
   * @param geometry the geometry for which to get extent
   */
  private getExtentFromGeometry(geometry: string): Extent | null {
    if (!geometry || geometry === FeatureService.PointEmpty) {
      return null;
    }
    const wkt = new WKT();
    const feature = new Feature(
      wkt.readGeometry(geometry, {
        dataProjection: MapConstants.dataProjection,
        featureProjection: MapConstants.mapProjection,
      })
    );

    return feature.getGeometry()!.getExtent();
  }

  /**
   * Finds current crop for a given field.
   * @param crops Array of crops on field
   */
  private findCurrentCrop(crops: Crop[]): Crop | undefined {
    let found: Crop | undefined;

    for (const crop of crops) {
      if (!found || crop.successionNo < found.successionNo) {
        found = crop;
      }
    }

    return found;
  }
}
