import { NgModule } from '@angular/core';
import { SortStringPipe } from '@app/shared/pipes/sort/sort-string.pipe';
import { SharedModule } from '@app/shared/shared.module';
import { FieldAnalysisFeaturesModule } from './features/field-analysis-features.module';
import { FieldAnalysisSideDrawerModule } from './field-analysis-side-drawer/field-analysis-side-drawer.module';
import { FieldAnalysisRoutingModule } from './field-analysis.routing.module';

@NgModule({
  providers: [SortStringPipe],
  imports: [SharedModule, FieldAnalysisFeaturesModule, FieldAnalysisSideDrawerModule, FieldAnalysisRoutingModule],
})
export class FieldAnalysisModule {}
