import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { BasisLayerMapStyles } from '@app/helpers/map/map-styles/basis-layer.map.styles';
import { BenchmarkStyles } from '@app/helpers/map/map-styles/benchmark.map.styles';
import { CornPrognosisStyles } from '@app/helpers/map/map-styles/corn-prognosis.map.styles';
import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import { GrowthRegulationStyles } from '@app/helpers/map/map-styles/growth-regulation.map.styles';
import { PointStyles } from '@app/helpers/map/map-styles/point.map.styles';
import { VraStyles } from '@app/helpers/map/map-styles/vra.map.styles';
import { YieldPrognosisStyles } from '@app/helpers/map/map-styles/yield-prognosis.map.styles';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import { SoilSampleLabelsStyle } from './soil-sample.map.styles';

export function getStyle(zoom: number, size: number, feature: Feature): Style[] {
  switch (feature.get('layerId')) {
    case MapLayerId.BASIS_LAYER:
      return size > 1 ? BasisLayerMapStyles.clusteredStyle(size.toString()) : BasisLayerMapStyles.basisLayerMapStyle(feature);
    case MapLayerId.VRA_FIELDS:
      return size > 1 ? FieldStyles.getClusteredFieldStyle(size.toString()) : FieldStyles.getVRAFieldStyle(zoom, feature);
    case MapLayerId.FIELDS:
    case MapLayerId.FIELDPLANS:
      return size > 1 ? FieldStyles.getClusteredFieldStyle(size.toString()) : FieldStyles.getFieldStyle(zoom, feature);
    case MapLayerId.AS_APPLIED:
      return VraStyles.getVraCellStyle(feature);
    case MapLayerId.AS_APPLIED_POINTS:
      return PointStyles.getPointStyle(feature);
    case MapLayerId.ISOXML:
      return VraStyles.getVraCellStyle(feature);
    case MapLayerId.ISOXML_POINTS:
      return PointStyles.getPointStyle(feature);
    case MapLayerId.ISOXML_POINTS_NODATA:
      return PointStyles.getPointStyle(feature);
    case MapLayerId.BLIGHT_FIELDS:
      return size > 1 ? FieldStyles.getClusteredFieldStyle(size.toString()) : FieldStyles.getBlightFieldStyle(zoom, feature);
    case MapLayerId.DRONE_IMAGE_IMPORT:
      return size > 1 ? FieldStyles.getClusteredFieldStyle(size.toString()) : FieldStyles.getDroneFieldStyle(zoom, feature);
    case MapLayerId.GROWTHCURVE:
      return FieldStyles.getGrowthCurveFieldStyle(zoom, feature);
    case MapLayerId.REGION:
      return BenchmarkStyles.getRegionStyle(feature);
    case MapLayerId.VRA:
      return VraStyles.getVraCellStyle(feature);
    case MapLayerId.GROWTHREGULATION:
      return GrowthRegulationStyles.getFieldStyle(zoom, feature);
    case MapLayerId.YIELD_PROGNOSIS:
      return YieldPrognosisStyles.getFieldStyle(zoom, feature);
    case MapLayerId.CORNPROGNOSIS:
      return CornPrognosisStyles.getFieldStyle(zoom, feature);
    case MapLayerId.HIGH_RES_FIELD_MARKERS:
      return FieldStyles.getPointsStyle(feature);
    case MapLayerId.SOILSAMPLELABELS:
      return SoilSampleLabelsStyle.getLabelStyle(feature, zoom);
    default:
      return [];
  }
}

export function getSelectedStyle(zoom: number, size: number, feature: Feature): Style[] {
  switch (feature.get('layerId')) {
    case MapLayerId.BASIS_LAYER:
      return size > 1 ? BasisLayerMapStyles.clusteredStyle(size.toString()) : BasisLayerMapStyles.basisLayerSelectedMapStyle(feature);
    case MapLayerId.VRA_FIELDS:
      return size > 1 ? FieldStyles.getClusteredFieldStyle(size.toString()) : FieldStyles.getVRAFieldStyle(zoom, feature);
    case MapLayerId.FIELDS:
    case MapLayerId.FIELDPLANS:
      return size > 1 ? FieldStyles.getClusteredSelectedFieldStyle(size.toString()) : FieldStyles.getSelectedFieldStyle(zoom, feature);
    case MapLayerId.BLIGHT_FIELDS:
      return size > 1
        ? FieldStyles.getClusteredSelectedFieldStyle(size.toString())
        : FieldStyles.getSelectedBlightFieldStyle(zoom, feature);
    case MapLayerId.DRONE_IMAGE_IMPORT:
      return size > 1 ? FieldStyles.getClusteredSelectedFieldStyle(size.toString()) : FieldStyles.getDroneSelectedFieldStyle(zoom, feature);
    case MapLayerId.GROWTHCURVE:
      return FieldStyles.getSelectedFieldStyle(zoom, feature);
    case MapLayerId.GROWTHREGULATION:
      return GrowthRegulationStyles.getSelectedFieldStyle(zoom, feature);
    case MapLayerId.YIELD_PROGNOSIS:
      return YieldPrognosisStyles.getSelectedFieldStyle(zoom, size, feature);
    case MapLayerId.REGION:
      return BenchmarkStyles.getSelectedRegionStyle(feature);
    case MapLayerId.CORNPROGNOSIS:
      return CornPrognosisStyles.getSelectedFieldStyle(zoom, size, feature);
    default:
      return [];
  }
}
