export enum DDI {
  ACTUAL_VOLUME_PER_AREA_APPLICATION_RATE = 2,
  ACTUAL_MASS_PER_AREA_APPLICATION_RATE = 7,
  APPLICATION_TOTAL_VOLUME = 80,
  APPLICATION_TOTAL_MASS = 81,
  ACTUAL_APPLICATION_OF_AMMONIUM = 402,
  ACTUAL_APPLICATION_DRY_MATTER = 405,
  ACTUAL_APPLICATION_RATE_OF_AMMONIUM = 437,
  ACTUAL_VOLUME_PER_AREA_APPLICATION_RATE_ALT = 589,
  ACTUAL_MASS_PER_AREA_APPLICATION_RATE_ALT = 634,
}

export interface Profile {
  ID: number;
  LOG: DDI[];
  SUM: DDI[];
  disabled?: boolean;
}
