import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@app/core/http/http-client';
import { LanguageService } from '@app/core/language/language.service';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { PathNameHelper } from '@app/helpers/feature-branch/path-name-helper';
import { NEVER, Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BffAuthService {
  private readonly ANTI_FORGERY_HEADER = new HttpHeaders().append('x-csrf', '1');
  private readonly LANGUAGE_SESSION_STORAGE_KEY = 'cm-language';
  private readonly REDIRECT_HASH_SESSION_STORAGE_KEY = 'redirectUrl';
  private readonly DEFAULT_HASH = '#/';
  private sessionStorage: Storage;
  private location: Location;
  private _currentAuthUserSubject = new ReplaySubject<BffUser | null>(1);

  private _isAuthenticated = new ReplaySubject<boolean>();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private languageService: LanguageService,
    private windowRefService: WindowRefService
  ) {
    this.sessionStorage = this.windowRefService.sessionStorage;
    this.location = this.windowRefService.location;
    this.handle401();
  }

  /**
   * Sets the initial authentication state. If the user request is successful the user is already authenticated. If 401 is returned, the user is not authenticated.
   */
  private setInitialAuthenticationState() {
    this.forceGetUser()
      .pipe(
        map((user) => {
          this._currentAuthUserSubject.next(user);
          return true;
        }),
        catchError(() => {
          this._currentAuthUserSubject.next(null);
          return of(false);
        })
      )
      .subscribe((isAuthenticated) => this._isAuthenticated.next(isAuthenticated));
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  /**
   * Sets initial authentication state, and navigates the user if the state changes.
   */
  public beginAuthenticationFlow(): void {
    const isReadyForLogin = this.windowRefService.location.href.includes('/#/login');
    this.setInitialAuthenticationState();
    this.isAuthenticated$.subscribe((isAuthenticated) => {
      this.setStateAndStoreRedirectUrl(this.location.hash);
      if (isAuthenticated) {
        this.setLanguage();
        this.redirect();
        this.clearUrlAndLanguageInSessionStorage();
      } else if (!isReadyForLogin) {
        void this.router.navigate(['unauthorized']);
      }
    });
  }

  /**
   * Redirects to the BFF's login flow
   * @param returnHash: The url to return to once login is successful - if none is given, the user will be navigated to cultivation journal.
   */
  public login(returnHash?: string): void {
    this.location.href = this.addQueryParametersToUrl(this.getUri(AuthEndpoint.login), this.getLoginHttpParams(returnHash));
  }

  /**
   * Return HttpParams containing scheme and returnHash.
   * ReturnUrl is not ser if @returnHash is not defined.
   * @param returnHash: The relative url to navigate to, when login is successful
   */
  private getLoginHttpParams(returnHash?: string): HttpParams {
    if (returnHash) {
      this.setStateAndStoreRedirectUrl(returnHash);
    }
    let params = new HttpParams().append('scheme', 'AgroId');
    const featureBranchPath = this.getFeatureBranchPath();
    if (featureBranchPath) {
      params = params.append('returnUrl', featureBranchPath + '/');
    }
    return params;
  }

  public logout(): void {
    this.setInitialAuthenticationState();
    this.forceGetUser().subscribe((user: BffUser) => {
      // The BFF needs the session ID to know what user to log out.
      this.location.href = this.addQueryParametersToUrl(this.getUri(AuthEndpoint.logout), this.getLogoutHttpParam(user));
    });
  }

  /**
   * Gets the currently authenticated user object directly from the bff.
   * Return 401 if no user is authenticated.
   * @param params
   */
  public forceGetUser(params?: HttpParams): Observable<BffUser> {
    return this.httpClient
      .get<
        BffUserFragment[]
      >(this.getUri(AuthEndpoint.user), { headers: this.ANTI_FORGERY_HEADER, params: params ?? new HttpParams() }, false)
      .pipe(map((userFragments) => new BffUser(userFragments)));
  }

  public get currentUser$(): Observable<BffUser | null> {
    return this._currentAuthUserSubject.asObservable();
  }

  /**
   * Is the user logged in from Naesgaard
   */
  public get isCurrentUserFromNaesgaard$(): Observable<boolean | undefined> {
    return this._currentAuthUserSubject.pipe(map((user) => user?.isNaesgaard));
  }

  private getLogoutHttpParam(user: BffUser) {
    let param = new HttpParams();
    param = param.append('sid', user.sessionId);
    const featureBranchName = this.getFeatureBranchPath();
    if (featureBranchName) {
      param.append('returnUrl', featureBranchName + '/');
    }
    return param;
  }

  /**
   * When a 401 is received by any call, the auth state of the session is checked.
   * If the session no longer has a valid auth state, _isAuthenticated is set to false.
   */
  private handle401(): Subscription {
    // This emits only when the httpClient receives a 401 from other sources than the user call
    return this.httpClient.onForbidden
      .pipe(
        switchMap(() => this.forceGetUser()),
        catchError(() => {
          this._currentAuthUserSubject.next(null);
          this._isAuthenticated.next(false);
          return NEVER;
        })
      )
      .subscribe();
  }

  /**
   * Redirects to the saved redirect URL, or cultivation journal if nothing is saved.
   */
  private redirect(): void {
    const redirectHash = this.sessionStorage.getItem(this.REDIRECT_HASH_SESSION_STORAGE_KEY);
    if (redirectHash) {
      void this.router.navigateByUrl(redirectHash);
    } else {
      void this.router.navigate(['map/cultivation-journal'], {
        queryParams: {
          currentLanguage: `"${this.languageService.currentLanguage.shortKey}"`,
        },
      });
    }
  }

  /**
   * Sets the language to the one saved in session storage, if one is saved.
   */
  private setLanguage(): void {
    const language = this.sessionStorage.getItem(this.LANGUAGE_SESSION_STORAGE_KEY);
    if (language) {
      this.languageService.setLanguage(language);
    }
  }

  /**
   * Adds @param to @url in the correct url syntax
   * @param url - the base url
   * @param param - the query param to be added
   */
  private addQueryParametersToUrl(url: string, param: HttpParams): string {
    return param ? `${url}?${param.toString()}` : url;
  }

  /**
   * Returns the base uri, decorated with feature branch name if relevant.
   * If @endpoint exists, the uri will point to the given endpoint
   * @param endpoint: The endpoint to add to the base url.
   *
   * Example:
   * Input: AuthEndpoint.login
   * Output: https://localhost.vfltest.dk:8000/bff/login
   *
   * Input: undefined
   * Output: https://localhost.vfltest.dk:8000
   *
   * Input: AuthEndpoint.login
   * Feature branch: test-branch
   * Output: https://localhost.vfltest.dk:8000/test-branch/bff/login
   */
  private getUri(endpoint?: AuthEndpoint): string {
    if (environment.production) {
      return this.location.origin + (endpoint ?? '');
    }
    return this.location.origin + (this.getFeatureBranchPath() ?? '') + (endpoint ?? '');
  }

  private getFeatureBranchPath() {
    return PathNameHelper.getFeatureBranchRegulatedPathName(this.location.pathname.toLowerCase());
  }

  /**
   * Removes language and redirectUrl from session storage.
   */
  private clearUrlAndLanguageInSessionStorage(): void {
    this.sessionStorage.removeItem(this.LANGUAGE_SESSION_STORAGE_KEY);
    this.clearSessionStorageRedirectUrl();
  }

  private clearSessionStorageRedirectUrl() {
    this.sessionStorage.removeItem(this.REDIRECT_HASH_SESSION_STORAGE_KEY);
  }

  /**
   * Saves @redirectHash in session storage, if storage is empty and @redirectHash is longer than the base url
   * @param redirectHash
   */
  private setStateAndStoreRedirectUrl(redirectHash: string): void {
    if (!this.sessionStorage.getItem(this.REDIRECT_HASH_SESSION_STORAGE_KEY) && redirectHash.length > this.DEFAULT_HASH.length) {
      this.sessionStorage.setItem(this.REDIRECT_HASH_SESSION_STORAGE_KEY, redirectHash.substring(this.DEFAULT_HASH.length));
    }
  }
}

enum AuthEndpoint {
  'user' = '/bff/user',
  'login' = '/bff/login',
  'logout' = '/bff/logout',
}

enum UserScheme {
  'AgroId' = 'AgroId',
  'Naesgaard' = 'Naesgaard',
}

export interface BffUserFragment {
  type: string;
  value: string | number;
}

export class BffUser {
  public bffUserFragments: BffUserFragment[];
  constructor(userFragments: BffUserFragment[]) {
    this.bffUserFragments = userFragments;
  }

  public get sessionId(): string {
    return this.bffUserFragments.find((userFragment) => userFragment.type === 'sid')?.value as string;
  }

  public get scheme(): UserScheme {
    return this.bffUserFragments.find((userFragment) => userFragment.type === 'scheme')?.value as UserScheme;
  }

  public get isNaesgaard(): boolean {
    return this.scheme === UserScheme.Naesgaard;
  }

  public get name(): string {
    return this.bffUserFragments.find((userFragment) => userFragment.type === 'name')?.value as string;
  }
}
