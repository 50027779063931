<form [formGroup]="form">
  <mat-card appearance="outlined">
    <mat-card-title
      >{{ 'main.asApplied.metaData.informationAboutFieldTask' | translate }}
      <app-remove-button *ngIf="!isEditMode" (clicked)="removeChildClicked()"></app-remove-button>
    </mat-card-title>
    <mat-card-content>
      <div class="input-container">
        <!--   POINT TOGGLE   -->
        <div class="toggle-container">
          <span>{{ 'main.asApplied.metaData.showPoints' | translate }}</span>
          <mat-slide-toggle color="primary" formControlName="showPoints" [labelPosition]="'before'" (change)="togglePoints()">{{
            (form.get('showPoints')?.value ? 'main.asApplied.metaData.visible' : 'main.asApplied.metaData.hidden') | translate
          }}</mat-slide-toggle>
        </div>

        <!--  FIELD NUMBER   -->
        <mat-form-field class="field-number-form-field">
          <mat-label>{{ 'main.asApplied.metaData.fieldNumber' | translate }}</mat-label>
          <input type="text" aria-label="Number" matInput [matAutocomplete]="auto" formControlName="fieldNumber" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let field of selectableFields$ | async" [value]="field.number">
              {{ field.number }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!-- Task -->
        <ng-container *ngIf="sortedSelectableTasks$ | async as tasksGroup; else emptySelect">
          <mat-form-field [formGroup]="form">
            <mat-label>
              {{ tasksGroup.length === 0 ? 'main.asApplied.metaData.noTaskOnField' : ('main.asApplied.metaData.fieldTask' | translate) }}
            </mat-label>
            <mat-select formControlName="metadataTask" [compareWith]="compareTasks">
              <mat-option *ngFor="let task of sortedSelectableTasks$ | async" [value]="task">
                <span matTooltip="{{ task.text }}" [matTooltipDisabled]="task.text.length <= 35" matTooltipShowDelay="1000">
                  {{ task.text }}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-template #emptySelect>
          <app-select [disabled]="true" placeholder="{{ 'main.asApplied.metaData.fieldTask' | translate }}"></app-select>
        </ng-template>

        <!-- UNIT -->
        <mat-form-field>
          <mat-label>{{ 'main.asApplied.metaData.unit' | translate }}</mat-label>
          <mat-select formControlName="unit" placeholder="{{ 'main.asApplied.metaData.unit' | translate }}">
            <mat-option *ngFor="let unit of this.units" [value]="unit">
              <div>{{ unit }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--  DATE  -->
        <mat-form-field [ngClass]="{ simple: true }">
          <mat-label>{{ 'main.asApplied.metaData.date' | translate }}</mat-label>
          <input
            aria-label="Date field"
            matInput
            [matDatepicker]="picker"
            [max]="maxDate"
            (click)="picker.open()"
            autocomplete="off"
            formControlName="appliedDate"
          />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>
