import { Injectable } from '@angular/core';
import { BadRequest, BadRequestMessages } from '@app/core/messages/badRequest.interface';
import { NdvisMessages } from '@app/core/ndvis/ndvis-repo.service';
import { ConsentMessages } from '@app/core/repositories/consent/consent-repo.service';
import { DataConnectionCredentialMessages } from '@app/core/repositories/data-connection-credentials/data-connection-credentials-repo.service';
import { DataConnectionMessages } from '@app/core/repositories/data-connections/data-connections-repo.service';
import { DataExchangeMessages } from '@app/core/repositories/data-exchange/data-exchange-repo.service';
import { FarmPickerMessages } from '@app/core/repositories/farms/farms-repo.service';
import { FieldsMessages } from '@app/core/repositories/fields/fields-repo.service';
import { OperationTypeGroupsMessages } from '@app/core/repositories/operation-type-groups/operation-type-groups-repo.service';
import { ProduceNormsMessages } from '@app/core/repositories/produce-norms/produce-norms-repo.service';
import { TasksMessages } from '@app/core/repositories/tasks/tasks-repo.service';
import { TextValueMessages } from '@app/core/repositories/text-values/text-values-repo.service';
import { ShapeFileImportMessages } from '@app/map/features/hotspots/hotspot-side-drawer/shape-file-import/interfaces/messages.interface';
import { FeedbackMessages } from '@app/shared/feedback-side-drawer/feedback-side-drawer-content/feedback/repository/feedback-messages.interface';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { JohnDeereMessages } from '../interfaces/john-deere-messages.interface';

export interface CommonMessages {
  unauthorized: string;
  internalServerError: string;
}

export interface IMessageService {
  getCommonMsg(): CommonMessages;
  getDataConnectionCredentialMsg(): DataConnectionCredentialMessages;
  getDataConnectionMsg(): DataConnectionMessages;
  getFarmPickerMsg(): FarmPickerMessages;
  getTasksMsg(): TasksMessages;
  getFieldsMsg(): FieldsMessages;
  getOperationTypeGroupsMsg(): OperationTypeGroupsMessages;
  getNdvisMsg(): NdvisMessages;
  getProduceNormsMsg(): ProduceNormsMessages;
  getQualityParamsMsg(): TextValueMessages;
  getConsentMsg(): ConsentMessages;
  getDataExchangeMsg(): DataExchangeMessages;
  getHttpStatusMsg(statusCode: number, message: string, data?: BadRequest): string;
  getFeedbackMsg(): FeedbackMessages;
  getJohnDeereMsg(): JohnDeereMessages;
}

@Injectable({
  providedIn: 'root',
})
export class MessageService implements IMessageService {
  private common: CommonMessages;
  private dataConnectionCredential: DataConnectionCredentialMessages;
  private dataConnection: DataConnectionMessages;
  private farmPickers: FarmPickerMessages;
  private badRequests: BadRequestMessages;
  private produceNorms: ProduceNormsMessages;
  private fields: FieldsMessages;
  private operationTypeGroups: OperationTypeGroupsMessages;
  private ndvis: NdvisMessages;
  private tasks: TasksMessages;
  private qualityParams: TextValueMessages;
  private consent: ConsentMessages;
  private dataExchange: DataExchangeMessages;
  private feedback: FeedbackMessages;
  private shapeFileImport: ShapeFileImportMessages;
  private johnDeere: JohnDeereMessages;

  constructor(private translate: TranslateService) {
    this.common = {} as CommonMessages;
    this.dataConnectionCredential = {} as DataConnectionCredentialMessages;
    this.dataConnection = {} as DataConnectionMessages;
    this.badRequests = {} as BadRequestMessages;
    this.produceNorms = {} as ProduceNormsMessages;
    this.fields = {} as FieldsMessages;
    this.operationTypeGroups = {} as OperationTypeGroupsMessages;
    this.ndvis = {} as NdvisMessages;
    this.tasks = {} as TasksMessages;
    this.farmPickers = {} as FarmPickerMessages;
    this.qualityParams = {} as TextValueMessages;
    this.consent = {} as ConsentMessages;
    this.dataExchange = {} as DataExchangeMessages;
    this.feedback = {} as FeedbackMessages;
    this.shapeFileImport = {} as ShapeFileImportMessages;
    this.johnDeere = {} as JohnDeereMessages;

    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.translateMessages();
    });

    this.translate.onDefaultLangChange.subscribe((params: DefaultLangChangeEvent) => {
      this.translateMessages();
    });

    this.translateMessages();
  }

  public getCommonMsg(): CommonMessages {
    return this.common;
  }

  public getDataConnectionCredentialMsg(): DataConnectionCredentialMessages {
    return this.dataConnectionCredential;
  }

  public getDataConnectionMsg(): DataConnectionMessages {
    return this.dataConnection;
  }

  public getFarmPickerMsg(): FarmPickerMessages {
    return this.farmPickers;
  }

  public getProduceNormsMsg(): ProduceNormsMessages {
    return this.produceNorms;
  }

  public getFieldsMsg(): FieldsMessages {
    return this.fields;
  }

  public getOperationTypeGroupsMsg(): OperationTypeGroupsMessages {
    return this.operationTypeGroups;
  }

  public getNdvisMsg(): NdvisMessages {
    return this.ndvis;
  }

  public getTasksMsg(): TasksMessages {
    return this.tasks;
  }

  public getQualityParamsMsg(): TextValueMessages {
    return this.qualityParams;
  }

  public getConsentMsg(): ConsentMessages {
    return this.consent;
  }

  public getDataExchangeMsg(): DataExchangeMessages {
    return this.dataExchange;
  }

  public getFeedbackMsg() {
    return this.feedback;
  }

  public getShapeFileImportMsg() {
    return this.shapeFileImport;
  }

  public getJohnDeereMsg() {
    return this.johnDeere;
  }

  public getHttpStatusMsg(statusCode: number, message: string, data?: BadRequest) {
    switch (statusCode) {
      case 401:
        return this.getCommonMsg().unauthorized;
      case 500:
        return this.getCommonMsg().internalServerError;
      case 400:
        return this.getBadRequestMsg(message, data);
      default:
        return message;
    }
  }

  public getBadRequestMsg(message: string, data?: BadRequest): string {
    if (data && data.errorCode === 1102) {
      return this.badRequests.dataCannotBeChangedHarvestYearIsLocked;
    } else {
      return message;
    }
  }

  private translateMessages() {
    const commonKeys = ['messages.common.unauthorized', 'messages.common.internalServerError'];
    this.translateKeys(commonKeys, this.common);

    const dataConnectionCredentialKeys = ['messages.dataConnectionCredential.loginError'];
    this.translateKeys(dataConnectionCredentialKeys, this.dataConnectionCredential);

    const dataConnectionKeys = [
      'messages.dataConnection.getTypesError',
      'messages.dataConnection.getSettingsError',
      'messages.dataConnection.postSettingError',
      'messages.dataConnection.putSettingError',
      'messages.dataConnection.deleteSettingError',
    ];
    this.translateKeys(dataConnectionKeys, this.dataConnection);

    const produceNormsKeys = ['messages.produceNorms.getError'];
    this.translateKeys(produceNormsKeys, this.produceNorms);

    const fieldsKeys = ['messages.fields.getError'];
    this.translateKeys(fieldsKeys, this.fields);

    const operationTypeGroups = ['messages.operationTypeGroups.getError'];
    this.translateKeys(operationTypeGroups, this.operationTypeGroups);

    const ndvisKeys = ['messages.ndvis.getError'];
    this.translateKeys(ndvisKeys, this.ndvis);

    const tasksKeys = ['messages.tasks.getError', 'messages.tasks.updateError', 'messages.tasks.duplicateError'];
    this.translateKeys(tasksKeys, this.tasks);

    const badRequestsKeys = [
      'messages.badRequests.overlappingTimetracks',
      'messages.badRequests.existingTimeTrackNotStopped',
      'messages.badRequests.pauseMinutesTooBig',
      'messages.badRequests.cannotSetPauseMinutesWhenTimeTrackIsNotStopped',
      'messages.badRequests.pauseMustBeEqualOrHigherThanZero',
      'messages.badRequests.startMustBeBeforeStop',
      'messages.badRequests.startTimeTooEarly',
      'messages.badRequests.startTimeInvalidFormat',
      'messages.badRequests.stopTimeInvalidFormat',
      'messages.badRequests.dataCannotBeChangedHarvestYearIsLocked',
    ];
    this.translateKeys(badRequestsKeys, this.badRequests);

    const farmPickerKeys = [
      'messages.farmPicker.getError',
      'messages.farmPicker.deleteError',
      'messages.farmPicker.updateError',
      'messages.farmPicker.saveError',
      'messages.farmPicker.noFarmsSelected',
    ];
    this.translateKeys(farmPickerKeys, this.farmPickers);

    const dataExchangeKeys = [
      'messages.dataexchange.saveFieldError',
      'messages.dataexchange.getWorksError',
      'messages.dataexchange.saveTaskSuccess',
      'messages.dataexchange.saveTaskError',
      'messages.dataexchange.missingPermissionError',
      'messages.dataexchange.saveAllTasksError',
      'messages.dataexchange.saveAllTasksSuccess',
    ];
    this.translateKeys(dataExchangeKeys, this.dataExchange);

    const feedbackKeys = [
      'messages.feedback.saveFeedbackError',
      'messages.feedback.saveFeedbackSuccess',
      'messages.feedback.saveFileError',
    ];
    this.translateKeys(feedbackKeys, this.feedback);

    const shapeFileImportKeys = [
      'messages.shapeFileImport.saveZipFileSuccess',
      'messages.shapeFileImport.saveZipFileError',
      'messages.shapeFileImport.importError',
      'messages.shapeFileImport.importSuccess',
      'messages.shapeFileImport.geometryInvalid',
      'messages.shapeFileImport.geometryNotSimple',
      'messages.shapeFileImport.geometryUnsupported',
      'messages.shapeFileImport.missingWriteAccess',
      'messages.shapeFileImport.memberNumbersNotUnique',
      'messages.shapeFileImport.shapeFileOk',
    ];
    this.translateKeys(shapeFileImportKeys, this.shapeFileImport);

    const johnDeereKeys = [
      'messages.johnDeere.JohnDeereGwUnknown',
      'messages.johnDeere.JohnDeereGwWorkPlanExportFailed',
      'messages.johnDeere.JohnDeereGwCurrentUserHasNoConnection',
      'messages.johnDeere.JohnDeereGwMemberOfToManyOrganizations',
      'messages.johnDeere.JohnDeereGwNeedOrganization',
      'messages.johnDeere.JohnDeereGwNotFoundOrganization',
    ];
    this.translateKeys(johnDeereKeys, this.johnDeere);
  }

  private translateKeys(keys: Array<string>, resultObject: { [key: string]: any }) {
    this.translate.get(keys).subscribe((success) => {
      for (const key in success) {
        if (success.hasOwnProperty(key)) {
          const keyFragments = key.split('.');
          const resultKey = keyFragments[keyFragments.length - 1];

          resultObject[resultKey] = success[key];
        }
      }
    });
  }
}
