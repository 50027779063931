export class ScrollbarUtils {
  static get getScrollbarWidth(): number {
    // Create a div with a large width and height and overflow
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    // Create an inner div
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    // Calculate scrollbar width
    const scrollbarWidth = outer.offsetWidth - outer.clientWidth;

    // Remove the divs
    document.body.removeChild(outer);

    return scrollbarWidth;
  }
}
