import { Injectable } from '@angular/core';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { ImageItem } from '@app/core/interfaces/image-item.interface';
import { filterNullish } from '@app/shared/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AssignHotspotGroupRelation } from '../shape-file-import/interfaces/assign-hotspot-group-relation.interface';
import { HotspotRepo } from './hotspot-repo/hotspot-repo.service';
import { IHotspotService } from './hotspot.service.interface';

@Injectable()
export class HotspotService implements IHotspotService {
  constructor(private hotspotRepo: HotspotRepo) {}

  public get(farmIds: number[]): Observable<HotspotDto[]> {
    return this.hotspotRepo.get(farmIds);
  }

  public update(hotspot: HotspotDto, deletedImages: ImageItem[], newImageFiles: File[]): Observable<HotspotDto> {
    // prepare observables
    let imagesToBeSaved$: Observable<string>[];

    if (newImageFiles.length > 0 && hotspot.id) {
      imagesToBeSaved$ = newImageFiles.map((file) =>
        this.hotspotRepo.addHotspotImage(file, hotspot.id).pipe(
          filterNullish(),
          map((urls) => urls[0])
        )
      );
    } else {
      imagesToBeSaved$ = [
        new Observable((observer) => {
          observer.next(undefined);
          observer.complete();
        }),
      ];
    }

    const imagesToBeDeleted$ =
      deletedImages.length > 0
        ? deletedImages.map((imageItem) => this.hotspotRepo.deleteHotspotImage(imageItem.originalUrl))
        : [
            new Observable((observer) => {
              observer.next(undefined);
              observer.complete();
            }),
          ];

    const updateHotspot$ = this.hotspotRepo.update(hotspot);

    return forkJoin(...imagesToBeDeleted$).pipe(
      switchMap((res) => forkJoin(...imagesToBeSaved$)),
      switchMap((urls) =>
        // urls are returned as nested arrays, therefore they are flattened.
        forkJoin(of(([] as string[]).concat.apply([], urls)), updateHotspot$)
      ),
      switchMap(([urls, updatedHotspot]) => {
        // imageurls are empty on hotspot returned from server
        // therefore we populate imageurls with original urls minus deleted urls, plus newly added urls

        const imageUrls: string[] | null = hotspot.imageUrls
          ? hotspot.imageUrls
              .map((url) => {
                const img = deletedImages.find((deletedImg) => deletedImg.originalUrl === url);
                return img === undefined ? url : null;
              })
              .filter((url): url is string => url !== null)
          : null;

        if (urls.length && urls[0] && imageUrls) {
          imageUrls.push(...urls);
        }

        return of({
          ...updatedHotspot,
          imageUrls: imageUrls,
        });
      })
    );
  }

  public post(hotspot: HotspotDto, imagesToBeSaved: File[]): Observable<HotspotDto | null> {
    if (imagesToBeSaved.length === 0) {
      return this.hotspotRepo.post(hotspot);
    }

    let hotspotToReturn: HotspotDto | null;

    return this.hotspotRepo.post(hotspot).pipe(
      tap((returnedHotspot) => (hotspotToReturn = returnedHotspot)),
      switchMap((returnedHotspot) =>
        forkJoin(
          imagesToBeSaved.map((file) => {
            const imageUrl = this.hotspotRepo.addHotspotImage(file, returnedHotspot?.id);

            return imageUrl.pipe(
              filterNullish(),
              map((urls) => urls[0])
            );
          })
        )
      ),
      switchMap((urls) => of({ ...hotspotToReturn, imageUrls: urls }))
    );
  }

  public delete(id: number): Observable<number | null> {
    return this.hotspotRepo.delete(id);
  }

  public updateHotspotGroupRelations(assignHotspotGroupRelation: AssignHotspotGroupRelation): Observable<void | null> {
    return this.hotspotRepo.postHotspotGroupRelations(assignHotspotGroupRelation);
  }

  public updateSingleHotspotGroupRelations(farmId: number, hotspotId: number, groupIds: number[]): Observable<HotspotDto | null> {
    return this.hotspotRepo.postSingleHotspotGroupRelations(farmId, hotspotId, groupIds);
  }
}
