import { FilterItem } from '@app/map/features/cultivation-journal/filter/filter-item';
import { Filterable } from '@app/map/features/cultivation-journal/filter/filterable';
import { ColumnKey } from '../column/column-key';

export class FilterHelper {
  public static asAvailableFilters<T>(columnKey: ColumnKey, filterables: Filterable<T>[], filterItems: FilterItem<T>[]): Filterable<T>[] {
    return this.uniqueByTitle(this.getInnerFilterables(filterables, columnKey), columnKey).map((filterable) => {
      filterable.setIsFilterActive(filterItems, columnKey);
      return filterable;
    });
  }

  private static getInnerFilterables(filterables: Filterable<any>[], columnKey: ColumnKey): Filterable<any>[] {
    return filterables.map((filterable) => filterable.getNestedFilterables(columnKey) ?? filterable).flat();
  }

  private static uniqueByTitle(filterables: Filterable<any>[], columnKey: ColumnKey): Filterable<any>[] {
    return Array.from(new Map(filterables.map((filterable) => [filterable.getTitle(columnKey), filterable])).values());
  }

  public static isEqual<T>(filterItem: FilterItem<T>, columnKey: ColumnKey, identifier: any): boolean {
    return filterItem.columnKey === columnKey && filterItem.identifier === identifier;
  }
}
