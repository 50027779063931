<mat-nav-list class="branch-list">
  <div mat-list-item *ngIf="fo_branches$ | async as foBranches">
    <div class="close-feature-branch material-symbols-outlined" (click)="close()"><mat-icon>close</mat-icon></div>
    <ng-container>
      <p *ngIf="foBranches.length === 0; else featureFoBranch">{{ environment }}</p>

      <ng-template #featureFoBranch>
        <div class="environment-feature-indicator">
          <mat-form-field class="custom-select" appearance="outline">
            <mat-label>Field Online API</mat-label>
            <mat-select (selectionChange)="changeFoFeatureBranch($event.value)" [value]="fo_selectedBranch">
              <mat-option *ngFor="let branch of foBranches" [value]="branch">
                {{ branch }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div mat-list-item *ngIf="cm_branches$ | async as cmBranches">
    <ng-container>
      <p *ngIf="cmBranches.length === 0; else featureCmBranch">{{ environment }}</p>

      <ng-template #featureCmBranch>
        <div class="environment-feature-indicator">
          <mat-form-field class="custom-select" appearance="outline">
            <mat-label>CropManager API</mat-label>
            <mat-select (selectionChange)="changeCmFeatureBranch($event.value)" [value]="cm_selectedBranch">
              <mat-option *ngFor="let branch of cmBranches" [value]="branch">
                {{ branch }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div mat-list-item *ngIf="vra_branches$ | async as vraBranches">
    <ng-container>
      <p *ngIf="vraBranches.length === 0; else featureVraBranch">{{ environment }}</p>

      <ng-template #featureVraBranch>
        <div class="environment-feature-indicator">
          <mat-form-field class="custom-select" appearance="outline">
            <mat-label>PrescriptionMap API</mat-label>
            <mat-select (selectionChange)="changeVraFeatureBranch($event.value)" [value]="vra_selectedBranch">
              <mat-option *ngFor="let branch of vraBranches" [value]="branch">
                {{ branch }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>
    </ng-container>
  </div>
</mat-nav-list>
