<div
  class="management-actions"
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  matTooltip="{{ tooltip }}"
  matTooltipPosition="after"
  [matTooltipDisabled]="!minimized"
  matTooltipClass="nav-tooltip"
>
  <a class="action" [ngClass]="{ minimized: minimized }" (click)="handleClick()">
    <mat-icon [ngClass]="{ unread: !noReminders }" [fontSet]="'cropfont-icons'" [fontIcon]="icon"></mat-icon>
    <span class="action-text" [ngClass]="{ hidden: minimized }" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
      {{ tooltip }} <mat-icon class="material-symbols-outlined offsite-icon" *ngIf="linksToOffsite">open_in_new</mat-icon>
    </span>
  </a>
</div>
