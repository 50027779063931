import { Injectable } from '@angular/core';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { BlightTableSubLine } from '@app/map/features/cultivation-journal/blight/models/blight-table-sub-line';
import { TableFilterManager } from '@app/shared/treelist-table/table-filter-manager';
import { TableSorter } from '@app/shared/treelist-table/table-sorter';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FilterLogicService } from '../../filter/filter-logic.service';
import { FilterStateService } from '../../filter/filter-state.service';
import { ScopeItem } from '../../filter/scope-item';
import { ColumnSorter } from '../../n-tools/n-tools-table/util/ColumnSorter';
import { ColumnKeyBlight } from './../../filter/column/column-key-blight';
import { BlightRepositoryService } from './../blight-repository.service';

@Injectable()
export class BlightTableService extends TableSorter<BlightTableSubLine> {
  public get expandedIds$(): Observable<number[]> {
    return this._expandedIds.asObservable();
  }

  public set expandedIds(expandedIds: number[]) {
    this._expandedIds.next(expandedIds);
  }

  public get tableData$(): Observable<BlightTableSubLine[]> {
    return this._tableData.asObservable().pipe(
      switchMap((tableLines) => this._tableFilterManager.filterData(tableLines, this._scope)),
      switchMap((tableLines) => this.sortTableLines(tableLines))
    );
  }

  private _expandedIds = new BehaviorSubject<number[]>([]);
  private subscriptions = new Subscription();
  private _tableFilterManager: TableFilterManager<BlightTableSubLine>;
  private _tableData = new ReplaySubject<BlightTableSubLine[]>(1);
  private readonly _scope = ScopeItem.BlightTableComponent;
  constructor(
    private blightRepositoryService: BlightRepositoryService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private filterLogicService: FilterLogicService,
    private filterStateService: FilterStateService
  ) {
    super();
    this._tableFilterManager = new TableFilterManager(filterStateService, filterLogicService);
    this.initializeSorting();
    this.subscriptions.add(this.loadTableData());
  }
  protected initializeSorting() {
    this.initializeSortingForColumn(ColumnKeyBlight.fieldNumber, (array: BlightTableSubLine[], asc) =>
      array.sort((a, b) => CompareHelper.compareFieldNumbers(a.number, b.number, asc))
    );
    this.initializeSortingForColumn(ColumnKeyBlight.cropName);
    this.initializeSortingForColumn(ColumnKeyBlight.productName);

    this.initializeSortingForColumn(ColumnKeyBlight.latestTreatment, (array: BlightTableSubLine[], asc) =>
      array.sort((a, b) => CompareHelper.compare(a.latestTreatmentDate, b.latestTreatmentDate, asc))
    );

    this.sortChange(this.getColumnSorterByKey(ColumnKeyBlight.fieldNumber) as ColumnSorter<BlightTableSubLine>);
  }
  public getAvailableFilterablesForColumn$(columnKey: ColumnKeyBlight) {
    return this._tableFilterManager.getAvailableFilterablesForColumn$(columnKey, this._scope);
  }
  public toggleFilterItem(tableLine: BlightTableSubLine, columnKey: ColumnKeyBlight) {
    this._tableFilterManager.toggleFilterItem(tableLine, columnKey, this._scope);
  }

  private loadTableData() {
    this.subscriptions.add(
      combineLatest([this.harvestYearStateService.harvestYear$, this.farmStateService.selectedFarmIds$])
        .pipe(
          switchMap(([harvestYear, farmIds]) => this.blightRepositoryService.get(farmIds, harvestYear!)),
          map((blightTableSubLines) => blightTableSubLines.map((subLine) => new BlightTableSubLine(subLine)))
        )
        .subscribe((tableData) => this._tableData.next(tableData))
    );
  }
}
