import { Component, OnInit } from '@angular/core';
import { ButtonParentComponent } from '@fe-shared/ui-components/buttons/button-parent-component';

@Component({
  selector: 'app-round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.scss'],
})
export class RoundButtonComponent extends ButtonParentComponent implements OnInit {
  public ngOnInit() {}
}
