<div class="no-consent-content">
  <h1 class="seges-view-title">
    <div class="highlight">{{ 'consent.headline' | translate }}</div>
    <div>{{ 'consent.missing' | translate }}</div>
  </h1>

  <div class="consents">
    <div *ngFor="let consent of consents" class="consent">
      <div class="title-wrapper">
        <div class="title">{{ consent.displayName }}</div>
        <a (click)="onGetConsentClicked(consent)">{{ 'consent.showDeclaration' | translate }}</a>
      </div>
      <a *ngIf="consent.statusId === consentTypes.Given" class="button" (click)="onGetConsentClicked(consent)">
        <app-checkbox [checked]="true"></app-checkbox>
      </a>
      <a *ngIf="consent.statusId !== consentTypes.Given" class="button inactive" (click)="onGetConsentClicked(consent)">
        <app-checkbox [checked]="false"></app-checkbox>
      </a>
    </div>
    <div class="accept-container">
      <button mat-button class="check-btn" (click)="checkAccepted()">
        {{ 'consent.consentAccepted' | translate }}
      </button>
    </div>
  </div>
</div>
