<div class="product-form">
  <form [formGroup]="normForm">
    <mat-form-field class="example-full-width">
      <mat-label>{{ 'editTask.productsAndActions.product' | translate }} </mat-label>
      <input type="text" matInput formControlName="norm" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">
            {{ option.name }}
            <span *ngIf="option.operationTypeGroupId === operationSeedingId">({{ option.varietyGroupName }})</span>
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <form class="product-form" [formGroup]="operationLineForm">
    <div class="quantity-input">
      <app-input
        type="text"
        formControlName="quantity"
        [localize]="true"
        onlyNumbers
        decimalSeparator="{{ 'common.decimalSeparator' | translate }}"
        [maxlength]="8"
        [testId]="'quantity'"
      >
        <app-input-error *ngIf="quantityControl.hasError('required')"></app-input-error>
        <app-input-error *ngIf="quantityControl.hasError('number')"></app-input-error>
        <app-input-error *ngIf="quantityControl.hasError('greaterThan')"></app-input-error>
      </app-input>
      <label class="label"
        ><span *ngIf="produceNorm">{{ produceNorm.unitText }}&#47;{{ 'common.hectareShort' | translate }}</span></label
      >
    </div>
    <div class="quantity-input">
      <app-input
        type="text"
        formControlName="totalQuantity"
        [localize]="true"
        onlyNumbers
        decimalSeparator="{{ 'common.decimalSeparator' | translate }}"
        [maxlength]="8"
        [testId]="'totalQuantity'"
      >
        <app-input-error *ngIf="totalQuantityControl.hasError('required') && totalQuantityControl.touched"></app-input-error>
        <app-input-error *ngIf="totalQuantityControl.hasError('number')"></app-input-error>
        <app-input-error *ngIf="totalQuantityControl.hasError('greaterThan')"></app-input-error>
      </app-input>
      <label class="label"
        ><span *ngIf="produceNorm">{{ produceNorm.unitText }}</span></label
      >
    </div>

    <div class="remove-btn" *ngIf="!disabled">
      <app-remove-button
        [toolTipDelay]="'long'"
        [tooltipText]="tooltipText"
        [tooltipPosition]="tooltipPosition"
        (clicked)="onRemoveClick()"
      ></app-remove-button>
    </div>
  </form>
</div>

<div class="errors">
  <div *ngIf="taskAreaControl!.invalid">{{ 'editTask.messages.taskAreaInvalid' | translate }}</div>
  <div *ngIf="totalQuantityControl.hasError('required') && totalQuantityControl.touched">
    {{ 'editTask.messages.totalQuantityRequired' | translate }}
  </div>
  <div *ngIf="totalQuantityControl.hasError('number')">{{ 'editTask.messages.totalQuantityNumbersOnly' | translate }}</div>
  <div *ngIf="totalQuantityControl.hasError('greaterThan')">{{ 'editTask.messages.totalQuantityGreaterThanZero' | translate }}</div>
  <div *ngIf="quantityControl.hasError('required') && quantityControl.touched">{{ 'editTask.messages.quantityRequired' | translate }}</div>
  <div *ngIf="quantityControl.hasError('number')">{{ 'editTask.messages.quantityNumbersOnly' | translate }}</div>
  <div *ngIf="quantityControl.hasError('greaterThan')">{{ 'editTask.messages.quantityGreaterThanZero' | translate }}</div>
  <div *ngIf="produceNormNumberControl.hasError('required') && produceNormNumberControl.touched">
    {{ produceNormNumberError | translate }}
  </div>
</div>
