<div class="flex-container flex-col wrapper">
  <div class="ndvi-content">
    <h5 class="info">{{ (headerTranslateKey$ | async)! | translate }}</h5>
    <div class="image-selector-buttons" *ngIf="showImageSelectorButtons$ | async">
      <app-square-button
        matTooltip="{{ 'mapFieldInspectorItem.ndviInfo.clearSkyHover' | translate }}"
        [buttonStyle]="
          (imagesFromClearSky$ | async)
            ? { background: buttonColorCode, border: buttonColorCode, 'border-radius': '10px' }
            : {
                background: buttonColorCodeNotSelected,
                border: buttonColorCodeNotSelected,
                'border-radius': '10px',
                color: textColorCodeNotSelected,
              }
        "
        [buttonType]="'Primary'"
        (clicked)="onClearSkyClicked()"
        >{{ 'mapFieldInspectorItem.ndviInfo.clearSky' | translate }}
      </app-square-button>
      <app-square-button
        matTooltip="{{ 'mapFieldInspectorItem.ndviInfo.satelliteHover' | translate }}"
        [buttonStyle]="
          !(imagesFromClearSky$ | async)
            ? { background: buttonColorCode, border: buttonColorCode, 'border-radius': '10px' }
            : {
                background: buttonColorCodeNotSelected,
                border: buttonColorCodeNotSelected,
                'border-radius': '10px',
                color: textColorCodeNotSelected,
              }
        "
        [buttonType]="'Primary'"
        (clicked)="onSatelliteClicked()"
        >{{ 'mapFieldInspectorItem.ndviInfo.satellite' | translate }}
      </app-square-button>
    </div>
    <div class="seges-calendar">
      <app-spinner *ngIf="loadingState.isLoading$ | async" [message]="(loadingState.message$ | async)!" [hasBackdrop]="true"></app-spinner>
      <kendo-calendar
        [max]="maxDate"
        [min]="minDate"
        [disabledDates]="disabledDates"
        [(ngModel)]="startDate"
        (valueChange)="onDateSelect($event)"
      >
        <ng-template kendoCalendarMonthCellTemplate let-date>
          <app-ndvi-date-img [ndviImageDatesMap]="ndviImageDatesMap" [date]="date"></app-ndvi-date-img>
        </ng-template>
      </kendo-calendar>
    </div>

    <div class="tile-type-dropdown" *ngIf="imagesFromClearSky$ | async">
      <app-select
        matTooltip="{{
          (selectedTileType$ | async) === 15
            ? ('mapFieldInspectorItem.ndviInfo.rgbHover' | translate)
            : '' || (selectedTileType$ | async) === 13
              ? ('mapFieldInspectorItem.ndviInfo.ndviHover' | translate)
              : '' || (selectedTileType$ | async) === 14
                ? ('mapFieldInspectorItem.ndviInfo.ndreHover' | translate)
                : ''
        }}"
        data-testid="ndvi-component-dropdown-select"
        [ngModel]="selectedTileType$ | async"
        placeholder="{{ 'mapFieldInspectorItem.ndviInfo.tileTypeDropDown' | translate }}"
        (valueChange)="selectTilType($event)"
      >
        <app-icon matPrefix icon="home_outline"></app-icon>
        <app-select-option
          attr.data-testid="select-option-{{ index }}"
          *ngFor="let tileType of clearSkyTileTypes; let index = index; trackBy: farmsTrackByFn"
          [value]="tileType.type"
        >
          {{ tileType.translationName | translate }}
        </app-select-option>
      </app-select>
    </div>

    <app-growth-curve *hasAccessTo="'field_analysis_biomass_benchmark'"></app-growth-curve>
  </div>
</div>
