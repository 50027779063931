<div class="map-layer-control-wrapper">
  <div class="map-layer-top-controls" *ngIf="!(isMobile$ | async)">
    <app-map-layer-generel-layers-selector
      matTooltip="{{ 'common.tooltips.select-layers' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'below'"
    ></app-map-layer-generel-layers-selector>
    <app-map-layer-control-biomass
      *ngIf="!(hideBiomassSelector$ | async)"
      matTooltip="{{ 'common.tooltips.select-biomass' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'below'"
    ></app-map-layer-control-biomass>
    <app-map-layer-control-soilsample
      *ngIf="!(isNaesgaard$ | async) && !(hideSoilsampleSelector$ | async)"
      matTooltip="{{ 'common.tooltips.select-soilsamples' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'below'"
    ></app-map-layer-control-soilsample>
    <app-map-crop-filter-control
      *ngIf="!(isNaesgaard$ | async) && !(hideCropFilter$ | async)"
      matTooltip="{{ 'common.tooltips.cropfilter' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'below'"
    ></app-map-crop-filter-control>
    <app-map-layer-control-hotspots
      *ngIf="!(isNaesgaard$ | async)"
      matTooltip="{{ 'common.tooltips.hotspots' | translate }}"
      [matTooltipShowDelay]="1000"
      [matTooltipPosition]="'below'"
    ></app-map-layer-control-hotspots>
  </div>
  <div class="map-layer-order-control">
    <app-map-layer-control-order *ngIf="!(isMobile$ | async)"> </app-map-layer-control-order>
  </div>
  <div class="map-layer-legend-control">
    <app-map-layer-control-legend *ngIf="!(isMobile$ | async)"></app-map-layer-control-legend>
  </div>
</div>
