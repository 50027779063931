import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PanelCloseComponent } from '@app/map/features/panel-close/panel-close.component';
import { PanelOpenComponent } from '@app/map/features/panel-open/panel-open.component';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { SideDrawerBackdropComponent } from '@app/shared/side-drawer/side-drawer-backdrop/side-drawer-backdrop.component';
import { SideDrawerContentComponent } from '@app/shared/side-drawer/side-drawer-content/side-drawer-content.component';
import { SideDrawerSubHeaderComponent } from '@app/shared/side-drawer/side-drawer-sub-header/side-drawer-sub-header.component';
import { SideDrawerToolbarButtonComponent } from '@app/shared/side-drawer/side-drawer-toolbar/side-drawer-toolbar-button/side-drawer-toolbar-button.component';
import { SideDrawerToolbarComponent } from '@app/shared/side-drawer/side-drawer-toolbar/side-drawer-toolbar.component';
import { SideDrawerComponent } from '@app/shared/side-drawer/side-drawer.component';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { SideDrawerBodyComponent } from './side-drawer-body/side-drawer-body.component';
import { SideDrawerFooterButtonComponent } from './side-drawer-footer/side-drawer-footer-button/side-drawer-footer-button.component';
import { SideDrawerFooterComponent } from './side-drawer-footer/side-drawer-footer.component';
import { SideDrawerHeaderButtonComponent } from './side-drawer-header/side-drawer-header-button/side-drawer-header-button.component';
import { SideDrawerHeaderComponent } from './side-drawer-header/side-drawer-header.component';
import { SideDrawerInfoHoverComponent } from './side-drawer-info-hover/side-drawer-info-hover.component';
import { SideDrawerWrapperComponent } from './side-drawer-wrapper/side-drawer-wrapper.component';

@NgModule({
  declarations: [
    SideDrawerComponent,
    SideDrawerBackdropComponent,
    SideDrawerContentComponent,
    SideDrawerToolbarComponent,
    SideDrawerToolbarButtonComponent,
    SideDrawerHeaderComponent,
    SideDrawerHeaderButtonComponent,
    SideDrawerFooterComponent,
    SideDrawerFooterButtonComponent,
    SideDrawerSubHeaderComponent,
    SideDrawerInfoHoverComponent,
    SideDrawerBodyComponent,
    SideDrawerWrapperComponent,
    PanelOpenComponent,
    PanelCloseComponent,
  ],
  exports: [
    SideDrawerComponent,
    SideDrawerToolbarComponent,
    SideDrawerToolbarButtonComponent,
    SideDrawerHeaderComponent,
    SideDrawerHeaderButtonComponent,
    SideDrawerFooterComponent,
    SideDrawerFooterButtonComponent,
    SideDrawerSubHeaderComponent,
    SideDrawerInfoHoverComponent,
    SideDrawerBodyComponent,
    SideDrawerWrapperComponent,
    PanelOpenComponent,
    PanelCloseComponent,
  ],
  imports: [CommonModule, ButtonsModule, OverlayModule, PortalModule, IconModule, MatIconModule],
})
export class SideDrawerModule {}
