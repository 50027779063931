import { Component, ElementRef, HostListener, OnDestroy, OnInit, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { OlLayerQuery } from '@app/map/services/layer/layer.query';
import { OlLayerService } from '@app/map/services/layer/layer.service';
import { LayerId } from '@app/map/services/layer/layer.store';
import { OlMapService } from '@app/map/services/map/ol-map.service';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { debounceTime, map, tap, withLatestFrom } from 'rxjs';
import { LayerIDHelper } from '../helpers/Layer-id-helper';
import { MapControlBiomassService } from '../map-layer-control-biomass/map-control-biomass.service';
import { MapControlHotspotsService } from '../map-layer-control-hotspots/map-layer-control-hotspots.service';
import { MapControlSoilsampleService } from '../map-layer-control-soilsample/map-control-soilsample.service';
import { MapLayerControlService } from '../map-layer-control.service';
import { LayerOpacityChange, LayerOrderChange, LayerOrderForm } from './layer-order-table/layer-order-form';

@Component({
  selector: 'app-map-layer-control-order',
  templateUrl: './map-layer-control-order.component.html',
  styleUrls: ['./map-layer-control-order.component.scss'],
})
export class MapLayerControlOrderComponentComponent implements OnInit, OnDestroy {
  protected showLayerControl = signal<boolean>(false);
  constructor(
    private layerService: OlLayerService,
    private layerQuery: OlLayerQuery,
    private mapService: OlMapService,
    private mapLayerControlService: MapLayerControlService,
    private biomassService: MapControlBiomassService,
    private soilsampleService: MapControlSoilsampleService,
    private hotspotsService: MapControlHotspotsService,
    private eRef: ElementRef
  ) {}

  protected distanceFromEdge = this.mapLayerControlService.sidedrawerWidth$;

  protected readonly form = new FormArray<FormGroup<LayerOrderForm>>([]);

  private _subscriptions = new SubscriptionArray();

  ngOnInit(): void {
    this._subscriptions.add(this._initializeForm());
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  protected onOpacityChange(data: LayerOpacityChange) {
    this.layerService.setLayerOpacity(data.layerId, data.opacity);
  }

  protected onOrderChange(data: LayerOrderChange) {
    this.layerService.moveLayerOrder(data.layerId, data.replacedLayerId);
  }

  protected onRemoveLayer(data: LayerId) {
    this.layerService.setLayerVisibility(data, false);

    if (data === LayerId.SOILSAMPLE) {
      this.soilsampleService.resetForm();
    }
    if (data === LayerId.BIOMASS) {
      this.biomassService.resetForm();
    }
    if (data === LayerId.HOTSPOTS) {
      this.hotspotsService.selectAllHotspotsSubject.next(false);
    }
  }

  protected removeAllGeneralLayers() {
    this.layerService.resetAllGeneralAndOverlayLayers();

    this.biomassService.resetForm();
    this.soilsampleService.resetForm();
    this.hotspotsService.selectAllHotspotsSubject.next(false);
  }

  protected toggleControlOrder() {
    this.showLayerControl.set(!this.showLayerControl());
  }

  private _initializeForm() {
    return this.layerQuery.orderedMapLayersUniqueByActivePage$
      .pipe(
        debounceTime(100),
        map((layers) => layers.filter((layer) => layer.group !== 'permanent' && layer.adjustable && layer.enabled)),
        withLatestFrom(this.mapService.zoomLevel$),
        withLatestFrom(this.layerQuery.activePage$),
        tap(([[layers, zoom], activePage]) => {
          this.form.clear();
          layers.forEach((layer) => {
            const disabledRemovable = layer.disableRemovalOnPage?.includes(activePage) ?? false;
            this.form.push(
              new FormGroup<LayerOrderForm>({
                layerId: new FormControl(layer.id, { nonNullable: true }),
                opacity: new FormControl(layer.opacity, { nonNullable: true }),
                layerName: new FormControl(LayerIDHelper.getDefaultLayerLabelFromId(layer.id), { nonNullable: true }),
                uiColor: new FormControl(layer.uiColor, { nonNullable: true }),
                removable: new FormControl((layer.group === 'generel' || layer.group === 'overlay') && !disabledRemovable, {
                  nonNullable: true,
                }),
                layerWithinZoom: new FormControl((layer.layerObjects[0].getMinZoom() ?? 0) <= zoom, { nonNullable: true }),
              })
            );
          });
        })
      )
      .subscribe();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (this.showLayerControl() && !this.eRef.nativeElement.contains(event.target)) {
      const isSliderOrCdk =
        (event.target as HTMLElement).classList.contains('cdk-overlay-backdrop') ||
        (event.target as HTMLElement).classList.contains('mdc-slider__input');

      !isSliderOrCdk && this.showLayerControl.set(false);
    }
  }
}
