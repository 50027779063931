import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import { Layer } from 'ol/layer';
import { BehaviorSubject } from 'rxjs';

export type PagesWithLayer =
  | 'none'
  | 'all'
  | 'cultivation-journal'
  | 'field-plan'
  | 'vra'
  | 'prognosis'
  | 'prognosis_yield'
  | 'prognosis_blight'
  | 'prognosis_corn'
  | 'prognosis_growth'
  | 'hotspots'
  | 'basis-layers'
  | 'yield-benchmark'
  | 'as-applied'
  | 'field-analysis'
  | 'iso-xml-data-visualizer'
  | 'biomass'
  | 'soil-sample';

export type LayerGroup = 'permanent' | 'generel' | 'overlay' | 'field' | 'cell-draw';

export interface LayerBundle {
  id: LayerId;
  layerObjects: Layer[];
  opacity: number; // 0-100
  order: number;
  group: LayerGroup;
  adjustable: boolean;
  enabled: boolean;
  allowedPages: PagesWithLayer[];
  translationKey: string;
  uiColor: string;
  disableRemovalOnPage?: PagesWithLayer[];
}

export enum LayerId {
  NONE = 0,
  OSM = 1,
  AIRPHOTO_DK = 2,
  AIRPHOTO_FOREIGN = 3,
  GEO_LOCATION = 4,
  BES_NATURE = 5,
  BES_WATERCOURSES = 6,
  BES_STONES = 7,
  BUFFER_MARGINS = 8,
  GLM_MEMORIES = 9,
  GLM_LAKES = 10,
  FIELD_SHRUBS = 11,
  BNBO_STATUS = 12,
  DIGI_SOIL = 13,
  GEO_SOIL = 14,
  FIELD_BLOCKS = 15,
  FIELD_PLANS = 16,
  FIELDS = 17,
  FIELD_LABELS = 18,
  FIELD_FILL = 19,
  FIELD_MARKERS = 20,
  HOTSPOTS = 21,
  HOTSPOT_MARKERS = 22,
  BIOMASS = 23,
  VRA_CELLS = 24,
  BASIS_LAYERS = 25,
  HEIGHT_MAP = 26,
  SHADOW_MAP = 27,
  CELL_HOVER = 28,
  CELL_DRAW = 29,
  SOILSAMPLE = 30,
  SOILSAMPLE_LABELS = 31,
  GROWTH_REGULATION = 32,
  CORN_PROGNOSIS = 33,
  HIGH_RES_FIELD_MARKERS = 34,
  HIGH_RES_POTATO_MARKERS = 35,
  BLIGHT_FIELDS = 36,
  BLIGHT_POLYGONS = 37,
  BLIGHT_FIELD_POLYGONS = 38,
  POTATO_BLIGHT = 39,
  VRA_PROGNOSIS = 40,
  YIELD_PROGNOSIS = 41,
  AS_APPLIED = 42,
  AS_APPLIED_POINTS = 43,
  ISOXML = 44, // ? Only for map-cover-flow item
  ISOXML_POINTS = 45,
  ISOXML_POINTS_NODATA = 46,
  CLAY = 48,
  HUMUS = 49,
  DEXTER = 50,
}

export interface LayerState {
  entities: Record<LayerId, LayerBundle>;
  ids: LayerId[];
}
@Injectable({ providedIn: 'root' })
export class OlLayerStore {
  public readonly store = createStore({ name: 'map-layers' }, withEntities<LayerBundle>());
  public readonly entitiesHistory = entitiesStateHistory(this.store);
  public readonly stateHistory = stateHistory(this.store);
  public activePageSubject = new BehaviorSubject<PagesWithLayer>('all');

  constructor() {}
}
