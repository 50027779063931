import { Injectable } from '@angular/core';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { Unit } from '@app/core/interfaces/unit.type';
import { createStore, withProps } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory } from '@ngneat/elf-state-history';
import { PotassiumSoilSampleCorrections, SoilSampleCorrections } from '../../interfaces/phosphorus-samples.interface';
import { PrescriptionMap, SatelliteImageSource } from '../../interfaces/prescription-map.interface';
import { LimeSetting, LimeTypeNeed } from '../../interfaces/vra-lime-setting.interface';

export interface Statistics {
  avg: number;
  total: number;
}

export interface UnitProps {
  unit: Unit | null;
}

interface LoadingProps {
  preLoading: boolean;
}

interface SatelliteImageSourceProps {
  satelliteImageSource: SatelliteImageSource;
}

export interface OperationTypeGroup {
  operationTypeGroup: OperationTypeGroupEnum | undefined | null;
}

@Injectable({ providedIn: 'root' })
export class PrescriptionMapStore {
  constructor() {}

  public readonly store = createStore(
    { name: 'prescription-maps' },
    withEntities<PrescriptionMap>(),
    withProps<Statistics>({ avg: 0, total: 0 }),
    withProps<UnitProps>({ unit: null }),
    withProps<OperationTypeGroup>({ operationTypeGroup: null }),
    withProps<LimeSetting>({ cutoff: 0, limeTypeNeed: LimeTypeNeed.None }),
    withProps<SoilSampleCorrections>({ intervals: [] }),
    withProps<PotassiumSoilSampleCorrections>({ potassiumIntervals: [] }),
    withProps<LoadingProps>({ preLoading: false }),
    withProps<SatelliteImageSourceProps>({ satelliteImageSource: SatelliteImageSource.Cloudless })
  );

  public readonly history = entitiesStateHistory(this.store);

  // private readonly _persist = persistState(this._store, { key: 'prescription-maps', storage: sessionStorageStrategy });
}
